/*
---
name: sw-Heading4B
namespace: SiteWide
category: Heading
id: [K2, HD-6B]
---

<span class="sw-Label">HD-6B</span>

`<h4>`で使用する見出しです。余白には`.st-Heading4`を使用します。`.sw-Heading4A`と違いボーダーは入りません。

```html
<div class="st-Wrapper">
<h4 class="sw-Heading4B st-Heading4">京都バイオ計測センター</h4>
</div>
```

```html
<div class="st-Wrapper">
<h4 class="sw-Heading4B st-Heading4">ここにテキストが入ります<br>改行した場合のレイアウトです</h4>
</div>
```
*/
.sw-Heading4B {
  font-family: $font-family-heading;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(18, lg);
  }
}
