/*
---
name: st-LocalNav2
namespace: Structure
category: Navigation
id: [K1, NV1-2]
---

<span class="sw-Label">NV1-2</span>

ローカルナビゲーションです。

- 表示中のページと同じリンクはカレント表示されます。
- 10カラム幅内でボタン等幅分割
- グループB
  - オフィス・ラボ下層
  - ホール・会議室下層のみ
- 

```html
<header class="st-LocalNav2_Container">
  <div class="st-Wrapper">
    <div class="st-LocalNav2">
      <ul class="st-LocalNav2_Items">
        <li class="st-LocalNav2_Item">
          <a href="/" class="st-LocalNav2_Link">
            <span class="st-LocalNav2_Icon" aria-hidden="true"></span>
            <span class="st-LocalNav2_LinkText">選ばれる理由</span>
          </a>
        </li>
        <li class="st-LocalNav2_Item">
          <a href="/outline/" class="st-LocalNav2_Link">
            <span class="st-LocalNav2_Icon" aria-hidden="true"></span>
            <span class="st-LocalNav2_LinkText">職場環境</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
```
*/
.st-LocalNav2_Container {
  display: none;

  @include mq-up(lg) {
    display: block;
    margin-top: -24px;
    position: relative;
    z-index: 2;
  }
}

.st-LocalNav2 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  .st-LocalNav2_Container & {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-image: none;

    .st-LocalNav2_Container & {
      display: block;
    }

    .st-ContentHeaderB_SubNav & {
      width: percentage(div(10, 12));
      margin: auto;
    }
  }
}

.st-LocalNav2_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  line-height: 1.2;

  @include mq-up(lg) {
    display: table;
    width: 100%;
    text-align: center;

    .st-ContentHeaderD_Block-fixed & {
      table-layout: auto;
    }
  }
}

.st-LocalNav2_Item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: rem(15);
  @include border-collapse(#393939);

  @include mq-up(lg) {
    display: table-cell;
    vertical-align: middle;
    width: percentage(div(1, 7));
    font-size: rem(14, lg);
    @include border-collapse(#e8e8e8);
    color: #393939;
    background-color: #fff;
    transition-duration: 0.3s;
    transition-property: background-color;

    .st-ContentHeaderA_Block-fixed & {
      color: $color-text;
      background-color: transparent;
      width: auto;

      // &:hover {
      //   color: #fff;
      //   background-color: #393939;
      // }
    }

    // &:hover {
    //   color: #fff;
    //   background-color: #393939;
    // }
  }
}

.st-LocalNav2_Link {
  position: relative;
  display: block;
  padding: 16px 5px;
  text-decoration: none;
  color: #fff;
  transition-duration: 0.3s;
  transition-property: background-color;

  @include mq-up(lg) {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 12px;
    font-weight: bold;
    text-align: center;
    color: currentColor;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }

    .st-LocalNav1_Item:hover &,
    .st-LocalNav1_Item-current & {
      // color: $color-brand;
    }

    .st-ContentHeaderA_Block-fixed & {
      font-weight: normal;

      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }
}

.st-LocalNav2_Link-current {

  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.st-LocalNav2_LinkText {
  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
  }
}

.st-LocalNav2_Icon {
  &:before {
    @include Icon("icon_arrow-right");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    font-size: 1em;
  }

  @include mq-up(lg) {
    display: inline-block;
    padding-right: 6px;
    vertical-align: middle;

    &:before {
      position: static;
      font-size: 1.1em;
      color: $color-brand;

      .st-LocalNav2_Link-current & {
        transform: rotate(90deg);
      }
    }
  }
}

.st-LocalNav2_Delimiter {
  display: inline-block;

  @include mq-up(lg) {
    display: block;
  }
}
