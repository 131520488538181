/*
---
name: sw-Link
namespace: SiteWide
category: Link
id: LI-2
---

<span class="sw-Label">LI-2</span>

テキストリンクのデフォルトスタイルです。

```html
<div class="st-Wrapper">
<p>ホール・会議室についてのご質問・資料請求は<a href="#" class="sw-Link">こちら</a>から</p>
</div>
```
*/
.sw-Link {
  @include sw-Link();
}
