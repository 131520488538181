/*
---
name: sw-TableB
namespace: SiteWide
category: List
id: [C3, TB-1]
---

<span class="sw-Label">TB-2</span>

ベーシックなテーブルです。横スクロール可能なテーブルの指定ができます。

- ヘッダー・ボディ・フッターといった、行の役割が分かっている場合は`<thead>`、`<tbody>`、`<tfoot>`タグを使って意味を明確にします。
- `<th>`タグは見出しセルになりますが、その見出しセルがどの行や列に対応しているかを明確にするために`scope`属性を指定します。`scope="row"`で同じ行（横方向）の見出しであることを示し、`scope="col"`で同じ列（縦方向）の見出しであることを示します。
- `colspan`属性と`rowspan`属性で複数のセルを結合することができますが、極力控えるようにします。
セルの結合はUAが解釈することが難しい機能で、特にスクリーンリーダーなどでうまく情報が伝えられない恐れがあります。1つの複雑なテーブルではなく、複数のシンプルなテーブルにできないか検討してください。
- キャプションは`table`タグの最初の子要素にしてください（[参照](https://html.spec.whatwg.org/multipage/tables.html#the-caption-element)）。テーブルの最後に配置するには`.sw-TableB_Caption-Foot`を指定します。
- セルの横幅を指定する場合は、`colgroup > col`にパーセンテージで幅を指定します。
- `.sw-TableB_Table`を`.sw-TableB_Scroll`で囲むとスクロール可能なテーブルになります。
- フォントサイズを小さくする場合は`.sw-TableB_Small`を指定します。
- テキストを左揃えにする場合は`.sw-TableB_Left`、PC以上で左揃えにする場合は`.sw-TableB_LeftLg`を指定します。
- テキストを中央揃えにする場合は`.sw-TableB_Center`、PC以上で中央揃えにする場合は`.sw-TableB_CenterLg`を指定します。
- テキストを右揃えにする場合は`.sw-TableB_Right`、PC以上で右揃えにする場合は`.sw-TableB_RightLg`を指定します。
- テキストの垂直位置を中央揃えにする場合は`.sw-TableB_Middle`、PC以上で中央寄せにする場合は`.sw-TableB_MiddleLg`を指定します。
- テキストを赤色にする場合は`.sw-TableB_Important`を指定します。

```html
<div class="st-Wrapper">
  <div class="sw-TableB_Scroll">
    <table class="sw-TableB_Table">
      <caption class="sw-TableB_Caption sw-TableB_Caption-Foot">単位：円（税抜）<br>
      消費税はご利用日時点の税率を適用いたします</caption>
      <colgroup>
        <col style="width: 25%;">
        <col style="width: 75%;">
      </colgroup>
      <tbody>
        <tr>
          <th scope="row">社名</th>
          <td>京都リサーチパーク株式会社</td>
        </tr>
        <tr>
          <th scope="row">代表者</th>
          <td>代表取締役社長　松尾　一哉</td>
        </tr>
        <tr>
          <th scope="row">所在地</th>
          <td>〒600-8813　京都市下京区中堂寺南町134番地</td>
        </tr>
        <tr>
          <th scope="row">設立</th>
          <td>1999年7月1日（創業1989年10月1日）</td>
        </tr>
        <tr>
          <th scope="row">資本金</th>
          <td>1億円（大阪ガスグループ 大阪ガス都市開発（株）100%出資）</td>
        </tr>
        <tr>
          <th scope="row">従業員 <span class="sw-TableB_Important">※</span> 補足</th>
          <td>85名（2017年4月現在）</td>
        </tr>
        <tr>
          <th scope="row">事業内容</th>
          <td>リサーチパークの開発・運営</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
```

- 左1列を固定する場合は`.sw-TableB_Table-fixedLeft`を追加します。
- 上1列を固定する場合は`.sw-TableB_Table-fixedHead`を追加します。  

```html
<div class="st-Wrapper">
  <div class="sw-TableB_Scroll">
    <table class="sw-TableB_Table sw-TableB_Table-fixedLeft">
      <caption class="sw-TableB_Caption sw-TableB_Caption-Foot">単位：円（税抜）<br>
      消費税はご利用日時点の税率を適用いたします</caption>
      <colgroup>
        <col style="width: 25%;">
        <col style="width: 75%;">
      </colgroup>
      <tbody>
        <tr>
          <th scope="row">社名</th>
          <td>京都リサーチパーク株式会社</td>
        </tr>
        <tr>
          <th scope="row">代表者</th>
          <td>代表取締役社長　松尾　一哉</td>
        </tr>
        <tr>
          <th scope="row">所在地</th>
          <td>〒600-8813　京都市下京区中堂寺南町134番地</td>
        </tr>
        <tr>
          <th scope="row">設立</th>
          <td>1999年7月1日（創業1989年10月1日）</td>
        </tr>
        <tr>
          <th scope="row">資本金</th>
          <td>1億円（大阪ガスグループ 大阪ガス都市開発（株）100%出資）</td>
        </tr>
        <tr>
          <th scope="row">従業員 <span class="sw-TableB_Important">※</span> 補足</th>
          <td>85名（2017年4月現在）</td>
        </tr>
        <tr>
          <th scope="row">事業内容</th>
          <td>リサーチパークの開発・運営</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
```
*/
.sw-TableB_Table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.sw-TableB_Scroll {
  overflow: auto;
  width: 100%;
}

.sw-TableB_Scroll table {
  width: 100%;
  margin-bottom: 2em;
  -webkit-overflow-scrolling: touch;
}


.sw-TableB_Scroll th,
.sw-TableB_Scroll td {
  white-space: nowrap;
}

.sw-TableB_Table-fixedLeft th,
.sw-TableB_Table-fixedLeft td {
  border: 0 !important;
  @include border-collapse(#ccc);
}

.sw-TableB_Table th,
.sw-TableB_Table td {
  padding: 12px 10px;
  border: 1px solid #ccc;
  vertical-align: top;
  font-size: rem(13);
  line-height: 1.6;

  &.sw-TableB_Center {
    text-align: center;
  }

  &.sw-TableB_Middle {
    vertical-align: middle;
  }

  @include mq-up(lg) {
    padding: 15px;
    font-size: rem(16, lg);

    &.sw-TableB_CenterLg {
      text-align: center;
    }

    &.sw-TableB_MiddleLg {
      vertical-align: middle;
    }
  }
}

.sw-TableB_Table th {
  font-weight: bold;
  background-color: #fbf3e9;
}

.sw-TableB_Caption {
  text-align: left;
  font-size: rem(11);
  line-height: 1.6;
  background-color: #fff;

  @include mq-up(lg) {
    text-align: right;
    font-size: rem(13, lg);
  }
}

.sw-TableB_Caption-Foot {
  display: table-caption;
  caption-side: bottom;
  padding-top: 15px;
}

.sw-TableB_Important {
  color: $color-text-important;
}

.sw-TableB_Small {
  font-size: rem(12);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: 1.2;
  }
}

.sw-TableB_Left {
  &,
  & th,
  & td {
    text-align: left;
  }
  
}

.sw-TableB_LeftLg {
  @include mq-up(lg) {
    &,
    & th,
    & td {
      text-align: left;
    }
  }
}

.sw-TableB_Center {
  &,
  & th,
  & td {
    text-align: center;
  }
}

.sw-TableB_CenterLg {
  @include mq-up(lg) {
    &,
    & th,
    & td {
      text-align: center;
    }
  }
}

.sw-TableB_Right {
  &,
  & th,
  & td {
    text-align: right;
  }
}

.sw-TableB_RightLg {
  @include mq-up(lg) {
    &,
    & th,
    & td {
      text-align: right;
    }
  }
}

.sw-TableB_Middle {
  &,
  & th,
  & td {
    vertical-align: middle;
  }
}

.sw-TableB_MiddleLg {
  @include mq-up(lg) {
    &,
    & th,
    & td {
      vertical-align: middle;
    }
  }
}
