/*
---
name: st-Heading4
namespace: Structure
category: Layout
---

h4相当の見出し自身の余白を指定します。

```html
<div class="st-Wrapper">
<section class="st-Section4">
  <h4 class="sw-Heading4A st-Heading4">京都バイオ計測センター</h4>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>
</div>
```
*/
.st-Heading4 {
  margin-bottom: $margin-heading4;

  @include mq-up(lg) {
    margin-bottom: $margin-heading4-lg;
  }
}
