/*
---
name: krpweek-TitleB
namespace: krpweek
category: Heading
id: [K2, HD-2C, HD-2D]
---

<span class="sw-Label">HD-2C</span>

ページタイトルです。KRPWEEKで使用します。

```html
<div class="st-Wrapper">
<h1 class="krpweek-TitleB st-Title">再生医療サポート<br>プラットフォームとは？</h1>
</div>
```

<span class="sw-Label">HD-2D</span>

個々の特色を持つページでは`.krpweek-TitleB-dark`を追加します。

```html
<div class="st-Wrapper">
<h1 class="krpweek-TitleB krpweek-TitleB-dark st-Title">再生医療サポート<br>プラットフォームとは？</h1>
</div>
```
*/
.krpweek-TitleB {
  position: relative;
  font-family: $font-family-heading;
  font-size: rem(21);
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: $color-brand;

  &:after {
    content: "";
    display: block;
    margin: 4px auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 7px 0 0;
    border-color: $color-brand transparent transparent transparent;
  }

  @include mq-up(lg) {
    font-size: rem(32, lg);

    &:after {
      margin-top: 13px;
      border-width: 10px 14px 0 0;
    }
  }
}

.krpweek-TitleB-dark {
  color: $color-text;
}
