// st-LocalNav2_Link_LabPortal
.st-LocalNav2_Link_LabPortal,
.st-LocalNav2_Link_LabPortalZenkoku {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 0;
    font-weight: bold;
    text-align: center;
    color: currentColor;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }

    .st-ContentHeaderA_Block-fixed & {
      font-weight: normal;

      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }

  & .st-LocalNav2_Icon {
    @include mq-up(lg) {
      padding-right: 3px;
    }
  }
}

.sw-BlockB_Label_LabPortal.sw-BlockB_Label {
  @include mq-up(lg) {
    margin-right: 3px;
    padding: 4px 7px;
  }
}

.st-LocalNav2_Link-current_LabPortal {
  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.st-LocalNav4_Link_LabPortal,
.st-LocalNav4_Link_LabPortalMap {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 8px 15px;
    font-weight: bold;
    color: $color-brand;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -14px;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.st-LocalNav4_Link_LabPortal.st-LocalNav4_Link-current_LabPortal,
.st-LocalNav4_Link_LabPortalMap.st-LocalNav4_Link-current_LabPortal,
.st-LocalNav2_Link_LabPortal.st-LocalNav4_Link-current_LabPortal,
.st-LocalNav2_Link_LabPortalZenkoku.st-LocalNav4_Link-current_LabPortal,
.st-LocalNav2_Link_LabPortalMap.st-LocalNav4_Link-current_LabPortal {
  background-color: #a81d34;

  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }

  & .st-LocalNav2_Icon:before {
    @include mq-up(lg) {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}

.sw-Block_LabelArea_LabPortal {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.sw-Block_Label_LabPortal {
  & .sw-Button {
    font-size: rem(16);
    padding: 10px 0;
    width: 125px;
    margin-right: 10px;
  }
}

.st-Content_LabPortal_Search {
  & .st-ContentHeaderB_Inner {
    background-color: #f9f9f9;
  }

  & .st-LocalNav2_Item {
    @include mq-up(lg) {
      width: 0%;
    }
  }

  & .st-LocalTitleA_Icon::before {
    color: #fff;
  }

  & .st-LocalTitleA {
    border-bottom: none;
    color: #fff;

    @include mq-up(lg) {
      color: $color-brand;
    }
  }

  & .st-LocalNav4_Items {
    padding: 20px 20px;
  }

  & .st-ContentHeaderB_Nav_LabPortal {
    display: none;

    @include mq-up(lg) {
      display: block;
    }
  }

  & .st-LocalNav1_LabPortal {
    display: block;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    background-color: #181818;
    background-image: url(/assets/img/structure/bg_local-nav3.png);
    background-repeat: repeat;
    background-size: auto;

    @include mq-up(lg) {
      display: block !important;
      margin: 0;
      padding: 0;
      background-color: transparent;
      background-image: none;
    }
  }

  & .st-LocalNav2_LabPortal {
    display: block;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding-right: calc(50vw - 50%);
    padding-left: calc(50vw - 50%);
    background-color: #181818;
    background-image: url(/assets/img/structure/bg_local-nav3.png);
    background-repeat: repeat;
    background-size: auto;

    @include mq-up(lg) {
      display: block !important;
      margin: 0;
      padding: 0;
      background-color: transparent;
      background-image: none;
    }
  }

  & .st-LocalTitleA_LabPortal {
    padding: 25px 0;
    border-bottom: 0;
    font-size: rem(36, lg);
    line-height: 1.4;
    text-align: center;
    font-weight: bold;
    color: $color-brand;

    @include mq-up(lg) {
      padding: 0;
    }

    & .st-LocalTitleA_LabPortal_Pc {
      display: none;

      @include mq-up(lg) {
        display: block;
      }
    }

    & .st-LocalTitleA_LabPortal_Sp {
      display: block;
      font-size: rem(16);

      @include mq-up(lg) {
        display: none;
      }
    }

    & .st-LocalTitleA_Small {
      display: block;
      text-transform: uppercase;
      font-size: rem(12);
      margin-top: 5px;
    }
  }

  & .st-ContentHeaderSearch_Title {
    @include mq-up(lg) {
      .st-ContentHeaderA_Block-fixed & {
        display: inline-block;
        vertical-align: middle;
        margin-right: 27px;
      }
    }
  }
}

.st-LocalNav4_LabPortal {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 50px 0 0 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }

  &.st-ContentHeaderB_Block-fixed {
    top: 57px;

    @include mq-up(lg) {
      position: static;
    }
  }
}

.st-LocalNav4_LabPortal_Area {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  @include mq-up(lg) {
    display: none;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  &.st-ContentHeaderB_Block-fixed {
    z-index: 10001;
  }
}

.st-ContentHeaderB_Block_LabPortal {
  @media print, screen and (min-width: 980px) {
    padding-top: 34px;
  }
}

.st-LocalTitle_LabPortal_Search {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 17px 40px 17px 20px;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  color: #fff;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    display: none;
    margin: 0;
    padding: 0;
    border-bottom: 0;
    font-size: rem(36, lg);
    line-height: 1.4;
    text-align: center;

    & br {
      display: inline;
    }
  }

  & .st-LocalTitleA_Icon {
    [aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
  }
}

.st-LocalTitle_LabPortal_SearchAreaName {
  padding: 10px 0;
  font-size: rem(16);
  font-weight: bold;

  @include mq-up(lg) {
    display: none;
  }
}

.st-LocalNav1_Link_LabPortal {
  position: relative;
  display: block;
  padding: 16px 5px;
  padding: 16px 20px;
  text-decoration: none;
  color: #fff;
  transition-duration: 0.3s;
  transition-property: background-color;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 24px 20px;
    font-weight: 700;
    text-align: center;
    color: currentColor;
    background-color: transparent;
  }
}

.st-LocalNav1_Item-current {
  & .st-LocalNav1_Link_LabPortal::after {
    @include mq-up(lg) {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-style: solid;
      border-width: 0 6.5px 6px 6.5px;
      border-color: transparent transparent #f9f9f9 transparent;
    }
  }
}

.st-LocalNav2_Link_LabPortalMap {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 12px;
    font-weight: bold;
    text-align: center;
    color: currentColor;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }

    .st-ContentHeaderA_Block-fixed & {
      font-weight: normal;

      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }
}

// ラボを探す/詳細 ------------------------------
.st-Contact_LabPortal {
  padding: 15px 0;

  @include mq-up(lg) {
    padding: 35px 40px 40px;
  }

  & h4 {
    margin-bottom: 10px;

    @include mq-up(lg) {
      margin-bottom: 15px;
    }
  }
}
