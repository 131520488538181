.st-HeaderSearch {
  padding: 20px;

  @include mq-up(lg) {
    display: none;
  }
}

.st-HeaderSearch_Input {

  box-sizing: border-box !important;
  line-height: 1 !important;

  .gsc-search-button {
    @include sr-only();
  }

  .gsst_b {
    display: none;
  }

  .cse .gsc-control-cse, .gsc-control-cse {
    padding: 0;
    border-color: transparent;
    border: 0;
  }

  form.gsc-search-box {
    margin-bottom: 0;
    font-size: 1rem;
  }

  table.gsc-search-box {
    margin-bottom: 0;
  }

  .gsc-input-box {
    border: 0;
    height: auto;
  }

  .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding-right: 0;
  }

  .gsc-search-box .gsc-input>input:hover, .gsc-input-box-hover {
    border: 0;
    box-shadow: none;
  }

  .gsib_a {
    padding: 0 !important;
  }

  .gsc-search-box-tools .gsc-search-box input.gsc-input {
    box-sizing: border-box !important;
    width: 100% !important;
    width: percentage(div(17, 14)) !important;
    height: 47px !important;
    margin: 0 !important;
    margin-left: (((div(17, 14) * 100) - (100)) * 0.5) * -1% !important;
    padding: 15px 12px !important;
    border: 0;
    border-radius: $form-border-radius !important;
    font-size: 17px !important; // デザイン上は14px
    transform: scale(div(14, 17)) !important;
    line-height: 1.8 !important;
    vertical-align: middle !important;
    appearance: none;
    color: $color-text;
    box-shadow: inset 0px 1px 2px 0px rgba(#000, 0.05);
    text-indent: 0 !important;
    background-color: #fff !important;
    background-image: none !important;

    &::placeholder {
      color: #818181;
      font-size: 1rem;
    }

    &:focus {
      outline-width: 0;
      border-color: $form-focus-border-color;
      box-shadow: $form-focus-box-shadow;
    }

    &:focus:not(.focus-ring) {
    }

    &:disabled {
      opacity: $form-disabled-opacity;
      background-color: $form-disabled-background-color;
      cursor: $form-disabled-cursor;
    }
  }
}
