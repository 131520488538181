/*
---
name: sw2-HeroImage
namespace: SiteWide2
category: InteractiveComponent
---

メインビジュアル用（一枚画像です。）

```html
<div class="sw2-HeroImage">
  <picture>
    <source srcset="/assets/img/access/index/hero.jpg" media="(min-width: 768px)"/>
    <img src="/assets/img/access/index/hero_sp.jpg" alt="" loading="lazy">
  </picture>
</div>
```

メインビジュアル用（動画のパターンです。）

```html
<div class="sw2-HeroImage -is-main -is-video">
  <video class="sw2-HeroImage_Video" id="sw2-HeroImage_Video" playsinline autoplay muted loop>
    <source src="/assets/img/serviceoffice/index/hero.mp4?ver=1">
  </video>
</div>
```

メインビジュアル用（PCのみ3枚画像のパターンです。）

```html
<div class="sw2-HeroImage -is-main">
  <div class="sw2-HeroImage_Split">
    <div class="sw2-HeroImage_SplitItem"><img src="/assets/img/rentaloffice/labo/hero_01.jpg" alt="" loading="lazy"></div>
    <div class="sw2-HeroImage_SplitItem"><img src="/assets/img/rentaloffice/labo/hero_02.jpg" alt="" loading="lazy"></div>
    <div class="sw2-HeroImage_SplitItem"><img src="/assets/img/rentaloffice/labo/hero_03.jpg" alt="" loading="lazy"></div>
  </div>
  <div class="sw2-HeroImage_Split_Sp"><img src="/assets/img/rentaloffice/labo/hero_sp.jpg" alt="" loading="lazy"></div>
</div>
```

*/
.sw2-HeroImage {
  img {
    width: 100%;
    object-fit: cover;
    @include mq-up(md) {
      height: rem(280, md);
      object-fit: cover;
    }
    @include mq-up(lg) {
      height: rem(280, lg);
    }
  }
  &.-is-main {
    img {
      @include mq-up(md) {
        height: rem(494, md);
        object-fit: cover;
      }
      @include mq-up(lg) {
        height: rem(494, lg);
      }
    }
  }
}
.sw2-HeroImage_Video {
  width: 100%;
  height: rem(228);
  object-fit: cover;
  vertical-align: bottom;
  @include mq-up(md) {
    height: rem(594, md);
    object-fit: cover;
  }
  @include mq-up(lg) {
    height: rem(594, lg);
  }
}

.sw2-HeroImage_Split {
  display: none;
  @include mq-up(md) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.sw2-HeroImage_SplitItem {
  @include mq-up(md) {
    width: percentage(div(1, 3));
  }
}
.sw2-HeroImage_Split_Sp {
  display: block;
  @include mq-up(md) {
    display: none;
  }
  @include mq-up(lg) {
    display: none;
  }
}