/*
---
name: st-ContainerC
namespace: Structure
category: Layout
id: [K2, CON-5]
---


<span class="sw-Label">CON-5</span>

`.st-ContainerC-Offset`を追加すると、`st-ContainerA`と`st-ContainerB`の`padding-bottom`を相殺します。

*/
.st-ContainerC {
}

.st-ContainerC-Offset {
  .st-ContainerA + & {
    margin-top: -60px;

    @include mq-up(lg) {
      margin-top: -100px;
    }
  }

  .st-ContainerB + & {
    margin-top: -50px;

    @include mq-up(lg) {
      margin-top: -80px;
    }
  }
}
