/*
---
name: sw-ListNote
namespace: SiteWide
category: List
id: [K2, TX-5]
---

<span class="sw-Label">TX-5</span>

注釈用のリストです。米印やアスタリスクが表示されます。余白には`.st-SmallText`を使用します。

```html
<div class="st-Wrapper">
<ul class="sw-ListNote st-SmallText">
  <li>固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。</li>
  <li>固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。</li>
</ul>
<div lang="en" class="st-SmallText">
  <ul class="sw-ListNote st-SmallText">
    <li>Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well. Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well. Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well.</li>
    <li>Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well. Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well. Those who are based on simple ideas and services that are not based on specific technologies will not be eligible for startup as well.</li>
  </ul>
</div>
</div>
```

- テーブルなどでは右揃えにすることもできます（`.sw-ListNote-right`で常に、`.sw-ListNote-rightLg`でPC以上で適応）
- 重要性や緊急性・深刻性がある場合は色を変えることもできます

```html
<div class="st-Wrapper">
<ul class="sw-ListNote sw-ListNote-important sw-ListNote-rightLg st-SmallText">
  <li>この会場は、夜間の貸し出しをいたしません。<br>夜間料金は120㎡以上の広さの会場と合わせてご利用いただく際のものです。</li>
</ul>
</div>
```
*/
.sw-ListNote {
  margin-left: 1.5em;
  padding-left: 0;
  font-size: rem(13);
  line-height: 1.6;
  list-style-type: none;

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1em;
  }

  & > li {
    position: relative;
  }

  & > li:before {
    content: "※";
    position: absolute;
    top: 0;
    left: -1.5em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*";
    left: -1em;
  }

  @include mq-up(lg) {
    font-size: rem(13, lg);
  }
}

.sw-ListNote-important {
  color: $color-text-important;
}

.sw-ListNote-right {
  overflow: hidden;

  & > li {
    float: right;
  }
}

.sw-ListNote-rightLg {
  @include mq-up(lg) {
    overflow: hidden;

    & > li {
      float: right;
    }
  }
}
