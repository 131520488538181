/*
---
name: st-Block
namespace: Structure
category: Layout
---

見出しや画像などを含んだブロック同士の余白を指定します。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

```html
<div class="st-Wrapper">
  <div class="st-Block st-Block-divider">
    <div class="sw-MediaA sw-MediaA-col9to3Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">ＣＯＲＥＳＣＯＰＥ株式会社</p>
        <p class="sw-MediaA_Text">「物事の核心（コア）を詳しく観察（スコープ）」<br>
        メカトロニクス（機械工学、電子工学および情報工学の融<br>合分野）の技術を応用し、電子機器、理化学機器、福祉機器およびロボットの企画、研究、開発、製造および販売に取り組んでおります。</p>
        <p class="sw-MediaA_Text">代表者：大久保　康<br>
        業種：製造業<br>
        <a class="sw-MediaA_Link" href="#">http://www.corescope.co.jp</a></p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/270x174.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
  <div class="st-Block st-Block-divider">
    <div class="sw-MediaA sw-MediaA-col9to3Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">株式会社Yumegurashi</p>
        <p class="sw-MediaA_Text">京町屋を活用した欧米富裕層向け旅館</p>
        <p class="sw-MediaA_Text">代表者：大久保　康<br>
        業種：製造業<br>
        <a class="sw-MediaA_Link" href="#">http://www.nijo-nazuna.jp/<span class="sw-MediaA_LinkExternal" aria-hidden="true"></span></a></p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/270x174.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
</div>
```
*/
.st-Block {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-block;

    @include mq-up(lg) {
      margin-top: $margin-block-lg;
    }
  }
}

.st-Block-divider {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-block-divider;

    @include mq-up(lg) {
      margin-top: $margin-block-divider-lg;
    }
  }
  
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    padding-bottom: $margin-block-divider;
    border-bottom: 1px solid #e3e3e3;

    @include mq-up(lg) {
      padding-bottom: $margin-block-divider-lg;
    }
  }
}
