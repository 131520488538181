/*
---
name: st2-Mvtop
namespace: Structure2
category: Layout
---

トップページ等のメインビジュアルで使用します。
メインビジュアルは`.sw2-MvSlider`を使用します。

```html
<div class="st2-Mvtop">
  <div class="st2-Mvtop_Heading">イノベーションが<br>生まれる「まち」。</div>
  <div class="st2-Mvtop_Scroll">SCROLL</div>
  <div class="sw2-MvSlider">
    <div class="sw2-MvSlider_In">
      <div>
        <picture>
          <source srcset="/assets/img/index/slider_01.jpg" media="(min-width: 768px)"/>
          <img src="/assets/img/index/slider_01_sp.jpg" alt="" loading="lazy">
        </picture>
      </div>
      <div>
        <picture>
          <source srcset="/assets/img/index/slider_02.jpg" media="(min-width: 768px)"/>
          <img src="/assets/img/index/slider_02_sp.jpg" alt="" loading="lazy">
        </picture>
      </div>
      <div>
        <picture>
          <source srcset="/assets/img/index/slider_03.jpg" media="(min-width: 768px)"/>
          <img src="/assets/img/index/slider_03_sp.jpg" alt="" loading="lazy">
        </picture>
      </div>
    </div>
  </div>
</div>
```
*/
.st2-Mvtop {
  position: relative;
}
.st2-Mvtop_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  color: #fff;
  font-size: rem(36);
  line-height: rem(54);
  position: absolute;
  bottom: rem(180);
  left: rem(20);
  z-index: 6;
  text-shadow: 0px 0px 30px #000000;
  @include mq-up(lg) {
    font-size: rem(54, lg);
    line-height: rem(80, lg);
    bottom: rem(80, lg);
    left: rem(20, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: calc((100vw - $max-width) / 2);
  }
}

.fadeInAnime1s {
    animation-name: fadeIn1s;
}
.fadeIn1s {
    animation-delay: 1s;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    transform: translateY(-50px);
    opacity: 0;
}
@keyframes fadeIn1s {
    0% {
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}  
.slick-one-noevent .st2-Mvtop_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  color: #fff;
  font-size: rem(36);
  line-height: rem(54);
  position: absolute;
  bottom: 3rem!important;
  left: rem(20);
  z-index: 6;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25), 0px 0px 20px #000!important;
  @include mq-up(lg) {
    font-size: rem(54, lg);
    line-height: rem(80, lg);
    bottom: rem(80, lg);
    left: rem(20, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: calc((100vw - $max-width) / 2);
  }
}

.st2-Mvtop_Scroll {
  display: none;
  position: absolute;
  bottom: rem(106);
  left: calc((100vw - $max-width) / 2);
  margin-left: 0;
  z-index: 6;
  transform: rotate(-90deg);
  @include mq-up(lg) {
    display: block;
    bottom: rem(106, lg);
    left: -104px;
  }
  @media print, screen and (min-width: $max-width) {
    left: calc(((100vw - $max-width) / 4) - 120px);
  }
}
.st2-Mvtop_ScrollIn {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
  @media print, screen and (min-width: $max-width) {
    align-items: flex-start;
  }
}
.st2-Mvtop_ScrollTitle {
  display: block;
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  color: #fff;
  font-size: rem(11);
  line-height: rem(11);
  letter-spacing: 0.05em;
  margin-left: rem(17);
  @include mq-up(lg) {
    font-size: rem(11, lg);
    line-height: rem(11, lg);
    margin-left: rem(17, lg);
  }
}
.st2-Mvtop_ScrollBar {
  display: block;
  position: relative;
  width: rem(160);
  height: rem(16);
  overflow: hidden;
  @include mq-up(lg) {
    width: rem(160, lg);
    height: rem(16, lg);
  }
  &::before {
    content: "";
    display: block;
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    height: rem(1);
    position: absolute;
    top: rem(7);
    left: 0;
    @include mq-up(lg) {
      height: rem(1, lg);
      top: rem(7, lg);
    }
  }
  &::after {
    content: "";
    display: block;
    background: #fff;
    width: 100%;
    height: rem(1);
    position: absolute;
    top: rem(7);
    left: 0;
    animation-name: scroll_bar;
    animation-fill-mode: backwards;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    @include mq-up(lg) {
      height: rem(1, lg);
      top: rem(7, lg);
    }
  }
}
.sw2-HeroImage {
  .st2-Mvtop_Scroll {
    display: block;
    left: -40px;
    bottom: 64px;
    margin-left: 0;
    @media print, screen and (min-width: $max-width) {
      bottom: rem(106, lg);
      margin-left: 0;
    }
  }
  .st2-Mvtop_ScrollTitle {
    font-size: rem(10);
    line-height: rem(10);
    margin-left: rem(12);
    @media print, screen and (min-width: $max-width) {
      font-size: rem(11, lg);
      line-height: rem(11, lg);
      margin-left: rem(17, lg);
    }
  }
  .st2-Mvtop_ScrollBar {
    display: block;
    position: relative;
    width: rem(80);
    height: 16px;
    @media print, screen and (min-width: $max-width) {
      width: rem(160, lg);
      height: rem(16, lg);
    }
  }
}

@keyframes scroll_bar {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
