/*
---
name: serviceoffice-StreetView
namespace: serviceoffice
category: ThirdPartyComponent
id: [C3, SV]
---

<span class="sw-Label">SV</span>

Googleストリートビューで表示します。

- BIZ NEXTで使用しています。
- マップはクリッカブルマップで作成してください。
  - 「[jQuery RWD Image Maps](https://github.com/stowball/jQuery-rwdImageMaps)」を使用しています。
  - 「[HTML Imagemap Generator](https://labs.d-s-b.jp/ImagemapGenerator/)」などを使ってクリッカブルマップを作成してください。
- クリッカブルマップのリンク先がGoogleストリートビューに反映されます。

```html
<div class="st-Wrapper">
  <div class="serviceoffice-StreetView">
    <div class="st-Grids st-Grids-col2Lg">
      <div class="st-Grids_Item serviceoffice-StreetView_Links">
        <img src="/assets/img/serviceoffice/floormap/map01.png" usemap="#ImageMap" alt="" />
        <map class="serviceoffice-StreetView_Map" name="ImageMap">
          <area shape="rect" coords="327,65,377,100" href="https://www.google.com/maps/embed?key=AIzaSyBiJiE-KzfulyLCaAQeYzfSyqrUC2MTaJw&pb=!1m0!3m2!1sja!2sjp!4v1486972329431!6m8!1m7!1sQWeZc7oDOA8AAAQvxXJw2A!2m2!1d34.99516060603462!2d135.7389064390211!3f0.82!4f-16.180000000000007!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="36,111,78,144" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sus!4v1486972316316!6m8!1m7!1sjbC8J9NMcFEAAAQvxXJwqQ!2m2!1d34.99514796983764!2d135.7386006488264!3f92.44!4f-3.6200000000000045!5f1.0811762147485968" alt="" />
          <area shape="rect" coords="75,200,121,233" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972212144!6m8!1m7!1s2fZ_uITgY6gAAAQvxXJwrA!2m2!1d34.99507309457315!2d135.7386259982973!3f91.34!4f-6.760000000000005!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="273,207,323,239" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972340028!6m8!1m7!1sG_hVou3mgFEAAAQvxXJw0A!2m2!1d34.99504229484154!2d135.738869563428!3f3.13!4f-14.39!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="187,230,238,260" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972241161!6m8!1m7!1sl4-JIs3LGbUAAAQvxXJwsA!2m2!1d34.99507344826747!2d135.7387495618693!3f201.36047007051963!4f-7.640236054803751!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="173,280,230,311" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972253384!6m8!1m7!1sRgAdQxiDQ-4AAAQvxXJwtg!2m2!1d34.99500949560558!2d135.7387637388326!3f245.13!4f-13.879999999999995!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="50,294,102,325" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sus!4v1486972278805!6m8!1m7!1sgQXEgkaCg8YAAAQvxXJwxQ!2m2!1d34.99496657646318!2d135.7386406474303!3f10.52!4f-11.969999999999999!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="111,322,168,352" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972266120!6m8!1m7!1sCEQIFy_gR6QAAAQvxXJwfA!2m2!1d34.99497482225014!2d135.7386980510598!3f156.14!4f-15.060000000000002!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="365,349,421,385" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972349594!6m8!1m7!1suYVwzWQVR1kAAAQvxXJwnQ!2m2!1d34.99495643242528!2d135.7389218357774!3f93.54!4f-16.239999999999995!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="35,391,82,421" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sus!4v1486972289957!6m8!1m7!1sL3TjA6V3WpgAAAQvxXJwvA!2m2!1d34.99492531829578!2d135.7385923160411!3f331.05!4f-20.049999999999997!5f0.7820865974627469" alt="" />
          <area shape="rect" coords="176,390,228,423" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sus!4v1486972306599!6m8!1m7!1s1C5SeOrDesMAAAQvxXJwwg!2m2!1d34.994909608827!2d135.7387655245382!3f18.14!4f-26.33!5f0.4000000000000002" alt="" />
          <area shape="rect" coords="254,414,308,448" href="https://www.google.com/maps/embed?pb=!1m0!3m2!1sja!2sjp!4v1486972359719!6m8!1m7!1s1Zs62doDeZwAAAQvxXJwoQ!2m2!1d34.99491226833143!2d135.7388830867365!3f245.88!4f-19.019999999999996!5f0.7820865974627469" alt="" />
        </map>
      </div>
      <div class="st-Grids_Item">
        <div class="serviceoffice-StreetView_Iframe">
          <iframe src="https://www.google.com/maps/embed?key=AIzaSyBiJiE-KzfulyLCaAQeYzfSyqrUC2MTaJw&pb=!4v1525232794410!6m8!1m7!1s2fZ_uITgY6gAAAQvxXJwrA!2m2!1d34.99507309457315!2d135.7386259982973!3f91.34!4f-6.760000000000005!5f0.7612704170384293" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <ul class="st-ButtonA st-ButtonA-center">
      <li class="st-ButtonA_Item">
        <a href="https://www.google.com/maps/@34.9950731,135.738626,3a,62.8y,91.34h,83.24t/data=!3m6!1e1!3m4!1s2fZ_uITgY6gAAAQvxXJwrA!2e0!7i10820!8i5410" class="sw-Button">全画面で見る<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
      </li>
    </ul>
  </div>
</div>
```
*/
.serviceoffice-StreetView {

}

.serviceoffice-StreetView_Links {
}

.serviceoffice-StreetView_Iframe {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(div(570, 549));
}

.serviceoffice-StreetView_Iframe iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
