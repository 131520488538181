/*
---
name: sw2-LinkMap
namespace: SiteWide2
category: Link
---

レスポンシブに対応したクリッカブルマップです。

- 「[jQuery RWD Image Maps](https://github.com/stowball/jQuery-rwdImageMaps)」を使用しています。
- 「[HTML Imagemap Generator](https://labs.d-s-b.jp/ImagemapGenerator/)」などを使ってクリッカブルマップを作成してください。
- 独自パーツですので隙間はこちらに入れています。

```html
<section class="sw2-LinkMap">
  <h2 class="sw2-LinkMap_Heading">OFFICE MAP</h2>
  <picture>
    <img src="/assets/img/rentaloffice/index/image_link_map.jpg" usemap="#LinkMap" alt="" class="sw2-LinkMap_Image" loading="lazy">
    <map name="LinkMap">
      <area shape="rect" coords="434,489,520,516" href="/rentaloffice/building/bldg1/" alt="1号館" />
      <area shape="rect" coords="68,418,154,443" href="/rentaloffice/building/bldg2/" alt="2号館" />
      <area shape="rect" coords="782,123,866,150" href="/rentaloffice/building/bldg3/" alt="3号館" />
      <area shape="rect" coords="677,360,765,387" href="/rentaloffice/building/bldg4/" alt="4号館" />
      <area shape="rect" coords="922,238,1012,266" href="/rentaloffice/building/bldg6/" alt="6号館" />
      <area shape="rect" coords="1141,230,1230,260" href="/rentaloffice/building/bldg8/" alt="8号館" />
      <area shape="rect" coords="1062,311,1150,337" href="/rentaloffice/building/bldg9/" alt="9号館" />
      <area shape="rect" coords="225,382,314,410" href="/rentaloffice/building/aste/" alt="ASTEM棟" />
      <area shape="rect" coords="221,554,312,580" href="/rentaloffice/building/kistic/" alt="KISTIC" />
      <area shape="rect" coords="1032,170,1126,199" href="/rentaloffice/building/studio/" alt="スタジオ棟" />
    </map>
  </picture>
</section>
```
*/

.sw2-LinkMap {
  padding: rem(60) rem(0) rem(0);
  background-color: #f5f5f5;
  position: relative;

  @include mq-up(lg) {
    padding: rem(0) rem(110, lg);
    background-color: #DADEE8;
  }
}

.sw2-LinkMap_Heading {
  display: inline-block;
  background-color: #333;
  font-family: $font-family-shippori-mincho;
  color: #fff;
  font-weight: 400;
  z-index: 1;
  position: relative;
  margin-left: rem(20);
  margin-bottom: rem(-10);
  font-size: rem(24);
  line-height: rem(34);
  padding: rem(8) rem(16);
  @include mq-up(lg) {
    padding: rem(16, lg);
    font-size: rem(32, lg);
    line-height: rem(32, lg);
    margin-bottom: rem(0);
    margin-left: rem(0);
    position: absolute;
    top: rem(20, lg);
    left: rem(20, lg);
  }
  @media print, screen and (min-width: $max-width) {
    top: rem(60, lg);
    left: rem(215, lg);
  }
}

.sw2-LinkMap_Image {
  display: block;
  width: 100%;
}