/*
---
name: serviceoffice-FeatureNavA
namespace: serviceoffice
category: Navigation
id: [CA-9]
---

<span class="sw-Label">CA-9</span>

- serviceoffice（BIZ NEXT）で使用
- カラムは`.st-Grids`で指定します

```html
<div class="st-Wrapper">
  <ul class="st-Grids st-Grids-col2Lg">
    <li class="st-Grids_Item">
      <a class="serviceoffice-FeatureNavA_Link" href="#">
        <div class="serviceoffice-FeatureNavA_Head">
          <img src="https://placehold.jp/750x760.png" alt="" class="serviceoffice-FeatureNavA_HeadImage">
        </div>
        <div class="serviceoffice-FeatureNavA_Body">
          <div class="serviceoffice-FeatureNavA_Heading">ラウンジ会員</div>
          <div class="serviceoffice-FeatureNavA_Text">月額20,000円〜</div>
          <div class="serviceoffice-FeatureNavA_ButtonArea">
            <span class="serviceoffice-FeatureNavA_Button">詳細をみる<span class="serviceoffice-FeatureNavA_ButtonIcon" aria-hidden="true"></span></span>
          </div>
        </div>
      </a>
      <div class="serviceoffice-FeatureNavA_Foot">
        <div class="serviceoffice-FeatureNavA_FootHeading">ビジネスラウンジ</div>
        <div class="serviceoffice-FeatureNavA_FootText">ホテルラウンジのような贅沢な空間には気兼ねなく大切なお客さまをお招きいただけます。電源と無線LANも完備しておりますのでデスクワークはもちろん、多彩なシーンでご利用いただけます。利用者数に上限を設けており混み合いません。</div>
      </div>
    </li>
    <li class="st-Grids_Item">
      <a class="serviceoffice-FeatureNavA_Link" href="#">
        <div class="serviceoffice-FeatureNavA_Head">
          <img src="https://placehold.jp/750x760.png" alt="" class="serviceoffice-FeatureNavA_HeadImage">
        </div>
        <div class="serviceoffice-FeatureNavA_Body">
          <div class="serviceoffice-FeatureNavA_Heading">オフィス会員</div>
          <div class="serviceoffice-FeatureNavA_Text">月額68,000円〜</div>
          <div class="serviceoffice-FeatureNavA_ButtonArea">
            <span class="serviceoffice-FeatureNavA_Button">詳細をみる<span class="serviceoffice-FeatureNavA_ButtonIcon" aria-hidden="true"></span></span>
          </div>
        </div>
      </a>
      <div class="serviceoffice-FeatureNavA_Foot">
        <div class="serviceoffice-FeatureNavA_FootHeading">ビジネスラウンジ＋オフィス</div>
        <div class="serviceoffice-FeatureNavA_FootText">オープンタイプ、完全個室の2種類からビジネスに合わせてお部屋を選択していただけるオフィス会員。家具は備え付けで、無線LANもすぐにご利用いただけるので、スピーディーに開業していただけます。ラウンジや、秘書サービスのご利用いただけるだけでなく、もちろん登記可能です。</div>
      </div>
    </li>
  </ul>
</div>
```
*/
.serviceoffice-FeatureNavA_Link {
  position: relative;
  display: block;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  color: #fff;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
  }
}

.serviceoffice-FeatureNavA_Head {
  display: block;

  @include mq-up(lg) {
    width: percentage(div(550, 570));
  }
}

.serviceoffice-FeatureNavA_HeadImage {
  display: block;
  max-width: none;
  width: 100%;
}

.serviceoffice-FeatureNavA_Body {
  padding: 15px 20px 24px;
  text-align: center;
  color: #fff;
  background-color: #333;

  @include mq-up(lg) {
    max-width: 340px;
    position: absolute;
    bottom: -20px;
    right: 0;
    padding: 20px 40px 30px;
    background-color: rgba(#000, 0.8);
  }
}

.serviceoffice-FeatureNavA_Heading {
  font-size: rem(18);
  line-height: 1.4;
  font-weight: bold;
  font-family: $font-family-heading-en;

  @include mq-up(lg) {
    font-size: rem(26, lg);
    line-height: 1.8;
  }
}

.serviceoffice-FeatureNavA_Delimiter {
  @include mq-up(lg) {
    display: block;
  }
}

.serviceoffice-FeatureNavA_Text {
  margin-top: 7px;
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    margin-top: 4px;
    font-size: rem(16, lg);
  }
}

.serviceoffice-FeatureNavA_ButtonArea {
  margin-top: 13px;

  @include mq-up(lg) {
    margin-top: 22px;
  }
}

.serviceoffice-FeatureNavA_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: #fff;
    background-color: $color-brand;
  }
}

.serviceoffice-FeatureNavA_ButtonIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;

  @include mq-up(lg) {
    right: 12px;
  }
}

.serviceoffice-FeatureNavA_Foot {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 16px 20px;

  @include mq-up(lg) {
    margin-right: auto;
    margin-left: auto;
    padding: 44px 0;
  }
}

.serviceoffice-FeatureNavA_FootHeading {
  font-size: rem(16);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(20, lg);
  }
}

.serviceoffice-FeatureNavA_FootText {
  margin-top: 4px;
  font-size: rem(13);
  line-height: 1.8;
  color: #000;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}
