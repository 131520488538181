/*
---
name: st-PageLink
namespace: Structure
category: Navigation
id: [K1, ACL]
---

<span class="sw-Label">ACL</span>

ページ内リンクです。

```html
<div class="st-PageLink">
  <ul class="st-PageLink_Items">
    <li class="st-PageLink_Item">
      <a href="#ref1" class="st-PageLink_Link" data-scroll>京都駅より<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-PageLink_Item">
      <a href="#ref2" class="st-PageLink_Link" data-scroll>JR丹波口駅より<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-PageLink_Item">
      <a href="#ref3" class="st-PageLink_Link" data-scroll>阪急西院駅・大宮駅より<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-PageLink_Item">
      <a href="#ref4" class="st-PageLink_Link" data-scroll>地下鉄五条駅より<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-PageLink_Item">
      <a href="#ref5" class="st-PageLink_Link" data-scroll>京阪清水五条駅より<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-PageLink_Item">
      <a href="#ref6" class="st-PageLink_Link" data-scroll>お車の場合・駐車場<span class="st-PageLink_Icon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>
```
*/
.st-PageLink {
  margin-top: 20px;

  @include mq-up(lg) {
    max-width: $max-width;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }

  &.-is-pc3col {
    .st-PageLink_Item {
      @include mq-up(lg) {
        width: percentage(div(1, 3));
      }
    }
  }
}

.st-PageLink_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
}

.st-PageLink_Item {
  position: relative;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  font-size: rem(9);
  font-weight: bold;
  line-height: 1.4;
  @include border-collapse(#e8e8e8, 1px);

  @include mq-up(lg) {
    width: percentage(div(1, 4));
    font-size: rem(14, lg);
  }
}

.st-PageLink_Link {
  display: block;
  padding: 10px 30px 10px 15px;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    opacity: $opacity;
  }

  @include mq-up(lg) {
    padding: 13px 40px 13px 15px;
    text-align: center;
  }
}

.st-PageLink_Icon {
  &:after {
    @include Icon("icon_arrow-down");
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    color: $color-brand;

    @include mq-up(lg) {
      right: 24px;
      font-size: 0.8em;
    }
    &:hover {
      @include mq-up(lg) {
        background: #C5383B;
        color: #fff;
      }
    }
    &.-is-active {
      background: #C5383B;
      color: #fff;
    }
  }
}
