.st-Header_Outer {
  position: relative;
  background-color: $color-krp;

  .st-Header-fixedActive & {
    height: 53.5px;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq-up(lg) {
    border-bottom: 1px solid #eee;
    background-color: transparent;

    .st-Header-fixedActive & {
      height: 70px;
    }
  }
}

.st-Header-fixed {
  transition-duration: $transition-duration;
}

.st-Header-fixedActive {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10000;

  @include mq-up(lg) {
    background-color: #fff;
  }
}

.st-Header_Logo {
  position: relative;
  z-index: 10000;
  float: left;
  width: calc(100% - 110px);
  height: (div(107, 2)) * 1px;
  background-color: $color-krp;

  .st-Header-spMenuOpen & {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  @include mq-up(lg) {
    width: 200px;
    height: 100px;

    .st-Header-fixedActive & {
      width: 186px;
      height: 70px;
      margin-left: 20px;
      background-color: transparent;
    }
  }

  @media print, screen and (min-width: 1300px) {
    width: 340px;
    height: 106px;
  }
}

.st-Header_Nav {
  width: 110px;
  height: (div(107, 2)) * 1px;
  float: right;

  @include mq-up(lg) {
    width: calc(100% - 200px);
    height: 100px;
    float: right;

    .st-Header-fixedActive & {
      width: auto;
      height: auto;
    }
  }

  @media print, screen and (min-width: 1300px) {
    width: calc(100% - 340px);
    height: 106px;
  }
}

.st-Header_Buttons {
  position: relative;
  z-index: 10000;

  .st-Header-spMenuOpen & {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 110px;
  }

  @include mq-up(lg) {
    display: none;
  }
}

.st-Header_Access {
  float: left;
  width: 50%;
  border-left: 1px solid #eee;
}

.st-Header_Menu {
  float: left;
  width: 50%;
  height: (div(107, 2)) * 1px;
  border-left: 1px solid #eee;
}

.st-Header_NavArea {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 0;
  color: #fff;
  background-position: 0 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling : touch;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_header-nav-area.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="false"] {
    height: 100vh;
    padding-top: 54px;
    padding-bottom: percentage(div(100, 320));
  }

  @include mq-up(lg) {
    right: 0;
    left: auto;
    display: block !important;
    width: calc(100% - 200px);
    height: 100px;
    overflow-y: visible;
    background: transparent;
  }

  @media print, screen and (min-width: 1300px) {
    width: calc(100% - 340px);
    height: 106px;
  }
}

.st-Header_Inner {
  @include mq-up(lg) {
    display: table;
    width: 100%;
  }
}

.st-Header_GlobalNav {
  @include mq-up(lg) {
    display: table-footer-group;

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.st-Header_SubGlobalNav {
  padding: 10px 20px 0;

  @include mq-up(lg) {
    display: table-header-group;
    padding: 0;
    text-align: right;

    .st-Header-fixedActive & {
      display: none;
    }
  }
}
