/*
---
name: sw2-LabelList
namespace: SiteWide2
category: Block
---

タイトルとラベルの一覧です。余白は`.st-Block`を使用してください。

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-LabelList">
      <div class="sw2-LabelList_Heading">実験・研究例</div>
      <div class="sw2-LabelList_Text">記載している研究開発例だけではなく、幅広い対応が可能ですので、一度ご相談ください。</div>
      <div class="sw2-LabelList_Main">
        <ul>
          <li><span>再生医療研究</span></li>
          <li><span>医薬品分析・開発</span></li>
          <li><span>材料研究・開発</span></li>
          <li><span>受託研究・開発</span></li>
          <li><span>機器、装置開発</span></li>
          <li><span>電子、半導体部品開発</span></li>
          <li><span>医療機器研究・開発</span></li>
          <li><span>食品開発・試験</span></li>
        </ul>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-LabelList {
  background-color: #EDEFF0;
  padding: rem(35) rem(20);
  margin-left: rem(-20);
  margin-right: rem(-20);
  @include mq-up(lg) {
    padding: rem(40, lg) rem(60, lg);
    margin-left: rem(0);
    margin-right: rem(0);
  }
}
.sw2-LabelList_Heading {
  font-weight: 700;
  font-size: rem(15);
  line-height: rem(21);
  margin-bottom: rem(10);
  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(30, lg);
    margin-bottom: rem(12, lg);
  }
  &.-is-center-Lg {
    @include mq-up(lg) {
      text-align: center;
    }
  }
}
.sw2-LabelList_Text {
  font-weight: 400;
  font-size: rem(13);
  line-height: rem(23);
  margin-bottom: rem(16);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(29, lg);
    margin-bottom: rem(20, lg);
  }
  &.-is-center-Lg {
    @include mq-up(lg) {
      text-align: center;
    }
  }
}
.sw2-LabelList_Main {
  & > ul {
    margin: rem(-8) 0 0 rem(-8);
    padding: 0;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    @include mq-up(lg) {
      margin: rem(-12) 0 0 rem(-12);
    }
    & > li {
      margin: rem(8) 0 0;
      padding: 0 0 0 rem(8);
      list-style: none;
      @include mq-up(lg) {
        margin: rem(12) 0 0;
        padding: 0 0 0 rem(12);
      }
      & > span {
        display: inline-block;
        font-weight: 400;
        font-size: rem(11);
        line-height: rem(18);
        background-color: #fff;
        border: 1px solid #E4E7E8;
        border-radius: 100px;
        padding: rem(6) rem(12);
        @include mq-up(lg) {
          font-size: rem(16, lg);
          line-height: rem(26, lg);
          padding: rem(8, lg) rem(24, lg);
        }
      }
    }
  }
  &.-is-active {
    span {
      color: $color-krp;
      border: 1px solid $color-krp;
    }
  }
  &.-is-center-Lg {
    & > ul {
      @include mq-up(lg) {
        justify-content: center;
      }
    }
  }
}
