@mixin sw-Label() {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid currentColor;
  color: $color-brand;
  font-size: rem(10);
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background-color: inherit;

  // aタグをマウスオーバーしたときのスタイル
  &[href]:hover {
    color: currentColor;
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 4px 14px;
    font-size: rem(14, lg);
  }
}
