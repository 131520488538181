.st-HeaderHome {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    display: none;
  }
}

.st-HeaderHome_Item {
  border-bottom: 1px solid #aaa;
}

.st-HeaderHome_Link {
  position: relative;
  display: block;
  padding: 16px 40px 16px 20px;
  font-size: rem(15);
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
}

.st-HeaderHome_Icon {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  
  &:before {
    @include Icon("icon_arrow-right");
  }
}
