/*
---
name: sw-BoxNavA
namespace: SiteWide
category: Navigation
id: [L2, CA-7A, CA-7B, CA-7C]
---

<span class="sw-Label">CA-7A</span>

- テキストメインの例
- 2カラムと3カラム
- 用途を示すアイコンあり

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavA_Items sw-BoxNavA_Items-col2Lg">
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_RoomIcon" aria-hidden="true"></span>
        部屋の広さ・利用人数で選ぶ
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_RoomIcon" aria-hidden="true"></span>
        部屋の広さ・利用人数で選ぶ<br>
        改行レイアウト
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
<ul class="sw-BoxNavA_Items sw-BoxNavA_Items-col3Lg">
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_RoomIcon" aria-hidden="true"></span>
        部屋の広さで選ぶ
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_PeopleIcon" aria-hidden="true"></span>
        利用人数で選ぶ
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_FacilityIcon" aria-hidden="true"></span>
        建物で選ぶ<br>
        改行
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_PriceIcon" aria-hidden="true"></span>
        料金一覧から選ぶ
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_VoiceIcon" aria-hidden="true"></span>
        ご利用者様の声
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        <span class="sw-BoxNavA_StrengthIcon" aria-hidden="true"></span>
        選ばれる理由 
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
</div>
```

<span class="sw-Label">CA-7B</span>

- テキストメインの例
- 2カラムと3カラム
- 用途を示すアイコンなし

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavA_Items sw-BoxNavA_Items-col2Lg">
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        学会・講演会<br>
        （試験会場、セミナー）
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        展示会<br>
        （商品発表会）
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
<ul class="sw-BoxNavA_Items sw-BoxNavA_Items-col3Lg">
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        学会・講演会<br>
        （試験会場、セミナー）
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        展示会<br>
        （商品発表会）
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        懇親会<br>
        （パーティー）
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
</div>
```

<span class="sw-Label">CA-7C</span>

- テキストメインの例
- 3カラム
- 用途を示すアイコンなし
- 補足情報のテキストあり

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavA_Items sw-BoxNavA_Items-col3Lg">
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        KRPオフィスツアーお申込み
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
      <div class="sw-BoxNavA_BoxFoot">
        <div class="sw-BoxNavA_BoxDescription">KRPのオフィスを見学いただける体験会を常時実施しています。</div>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        KRP ご入居様 専用サイト
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
      <div class="sw-BoxNavA_BoxFoot">
        <div class="sw-BoxNavA_BoxDescription">京都リサーチパークで活動されている方々に必要なお手続きや、お得なサービス情報についてお知らせしています。</div>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavA_Item">
    <a class="sw-BoxNavA_Box" href="#">
      <div class="sw-BoxNavA_BoxBody">
        お問い合わせ<br>
        改行レイアウト
        <span class="sw-BoxNavA_Icon" aria-hidden="true"></span>
      </div>
      <div class="sw-BoxNavA_BoxFoot">
        <div class="sw-BoxNavA_BoxDescription">御社へ出向いての出張プレゼンもご相談ください。</div>
      </div>
    </a>
  </li>
</ul>
</div>
```

*/
.sw-BoxNavA_Items {
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    margin-left: -30px;

    & + & {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavA_Item {
  display: inline-block;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;
  width: 100%;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    padding-left: 30px;

    & + & {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavA_Items-col2Lg {
  @include mq-up(lg) {
    & > .sw-BoxNavA_Item {
      width: percentage(div(1, 2));
    }

    & > .sw-BoxNavA_Item + .sw-BoxNavA_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavA_Item:nth-of-type(n+3) {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavA_Items-col3Lg {
  @include mq-up(lg) {
    & > .sw-BoxNavA_Item {
      width: percentage(div(1, 3));
    }

    & > .sw-BoxNavA_Item + .sw-BoxNavA_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavA_Item:nth-of-type(n+4) {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavA_Box {
  display: block;
  padding: 20px 30px;
  text-decoration: none;
  color: $color-brand;
  background-color: #fff;
  border: 1px solid $color-brand;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  position: relative;

  @include mq-up(lg) {
    padding: 0 40px;
  }

  &:hover {
    text-decoration: none;
    color: $color-brand;
    background-color: #fdeeee;
  }
}

.sw-BoxNavA_BoxBody {
  position: static;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;
  text-align: center;

  @include mq-up(lg) {
    position: relative;
    padding: 32px 0;
    font-size: rem(20, lg);
  }
}

.sw-BoxNavA_BoxFoot {
  margin-right: 0;
  margin-left: 0;
  padding-top: 10px;

  @include mq-up(lg) {
    padding: 0 0 32px;
  }
}

.sw-BoxNavA_BoxDescription {
  font-size: rem(13);
  line-height: 1.6;
  color: $color-text;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-BoxNavA_Icon {
  &:before {
    @include Icon("icon_arrow-right");
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    font-size: 0.8em;
    @include mq-up(lg) {
      right: -28px;
    }
  }
}

.sw-BoxNavA_RoomIcon {
  &:before {
    @include Icon("icon_space");
    position: relative;
    top: -0.1em;
    right: 21px;
    font-size: 1.7em;
  }
}

.sw-BoxNavA_PeopleIcon {
  &:before {
    @include Icon("icon_people");
    position: relative;
    top: -0.1em;
    right: 22px;
    font-size: 1.7em;
  }
}

.sw-BoxNavA_FacilityIcon {
  &:before {
    @include Icon("icon_build-facility");
    position: relative;
    top: -0.1em;
    right: 22px;
    font-size: 1.7em;
  }
}

.sw-BoxNavA_PriceIcon {
  &:before {
    @include Icon("icon_price");
    position: relative;
    top: -0.1em;
    right: 22px;
    font-size: 1.2em;
  }
}

.sw-BoxNavA_VoiceIcon {
  &:before {
    @include Icon("icon_voice");
    position: relative;
    top: -0.1em;
    right: 22px;
    font-size: 1.6em;
  }
}

.sw-BoxNavA_StrengthIcon {
  &:before {
    @include Icon("icon_strengt");
    position: relative;
    top: -0.05em;
    right: 22px;
    font-size: 1.6em;
  }
}
