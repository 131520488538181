/*
---
name: sw-ListUnorder
namespace: SiteWide
category: List
id: [K2, LT-1]
---

<span class="sw-Label">LT-1</span>

順序をもたない並列なリストです。

```html
<div class="st-Wrapper">
<ul class="sw-ListUnorder">
  <li>サイトマップ</li>
  <li>プライバシーポリシー
    <ul class="sw-ListUnorder_Child">
      <li>ご利用にあたって</li>
      <li>ご利用にあたって</li>
    </ul>
  </li>
  <li>サイトマップ</li>
</ul>
</div>
```

順序ありリストモジュールと併用したパターンです。

```html
<div class="st-Wrapper">
<ul class="sw-ListUnorder">
  <li>サイトマップ</li>
  <li>プライバシーポリシー
    <ol class="sw-ListOrder">
      <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
      <li>入れ子に対応した順序リストです。</li>
      <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
    </ol>
  </li>
  <li>サイトマップ</li>
</ul>
</div>
```
*/
.sw-ListUnorder {
  margin-left: (div(16, 12)) * 1em;
  padding-left: 0;
  line-height: 1.4;
  list-style-type: none;
  font-size: rem(14);

  & > li {
    position: relative;
  }

  & > li:nth-of-type(n+2) {
    margin-top: 9px;

    // 別のリストモジュール内にある時
    ul &,
    ol & {
      margin-top: 9px;
    }
  }

  & > li:before {
    content: "";
    display: block;
    position: absolute;
    top: 0.5em;
    left: -1em;
    width: 6px;
    height: 6px;
    border: 3px solid #878787;
    border-radius: 50%;
  }

  @include mq-up(lg) {
    font-size: rem(16, lg);

    & > li:nth-of-type(n+2) {
      margin-top: 10px;

      ul &,
      ol & {
        margin-top: 10px;
      }
    }
  }
}

.sw-ListUnorder_Child {
  margin-top: 9px;
  padding-left: 0;
  list-style-type: none;

  & > li {
    position: relative;
    margin-left: 1.5em;
  }

  & > li:nth-of-type(n+2) {
    margin-top: 9px;
  }

  & > li:before {
    content: "";
    display: block;
    position: absolute;
    top: 0.5em;
    left: -1em;
    width: 6px;
    height: 6px;
    border: 3px solid #878787;
    border-radius: 50%;
    background-color: #fff;
  }

  @include mq-up(lg) {
    margin-top: 10px;

    & > li:nth-of-type(n+2) {
      margin-top: 10px;
    }
  }
}
