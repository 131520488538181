/*
---
name: st2-Block
namespace: Structure2
category: Layout
---

見出しや画像などを含んだブロック同士の余白を指定します。

```html
<div class="st-Wrapper">
  <div class="st2-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
  <div class="st2-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.st2-Block {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-block;

    @include mq-up(lg) {
      margin-top: rem(60, lg);
    }
  }
}
