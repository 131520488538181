/*
---
name: sw2-PageLinkImage
namespace: SiteWide2
category: InteractiveComponent
---

画像とテキストリンクありのパーツです。

```html
<div class="st-Block">
  <div class="sw2-PageLinkImage">
    <div class="sw2-PageLinkImage_Heading">KRPは新ビジネス・新産業創出のための<br>魅力的な交流の舞台を提供しています</div>
    <div class="sw2-PageLinkImage_Box">
      <div class="sw2-PageLinkImage_LinkList">
        <ol>
          <li>
            <a href="#explanation01" class="-anc-link">
              <div class="sw2-PageLinkImage_LinkListItem">
                <div class="sw2-PageLinkImage_LinkListItem_Heading">オフィス・ラボ</div>
                <div class="sw2-PageLinkImage_LinkListItem_Text">OFFICE & RESEARCH SPACES</div>
              </div>
            </a>
          </li>
          <li>
            <a href="#explanation02" class="-anc-link">
              <div class="sw2-PageLinkImage_LinkListItem">
                <div class="sw2-PageLinkImage_LinkListItem_Heading">ホール・会議室</div>
                <div class="sw2-PageLinkImage_LinkListItem_Text">CONVENTION</div>
              </div>
            </a>
          </li>
          <li>
            <a href="#explanation03" class="-anc-link">
              <div class="sw2-PageLinkImage_LinkListItem">
                <div class="sw2-PageLinkImage_LinkListItem_Heading">イノベーション創発活動</div>
                <div class="sw2-PageLinkImage_LinkListItem_Text">NEW BUSINESS CREATION</div>
              </div>
            </a>
          </li>
        </ol>
      </div>
      <div class="sw2-PageLinkImage_LinkImage">
        <div class="sw2-PageLinkImage_LinkImageIn">
          <div class="sw2-PageLinkImage_LinkImageItem">
            <picture>
              <img src="/assets/img/index/linkimage_01.jpg" alt="オフィス・ラボ" loading="lazy">
            </picture>
          </div>
          <div class="sw2-PageLinkImage_LinkImageItem">
            <picture>
              <img src="/assets/img/index/linkimage_02.jpg" alt="ホール・会議室" loading="lazy">
            </picture>
          </div>
          <div class="sw2-PageLinkImage_LinkImageItem">
            <picture>
              <img src="/assets/img/index/linkimage_03.jpg" alt="イノベーション創発活動" loading="lazy">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
@keyframes LinkListItemfadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}
@keyframes LinkListItemfadeOut {
  0% {opacity: 1}
  100% {opacity: 0}
}

.sw2-PageLinkImage {
}
.sw2-PageLinkImage_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: rem(24);
  line-height: rem(38);
  margin-bottom: rem(36);
  @include mq-up(lg) {
    font-size: rem(30, lg);
    line-height: rem(48, lg);
    margin-bottom: rem(30, lg);
    text-align: center;
  }
  @media print, screen and (min-width: $max-width) {
    margin-bottom: rem(60, lg);
  }
  & > br {
    display: none;
    @include mq-up(lg) {
      display: initial;
    }
  }
}
.sw2-PageLinkImage_Box {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: rem(-30, lg);
    @media print, screen and (min-width: $max-width) {
      margin-left: rem(-100, lg);
    }
  }
  & > div {
    @include mq-up(lg) {
      width: 50%;
      padding-left: rem(30, lg);
    }
    @media print, screen and (min-width: $max-width) {
      padding-left: rem(100, lg);
    }
  }
}
.sw2-PageLinkImage_LinkList {
  counter-reset: pl-counter;
  @media print, screen and (min-width: $max-width) {
    width: rem(600, lg) !important;
  }
  & > ol {
    margin: 0;
    padding: 0;
    & > li {
      counter-increment: pl-counter;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      &::before {
        content: counter(pl-counter,decimal-leading-zero);
        font-family: $font-family-montserrat;
        font-weight: 600;
        letter-spacing: 0.05em;
        font-size: rem(14);
        line-height: rem(14);
        color: $color-krp;
        position: absolute;
        top: rem(24);
        left: 0;
        @include mq-up(lg) {
          font-size: rem(16, lg);
          line-height: rem(16, lg);
          top: rem(32, lg);
        }
      }
      &::after {
        @include Icon("icon_arrow-down02");
        vertical-align: baseline;
        color: $color-krp;
        position: absolute;
        top: 50%;
        right: rem(10);
        transform: translate(0, -50%);
        @include mq-up(lg) {
          font-size: rem(18, lg);
        }
      }
    }
  }
  a {
    display: block;
    text-decoration: none;
    color: #000;
    position: relative;
    transition-duration: 0.5s;
    transition-timing-function: $transition-timing-function;

    &::after {
      @include mq-up(lg) {
        content: "";
        display: block;
        width: 0;
        height: 1px;
        background-color: $color-krp;
        position: absolute;
        bottom: 0;
        left: 0;
        transition-duration: 0.5s;
        transition-timing-function: $transition-timing-function;
      }
    }
    &:hover {
      @include mq-up(lg) {
        color: $color-krp;
      }
      &::after {
        width: 100%;
      }
    }
  }
}
.sw2-PageLinkImage_LinkListItem {
  padding: rem(24) rem(37);
  border-bottom: 1px solid #E4E7E8;
  @include mq-up(lg) {
    padding: rem(32, lg) rem(50, lg);
  }
}
.sw2-PageLinkImage_LinkListItem_Heading {
  font-family: $font-family-shippori-mincho;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.05em;
  font-size: rem(24);
  line-height: rem(24);
  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(32, lg);
  }
}
.sw2-PageLinkImage_LinkListItem_Text {
  font-family: $font-family-lato;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #9CA7AC;
  font-size: rem(11);
  line-height: rem(11);
  margin-top: rem(20);
  @include mq-up(lg) {
    font-size: rem(12, lg);
    line-height: rem(12, lg);
    margin-top: rem(20, lg);
  }
}
.sw2-PageLinkImage_LinkImage {
  margin-top: rem(35);
  margin-right: rem(-20);
  margin-left: rem(-20);
  @include mq-up(lg) {
    margin-top: rem(0);
    margin-right: rem(0);
    margin-left: rem(0);
  }

  // slick CORE
  .slick-slider { position: relative; display: block; box-sizing: border-box; user-select: none; touch-action: pan-y; -webkit-touch-callout: none; -khtml-user-select: none; -webkit-tap-highlight-color: transparent;}
  .slick-list { position: relative; display: block; overflow: hidden; margin: 0; padding: 0;}
  .slick-list:focus {outline: none;}
  .slick-list.dragging {cursor: pointer;cursor: hand;}
  .slick-slider .slick-track,.slick-slider .slick-list {transform: translate3d(0, 0, 0);}
  .slick-track {position: relative;top: 0;left: 0;display: block;margin-left: auto;margin-right: auto;}
  .slick-track:before,.slick-track:after {display: table;content: '';}
  .slick-track:after {clear: both;}
  .slick-loading .slick-track {visibility: hidden;}
  .slick-slide {display: none;float: left;height: 100%;min-height: 1px;}
  [dir='rtl'] .slick-slide {float: right;}
  .slick-slide img {display: block;}
  .slick-slide.slick-loading img {display: none;}
  .slick-slide.dragging img {pointer-events: none;}
  .slick-initialized .slick-slide {display: block;}
  .slick-loading .slick-slide {visibility: hidden;}
  .slick-vertical .slick-slide {display: block;height: auto;border: 1px solid transparent;}
  .slick-arrow.slick-hidden {display: none;}
  // -----------------------------------------------

}
.sw2-PageLinkImage_LinkImageIn {
  @include mq-up(lg) {
    position: relative;
    height: 100%;
  }
  @media print, screen and (min-width: $max-width) {
    margin-right: calc(((((100vw - $max-width) / 2))) * -1);
  }

}
.sw2-PageLinkImage_LinkImageItem {
  width: 100%;
  &:first-child {
    display: block;
    @include mq-up(lg) {
      position: relative;
      z-index: 3;
    }
  }
  @include mq-up(lg) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:nth-child(2) {
    @include mq-up(lg) {
      z-index: 2;
    }
  }
  &:nth-child(3) {
    @include mq-up(lg) {
      z-index: 1;
    }
  }
  img {
    width: 100%;
  }
}
.sw2-PageLinkImage_Text {
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(25);
  margin-bottom: rem(20);
  @include mq-up(lg) {
    text-align: center;
    margin-bottom: rem(60, lg);
  }
  & > br {
    display: none;
    @include mq-up(lg) {
      display: initial;
    }
  }
}
.sw2-PageLinkImage.-is-innovation {
  .sw2-PageLinkImage_Heading {
    font-family: $font-family-sans-serif;
    margin-bottom: rem(20);
    @include mq-up(lg) {
      font-size: rem(32, lg);
      line-height: rem(32, lg);
      margin-bottom: rem(24, lg);
    }
    @media print, screen and (min-width: $max-width) {
      margin-bottom: rem(24, lg);
    }
  }

  .sw2-PageLinkImage_LinkList {
    & > ol {
      & > li {
        &::before {
          top: rem(30);
          @include mq-up(lg) {
            top: rem(45, lg);
          }
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .sw2-PageLinkImage_LinkListItem {
    padding: rem(24) rem(0) rem(24) rem(34);
    @include mq-up(lg) {
      padding: rem(40, lg) rem(50, lg);
    }
  }
  .sw2-PageLinkImage_LinkListItem_Heading {
    font-family: $font-family-sans-serif;
    font-size: rem(24);
    line-height: rem(34);
    @include mq-up(lg) {
      font-size: rem(32, lg);
      line-height: rem(32, lg);
    }
  }
}