/*
---
name: st-Section4
namespace: Structure
category: Layout
---

h4相当の見出しをラップするセクションの余白を指定します。


レイアウト例です。

```html
<div class="st-Wrapper">
<section class="st-Section3">
  <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>

  <section class="st-Section4">
    <h4 class="sw-Heading4A st-Heading4">京都バイオ計測センター</h4>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>

  <section class="st-Section4">
    <h4 class="sw-Heading4A st-Heading4">京都バイオ計測センター</h4>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>

<section class="st-Section3">
  <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>

  <section class="st-Section4">
    <h4 class="sw-Heading4A st-Heading4">京都バイオ計測センター</h4>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>
</section>
</div>
```
*/
.st-Section4 {
  &[id]:before {
    content: "";
    display: block;
    margin-top: -120px;
    padding-top: 120px;
  }
  
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section4;

    @include mq-up(lg) {
      margin-top: $margin-section4-lg;
    }
  }

  .st-Heading3 + & {
    margin-top: div($margin-section4, 2);

    @include mq-up(lg) {
      margin-top: div($margin-section4-lg, 2);
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
