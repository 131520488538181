/*
---
name: sw-Sup
namespace: SiteWide
category: Text
---

登録商標（アールマーク）や著作権（マルシーマーク）、注釈リンク（※1）などに使う上付き文字です。


```html
<p class="st-Text">登録商標<sup class="sw-Sup">®</sup>です。</p>
<p class="st-Text">注釈<sup class="sw-Sup"><a href="#">※1</a></sup>です。</p>
```
*/
.sw-Sup {
  font-size: 0.85em;
  position: static;
  vertical-align: super;
}
