/*
---
name: st2-Grids
namespace: Structure2
category: Layout
---

グリッドシステムです。親要素（`.st2-Grids`）にクラスを追加することで、1/2や1/3のような均等なカラムができます。

以下のような指定ができます。

- `.st2-Grids-col2Lg`を追加するとPC時1/2均等なカラム指定。
- `.st2-Grids-col3Lg`を追加するとPC時1/3均等なカラム指定。

### 均等なカラム指定。

```html
<div class="st-Wrapper">
  <ul class="st2-Grids st2-Grids-col3Lg">
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price01.jpg" alt="オフィス会員"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">オフィス会員</div>
            <div class="sw2-FeatureNavC_HeadingText">個室+ラウンジ利用+法人登記可</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">専用で使える個室にはオフィス家具・無線LANなど、ビジネスを開始する時に必要なものがすべて揃っているのですぐに仕事を始めていただけます。ラウンジ利用や秘書サービスはもちろん、KRP BIZ NEXTの住所もご利用いただけます。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/office/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price02.jpg" alt="ラウンジ会員（登記可）"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員（登記可）</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用+法人登記可</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ラウンジ利用に加えて、KRP BIZ NEXTの住所がご利用いただけるプランです。鍵付きロッカーを専用ポストとして、郵便物/宅配便の受取りも可能です。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/lounge/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price03.jpg" alt="ラウンジ会員"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ホテルラウンジのような贅沢な空間に、気兼ねなく大切なお客さまをお招きいただけます。電源と無線LANも完備しておりますので、デスクワークはもちろん、多彩なシーンでご利用いただけます。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/lounge/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
```
*/

.st2-Grids {
  margin: 0;
  padding: 0;
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-30, lg);
  }
  &.st2-Grids-col2Lg {
    .st2-Grids_Item {
      @include mq-up(lg) {
        width: percentage(div(1, 2));
      }
    }
  }
  &.st2-Grids-col3Lg {
    .st2-Grids_Item {
      @include mq-up(lg) {
        width: percentage(div(1, 3));
      }
    }
  }
}
.st2-Grids_Item {
  list-style: none;
  margin: rem(45) 0 0;
  padding: 0;
  &:first-child {
    margin: 0;
  }
  @include mq-up(lg) {
    padding-left: rem(30, lg);
    margin: 0;
    align-self: stretch;
  }
}
