/*
---
name: krpweek-FilterNav
namespace: krpweek
category: InteractiveComponent
id: [FL-A]
---

<span class="sw-Label">FL-A</span>

要素の絞り込みができるモジュールです。絞り込みリンクのいずれかを選択すると、それに紐づく記事が表示されます。

- 絞り込みリンク（`.krpweek-FilterNav_Button`）と表示される記事（`.krpweek-FilterBlock_Item`）にカスタムデータ属性の`data-filter`を指定します。
  - 絞り込みリンクの値は1つだけ指定できます。
    - 例：コンテスト `data-filter="contest"`
  - 絞り込みリンクの値を空にすると、すべての記事が表示されます。
    - 例：すべて `data-filter=""`
  - 記事には1つ以上の値が指定できます。
    - 例1：値を1つ指定 `data-filter='["contest"]'`
    - 例2：値を2つ指定 `data-filter='["trade-show", "contest"]'`

```html
<header class="krpweek-FilterNav">
  <div class="st-Wrapper">
    <div class="krpweek-FilterNav_Head">
      <p class="krpweek-FilterNav_Title" id="krpweek-FilterNav_Title" aria-controls="krpweek-FilterNav_Nav" aria-expanded="false" role="heading">
        イベント
        <span class="krpweek-FilterNav_Icon" aria-hidden="true"></span>
      </p>
    </div>

    <div class="krpweek-FilterNav_Nav" id="krpweek-FilterNav_Nav" aria-labelledby="st-PageCategory" aria-hidden="true">
      <ul class="krpweek-FilterNav_Items">
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="">すべて</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="lecture-meeting">講演会（セミナー・シンポジウム）</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="trade-show">展示会</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="contest">コンテスト</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="tour">見学・ツアー</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="parent-child-event">親子イベント</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="experience-event">体験イベント</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="meet-and-greet">交流会</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="flotation">起業／事業支援</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="international-cooperation">海外連携支援</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="ict">ICT</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="fabrication">ものづくり</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="life-science">ライフサイエンス</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="open-innovation">オープンイノベーション</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="safety-measure">安全対策</button>
        </li>
        <li class="krpweek-FilterNav_Item">
          <button class="krpweek-FilterNav_Button" type="button" data-filter="culture">カルチャー</button>
        </li>
      </ul>
    </div>
  </div>
</header>

<div class="st-ContainerA">
  <div class="st-Wrapper">
    <ul class="krpweek-FilterBlock">
      <li class="krpweek-FilterBlock_Item" data-filter='["lecture-meeting", "trade-show"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">UPDATE</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_AmLabel">午前</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">講演会（セミナー・シンポジウム）&展示会</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["trade-show", "contest"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_PmLabel">午後</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">展示会&コンテスト</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["contest"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_PmLabel">午後</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">コンテスト</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["tour"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_NightLabel">夜</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【1】「人の心と行動」を軸に売上と顧客を創る方法
            </h3>
            <p class="krpweek-MediaNav_Text">見学・ツアー</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["parent-child-event"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">UPDATE</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_AmLabel">午前</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">親子イベント</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["experience-event"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_NightLabel">夜</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【1】「人の心と行動」を軸に売上と顧客を創る方法
            </h3>
            <p class="krpweek-MediaNav_Text">体験イベント</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["meet-and-greet"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">UPDATE</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_AmLabel">午前</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">交流会</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["flotation"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_PmLabel">午後</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">起業／事業支援</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["international-cooperation"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_NightLabel">夜</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【1】「人の心と行動」を軸に売上と顧客を創る方法
            </h3>
            <p class="krpweek-MediaNav_Text">海外連携支援</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["ict"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">UPDATE</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_AmLabel">午前</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">ICT</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["fabrication"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_PmLabel">午後</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">ものづくり</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["life-science"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_NightLabel">夜</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【1】「人の心と行動」を軸に売上と顧客を創る方法
            </h3>
            <p class="krpweek-MediaNav_Text">ライフサイエンス</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["open-innovation"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">UPDATE</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_AmLabel">午前</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">オープンイノベーション</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["safety-measure"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_PmLabel">午後</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">有料</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_DefaultLabel">事前申込要</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【2】京都市ベンチャー企業
            </h3>
            <p class="krpweek-MediaNav_Text">安全対策</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>

      <li class="krpweek-FilterBlock_Item" data-filter='["culture"]'>
        <a href="#" class="krpweek-MediaNav">
          <div class="krpweek-MediaNav_ImageArea">
            <div class="krpweek-MediaNav_UpdateLabelArea">
              <span class="krpweek-MediaNav_UpdateLabel">NEW</span>
            </div>
            <img src="https://placehold.jp/170x109.png" alt="" class="krpweek-MediaNav_Image">
          </div>
          <div class="krpweek-MediaNav_Body">
            <ul class="krpweek-MediaNav_DetailLabelArea">
              <li class="krpweek-MediaNav_DetailLabel">
                <span class="krpweek-MediaNav_NightLabel">夜</span>
              </li>
            </ul>
            <h3 class="krpweek-MediaNav_Heading">
              <span class="krpweek-MediaNav_HeadingIcon" aria-hidden="true"></span>
              【1】「人の心と行動」を軸に売上と顧客を創る方法
            </h3>
            <p class="krpweek-MediaNav_Text">カルチャー</p>
            <p class="krpweek-MediaNav_EventTime">2017/07/22(土)　10:00〜12:00</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
```
*/
.krpweek-FilterNav {
  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.krpweek-FilterNav_Head {

}

.krpweek-FilterNav_Title {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 14px 40px 14px 20px;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px solid #393939;
  color: #fff;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  @include mq-up(lg) {
    display: none;
  }
}

.krpweek-FilterNav_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  transition-duration: 0.3s;

  .krpweek-FilterNav_Title[aria-expanded="true"] & {
    transform: rotate(-180deg);
  }
}

.krpweek-FilterNav_Nav {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: calc(50vw - 50%);
  padding-bottom: 15px;
  padding-left: calc(50vw - 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }
}

.krpweek-FilterNav_Items {
  display: block;
  margin-left: -2.5px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  line-height: 1.2;

  @include mq-up(lg) {
    text-align: center;

    .krpweek-FilterNav-right & {
      text-align: right;
    }
  }
}

.krpweek-FilterNav_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  padding-left: 2.5px;
  font-size: rem(9);
  text-align: center;

  &:nth-of-type(n+4) {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    font-size: rem(12, lg);

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    &:nth-of-type(n+1) {
      margin: 10px 0 15px;
    }

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 30px;
      border-left: 1px solid $color-brand;
    }
  }
}

.krpweek-FilterNav_Button {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px 5px;
  border: none;
  border-radius: 0;
  font-family: inherit;
  text-decoration: none;
  color: #fff;
  background-color: #393939;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 8px 15px;
    font-weight: bold;
    color: $color-brand;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -14px;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after,
    &.krpweek-FilterNav_Button-selected:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.krpweek-FilterNav_Link-current {
  background-color: #a81d34;

  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}
