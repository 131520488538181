/*
---
name: sw2-TitleA
namespace: SiteWide2
category: Heading
---

ページタイトルです。

```html
<div class="st-Wrapper">
<h2 class="sw2-TitleA">
<span class="sw2-TitleA_Text">プログラム・イベント</span>
<span class="sw2-TitleA_Heading">PROGRAM & <br>EVENT</span>
</h2>
</div>
```
*/
.sw2-TitleA_Text {
  font-weight: 700;
  font-size: rem(12);
  line-height: rem(12);
  margin-bottom: rem(8);
  display: block;
  color: $color-krp;
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(16, lg);
    margin-bottom: rem(16, lg);
  }
}
.sw2-TitleA_Heading {
  font-family: $font-family-lato;
  font-weight: 700;
  font-size: rem(42);
  line-height: rem(50);
  display: block;
  color: $color-krp;
  margin-bottom: rem(25);
  @include mq-up(lg) {
    font-size: rem(64, lg);
    line-height: rem(64, lg);
    margin-bottom: rem(40, lg);
  }
  & > br {
    display: initial;
    @include mq-up(lg) {
      display: none;
    }
  }
  & > em {
    font-style: normal;
    @include mq-up(lg) {
      display: block;
      text-align: right;
    }
  }
}