.krpweek-FilterBlock {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
}

.krpweek-FilterBlock_Item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-size: 1rem;
  border-bottom: 1px solid #e5e5e5;

  @include mq-up(lg) {
    width: 50%;
  }
}
