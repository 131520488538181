/*
---
name: sw-TitleA
namespace: SiteWide
category: Heading
id: [K2, HD-1A, HD-1B]
---

<span class="sw-Label">HD-1A</span>

ページタイトルです。KRP色の強いページで使用します。

```html
<div class="st-Wrapper">
<h1 class="sw-TitleA st-Title">NEWS & Activity<span class="sw-TitleA_Small">ニュース＆アクティビティ</span></h1>
</div>
```

<span class="sw-Label">HD-1B</span>

個々の特色を持つページでは`.sw-TitleA-dark`を追加します。

```html
<div class="st-Wrapper">
<h1 class="sw-TitleA sw-TitleA-dark st-Title">NEWS & Activity<span class="sw-TitleA_Small">ニュース＆アクティビティ</span></h1>
</div>
```
*/
.sw-TitleA {
  position: relative;
  font-family: $font-family-heading-en;
  font-size: rem(24);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: $color-brand;

  &:after {
    content: "";
    display: block;
    margin: 4px auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 7px 0 0;
    border-color: $color-brand transparent transparent transparent;
  }

  @include mq-up(lg) {
    font-size: rem(30, lg);
    line-height: 1.4;

    &:after {
      margin: 5px auto 0;
      border-width: 10px 14px 0 0;
    }
  }
}

.sw-TitleA-dark {
  color: $color-text;
}

.sw-TitleA_Small {
  display: block;
  font-size: rem(15, lg);
  line-height: 1.4;
  color: $color-brand;
}
