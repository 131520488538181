/*
---
name: sw-Button
namespace: SiteWide
category: [Button, Link, Form]
id: [K2, BT-1, BT-2, BT-3]
---

<span class="sw-Label">BT-1</span>

ボタンのデフォルトスタイルです。

- 幅は290px固定です。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button">固定幅ボタン<br>改行した場合のレイアウト<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
</div>
```

`.sw-Button-invert`を追加すると、フォントと背景色が反転します。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button sw-Button-invert">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-invert">固定幅ボタン<br>改行した場合のレイアウト<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
</div>
```

グレーなどの背景色の上に置く場合は`.sw-Button-dark`のように指定します。

```html
<div class="st-Wrapper">
<div style="background: #666;">
  <a href="#" class="sw-Button sw-Button-dark">矢印付きボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  <a href="#" target="_blank" class="sw-Button sw-Button-dark">別窓リンクボタン<span class="sw-Button_External" aria-hidden="true"></span></a>
  <button type="button" class="sw-Map_Button sw-Button-dark js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button>
  <a href="#" class="sw-Button sw-Button-dark"><span class="sw-Button_Map" aria-hidden="true"></span>Google Mapでみる</a>
</div>
</div>
```

<span class="sw-Label">BT-2</span>

`.sw-Button-full`を追加すると、100%表示になります。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button sw-Button-full">カラム幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-full">固定幅ボタン<br>改行した場合のレイアウト<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
</div>
```

<span class="sw-Label">BT-3</span>

`.sw-Button-auto`を追加すると、なりゆきで幅が変わります。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button sw-Button-auto">文字数幅（成り行き）ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-auto">文字数幅（成り行き）ボタン 文字数幅（成り行き）ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
</div>
```

矢印付きのボタンの場合は`span.sw-Button_RightArrow`のように指定します。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button">矢印付きボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-invert">矢印付きボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-dark">矢印付きボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
</div>
```

別窓リンクの場合は`span.sw-Button_External`のように指定します。

```html
<div class="st-Wrapper">
<a href="#" target="_blank" class="sw-Button">別窓リンクボタン<span class="sw-Button_External" aria-hidden="true"></span></a>
<a href="#" target="_blank" class="sw-Button sw-Button-invert">別窓リンクボタン<span class="sw-Button_External" aria-hidden="true"></span></a>
<a href="#" target="_blank" class="sw-Button sw-Button-dark">別窓リンクボタン<span class="sw-Button_External" aria-hidden="true"></span></a>
</div>
```

印刷リンクの場合は`span.sw-Button_Print`のように指定します。

```html
<div class="st-Wrapper">
<button type="button" class="sw-Map_Button js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button>
<button type="button" class="sw-Map_Button sw-Button-invert js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button>
<button type="button" class="sw-Map_Button sw-Button-dark js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button>
</div>
```

Google Mapsリンクの場合は`span.sw-Button_Map`のように指定します。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button"><span class="sw-Button_Map" aria-hidden="true"></span>Google Mapでみる</a>
<a href="#" class="sw-Button sw-Button-invert"><span class="sw-Button_Map" aria-hidden="true"></span>Google Mapでみる</a>
<a href="#" class="sw-Button sw-Button-dark"><span class="sw-Button_Map" aria-hidden="true"></span>Google Mapでみる</a>
</div>
```

PDFリンクの場合は`span.sw-Button_Pdf`のように指定します。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button">駐車場マップ（PDF）<span class="sw-Button_Pdf" aria-hidden="true"></span></a>
<a href="#" class="sw-Button sw-Button-invert"></span>駐車場マップ（PDF）<span class="sw-Button_Pdf" aria-hidden="true"></a>
<a href="#" class="sw-Button sw-Button-dark">駐車場マップ（PDF）<span class="sw-Button_Pdf" aria-hidden="true"></span></a>
</div>
```

`<a>`、`<button>`、`<input>`での表示です。

```html
<div class="st-Wrapper">
<a href="#" class="sw-Button">aタグボタン</a><a href="#" class="sw-Button sw-Button-disabled">aタグボタン</a>
<button type="button" class="sw-Button">buttonタグボタン</button>
<button disabled="disabled" type="button" class="sw-Button">buttonタグボタン</button>
<input type="button" value="inputタグボタン" class="sw-Button">
<input type="button" value="inputタグボタン" disabled="disabled" class="sw-Button">
</div>
```
*/
.sw-Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;

  &.-is-square {
    border-radius: 0;
    width: 200px;
    padding-left: 0;
    padding-right: 0;
    @include mq-up(lg) {
      width: 140px;
      padding-top: 11px;
      padding-bottom: 11px;
      font-size: 12px;
      line-height: 12px;
    }
    & > span {
      display: none;
    }
    & > em {
      position: relative;
      &::after {
        @include Icon("icon_arrow-right");
        display: inline-block;
        font-size: 10px;
        line-height: 18px;
        margin-left: 12px;
        vertical-align: initial;
        @include mq-up(lg) {
          margin-left: 8px;
        }
      }
    }
  }
  &.-is-radius {
    @include mq-up(lg) {
      width: 190px;
      margin-left: rem(-30);
      border-radius: 100px;
      font-size: 16px;
    }
  }
}

.sw-Button-invert {
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: $color-brand;
    background-color: #fff;
  }
}

.sw-Button-dark {
  border-color: #fff;
  color: #fff;
  background-color: #666;

  &:hover {
    border-color: #fff;
    color: #666;
    background-color: #fff;
  }
}

.sw-Button-full {
  width: 100%;
  max-width: none;
}

.sw-Button-auto {
  width: auto;
}

.sw-Button_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;

  @include mq-up(lg) {
    right: 12px;
  }
}

.sw-Button_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;

  @include mq-up(lg) {
    right: 15px;
  }
}

.sw-Button_Print:before {
  @include Icon("icon_print");
  position: relative;
  top: -0.05em;
  left: 0;
  margin-right: 10px;
  font-size: 1.2em;

  @include mq-up(lg) {
    top: -0.1em;
    margin-right: 8px;
  }
}

.sw-Button_Map:before {
  @include Icon("icon_access-pin");
  position: relative;
  top: -0.05em;
  left: 0;
  margin-right: 10px;
  font-size: 1.2em;

  @include mq-up(lg) {
    top: -0.1em;
    margin-right: 8px;
  }
}

.sw-Button_Pdf:before {
  @include sw-LinkPdf();
}