.companySearch {
  padding: $padding-conitaner-b;
  border-bottom: 1px solid #aaaaaa;
  background: #F6F8F9;
  @include mq-up(lg) {
    padding: $padding-conitaner-b-lg;
    border: none;
  }
}

.companySearch-wrapper {
  position: relative;
  max-width: 1028px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
  @include mq-up(lg) {
    padding: 0;
  }
}

.companySearch-menu {
  border-top: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  @include mq-up(lg) {
    border: none;
  }
}

.companySearch-menuTitle {
  padding: 12px 15px;
  color: #606060;
  font-size: 16px;
  // font-size: rem(13);
  border-bottom: 1px solid #aaaaaa;
  cursor: pointer;
  position: relative;
  @include mq-up(lg) {
    padding: 15px;
    // font-size: rem(16);
    width: percentage(div(489, 1028));
    height: 50px;
    // padding: 0 3em 0 1em;
    border: 1px solid #d0d0d0;
    border-radius: $form-border-radius;
    line-height: 1;
    vertical-align: middle;
    background-color: #fff;
    appearance: none;
    box-shadow: 1px 1px 6px -3px #9e9e9e inset;
  }
}

.companySearch-menuTitle_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  color: $color-brand;
  transition-duration: 0.3s;
  transition-property: transform;
  .companySearch-menuTitle.is-close & {
    transform: rotate(-90deg);
  }
}

.companySearch-menuContent {
  @include mq-up(lg) {
    padding-top: 30px;
  }
  .companySearch-menuTitle.is-close + & {
    display: none;
  }
}


// .companySearch-menuContent {
//   &[aria-hidden="true"] {
//     display: none;
//   }
//   @include mq-up(lg) {
//     padding-top: 30px;
//   }
// }

.companySearch-categoryTitle {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-bottom: 10px;
    font-size: rem(16);
  }
}

.companySearch-categoryList {
  padding: 0;
  list-style: none;
  font-size: rem(12);
  background: #F6F8F9;
  @include mq-up(lg) {
    display: flex;
    flex-wrap: wrap;
  }

  & > li {
    display: block;
    padding: 10px 10px 14px 10px;
    border-bottom: 1px solid #aaaaaa;
    @include mq-up(lg) {
      width: percentage(div(1, 4));
      border: none;
    }
  }
  .sw-FormCheckbox+label {
    display: block;
    &:before {
      background-color: #FFF;
    }
  }
}

/* 絞り込み */
.companySearch-refine {
  // border-top: 1px solid #aaaaaa;
  @include mq-up(lg) {
    margin-top: 10px;
    border: none;
    background-color: #F6E8E9;
  }
}

.companySearch-refineTitle {
  padding: 10px;
  color: #BE3134;
  font-size: rem(12);
  border-bottom: 1px solid #aaaaaa;
  @include mq-up(lg) {
    font-size: rem(16);
    border: none;
  }
}

.companySearch-refineList {
  padding: 0;
  list-style: none;
  font-size: rem(12);
  background-color: #F6E8E9;
  @include mq-up(lg) {
    display: flex;
    flex-wrap: wrap;
  }

  & > li {
    display: block;
    padding: 10px 10px 14px 10px;
    border-bottom: 1px solid #aaaaaa;
    @include mq-up(lg) {
      // display: inline-block;
      width: percentage(div(1, 4));
      border: none;
    }
    // & + li {
    //   border-top: 1px solid #aaaaaa;
    //   @include mq-up(lg) {
    //     border: none;
    //   }
    // }
  }
  .sw-FormCheckbox+label {
    display: block;
    &:before {
      background-color: #FFF;
    }
  }
}

.companySearch-inputWrap {
  margin-top: 15px;
  @include mq-up(lg) {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: percentage(div(508, 1028));
    width: percentage(div(270, 1028));
  }
}

.companySearch-input {
  height: 50px;
  @include sw-FormInput();
  transform:none;
  margin-left: 0;
  width: 100%;
}

.companySearch-button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 260px;
  @include mq-up(lg) {
    margin-top: 0;
    width: percentage(div(215, 1028));
    position: absolute;
    top: 0;
    right: 0;
  }
}



