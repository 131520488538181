/*
---
name: FormCheckbox
namespace: SiteWide
category: Form
id: FO-4
---

<span class="sw-Label">FO-4</span>

`type="checkbox"`を縦にレイアウトします。

```html
<div class="st-Wrapper">
<fieldset class="inquiry-Layout_Item">
  <legend class="sw-FromLabel">タイプ</legend>
  <ul class="inquiry-Layout_Stack inquiry-Layout_Stack-checkbox">
    <li class="inquiry-Layout_StackItem">
      <input type="checkbox" id="type1" class="sw-FormCheckbox" name="type1">
      <label for="type1">オフィススペース</label>
    </li>
    <li class="inquiry-Layout_StackItem">
      <input type="checkbox" id="type2" class="sw-FormCheckbox" name="type2">
      <label for="type2">実験研究スペース（ウェットラボ仕様）</label>
    </li>
  </ul>
</fieldset>
</div>
```

`type="checkbox"`をグリッドでレイアウトします。

```html
<div class="st-Wrapper">
<fieldset class="inquiry-Layout_Item">
  <legend class="sw-FromLabel">タイプ</legend>
  <ul class="inquiry-Layout_Horizontal inquiry-Layout_Horizontal-checkbox">
    <li class="inquiry-Layout_HorizontalItem">
      <input type="checkbox" id="type3" class="sw-FormCheckbox" name="type3">
      <label for="type3">オフィススペース</label>
    </li>
    <li class="inquiry-Layout_HorizontalItem">
      <input type="checkbox" id="type4" class="sw-FormCheckbox" name="type4">
      <label for="type4">実験研究スペース（ウェットラボ仕様）</label>
    </li>
  </ul>
</fieldset>
</div>
```
*/
.sw-FormCheckbox {
  @include sw-FormCheckbox();
}
