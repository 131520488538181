/*
---
name: sw2-MediaA
namespace: SiteWide2
category: Block
---

画像とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-MediaA">
      <div class="sw2-MediaA_ImageArea">
        <div class="sw2-MediaA_ImageWrap">
          <picture>
            <img src="https://placehold.jp/570x290.png" alt="" class="sw2-MediaA_Image" loading="lazy">
          </picture>
        </div>
      </div>
      <div class="sw2-MediaA_Body">
        <div class="sw2-MediaA_Logo">
          <picture>
            <img src="https://placehold.jp/100x60.png" alt="" class="sw2-MediaA_Logo_Image" loading="lazy">
          </picture>
        </div>
        <p class="sw2-MediaA_Heading">KRP BIZ NEXT（サービスオフィス）</p>
        <p class="sw2-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの1～5名様向けのサービスオフィス。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。</p>
        <div class="sw2-MediaA_ButtonArea">
          <a href="#" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

*/
.sw2-MediaA {
  margin-left: rem(-20);
  margin-right: rem(-20);
  padding: rem(50) rem(20);
  background-color: #EDEFF0;

  @include mq-up(lg) {
    margin-left: rem(0);
    margin-right: rem(0);
    padding: rem(40, lg) rem(60, lg) rem(40, lg) rem(40, lg);
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
  }

}

.sw2-MediaA_ImageArea {
  margin-bottom: rem(20);
  @include mq-up(lg) {
    width: 50%;
    align-self: stretch;
    margin-bottom: rem(0);
  }

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.-is-col2 {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      width: 50%;
      align-self: stretch;
    }
  }
}
.sw2-MediaA_Body {
  @include mq-up(lg) {
    width: 50%;
    padding-left: rem(60, lg);
  }
}
.sw2-MediaA_Logo {
  text-align: center;
  margin-bottom: rem(20);
  @include mq-up(lg) {
    margin-bottom: rem(20, lg);
  }
}
.sw2-MediaA_Heading {
  font-size: rem(20);
  line-height: rem(29);
  font-weight: bold;
  text-align: center;
  margin-bottom: rem(10);
  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(29, lg);
    margin-bottom: rem(16, lg);
  }
}
.sw2-MediaA_Text {
  font-size: rem(13);
  line-height: rem(23);
  margin-bottom: rem(20);
  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: rem(21, lg);
    margin-bottom: rem(20, lg);
  }
}
.sw2-MediaA_ButtonArea {
  text-align: center;
}