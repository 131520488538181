/*
---
name: st2-FooterRegistration
namespace: Structure2
category: Global
---

メールやFacebookなどの登録を促すエリアです。

```html
<section class="st2-FooterRegistration">
  <h3 class="st2-FooterRegistration_Heading">KRPからのお知らせを受け取る</h3>
  <p class="st2-FooterRegistration_Description">KRPで開催するイノベーション創出と交流のための情報や、<br>コラボレーション事例などをお届けしています。</p>
  <div class="st2-FooterRegistration_Inner">
    <ul class="st2-FooterRegistration_Items">
      <li class="st2-FooterRegistration_Item">
        <a class="st2-FooterRegistration_Link" href="#">
          <span class="st2-FooterRegistration_MailIcon" aria-hidden="true"></span>
          <span class="st2-FooterRegistration_Text">メール会員登録</span>
        </a>
      </li>
      <li class="st2-FooterRegistration_Item">
        <a class="st2-FooterRegistration_Link" href="#" target="_blank" rel="noopener">
          <span class="st2-FooterRegistration_FacebookIcon" aria-hidden="true"></span>
          <span class="st2-FooterRegistration_Text-en">Facebook</span>
        </a>
      </li>
      <li class="st2-FooterRegistration_Item">
        <a class="st2-FooterRegistration_Link" href="#" target="_blank" rel="noopener">
          <span class="st2-FooterRegistration_TwitterIcon" aria-hidden="true"></span>
          <span class="st2-FooterRegistration_Text-en">X</span>
        </a>
      </li>
      <li class="st2-FooterRegistration_Item">
        <a class="st2-FooterRegistration_Link" href="#" target="_blank" rel="noopener">
          <span class="st2-FooterRegistration_InstagramIcon" aria-hidden="true"></span>
          <span class="st2-FooterRegistration_Text-en">Instagram</span>
        </a>
      </li>
    </ul>
  </div>
</section>
```
*/
.st2-FooterRegistration {
  background: url("/assets/img/structure2/FooterRegistration/bg_sp.webp") center center no-repeat;
  background-size: cover;
  padding: rem(60) rem(20);

  @include mq-up(lg) {
    background: url("/assets/img/structure2/FooterRegistration/bg_pc.webp") center center no-repeat;
    background-size: cover;
    padding: rem(60, lg) rem(20, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding: rem(80, lg) rem(0);
  }
}
.st2-FooterRegistration_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: rem(22);
  line-height: rem(22);
  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(32, lg);
  }
}

.st2-FooterRegistration_Description {
  color: #fff;
  text-align: center;
  margin-top: rem(20);
  font-size: rem(12);
  line-height: rem(18);
  @include mq-up(lg) {
    margin-top: rem(32, lg);
    font-size: rem(14, lg);
    line-height: rem(28, lg);
  }
}

.st2-FooterRegistration_Inner {
  margin-top: rem(30);
  @include mq-up(lg) {
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
  }
}
.st2-FooterRegistration_Items {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  padding: rem(16) rem(30);

  @include mq-up(lg) {
    padding: rem(0);
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.st2-FooterRegistration_Item {
  list-style: none;
  border-top: 1px solid #fff;

  @include mq-up(lg) {
    border-top: none;
    width: 25%;
    position: relative;
  }

  &::after {
    @include mq-up(lg) {
      content: "";
      display: block;
      width: rem(1, lg);
      height: rem(70, lg);
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  &:first-child {
    border-top: none;

    &::after { 
      display: none;
    }
  }
}
.st2-FooterRegistration_Link {
  text-decoration: none;
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: rem(16) rem(10);
  color: #fff;
  @include mq-up(lg) {
    display: block;
    text-align: center;
    padding: rem(40, lg) rem(0);
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }

  &:hover {
    @include mq-up(lg) {
      color: $color-krp;
    }
  }
}
.st2-FooterRegistration_Text {
  display: block;
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(14);
  @include mq-up(lg) {
    font-weight: 500;
    margin-top: rem(20, lg);
    font-size: rem(16, lg);
    line-height: rem(16, lg);
  }
}
.st2-FooterRegistration_Text-en {
  display: block;
  font-family: $font-family-lato;
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(16);
  @include mq-up(lg) {
    margin-top: rem(20, lg);
    font-size: rem(18, lg);
    line-height: rem(18, lg);
  }
}
.st2-FooterRegistration_MailIcon {
  display: block;
  margin-right: rem(24);
  @include mq-up(lg) {
    margin-right: rem(0);
  }
  &::before {
    @include Icon("icon_mail");
    font-size: rem(28);
    @include mq-up(lg) {
      font-size: rem(32, lg);
    }
  }
}
.st2-FooterRegistration_FacebookIcon {
  display: block;
  margin-right: rem(24);
  @include mq-up(lg) {
    margin-right: rem(0);
  }
  &::before {
    @include Icon("icon_facebook");
    font-size: rem(28);
    @include mq-up(lg) {
      font-size: rem(32, lg);
    }
  }
}
.st2-FooterRegistration_TwitterIcon {
  display: block;
  margin-right: rem(24);
  @include mq-up(lg) {
    margin-right: rem(0);
  }
  &::before {
    @include Icon("icon_twitter");
    font-size: rem(28);
    @include mq-up(lg) {
      font-size: rem(32, lg);
    }
  }
}
.st2-FooterRegistration_InstagramIcon {
  display: block;
  margin-right: rem(24);
  @include mq-up(lg) {
    margin-right: rem(0);
  }
  &::before {
    @include Icon("icon_instagram");
    font-size: rem(28);
    @include mq-up(lg) {
      font-size: rem(32, lg);
    }
  }
}
