/*
---
name: serviceoffice-MediaB
namespace: serviceoffice
category: Block
id: [TH-12]
---

BIZ NEXTのメリットページで使用する、動画とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

<span class="sw-Label">TH-12</span>

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="serviceoffice-MediaB">
      <div class="serviceoffice-MediaB_Left">
        <img src="https://placehold.jp/570x600.png" alt="">
      </div>
      <div class="serviceoffice-MediaB_Right">
        <div class="serviceoffice-MediaB_RightHead">
          <img src="https://placehold.jp/570x410.png" alt="">
        </div>
        <div class="serviceoffice-MediaB_RightBody">
          <h2 class="serviceoffice-MediaB_Heading">上質感がつたわる家具・内装材でデザインしています。</h2>
          <p class="serviceoffice-MediaB_Text">ゆったりと背もたれにもたれながら打ち解けた会話ができる場、体を起こして集中する 商談の場、広めのテーブルで資料を広げながら打合せができる場、それぞれの場や気分 に合わせてご利用いただけるビジネスラウンジです。<br>
          窓際に電源付きのカウンター席を配置し、気分を変えての執務にもご利用いただけます。</p>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.serviceoffice-MediaB {
  margin-left: -15px;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.serviceoffice-MediaB_Left {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  .serviceoffice-MediaB_Right + & {
    margin-top: 30px;
  }

  @include mq-up(lg) {
    width: percentage(div(1, 2));
    padding-left: 30px;

    .serviceoffice-MediaB_Left + & {
      margin-top: 0;
    }
  }
}

.serviceoffice-MediaB_Right {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  .serviceoffice-MediaB_Left + & {
    margin-top: 30px;
  }

  @include mq-up(lg) {
    width: percentage(div(1, 2));
    padding-left: 30px;

    .serviceoffice-MediaB_Left + & {
      margin-top: 0;
    }

    & > :first-child {
      margin-top: 0;
    }
  }
}

.serviceoffice-MediaB_RightHead {
  margin-bottom: 8px;

  @include mq-up(lg) {
    margin-bottom: 16px;
  }
}

.serviceoffice-MediaB_RightBody {

}

.serviceoffice-MediaB_Heading {
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(20, lg);
  }
}

.serviceoffice-MediaB_Text {
  margin-top: 4px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    margin-top: 6px;
    font-size: rem(16, lg);
  }
}

.serviceoffice-MediaB_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.serviceoffice-MediaB_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.serviceoffice-MediaB_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.serviceoffice-MediaB_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}

.serviceoffice-MediaB_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.serviceoffice-MediaB_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.serviceoffice-MediaB_Link {
  @include sw-Link();
}

.serviceoffice-MediaB_LinkExternal:before {
  @include sw-LinkExternal();
}

.serviceoffice-MediaB_LinkPdf:before {
  @include sw-LinkPdf();
}

