/*
---
name: st-ButtonA
namespace: Structure
category: Layout
---

ボタンをスマホで1カラム、PCで横並びにします。

```html
<div class="st-Wrapper">
<ul class="st-ButtonA">
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
</ul>
</div>
```

`.st-ButtonA-center`を追加するとボタンをセンタリングできます。

```html
<div class="st-Wrapper">
<ul class="st-ButtonA st-ButtonA-center">
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
</ul>
</div>
```

`.st-ButtonA-centerLg`を追加するとボタンをPC幅からセンタリングできます。

```html
<div class="st-Wrapper">
<ul class="st-ButtonA st-ButtonA-centerLg">
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonA_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
</ul>
</div>
```
*/
.st-ButtonA {
  display: block;
  padding-left: 0;
  list-style-type: none;

  & + &,
  :not(#{&}) + & {
    margin-top: $margin-text;

    @include mq-up(lg) {
      margin-top: $margin-text-lg;
    }
  }

  .st-Heading2 + &,
  .st-Heading3 + &,
  .st-Heading4 + &,
  .st-Heading5 + &,
  .st-Lead + &,
  .st-Title + & {
    margin-top: 0;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq-up(lg) {

  }
}

.st-ButtonA-center {
  text-align: center;

  @include mq-up(lg) {
    & > .st-ButtonA_Item {
      float: none;
      display: inline-block;
    }
  }
}

.st-ButtonA-centerLg {
  @include mq-up(lg) {
    text-align: center;

    & > .st-ButtonA_Item {
      float: none;
      display: inline-block;
    }
  }
}

.st-ButtonA_Item {
  display: block;

  & + & {
    margin-top: 20px;
  }

  @include mq-up(lg) {
    float: left;
    & + & {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}
