// st-Header_LabPortal
.st-Header_LabPortal {
  & .st-Header_Outer {
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }

  & .st-Header_Logo {
    width: calc(100% - 60px);
    background-color: #fff;

    @include mq-up(lg) {
      width: 275px;
    }
    @media print, screen and (min-width: 1200px) {
      width: 300px;
    }
    @media print, screen and (min-width: 1300px) {
      width: 400px;
    }
  }

  & .st-HeaderLogo {
    background-color: transparent;
    height: auto;
    padding: 4px 6px 7px 13px;

    @include mq-up(lg) {
      padding: 20px 0 17px 20px;
    }

    @media print, screen and (min-width: 1200px) {
      padding: 22px 0 20px 40px;
    }
  }
}

.sw-HideLabPortal {
  @media print, screen and (min-width: 1200px) {
    &[class^="sw-Show"] {
      display: none;
    }
  }
}

.st-HeaderLogo_SiteLogoImage_LabPortal {
  width: 215px;

  @include mq-up(lg) {
    width: 275px;

    .st-Header-fixedActive & {
      display: none;
    }
  }

  @media print, screen and (min-width: 1200px) {
    width: 300px;
  }
  @media print, screen and (min-width: 1300px) {
    width: 400px;
  }
}

.st-HeaderLogo_SiteName_LabPortal {
  font-size: rem(10.5);
  font-weight: bold;
  color: #000;
  margin-left: -1px;

  @include mq-up(lg) {
    font-size: rem(14.5);
    margin-top: 5px;

    .st-Header-fixedActive & {
      display: none;
    }
  }
}

.st-HeaderNav_IconText {
  font-size: rem(9);
  margin-top: 5px;
}

// st-Header_Nav_LabPortal
.st-Header_Nav_LabPortal {
  &.st-Header_Nav {
    width: 60px;

    @include mq-up(lg) {
      width: calc(100% - 275px);
    }

    @media print, screen and (min-width: 1200px) {
      width: calc(100% - 300px);
    }
    @media print, screen and (min-width: 1300px) {
      width: calc(100% - 400px);
    }
  }

  & .st-Header_Menu {
    width: 100%;
  }

  & .st-HeaderToggleMenu {
    color: #000;
    background-color: #fff;

    &:hover {
      text-decoration: none;
      background-color: #fff;
      color: #000;
    }

    &[aria-expanded="true"] {
      color: #fff;
      background-color: #000;
    }

    &:after {
      content: "メニュー";
      bottom: 7px;
    }

    &[aria-expanded="true"] {
      &:after {
        content: "閉じる";
      }
    }
  }

  & .st-HeaderToggleMenu_Icon {
    top: 5.5px;
  }

  & .st-Header_Buttons {
    .st-Header-spMenuOpen & {
      width: 60px;
    }
  }

  & .st-HeaderSocial_Item:last-child {
    padding-top: 10px;
  }
}

// st-Header_NavArea_LabPortal
.st-Header_NavArea_LabPortal {
  @include mq-up(lg) {
    width: calc(100% - 275px);
  }

  @media print, screen and (min-width: 1200px) {
    width: calc(100% - 300px);
  }
  @media print, screen and (min-width: 1300px) {
    width: calc(100% - 400px);
  }

  &.st-Header_NavArea {
    @include mq-up(lg) {
      width: calc(100% - 275px);
    }

    @media print, screen and (min-width: 1200px) {
      width: calc(100% - 300px);
    }
    @media print, screen and (min-width: 1300px) {
      width: calc(100% - 400px);
    }
  }

  & .st-HeaderNav_Items {
    @include mq-up(lg) {
      margin-right: 10px;

      .st-Header-fixedActive & {
        padding: 22px 0;
      }
    }

    @media print, screen and (min-width: 1300px) {
      margin-right: 15px;
    }
  }

  & .st-HeaderNav_Link {
    @include mq-up(lg) {
      padding: 0 14px;
      line-height: 1.6;
    }

    @media print, screen and (min-width: 1200px) {
      padding: 0 20px;
    }

    &:hover:after {
      @include mq-up(lg) {
        bottom: -16px;
      }

      @media print, screen and (min-width: 1300px) {
        bottom: -22px;
      }
    }

    .st-Header-fixedActive &.st-HeaderNav_Link-current:after,
    .st-Header-fixedActive &:hover:after {
      @include mq-up(lg) {
        bottom: -26px;
      }
    }
  }

  & .st-HeaderNav_Link-icon {
    @include mq-up(lg) {
      padding: 0 5px;
    }

    &:first-child {
      @include mq-up(lg) {
        padding-left: 10px;
      }

      @media print, screen and (min-width: 1200px) {
        padding-left: 20px;
      }
    }
  }

  & .st-Header_SubGlobalNav {
    @include mq-up(lg) {
      display: block;
      height: 15px;
    }
  }

  & .st-HeaderSubNav_Link {
    @include mq-up(lg) {
      font-size: rem(10.6);
      font-weight: bold;
      color: #a3a3a3;
    }
  }

  & .st-HeaderNav_MailIcon {
    font-size: 30px;
  }

  .st-HeaderNav_DownloadIcon {
    @include mq-up(lg) {
      font-size: 25px;
      color: #1877f2;

      &:before {
        @include Icon("icon_download");
        width: 24px;
        height: 35px;
        font-size: 4.4rem;
      }
    }
  }

  & .st-HeaderNav_FacebookIcon {
    font-size: 30px;
  }

  & .st-HeaderNav_Link.st-HeaderNav_Link-current:after {
    @include mq-up(lg) {
      bottom: -16px;
    }

    @media print, screen and (min-width: 1300px) {
      bottom: -22px;
    }
  }
}

.st-HeaderSocial_Download {
  position: absolute;
  top: 0;
  left: 9px;
  bottom: 0;
  width: 42px;
  height: 24px;
  margin: auto;

  &:before {
    @include Icon("icon_download");
    font-size: 33px;
  }
}

.st-HeaderNav_Item_LabPortal {
  border-bottom: 1px solid #aaa;

  &:last-of-type {
    display: none;
  }

  &:first-child {
    display: none;

    @include mq-up(lg) {
      display: table-cell;
    }
  }

  @include mq-up(lg) {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 0;
    text-align: left;

    &:last-of-type {
      display: table-cell;

      .st-Header-fixedActive & {
        display: none;
      }
    }

    &:nth-child(5) {
      .st-Header-fixedActive & {
        display: none;
      }
    }
  }
}

.st-HeaderNav_LabPortal_Border {
  @include mq-up(lg) {
    border-right: 1px solid #e2e2e2;
    height: 22px;
  }
}

.st-Header-fixedActive {
  & .st-HeaderNav_Item_LabPortal:nth-child(4) {
    & .st-HeaderNav_LabPortal_Border {
      @include mq-up(lg) {
        border: none;
      }
    }
  }
}
