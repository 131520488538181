/*
---
name: sw-TextCaution
namespace: SiteWide
category: Text
id: [K2, TXT-6]
---

<span class="sw-Label">TXT-6</span>

`<b>`で使われるようなスタイルです。注意だけを引きたい場合に使うキーワードや製品名でリード文などに使用します。

```html
<div class="st-Wrapper">
<p class="st-Text">京都発で世界を目指すStartupを育成する拠点として<b class="sw-TextCaution">Tech-nology Startup Accelerator『迅』 -HAYATE-</b>を立ち上げました。</p>
</div>
```
*/
.sw-TextCaution {
  font-weight: bold;
}
