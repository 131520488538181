/*
---
name: sw-Heading2A
namespace: SiteWide
category: Heading
id: [K2, HD-3A, HD-3B]
---

<span class="sw-Label">HD-3A</span>

`<h2>`で使用する見出しです。余白には`.st-Heading2`を使用します。

- PC未満では`<br>`は適応されません。

```html
<div class="st-Wrapper">
<h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・<br>新産業創出拠点」</h2>
</div>
```

<span class="sw-Label">HD-3B</span>

`.sw-Heading2A-dark`を追加すると、ブランドカラーから黒字に変わります。

```html
<div class="st-Wrapper">
<h2 class="sw-Heading2A sw-Heading2A-dark st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
</div>
```
*/
.sw-Heading2A {
  margin-right: -10px;
  margin-left: -10px;
  font-family: $font-family-heading;
  font-size: rem(22);
  font-weight: bold;
  line-height: 1.4;
  color: #be3134;

  &:after {
    content: "";
    display: block;
    margin-top: 8px;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    border-top: 2px solid #be3134;
  }

  & br {
    display: none;
  }

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
    font-size: rem(26, lg);
    text-align: center;

    &:after {
      margin-top: 12px;
      margin-right: 0;
      margin-left: 0;
      border-top: 3px solid #be3134;
    }

    & br {
      display: inline;
    }
  }
}

.sw-Heading2A-dark {
  color: $color-text;
}
