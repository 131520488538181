/*
---
name: st-Wrapper
namespace: Structure
category: Layout
---

横幅を制限します。横幅によって左右の余白がつきます。

`st-Wrapper`の子要素で、画面（ビューポート）いっぱいに表示したい場合は`.st-Wrapper_Full`を指定します。

`st-Wrapper`の子要素で、背景画像を含めて画面（ビューポート）いっぱいに表示したい場合は`.st-Wrapper_FullWrap`を指定します。
*/
.st-Wrapper {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;

  @media print, screen and (min-width: $max-width) {
    padding: 0;
  }
}

.st-Wrapper_Full {
  margin-left: calc(-50vw + 50%) !important;
  margin-right: calc(-50vw + 50%) !important;
}

.st-Wrapper_FullWrap {
  margin-left: calc(-50vw + 50%) !important;
  margin-right: calc(-50vw + 50%) !important;
  padding-right: calc(50vw - 50%) !important;
  padding-left: calc(50vw - 50%) !important;
}
