/*
---
name: sw-Caption
namespace: SiteWide
category: Image
id: [K2, IMG-2]
---

<span class="sw-Label">IMG-2</span>

画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。

ベーシックなスタイルです。

```html
<div class="st-Wrapper">
<figure class="sw-Caption st-Image"><img src="https://placehold.jp/300x200.png" alt="" class="sw-Caption_Image">
  <figcaption class="sw-Caption_Text">画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。</figcaption>
</figure>
</div>
```

`.sw-Caption_Text-center`でキャプションをセンタリングします。

```html
<div class="st-Wrapper">
<figure class="sw-Caption st-Image"><img src="https://placehold.jp/300x200.png" alt="" class="sw-Caption_Image">
  <figcaption class="sw-Caption_Text sw-Caption_Text-center">画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。</figcaption>
</figure>
</div>
```

`.sw-Caption_Text-right`でキャプションを右揃えにします。

```html
<div class="st-Wrapper">
<figure class="sw-Caption st-Image"><img src="https://placehold.jp/300x200.png" alt="" class="sw-Caption_Image">
  <figcaption class="sw-Caption_Text sw-Caption_Text-right">画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。</figcaption>
</figure>
</div>
```

`.sw-Caption-center`でコンポーネント自体をセンタリングします。

```html
<div class="st-Wrapper">
<figure class="sw-Caption sw-Caption-center st-Image"><img src="https://placehold.jp/300x200.png" alt="" class="sw-Caption_Image">
  <figcaption class="sw-Caption_Text">画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。</figcaption>
</figure>
</div>
```
*/
/**
 * 1. キャプションの最小幅です。
 *    画像が指定幅以下になってもキャプションの幅はそれ以上小さくなりません。
 */
.sw-Caption {
  display: table;
  min-width: 100px; /* 1 */
}

.sw-Caption_Image {
}

/**
 * 1. キャプションは常に画像の下に配置します
 */
.sw-Caption_Text {
  display: table-caption;
  margin-top: 8px;
  font-size: rem(12);
  line-height: 1.6;
  caption-side: bottom; /* 1 */

  @include mq-up(lg) {
    margin-top: 12px;
    font-size: rem(13, lg);
  }
}

/**
 * コンポーネント自体をセンタリングします。
 */
.sw-Caption-center {
  margin-right: auto;
  margin-left: auto;
}

/**
 * テキストをセンタリングします。
 */
.sw-Caption_Text-center {
  text-align: center;
}

/**
 * テキストを右揃えにします。
 */
.sw-Caption_Text-right {
  text-align: right;
}
