/*
---
name: sw-VisualNavB
namespace: SiteWide
category: Navigation
id: [L1, CA-4]
---

<span class="sw-Label">CA-4</span>

- オフィスTOP
- ホール・会議室TOP（4列見せ）
- スマホでは横長の比率で上揃えになります。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg st-Grids-narrow">
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">スタッフのサポート</div>
        <div class="sw-VisualNavB_IconArea">
          <span class="sw-VisualNavB_Button">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">充実の共有部</div>
        <div class="sw-VisualNavB_ButtonArea">
          <span class="sw-VisualNavB_IconArea">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg st-Grids-narrow">
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">スタッフのサポート</div>
        <div class="sw-VisualNavB_IconArea">
          <span class="sw-VisualNavB_Button">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">充実の共有部</div>
        <div class="sw-VisualNavB_ButtonArea">
          <span class="sw-VisualNavB_IconArea">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">やすらぎの緑</div>
        <div class="sw-VisualNavB_IconArea">
          <span class="sw-VisualNavB_Button">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">400組織 4,400人が集積</div>
        <div class="sw-VisualNavB_ButtonArea">
          <span class="sw-VisualNavB_IconArea">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">サークル活動</div>
        <div class="sw-VisualNavB_ButtonArea">
          <span class="sw-VisualNavB_IconArea">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavB">
      <img src="https://placehold.jp/585x240.png" alt="" class="sw-VisualNavB_Image">
      <div class="sw-VisualNavB_Ovelay"></div>
      <div class="sw-VisualNavB_Item">
        <div class="sw-VisualNavB_Text">理化学機器・医療機器モール<br>リカモ</div>
        <div class="sw-VisualNavB_ButtonArea">
          <span class="sw-VisualNavB_IconArea">
            <span class="sw-VisualNavB_Icon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```

*/
.sw-VisualNavB {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: percentage(div(240, 670));
  text-decoration: none;
  color: #fff;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  @include mq-up(lg) {
    padding-top: 0;
  }

  .english-home-VisualNavB & {
    padding-top: percentage(div(600, 800));
    @include mq-up(lg) {
      padding-top: 0;
    }
  }
}

.sw-VisualNavB_Image {
  display: block;
  width: 100%;
  height: auto;
  max-width: none;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);

  @include mq-up(lg) {
    position: static;
    transform: translate(0, 0);
  }
}

.sw-VisualNavB_Ovelay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.35);
}

.sw-VisualNavB_Item {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 100%;
  padding: 0 20px;
  text-align: center;

  @include mq-up(lg) {
    padding: 0 25px;
  }
}

.sw-VisualNavB_Text {
  font-size: rem(15);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(22, lg);
    line-height: 1.4;
  }
}

.sw-VisualNavB_IconArea {
  margin-top: 14px;

  @include mq-up(lg) {
    margin-top: 10px;
  }
}

.sw-VisualNavB_Icon:before {
  @include Icon("icon_arrow-circle-right");
  width: 1em;
  height: 1em;
  margin: auto;
  color: #fff;
  font-size: 1.6em;
}
