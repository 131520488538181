/*
---
name: st-ContentHeaderB
namespace: Structure
category: Navigation
id: [K1, PT2, PT2-2]
---

<span class="sw-Label">PT2</span>

個別に特色を持つページの2階層・TOP用のコンテンツヘッダーです。

- グループB 下部のナビ（NV1-2）なし
  - 会社案内
  - アクセス
  - サイトマップなど

```html
<header class="st-ContentHeaderB">
  <div class="st-ContentHeaderB_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderB_Block">
        <div class="st-ContentHeaderB_Title">
          <p class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false" role="heading">会社案内
            <span class="st-LocalTitleA_Small">Company Profile</span>
            <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
          </p>
        </div>
        <div class="st-ContentHeaderB_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/outline/" class="st-LocalNav1_Link">会社案内<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/outline/idea.html" class="st-LocalNav1_Link">京都リサーチパーク（株）<span class="st-LocalNav1_Delimiter">経営理念</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

```

<span class="sw-Label">PT2-2</span>

階層の深いカテゴリーで使用します。

- 下層ナビは2箇所に記述します（スマホとPCで表示切り替え）。
  - `.st-LocalNav1_SubItems`はスマホでのみ表示されます。`.st-LocalNav1_Link-isChild`を忘れず指定してください。
  - `.st-ContentHeaderB_SubNav`はPCでのみ表示されます。

- グループB
  - オフィス・ラボの下層
  - ホール・会議室の下層

```html
<header class="st-ContentHeaderB">
  <div class="st-ContentHeaderB_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderB_Block">
        <div class="st-ContentHeaderB_Title">
          <p class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false" role="heading">オフィス・ラボ
            <span class="st-LocalTitleA_Small">Office &amp; Lab</span>
            <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
          </p>
        </div>
        <div class="st-ContentHeaderB_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/" class="st-LocalNav1_Link st-LocalNav1_Link-isChild">オフィス紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
                <ul class="st-LocalNav1_SubItems">
                  <li class="st-LocalNav1_SubItem">
                    <a href="#" class="st-LocalNav1_SubLink">部屋の広さ・人数別<span class="st-LocalNav1_SubIcon" aria-hidden="true"></span></a>
                  </li>
                  <li class="st-LocalNav1_SubItem">
                    <a href="#" class="st-LocalNav1_SubLink">建物紹介<span class="st-LocalNav1_SubIcon" aria-hidden="true"></span></a>
                  </li>
                </ul>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/service/" class="st-LocalNav1_Link">魅力<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice" class="st-LocalNav1_Link">環境・サービス<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/charge/" class="st-LocalNav1_Link">料金・<span class="st-LocalNav1_Delimiter">契約の流れ</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/faq/" class="st-LocalNav1_Link">よくあるご質問<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/inquiry/" class="st-LocalNav1_Link">お問い合わせ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</header>

<div class="st-LocalNav2_Container">
  <div class="st-Wrapper">
    <div class="st-LocalNav2">
      <ul class="st-LocalNav2_Items">
        <li class="st-LocalNav2_Item">
          <a href="/" class="st-LocalNav2_Link">
            <span class="st-LocalNav2_Icon" aria-hidden="true"></span>
            <span class="st-LocalNav2_LinkText">選ばれる理由</span>
          </a>
        </li>
        <li class="st-LocalNav2_Item">
          <a href="/outline/" class="st-LocalNav2_Link">
            <span class="st-LocalNav2_Icon" aria-hidden="true"></span>
            <span class="st-LocalNav2_LinkText">職場環境</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
```

```html
<header class="st-ContentHeaderB">
  <div class="st-ContentHeaderB_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderB_Block">
        <div class="st-ContentHeaderB_Title">
          <p class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false" role="heading">入居企業
            <span class="st-LocalTitleA_Small"></span>
            <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
          </p>
        </div>
        <div class="st-ContentHeaderB_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/" class="st-LocalNav1_Link">TOP<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/rentaloffice/service/" class="st-LocalNav1_Link">企業特集<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="st-LocalNav">
  <ul class="st-LocalNav4_Items">
    <li class="st-LocalNav4_Item">
      <a href="#" class="st-LocalNav4_Link">すべて</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="#" class="st-LocalNav4_Link">ニュース</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="#" class="st-LocalNav4_Link">イベント</a>
    </li>
  </ul>
</div>
```
*/
.st-ContentHeaderB {
}

.st-ContentHeaderB_Inner {
  position: relative;

  @include mq-up(lg) {
    height: 284px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    background-color: #f9f9f9;
  }
}

.st-ContentHeaderB_Block {
  @include mq-up(lg) {
    padding-top: 34px;
  }
}

.st-ContentHeaderB_Block-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}

.st-ContentHeaderB_Title {
  @include mq-up(lg) {
    .st-ContentHeaderB_Block-fixed & {
      display: inline-block;
      vertical-align: middle;
      margin-right: 27px;
    }
  }
}

.st-ContentHeaderB_Nav {
  .st-ContentHeaderB_Block-fixed & {
    padding: 0 20px;
  }

  @include mq-up(lg) {
    margin-top: 34px;

    .st-ContentHeaderB_Block-fixed & {
      margin-top: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.st-ContentHeaderB_SubNav {
  display: none;

  @include mq-up(lg) {
    position: absolute;
    bottom: -30px;
    right: 0;
    left: 0;
    display: block;
    max-width: $max-width;
    margin: auto;

    .st-ContentHeaderB_Block-fixed & {
      display: none;
    }
  }
}

.st-ContentHeaderB_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    z-index: -1;
  }
}

.st-ContentHeaderB_Text {
  display: none;
  font-weight: bold;
  font-size: rem(42, lg);
  line-height: 1.4;
  letter-spacing: 0.25em;
  color: #fff;
  text-align: center;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    display: block;

    & br {
      display: inline;
    }

    .st-ContentHeaderB_Title + & {
      margin-top: 110px;
    }

    .st-ContentHeaderB_Nav + & {
      margin-top: 86px;
    }

    .st-ContentHeaderB_Block-fixed & {
      display: none;
    }
  }
}

// .st-ContentHeaderB_SubNav {
//   @include mq-up(lg) {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//   }
// }
