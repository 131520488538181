/*
---
name: serviceoffice-MediaA
namespace: serviceoffice
category: Block
id: [TH-10]
---

動画とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

カラム幅の指定オプションです。`.serviceoffice-MediaA`にクラスを追加できます。

- PC幅で6:6 `.serviceoffice-MediaA-col6to6Lg`
- PC幅で3:9 `.serviceoffice-MediaA-col3to9Lg`
- PC幅で9:3 `.serviceoffice-MediaA-col9to3Lg`
- PC幅で4:8 `.serviceoffice-MediaA-col4to8Lg`
- PC幅で8:4 `.serviceoffice-MediaA-col8to4Lg`
- PC幅で5:7 `.serviceoffice-MediaA-col5to7Lg`
- PC幅で7:5 `.serviceoffice-MediaA-col7to5Lg`
- カラムの反転：`.serviceoffice-MediaA-reverse`（HTML上で順番を変えるだけでは再現できない場合に使用します）
- PC幅でカラムの反転：`.serviceoffice-MediaA-reverseLg`（HTML上で順番を変えるだけでは再現できない場合に使用します）

<span class="sw-Label">TH-10</span>

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-ContainerA st-ContainerA-gray">
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="serviceoffice-MediaA serviceoffice-MediaA-col6to6Lg">
      <div class="serviceoffice-MediaA_Body">
        <h2 class="serviceoffice-MediaA_Heading">京都駅から１駅</h2>
        <p class="serviceoffice-MediaA_Text"><strong class="serviceoffice-MediaA_Caution">東京・大阪からもアクセスしやすい好立地。<br>
        月額：20,000円〜 / 1名</strong></p>
        <p class="serviceoffice-MediaA_Text">素早くビジネスを始めたい方、上質で落ち着いたワークスペースをお探しの方、様々なご要望にお答えする新しいビジネス空間です。</p>
      </div>
      <div class="serviceoffice-MediaA_VideoArea">
        <div class="serviceoffice-MediaA_Video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/k0mJhptZjsY" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
```
*/
.serviceoffice-MediaA {
  margin-left: -15px;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.serviceoffice-MediaA-col6to6Lg {
  @include mq-up(lg) {
    & > .serviceoffice-MediaA_VideoArea,
    & > .serviceoffice-MediaA_Body {
      width: percentage(div(1, 2));
    }
  }
}

.serviceoffice-MediaA-col3to9Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(3, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(9, 12)); }
  }
}
.serviceoffice-MediaA-col9to3Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(9, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(3, 12)); }
  }
}

.serviceoffice-MediaA-col4to8Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(4, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(8, 12)); }
  }
}
.serviceoffice-MediaA-col8to4Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(8, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(4, 12)); }
  }
}

.serviceoffice-MediaA-col5to7Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(5, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(7, 12)); }
  }
}
.serviceoffice-MediaA-col7to5Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(7, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(5, 12)); }
  }
}

.serviceoffice-MediaA-reverse {
  text-align: left;
  direction: rtl;

  & > .serviceoffice-MediaA_ImageArea,
  & > .serviceoffice-MediaA_Body {
    text-align: left;
    direction: ltr;
  }
}
.serviceoffice-MediaA-reverseLg {
  @include mq-up(lg) {
    text-align: left;
    direction: rtl;

    & > .serviceoffice-MediaA_ImageArea,
    & > .serviceoffice-MediaA_Body {
      text-align: left;
      direction: ltr;
    }
  }
}

.serviceoffice-MediaA_VideoArea {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  .serviceoffice-MediaA_Body + & {
    margin-top: 30px;
  }

  @include mq-up(lg) {
    padding-left: 30px;

    .serviceoffice-MediaA_Body + & {
      margin-top: 0;
    }
  }
}

.serviceoffice-MediaA_Video {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(div(9, 16));
}


.serviceoffice-MediaA_Video iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.serviceoffice-MediaA_Body {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;

    & > :first-child {
      margin-top: 0;
    }
  }
}

.serviceoffice-MediaA_Heading {
  position: relative;
  padding-bottom: 8px;
  padding-left: 10px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;
  border-bottom: 1px solid #d2d2d2;

  @include mq-up(lg) {
    margin-top: 12px;
    padding-bottom: 10px;
    padding-left: 0;
    font-size: rem(18, lg);
    line-height: 1.55;
  }
}

.serviceoffice-MediaA_Text {
  margin-top: 12px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    margin-top: 20px;
    font-size: rem(16, lg);
  }
}

.serviceoffice-MediaA_Caution {
  font-weight: bold;
}


.serviceoffice-MediaA_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.serviceoffice-MediaA_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.serviceoffice-MediaA_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.serviceoffice-MediaA_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}

.serviceoffice-MediaA_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.serviceoffice-MediaA_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.serviceoffice-MediaA_Link {
  @include sw-Link();
}

.serviceoffice-MediaA_LinkExternal:before {
  @include sw-LinkExternal();
}

.serviceoffice-MediaA_LinkPdf:before {
  @include sw-LinkPdf();
}

