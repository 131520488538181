/*
---
name: sw-Pagination
namespace: SiteWide
category: Navigation
id:  [K2, PN]
---

<span class="sw-Label">PN</span>

```html
<div class="st-Wrapper">
<div class="sw-Pagination st-Block">
  <ul class="sw-Pagination_Items">
    <li class="sw-Pagination_Item">
      1
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">2</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">3</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-last" href="#">44</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-next" href="#">Next<span class="sw-Pagination_NextIcon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>

<div class="sw-Pagination st-Block">
  <ul class="sw-Pagination_Items">
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-prev" href="#"><span class="sw-Pagination_PrevIcon" aria-hidden="true"></span>Prev</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-first" href="#">1</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">4</a>
    </li>
    <li class="sw-Pagination_Item">
      5
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">6</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-last" href="#">44</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-next" href="#">Next<span class="sw-Pagination_NextIcon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>

<div class="sw-Pagination st-Block">
  <ul class="sw-Pagination_Items">
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-prev" href="#"><span class="sw-Pagination_PrevIcon" aria-hidden="true"></span>Prev</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-first" href="#">1</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">41</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">42</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link" href="#">43</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-last" href="#">44</a>
    </li>
    <li class="sw-Pagination_Item">
      <a class="sw-Pagination_Link sw-Pagination_Link-next" href="#">Next<span class="sw-Pagination_NextIcon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>
</div>
```
*/
.sw-Pagination {

}

.sw-Pagination_Items {
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  text-align: center;
}

.sw-Pagination_Item {
  display: inline-block;
  padding: 2px 8px;
  vertical-align: middle;
  font-size: rem(12);
  line-height: 1.4;

  @include mq-up(lg) {
    padding: 6px 8px;
    font-size: rem(16, lg);
  }
}

.sw-Pagination_Link {
  display: block;
  color: $color-brand;
  text-decoration: none;

  &:hover {
    color: $color-brand;
    text-decoration: underline;
  }
}

.sw-Pagination_Link-prev {
  padding: 2px 20px;
  font-size: rem(10);
  border: 1px solid $color-brand;

  @include mq-up(lg) {
    padding: 6px 20px;
  }
}

.sw-Pagination_Link-next {
  padding: 2px 20px;
  font-size: rem(10);
  border: 1px solid $color-brand;

  @include mq-up(lg) {
    padding: 6px 20px;
  }
}

.sw-Pagination_PrevIcon {

}

.sw-Pagination_NextIcon {

}

.sw-Pagination_Link-first {
  &:after {
    content: "…";
    display: inline-block;
    margin-left: 16px;
  }
}

.sw-Pagination_Link-last {
  &:before {
    content: "…";
    display: inline-block;
    margin-right: 16px;
  }
}
