/*
---
name: st2-ContentHeaderA
namespace: Structure2
category: Navigation
---

個別に特色を持つページのコンテンツヘッダーです。
st2-ContentHeaderA_MainContent の中に各メインビジュアル用のコンテンツを入れて使用します。

```html
<header class="st2-ContentHeaderA">
  <div class="st2-ContentHeaderA_Inner">
    <div class="st2-ContentHeaderA_MainContent">
      <!-- ここに各メインビジュアル用のコンテンツを入れて使用します。 -->
      <img src="https://placehold.jp/570x450.png" alt="">
    </div>
    <div class="st2-ContentHeaderA_MainText">
      <div class="st2-ContentHeaderA_MainText_Inner">
        <h1 class="st2-ContentHeaderA_Heading"><span>オフィス</span></h1>
        <div class="st2-ContentHeaderA_Text">京都のビジネス拠点として<br>さまざまなニーズに対応</div>
      </div>
    </div>
  </div>
</header>
```

別スタイルバージョン

```html
<header class="st2-ContentHeaderA">
  <div class="st2-ContentHeaderA_Inner">
    <div class="st2-ContentHeaderA_MainContent">
      <!-- ここに各メインビジュアル用のコンテンツを入れて使用します。 -->
      <img src="https://placehold.jp/570x450.png" alt="">
    </div>
    <div class="st2-ContentHeaderA_PointTitle">
      <div class="st2-ContentHeaderA_PointTitle_Text">FEATURES</div>
      <h1 class="st2-ContentHeaderA_PointTitle_Heading">KRPの特長</h1>
    </div>
  </div>
</header>
```

*/

.st2-ContentHeaderA {

}
.st2-ContentHeaderA_Inner {
  position: relative;
}
.st2-ContentHeaderA_MainContent {

}
.st2-ContentHeaderA_MainText {
  margin-top: -124px; //-174
  position: relative;
  z-index: 2;
  @include mq-up(md) {
    margin-top: rem(0);
    padding: rem(0) rem(20, md);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.st2-ContentHeaderA_MainText_Inner {

  @include mq-up(md) {
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
    padding: rem(36, md) rem(40, md);
    background: linear-gradient(90deg, #AE2135 0%, #AE2135 160px, transparent 160px, transparent 100%);
  }
  @include mq-up(lg) {
    padding: rem(36, lg) rem(40, lg);
  }
}
.st2-ContentHeaderA_Heading {
  padding: rem(29) rem(20);
  margin-left: rem(20);
  background: linear-gradient(90deg, #AE2135 0%, #AE2135 100px, transparent 100px, transparent 100%);

  @include mq-up(md) {
    margin-left: rem(0);
    margin-bottom: rem(46, md);
    padding: rem(0);
    background: none;
  }
  @include mq-up(lg) {
    margin-bottom: rem(46, lg);
  }

  span {
    display: inline-block;
    background-color: #333;
    color: #fff;
    font-size: rem(32);
    line-height: rem(50);
    font-family: $font-family-shippori-mincho;
    font-weight: 400;
    padding: rem(8) rem(16);
    vertical-align: bottom;
    &.-is-top {
      font-size: rem(36);
    }
    &.-is-notosans {
      font-family: $font-family-sans-serif;
    }
    @include mq-up(md) {
      font-size: rem(42, md);
      line-height: rem(42, md);
      padding: rem(12, md) rem(28, md);
      &.-is-top {
        font-size: rem(42, md);
      }
    }
    @include mq-up(lg) {
      font-size: rem(42, lg);
      line-height: rem(42, lg);
      padding: rem(12, lg) rem(28, lg);
      &.-is-top {
        font-size: rem(42, lg);
      }
    }
  }
}
.st2-ContentHeaderA_Text {
  background-color: #EDEFF0;
  font-size: rem(22);
  line-height: rem(35);
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  padding: rem(20);

  @include mq-up(md) {
    background-color: #fff;
    display: inline-block;
    font-size: rem(28, md);
    line-height: rem(45, md);
    padding: rem(20, md) rem(28, md);
  }
  @include mq-up(lg) {
    font-size: rem(28, lg);
    line-height: rem(45, lg);
    padding: rem(20, lg) rem(28, lg);
  }

  br {
    display: none;
    @include mq-up(md) {
      display: initial;
    }
  }
}
.st2-ContentHeaderA_PointTitle {
  position: absolute;
  top: 50%;
  left: rem(20);
  transform: translate(0, -50%);
  @media print, screen and (min-width: $max-width) {
    left: calc((100vw - $max-width) / 2);
  }
}
.st2-ContentHeaderA_PointTitle_Text {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(14);
  color: $color-krp;
  position: relative;
  padding-left: rem(14);
  margin-bottom: rem(16);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(16, lg);
    padding-left: rem(18, lg);
    margin-bottom: rem(24, lg);
  }
  &::before {
    content: "";
    display: block;
    width: rem(6);
    height: rem(6);
    background-color: $color-krp;
    border-radius: 100%;
    position: absolute;
    top: rem(5);
    left: 0;
    @include mq-up(lg) {
      width: rem(6, lg);
      height: rem(6, lg);
      top: rem(6, lg);
    }
  }
}
.st2-ContentHeaderA_PointTitle_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(32);
  line-height: rem(32);
  @include mq-up(lg) {
    font-size: rem(42, lg);
    line-height: rem(42, lg);
  }
}
.st2-ContentHeaderA_Lead {
  background-color: #f5f5f5;
  padding: rem(20) rem(20) rem(60);
  @include mq-up(lg) {
    padding: rem(60, lg) rem(20, lg);
    text-align: center;
  }
}
.st2-ContentHeaderA_LeadText {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  font-size: rem(22);
  line-height: rem(35);
  @include mq-up(lg) {
    text-align: center;
    font-size: rem(30, lg);
    line-height: rem(48, lg);
  }
  br {
    display: none;
    @include mq-up(lg) {
      display: initial;
    }
  }
  .-is-Sp {
    display: initial;
    @include mq-up(lg) {
      display: none;
    }
  }
}
.st2-ContentHeaderA_LeadCaption {
  margin-bottom: rem(60);
  padding: rem(20);
  background-color: #E4E7E8;
  @include mq-up(lg) {
    margin-top: rem(-40, lg);
    margin-bottom: rem(80, lg);
    padding: rem(12, lg);
    text-align: center;
  }
  a {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(20);
    color: #333;
    @include mq-up(lg) {
      font-size: rem(14, lg);
      line-height: rem(14, lg);
      display: inline-block;
    }
    &:hover {
      @include mq-up(lg) {
        text-decoration: none;
      }
    }
  }
}
.st2-ContentHeaderA_CenterText {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.st2-ContentHeaderA_CenterText_Heading {
  font-weight: 500;
  font-size: rem(36);
  line-height: rem(36);
  color: #fff;
  @include mq-up(lg) {
    font-size: rem(64, lg);
    line-height: rem(86, lg);
  }
}
.st2-ContentHeaderA_CenterText_Text {
  margin-top: rem(20);
  font-family: $font-family-lato;
  font-weight: 700;
  font-size: rem(12);
  line-height: rem(12);
  letter-spacing: 0.05em;
  color: #fff;
  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(20, lg);
  }
}
