/*
---
name: sw-BoxNavC
namespace: SiteWide
category: Navigation
id: [L2, CA-8A]
---

<span class="sw-Label">CA-8A</span>

- イノベーション創発＞環境分野

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavC_Items sw-BoxNavC_Items-col3Lg">
  <li class="sw-BoxNavC_Item">
    <a class="sw-BoxNavC_Box" href="#">
      <div class="sw-BoxNavC_ImageArea">
        <img src="https://placehold.jp/80x53.png" alt="" class="sw-BoxNavC_Image">
      </div>
      <div class="sw-BoxNavC_BoxBody">
        <span class="sw-BoxNavC_txt">テキスト一行の場合</span>
        <span class="sw-BoxNavC_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavC_Item">
    <a class="sw-BoxNavC_Box" href="#">
      <div class="sw-BoxNavC_ImageArea">
        <img src="https://placehold.jp/80x53.png" alt="" class="sw-BoxNavC_Image">
      </div>
      <div class="sw-BoxNavC_BoxBody">
        <span class="sw-BoxNavC_txt">テキスト二行の<br>場合</span>
        <span class="sw-BoxNavC_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavC_Item">
    <a class="sw-BoxNavC_Box" href="#">
      <div class="sw-BoxNavC_ImageArea">
        <img src="https://placehold.jp/80x53.png" alt="" class="sw-BoxNavC_Image">
      </div>
      <div class="sw-BoxNavC_BoxBody">
        <span class="sw-BoxNavC_txt">京都大学<br>
        デザインスクール</span>
        <span class="sw-BoxNavC_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
</div>
```

*/
.sw-BoxNavC_Items {
  margin-left: 0px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    margin-left: 0px;

    & + & {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavC_Item {
  display: inline-block;
  vertical-align: middle;
  padding-left: 0px;
  width: 50%;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  box-shadow: 1px 1px 0 0 #e8e8e8, inset 1px 1px 0 0 #e8e8e8;
  min-height: 12.33vw; 
  // box-sizing: border-box;
  & > .sw-BoxNavC_Item {
    width: percentage(div(1, 2));
  }

  & > .sw-BoxNavC_Item + .sw-BoxNavC_Item {
    margin-top: 0;
  }

  & > .sw-BoxNavC_Item:nth-of-type(n+3) {
    margin-top: 30px;
  }

  @include mq-up(lg) {
    padding-left: 0px;
    min-height: 85px;
    & + & {
      margin-top: 30px;
    }
  }
  @include mq-up(md) {
    // min-height: 0; 
  }
}

.sw-BoxNavC_Items-col2Lg {
  @include mq-up(lg) {
    & > .sw-BoxNavC_Item {
      width: percentage(div(1, 2));
    }

    & > .sw-BoxNavC_Item + .sw-BoxNavC_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavC_Item:nth-of-type(n+3) {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavC_Items-col3Lg {
  & > .sw-BoxNavC_Item:nth-of-type(3) {
    height: 53px;
  }
  @include mq-up(lg) {
    & > .sw-BoxNavC_Item {
      width: percentage(div(1, 3));
      height: 85px;
    }

    & > .sw-BoxNavC_Item + .sw-BoxNavC_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavC_Item:nth-of-type(n+4) {
      margin-top: 30px;
    }
    & > .sw-BoxNavC_Item:nth-of-type(3) {
      height: 85px;
    }
  }
}

.sw-BoxNavC_Items-centerLg {
  @include mq-up(lg) {
    text-align: center;
  }
}

.sw-BoxNavC_Box {
  position: relative;
  display: flex;
  height: 100%;
  padding: 1.4vw 1.2vw;
  line-height: 1.2;
  text-decoration: none;
  color: $color-text;
  background-color: #fff;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &:hover {
    text-decoration: none;
    color: $color-text;
    opacity: 0.5;
    /*background-color: #fdeeee;*/
  }

  @include mq-up(lg) {
    padding: 16px 30px 16px 20px;
  }
}

.sw-BoxNavC_ImageArea {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  width: 15vw;
  position: relative;
  @include mq-up(lg) {
    width: 80px;
  }  
}
.sw-BoxNavC_Image {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: 41.5vw;
  height: 10.4vw;
  @include mq-up(lg) {
    width: 80px;
    height:53px;
  }
  @media screen and (min-width: 768px) and (max-width: 980px) {
      width: auto;
      height: auto;
  }
}
.sw-BoxNavC_BoxBody {
  display: table;
  padding-left: 0.9vw;
  vertical-align: middle;
  font-size: rem(13);
  font-weight: bold;
  line-height: 1.4;
  // width: 70%;
  @include mq-up(lg) {
    // width: 100%;
    padding-left: 16px;
    font-size: rem(16, lg);
    line-height: 1.6;
    width: calc(100% - 80px);
  }
}

.sw-BoxNavC_BoxBody > .sw-BoxNavC_txt {
  display: table-cell;
  vertical-align: middle;
  font-size: 2.2vw;
  line-height: 1.2;
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: 1.6;
  }  
}
.sw-BoxNavC_Icon {
  &:after {
    content: "\EA0A";
    font-size: 1em;
    font-family: iconfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-decoration: none;
    text-transform: none;
    speak: none;
    position: absolute;
    top: 0;
    right: 1vw;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    color: #be3134;
    @include mq-up(lg) {
      right: 16px;
    } 
  }
}
// スマホの時だけ改行させるクラス
.br-sp {
  display: block!important;
  @include mq-up(lg) {
    display: none!important;
  }
}
// sw-Leadでスマホの時に改行させるクラス
.br-spsw {
  display: block!important;
}
