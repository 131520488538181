@mixin sw-Button() {
  display: inline-block;
  margin: 0;
  padding: 10px 40px;
  border: none;
  border: 1px solid $color-brand;
  border-radius: 3em;
  color: $color-brand;
  font-family: inherit;
  font-size: rem(13);
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background: transparent;
  background-color: #fff;
  appearance: none;
  cursor: pointer;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: $color-brand;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  &:disabled,
  &-disabled {
    opacity: $form-disabled-opacity;
    border-color: $form-disabled-background-color;
    color: #555;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;

    &:hover {
      color: #555;
    }
  }
  &-disabled {
    pointer-events: none;
  }

  @include mq-up(lg) {
    padding: 13px 50px;
    border-width: 2px;
  }
}
