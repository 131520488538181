/*
---
name: st-FooterInfo
namespace: Structure
category: Global
id: K1
---

グローバルフッターにあるインフォメーションエリアです。

```html
<section class="st-FooterInfo">
  <div class="st-FooterInfo_Inner">
    <h3 class="st-FooterInfo_Heading">
      <img src="/assets/img/common/logo_krp-red.svg" class="st-FooterInfo_HeadingImage" alt="KRP">
      <span class="st-FooterInfo_HeadingText">Area Information</span>
    </h3>
    <ul class="st-FooterInfo_Head">
      <li class="st-FooterInfo_HeadItems">
        <a href="#" class="st-FooterInfo_HeadItem">
          <div class="st-FooterInfo_HeadIcon st-FooterInfo_HeadIcon-access" aria-hidden="true"></div>
          <p class="st-FooterInfo_Text">アクセス</p>
        </a>
      </li>
      <li class="st-FooterInfo_HeadItems">
        <a href="#" class="st-FooterInfo_HeadItem">
          <div class="st-FooterInfo_HeadIcon st-FooterInfo_HeadIcon-map" aria-hidden="true"></div>
          <p class="st-FooterInfo_Text">エリアマップ</p>
        </a>
      </li>
      <li class="st-FooterInfo_HeadItems">
        <a href="#" class="st-FooterInfo_HeadItem">
          <div class="st-FooterInfo_HeadIcon st-FooterInfo_HeadIcon-shops" aria-hidden="true"></div>
          <p class="st-FooterInfo_Text">商業施設・店舗情報</p>
        </a>
      </li>
      <li class="st-FooterInfo_HeadItems">
        <a href="#" class="st-FooterInfo_HeadItem">
          <div class="st-FooterInfo_HeadIcon st-FooterInfo_HeadIcon-institution" aria-hidden="true"></div>
          <p class="st-FooterInfo_Text">公的産業支援機関</p>
        </a>
      </li>
    </ul>
    <ul class="st-FooterInfo_Foot">
      <li class="st-FooterInfo_FootItem">
        <a href="#" class="st-FooterInfo_FootLink">ご入居企業さま　専用サイト</a>
      </li>
      <li class="st-FooterInfo_FootItem">
        <a href="#" class="st-FooterInfo_FootLink">入居企業・団体一覧</a>
      </li>
    </ul>
  </div>
</section>
```
*/
.st-FooterInfo {
  padding: 30px 45px 26px;
  background-color: #fff;

  @include mq-up(lg) {
    padding: 52px 0 64px;
    background-color: #f3f3f3;
  }
}

.st-FooterInfo_Inner {
  max-width: $max-width;
  margin: auto;
}

.st-FooterInfo_Heading {
  overflow: hidden;
  position: relative;
  font-family: $font-family-heading-en;
  font-size: rem(15.6);
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
  color: $color-brand;
  text-transform: uppercase;

  @include mq-up(lg) {
    font-size: rem(24, lg);
    text-align: left;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 300px;
      bottom: 0;
      display: block;
      width: 100%;
      height: 4px;
      margin: auto;
      border-top: 4px solid $color-brand;
    }
  }
}

.st-FooterInfo_HeadingImage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 59px;
  background-color: #fff;

  @include mq-up(lg) {
    width: 91px;
    background-color: #f3f3f3;
  }
}

.st-FooterInfo_HeadingText {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  background-color: #fff;

  @include mq-up(lg) {
    padding-right: 78px;
    padding-left: 13px;
    background-color: #f3f3f3;
  }
}

.st-FooterInfo_Head {
  margin-top: 26px;
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-top: 48px;
    margin-left: -30px;
  }
}

.st-FooterInfo_Head-col2 {

}

.st-FooterInfo_HeadItems {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-left: 15px;
  font-size: 1rem;

  &:nth-of-type(n+3) {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    width: 25%;
    padding-left: 30px;
    font-size: rem(20, lg);

    &:nth-of-type(n+3) {
      margin-top: 0;
    }

    .st-FooterInfo_Head-col2 & {
      width: 50%;
    }
  }
}

.st-FooterInfo_HeadItem {
  display: block;
  height: 68px;
  border: 1px solid $color-brand;
  font-size: rem(12);
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: $color-brand;
  text-decoration: none;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    color: #fff;
    background-color: $color-brand;
  }

  @include mq-up(lg) {
    height: 130px;
    background-color: #fff;

    .st-FooterInfo_Head-col2 & {
      position: relative;
      display: table;
      width: 100%;
      height: 68px;
    }
  }
}

.st-FooterInfo_HeadIcon-access {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1.9em;

  &:before {
    @include Icon("icon_access");
  }

  @include mq-up(lg) {
    padding-top: 24px;
    padding-bottom: 22px;
    font-size: 3.4em;

    .st-FooterInfo_Head-col2 & {
      position: absolute;
      top: -0.25em;
      left: 30px;
      bottom: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      padding: 0;
      font-size: 2.8em;
    }
  }
}

.st-FooterInfo_HeadIcon-map {
  padding-top: 12px;
  padding-bottom: 11px;
  font-size: 2em;

  &:before {
    @include Icon("icon_areamap");
  }

  @include mq-up(lg) {
    padding-top: 25px;
    padding-bottom: 18px;
    font-size: 3.6em;

    .st-FooterInfo_Head-col2 & {
      position: absolute;
      top: -0.25em;
      left: 30px;
      bottom: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      padding: 0;
      font-size: 2.8em;
    }
  }
}

.st-FooterInfo_HeadIcon-shops {
  padding-top: 10px;
  padding-bottom: 9px;
  font-size: 2.2em;

  &:before {
    @include Icon("icon_build-facility");
  }

  @include mq-up(lg) {
    padding-top: 22px;
    padding-bottom: 18px;
    font-size: 3.8em;
  }
}

.st-FooterInfo_HeadIcon-institution {
  padding-top: 8px;
  padding-bottom: 9px;
  font-size: 2.6em;

  &:before {
    @include Icon("icon_build-public");
  }

  @include mq-up(lg) {
    padding-top: 19px;
    padding-bottom: 18px;
    font-size: 4.2em;
  }
}

.st-FooterInfo_Foot {
  display: block;
  margin-top: 18px;
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-top: 24px;
    margin-left: -30px;
  }
}

.st-FooterInfo_FootItem {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: 15px;
  font-size: 1rem;

  & + & {
    margin-top: 13px;
  }

  @include mq-up(lg) {
    width: 50%;
    padding-left: 30px;
    font-size: rem(20, lg);

    & + & {
      margin-top: 0;
    }
  }
}

.st-FooterInfo_FootItem-col3 {
  @include mq-up(lg) {
    width: 33.3333%;
  }
}

.st-FooterInfo_FootLink {
  display: block;
  padding: 11px 1em;
  border: 1px solid $color-brand;
  font-size: rem(12);
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $color-brand;
  text-decoration: none;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    color: #fff;
    background-color: $color-brand;
  }

  @include mq-up(lg) {
    padding: 14px 1em;
    font-size: rem(19.2, lg);
    background-color: #fff;
  }
}

.st-FooterInfo_Text {
  font-size: rem(12);

  @include mq-up(lg) {
    font-size: rem(19.2, lg);

    .st-FooterInfo_Head-col2 & {
      display: table-cell;
      vertical-align: middle;
      font-size: rem(20, lg);
    }
  }
}
