/*
---
name: st-Section2
namespace: Structure
category: Layout
---

h2相当の見出しをラップするセクションの余白を指定します。

レイアウト例です。

```html
<div class="st-Wrapper">
<section class="st-Section2">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>

<section class="st-Section2">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>
</div>
```

レイアウト例です。`.st-Section2-divider`を追加すると`.st-Section2`同士の間にボーダーがつきます。

```html
<div class="st-Wrapper">
<section class="st-Section2 st-Section2-divider">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>

<section class="st-Section2 st-Section2-divider">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>
</div>
```

レイアウト例です。`.st-Section2-dividerFull`を追加すると`.st-Section2`同士の間にボーダーがつきます。ボーダーの横幅は画面に対して常に100%（`100vw`）になります。

```html
<div class="st-Wrapper">
<section class="st-Section2 st-Section2-divider">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>

<section class="st-Section2 st-Section2-dividerFull">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>
</div>
```
*/
.st-Section2 {
  &[id]:before {
    content: "";
    display: block;
    margin-top: -120px;
    padding-top: 120px;
  }

  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section2;

    @include mq-up(lg) {
      margin-top: $margin-section2-lg;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-Section2-divider {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section2-divider;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      padding-top: $margin-section2-divider;
      border-top: 1px solid #e3e3e3;
    }

    @include mq-up(lg) {
      margin-top: $margin-section2-divider-lg;

      &:before {
        padding-top: $margin-section2-divider-lg;
      }
    }
  }
}


.st-Section2-dividerFull {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section2-divider;

    &:before {
      content: "";
      display: block;
      height: 1px;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding-top: $margin-section2-divider;
      border-top: 1px solid #e3e3e3;
    }

    @include mq-up(lg) {
      margin-top: $margin-section2-divider-lg;

      &:before {
        padding-top: $margin-section2-divider-lg;
      }
    }
  }
}
