.goconc {
  .st-LocalTitleC {
    height: 105.5px;
    text-align: center;

    @include mq-up(md) {
      height: 213px;
    }
  }

  .st-LocalTitleC img {
    height: 100%;
  }
}

.goconc-event {
  .st-LocalTitleB_Logo img {
    height: 70px;
  }
}
