/*
---
name: sw2-AccessMap
namespace: SiteWide2
category: Block
---

アクセスマップ用のモジュールです。余白は`.st-Block`を使用してください。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-AccessMap">
      <div class="sw2-AccessMap_Body">
        <div class="sw2-AccessMap_Heading">ACCESS MAP</div>
        <p class="sw2-AccessMap_Text">京都駅から電車で5分！ 京都駅は「新幹線」発着だけでなく、JR特急「はるか」で関西国際空港とつながっています。関西圏にとどまらず、関東、海外からのアクセスも容易です。</p>
        <div class="sw2-AccessMap_ButtonArea">
          <a href="#" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
      <div class="sw2-AccessMap_ImageArea">
        <picture>
          <img class="sw2-AccessMap_Image" src="/assets/img/rentaloffice/index/image_access_map.jpg" alt="ACCESS MAP" loading="lazy">
        </picture>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-AccessMap {
  background-size: auto auto;
  background-color: rgba(51, 51, 51, 1);
  background-image: url(/assets/img/structure/bg_footer-registration.png);
  margin-right: rem(-20);
  margin-left: rem(-20);
  padding: rem(45) rem(20) rem(120);
  position: relative;

  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-right: rem(0, lg);
    margin-left: rem(0, lg);
    padding: rem(0, lg);
  }

}

.sw2-AccessMap_Body {
  margin-bottom: rem(20);

  @include mq-up(lg) {
    width: 50%;
    padding: 0 rem(60, lg);
    margin-bottom: rem(0);
  }

}

.sw2-AccessMap_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  color: #fff;
  position: relative;
  text-align: center;
  font-size: rem(24);
  line-height: rem(34);
  padding-bottom: rem(20);
  margin-bottom: rem(20);

  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(32, lg);
    padding-bottom: rem(28, lg);
    margin-bottom: rem(28, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding-bottom: rem(40, lg);
    margin-bottom: rem(40, lg);
  }

  &::after {
    content: "";
    display: block;
    width: rem(50);
    height: rem(2);
    background-color: #fff;
    opacity: 0.4;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    @include mq-up(lg) {
      width: rem(84, lg);
      height: rem(1, lg);
    }
  }
}

.sw2-AccessMap_Text {
  color: #fff;
  font-size: rem(13);
  line-height: rem(23);
  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: rem(28, lg);
  }
}

.sw2-AccessMap_ImageArea {
  @include mq-up(lg) {
    width: 50%;
  }
  img {
    width: 100%;
  }
}

.sw2-AccessMap_ButtonArea {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: rem(45);
  left: 0;
  @include mq-up(lg) {
    position: static;
    margin-top: rem(40, lg);
  }
}