/*
---
name: st-Heading3
namespace: Structure
category: Layout
---

h3相当の見出し自身の余白を指定します。

```html
<div class="st-Wrapper">
<section class="st-Section3">
  <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</section>
</div>
```
*/
.st-Heading3 {
  margin-bottom: $margin-heading3;

  @include mq-up(lg) {
    margin-bottom: $margin-heading3-lg;
  }
}
