.st-HeaderAccess {
  position: relative;
  display: block;
  width: 100%;
  height: (div(107, 2)) * 1px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: $color-krp;

  &:after {
    content: "アクセス";
    position: absolute;
    right: 0;
    bottom: 2px;
    left: 0;
    display: block;
    margin: auto;
    font-size: rem(8);
    line-height: 1;

    html[lang="en"] &,
    html[lang="zh-cmn-Hans"] &,
    html[lang="ko"] & {
      content: "Access";
    }
  }
}

.st-HeaderAccess_Icon {
  display: inline-block;
  margin-top: 7px;
  line-height: 1;
  font-size: 1.5em;
  vertical-align: top;

  &:before {
    @include Icon("icon_access-pin");
    position: relative;
    top: 0.05em;
  }
}
