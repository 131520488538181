.st-LocalSubNavA {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
}

.st-LocalSubNavA_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  font-size: 1rem;
}

.st-LocalSubNavA_Link {
  display: block;
  text-decoration: none;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.st-LocalSubNavA_Image {
  width: 100%;
  max-width: none;
}

.st-LocalSubNavA_Text {
  display: block;
  margin-top: 10px;
  font-size: rem(10);
  line-height: 1.4;
  color: #999;
  text-align: center;

  @include mq-up(lg) {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #fff;
    text-align: right;
  }
}
