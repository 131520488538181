/*
---
name: sw-TileLead
namespace: SiteWide
category: Heading
id: [C3, TIL]
---

<span class="sw-Label">TIL</span>

- プラットフォーム活動＞再生医療プラットフォームとは　　のみ

```html
<div class="st-Wrapper">
  <div class="sw-TileLead">
    <div class="sw-TileLead_Item">
      <p class="sw-TileLead_Text">再生医療の早期実用化・産業化</p>
    </div>
    <div class="sw-TileLead_Item">
      <p class="sw-TileLead_Text">モノづくり起業の<br>
      高付加価値化・イノベーション</p>
    </div>
  </div>
</div>
```
*/
.sw-TileLead {
  display: block;
  padding-left: 0;
  list-style-type: none;
  margin-left: -$grid-column-gap;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -$grid-column-gap-lg;
  }
}

.sw-TileLead_Item {
  display: inline-block;
  width: 100%;
  padding-left: $grid-column-gap;
  vertical-align: top;
  font-size: 1rem;

  & + & {
    margin-top: 20px;
  }

  &:nth-of-type(n+3) {
    margin-top: $grid-column-gap;
  }

  @include mq-up(lg) {
    width: 50%;
    padding-left: $grid-column-gap-lg;

    & + & {
      margin-top: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $grid-column-gap-lg;
    }
  }
}

.sw-TileLead_Text {
  padding: 25px 30px;
  font-size: rem(14);
  line-height: 1.4;
  font-weight: bold;
  text-align: center;
  background-image: url("/assets/img/sitewide/TileLead/bg_stripe.png");
  background-repeat: round;
  background-size: auto;

  @include mq-up(lg) {
    padding: 44px;
    font-size: rem(22, lg);
  }
}
