/*
---
name: sw-TextSecondary
namespace: SiteWide
category: Text
id: [K2, TX-4]
---

<span class="sw-Label">TX-4</span>

`<p>`や`<small>`で使われるようなテキストスタイルです。補足的・副次的な意味合いを持たせる場合に使います。余白には`.st-SmallText`を使用します。

```html
<div class="st-Wrapper">
<p class="sw-TextSecondary st-SmallText">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.sw-TextSecondary {
  font-size: rem(12);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(13, lg);
  }
}
