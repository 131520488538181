/*
---
name: sw-QandA
namespace: SiteWide
category: InteractiveComponent
id: [C3, AC-1]
---

<span class="sw-Label">AC-1</span>

Q&Aのアコーディオンモジュールです。

- 最初の回答は初めから開きます。
- 同時に複数の回答を開くことができます。

```html
<div class="st-Wrapper">
<dl class="sw-QandA">
  <dt class="sw-QandA_Question">
    <button class="sw-QandA_QuestionButton" type="button">
      <span class="sw-QandA_Q">Q.</span>
      <span class="sw-QandA_Icon" aria-hidden="true"></span>
      宅急便で事前に資料を送りたいのですが対応可能ですか？
    </button>
  </dt>
  <dd class="sw-QandA_Answer">
    <span class="sw-QandA_A">A.</span>
    <p class="sw-QandA_Text">会議開催日の2日前から受け取り可能です。<br>
      発送される場合は必ず事前にご一報ください。（日曜・祝日・休館日は受領できませんのでご注意願います。）<br>
    利用日、利用会議室名、弊社 担当者名を明記の上、ご発送ください。</p>
  </dd>
  <dt class="sw-QandA_Question">
    <button class="sw-QandA_QuestionButton" type="button">
      <span class="sw-QandA_Q">Q.</span>
      <span class="sw-QandA_Icon" aria-hidden="true"></span>
      起業前 or 起業したばかりの会社ですが入居できますか？
    </button>
  </dt>
  <dd class="sw-QandA_Answer">
    <span class="sw-QandA_A">A.</span>
    <p class="sw-QandA_Text">会議開催日の2日前から受け取り可能です。<br>
      発送される場合は必ず事前にご一報ください。（日曜・祝日・休館日は受領できませんのでご注意願います。）<br>
    利用日、利用会議室名、弊社 担当者名を明記の上、ご発送ください。</p>
    <p class="sw-QandA_Text">◆1号館ご利用の場合<br>
      〒600-8813　京都市下京区中堂寺南町134　ＫＲＰ1号館2階<br>
      京都リサーチパーク(株)MICE推進部　（弊社担当者名）宛<br>
      ＴＥＬ　<a class="sw-QandA_Link" href="tel:0753227888">075-322-7888</a><br>
      （○月○日　（利用会議室名）使用分）<br>
      ◆4号館ご利用の場合<br>
      〒600-8815　京都市下京区中堂寺粟田町93　ＫＲＰ4号館受付気付<br>
      京都リサーチパーク(株)MICE推進部　（弊社担当者名）宛<br>
      ＴＥＬ　075-322-7888<br>
      （○月○日　（利用会議室名）使用分）<br>
    また会議終了後、着払いに限り返送の手配も承ります。</p>
  </dd>
</dl>
</div>
```
*/
.sw-QandA {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
  }
}

.sw-QandA_Question {

  .sw-QandA_Answer + & {
    margin-top: 20px;
  }
}

.sw-QandA_QuestionButton {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 13px 40px 13px 50px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  color: $color-text;
  background-color: #f8f8f8;
  font-family: inherit;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &[type=button] {
    appearance: none;
  }

  &:hover {
    color: #c5383b;
    background-color: #f8f8f8;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  @include mq-up(lg) {
    padding: 22px 66px 22px 56px;
    font-size: rem(17, lg);
    line-height: 1.4;
  }

}

.sw-QandA_Q {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  height: 1em;
  margin: auto;
  font-size: rem(22);
  font-family: $font-family-en;
  color: #c5383b;
  transform: translateY(-0.3em);

  @include mq-up(lg) {
    left: 16px;
    font-size: rem(26, lg);
  }
}

.sw-QandA_Icon {
  &:before {
    @include Icon("icon_arrow-down");
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 1em;
    color: #666;

    .sw-QandA_QuestionButton[aria-expanded="true"] & {
      transform: rotate(180deg);
    }
  }
}

.sw-QandA_Answer {
  position: relative;
  padding: 18px 20px 25px;
  text-indent: 30px;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  transition-property: padding-top, padding-bottom;

  &[aria-hidden="true"] {
    overflow: hidden;
    visibility: hidden;
    height: 0 !important;
    padding: 0 !important;
  }

  &[aria-hidden="false"] {
    overflow: visible;
    visibility: visible;
    height: auto;
  }

  /* 「A. 回答」に続く要素のインデントを左に揃える。 */
  & > *:nth-child(n+3) {
    text-indent: 0;
  }

  @include mq-up(lg) {
    padding: 20px 56px 40px;
    text-indent: 0;
  }
}

.sw-QandA_A {
  position: absolute;
  top: 0;
  left: -0.25em;
  margin: auto;
  font-size: rem(22);
  font-family: $font-family-en;
  line-height: 1;
  color: #666;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  transition-property: top;

  .sw-QandA_Answer[aria-hidden="false"] & {
    opacity: 1;
    top: 18px;
  }

  @include mq-up(lg) {
    top: 0;
    left: 16px;
    font-size: rem(26, lg);

    .sw-QandA_Answer[aria-hidden="false"] & {
      top: 23px;
    }
  }
}

.sw-QandA_Text {
  font-size: rem(16, lg);
  line-height: 1.8;

  & + & {
    margin-top: 20px;
  }
}

.sw-QandA_Link {
  @include sw-Link();
}
