@mixin sw-FormSelect() {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    left: 0;
    width: 99%;
    width: calc(100% - 6px);
    margin: auto;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &:after {
    $size: 1em;
    content: "";
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    width: $size;
    height: $size;
    margin: auto;
    background-image: url("/assets/img/SiteWide/Icon/FormSelect.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;
  }

  &.is-disabled:after {
    opacity: $form-disabled-opacity;
  }

  & > select {
    width: 100%;
    height: 2.5em;
    padding: 0 3em 0 1em;
    border: 1px solid #d0d0d0;
    border-radius: $form-border-radius;
    font-size: 16px;
    line-height: 1;
    vertical-align: middle;
    background-color: #fff;
    appearance: none;
    box-shadow: 1px 1px 6px -3px #9e9e9e inset;
  }

  & > select:focus {
    outline-width: 0;
  }

  & > select.focus-ring:focus {
    border-color: $form-focus-border-color;
    box-shadow: $form-focus-box-shadow;
  }

  & > select:disabled {
    opacity: $form-disabled-opacity;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;
  }

  /* Firefox Hack */
  & > select {
    text-indent: 0.01px;
    text-overflow: "";
  }

  /* IE10以上 */
  & > select::-ms-expand {
    display: none;
  }

  /* IE9ではデフォルトの矢印アイコンを表示する。 */
  &:not(:target):after {
    background-image: none\9;
  }
  & > select:not(:target) {
    padding-right: 0\9;
  }
}
