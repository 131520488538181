// _FooterLabPortal ------------------------------
.st-FooterLabPortal {
  &.st-FooterRegistration {
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  & .st-FooterRegistration_Item {
    @include mq-up(lg) {
      font-size: rem(16) !important;
      width: 33%;
    }

    @media print, screen and (min-width: 1200px) {
      font-size: rem(20, lg);
    }
  }

  & .st-FooterRegistration_Item:nth-child(2) {
    & .st-FooterRegistration_Link {
      padding: 40px 0 40px 43px;

      @media print, screen and (min-width: 1200px) {
        padding: 30px 0 30px 50px;
      }
    }
  }

  & .st-FooterRegistration_Link {
    padding: 40px 0 40px 20px;
    height: auto !important;

    @media print, screen and (min-width: 1200px) {
      padding: 30px 40px;
    }
  }

  & .st-FooterRegistration_Heading {
    margin: 0 7vw;

    @include mq-up(md) {
      margin: 0;
    }
  }

  & .st-FooterRegistration_MailIcon {
    font-size: 2rem;

    @media print, screen and (min-width: 1200px) {
      font-size: 2.6rem;
    }

    &::before {
      @include mq-up(lg) {
        top: 0;
      }

      @media print, screen and (min-width: 1200px) {
        top: -0.2em;
      }
    }
  }

  & .st-FooterRegistration_DownloadIcon {
    height: 1em;
    position: absolute;
    left: -0.3em;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 5rem;

    @media print, screen and (min-width: 1200px) {
      font-size: 6rem;
    }

    &:before {
      @include Icon("icon_download");
      position: relative;
      top: -0.07em;
      left: 0.5em;

      @media print, screen and (min-width: 1200px) {
        top: -0.3em;
        left: 0.6em;
        height: 52px;
      }
    }
  }

  & .st-FooterRegistration_FacebookIcon {
    font-size: 2rem;

    @media print, screen and (min-width: 1200px) {
      font-size: 2.6rem;
    }

    &:before {
      @include mq-up(lg) {
        top: 0;
      }

      @media print, screen and (min-width: 1200px) {
        top: -0.3em;
      }
    }
  }
}

// _FooterCopyright_LabPortal ------------------------------
.st-FooterCopyright_LabPortal {
  &.st-FooterCopyright {
    background-color: #2b2b2b;
    background-image: url(/assets/img/structure/bg_footer-registration.png);
    background-repeat: repeat;
    background-size: auto;
  }
}

// footer_sitemap_labPortalSearch ------------------------------
.st-FooterSitemap_Item_LabPortalSp {
  display: block;

  @include mq-up(lg) {
    display: none;
  }
}

.st-FooterSitemap_Item_LabPortalPc {
  display: none;

  @include mq-up(lg) {
    display: block;
  }
}

// st-Grids_LabPortal ------------------------------
.st-Grids_LabPortal {
  & .sw-VisualNavA_Item {
    bottom: 42%;
  }

  & .st-FooterRegistration_MailIcon {
    left: 47%;

    &::before {
      top: -1.38em;
      font-size: 2.6rem;
    }

  }

  .st-FooterRegistration_FacebookIcon {
    left: 45%;

    &::before {
      top: -1.38em;
      font-size: 2.6rem;
    }

  }

  & .st-FooterRegistration_DownloadIcon {
    position: absolute;
    height: 1em;
    left: 48%;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 6rem;

    &::before {
      @include Icon("icon_download");
      position: relative;
      top: -0.95em;
      height: 52px;
    }
  }
}
