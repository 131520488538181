/*
---
name: sw2-Marquee
namespace: SiteWide2
category: Marquee
---

装飾文字スクロールです。

```html
<div class="sw2-Marquee">INNOVATION</div>
```
*/
.sw2-Marquee {
  display: flex;
  width: 100vw;
  height: rem(140);
  overflow: hidden;
  background-color: #f5f5f5;
  pointer-events: none;
  @include mq-up(lg) {
    height: rem(200, lg);
  }
}
.sw2-Marquee div {
  flex: 0 0 auto;
  white-space: nowrap;
  font-family: $font-family-lato;
  font-style: normal;
  font-weight: 600;
  font-size: rem(140);
  line-height: rem(140);
  color: #fff;
  @include mq-up(lg) {
    font-size: rem(200, lg);
    line-height: rem(200, lg);
  }
}
.sw2-Marquee div:nth-child(odd) {
  animation: loop 50s -25s linear infinite;
}
.sw2-Marquee div:nth-child(even) {
  animation: loop2 50s linear infinite;
}
@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

