/*
---
name: sw-MediaA
namespace: SiteWide
category: Block
id: [C1, TH-5A, TH-5B, TH-5B, TH-5C, TH-5D, TH-5E, TH-5F, TH-6, TH-8A, TH-8B, TH-8C, TH-9, TH-13]
---

画像とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

カラム幅の指定オプションです。`.sw-MediaA`にクラスを追加できます。

- PC幅で6:6 `.sw-MediaA-col6to6Lg`
- PC幅で3:9 `.sw-MediaA-col3to9Lg`
- PC幅で9:3 `.sw-MediaA-col9to3Lg`
- PC幅で4:8 `.sw-MediaA-col4to8Lg`
- PC幅で8:4 `.sw-MediaA-col8to4Lg`
- PC幅で5:7 `.sw-MediaA-col5to7Lg`
- PC幅で7:5 `.sw-MediaA-col7to5Lg`
- カラムの反転：`.sw-MediaA-reverse`（HTML上で順番を変えるだけでは再現できない場合に使用します）
- PC幅でカラムの反転：`.sw-MediaA-reverseLg`（HTML上で順番を変えるだけでは再現できない場合に使用します）

<span class="sw-Label">TH-5A</span>

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Startup">Startup Support Seminar</p>
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <p class="sw-MediaA_Text"><strong class="sw-MediaA_Caution">ラウンジ会員　20,000円/月 1人<br>
        オフィス会員（個室）　68,000円〜/月 1室</strong></p>
        <ul class="sw-MediaA_Note sw-MediaA_Text">
          <li class="sw-MediaA_NoteItem">固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。</li>
        </ul>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-5B</span>

- 見出しとテキストとボタンのバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-5C</span>

- 見出しとテキストとボタンのバリエーション。
- 見出しとテキストとリンクのバリエーション。
- リンク付き見出しとテキストのバリエーション。
- 見出しのリンクなし。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">BIZ NEXT</p>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <p class="sw-MediaA_Text">
        <a class="sw-MediaA_Link" href="#" target="_blank">http://www.nijo-nazuna.jp/<span class="sw-MediaA_LinkPdf" aria-hidden="true"></span></a>
        </p>
      </div>
    </div>
  </div>
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <a class="sw-MediaA_Heading" href="#" target="_blank"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT<span class="sw-MediaA_LinkExternal" aria-hidden="true"></span></a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <p class="sw-MediaA_Text">
        <a class="sw-MediaA_Link" href="#" target="_blank">http://www.nijo-nazuna.jp/<span class="sw-MediaA_LinkExternal" aria-hidden="true"></span></a>
        </p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-5D</span>

- 見出しとテキストと強調テキストとボタンのバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <p class="sw-MediaA_Text"><strong class="sw-MediaA_Caution">ラウンジ会員　20,000円/月 1人<br>
        オフィス会員（個室）　68,000円〜/月 1室</strong></p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-5E</span>

- 見出しとテキストと注釈とボタンのバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <ul class="sw-MediaA_Note sw-MediaA_Text">
          <li class="sw-MediaA_NoteItem">固有の技術をベースとしない単なるアイデア、サービスをベースとした方は、スタートアップでも対象外させて頂きます。</li>
        </ul>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-5F</span>

- スタートアップ用テキストと見出しとテキストとボタンのバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Startup">Startup Support Seminar</p>
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col4to8Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Startup">Startup Support Seminar</p>
        <a class="sw-MediaA_Heading" href="#"><span class="sw-MediaA_HeadingIcon" aria-hidden="true"></span>BIZ NEXT</a>
        <p class="sw-MediaA_Text">机・イス・電話機他のオフィス家具、無線LAN付きの１～4名様向けの個室が全51室。ご利用開始後すぐにお仕事を始められます。コンパクトなスペースながら閉塞感を軽減する壁面や間接照明を用いた空間が特長です。（完全個室と半オープンの個室の2タイプございます）</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">詳細をみる<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-6</span>

- ラベルを配置したバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col6to6Lg">
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
      <div class="sw-MediaA_Body">
        <ul class="sw-MediaA_LabelArea">
          <li class="sw-MediaA_Label">オフィススペース</li>
          <li class="sw-MediaA_Label">実験研究スペース</li>
          <li class="sw-MediaA_Label">貸会議室</li>
          <li class="sw-MediaA_Label">飲食店</li>
        </ul>
        <p class="sw-MediaA_Text">ステータスを感じさせる重厚な建物。<br>
        レストランや貸会議室など、さまざまな機能を備えています。広いロビーでは、飲み物のケータリングも利用でき、ゆったりとお客さまをお迎えできます。<br>
        陽光あふれる中庭は、四季の彩りを感じさせます。</p>
        <div class="sw-MediaA_ButtonArea">
          <a href="#" class="sw-MediaA_Button">もっと見る<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-8A</span>

- それぞれにボタンを配置したバリエーション。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col8to4Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Text">KRPのFacebookに「いいね!」いただくか、メールマガジンに登録いただくと、4号館の「Biz　NEXT」のビジネスラウンジを1日無料で利用できます。<br>
        Facebookの画面か、メルマガ登録時の完了画面を、受付にご提示ください。<br>
        ※お一人様、1回限り。</p>
        <ul class="sw-MediaA_ButtonArea">
          <li class="sw-MediaA_ButtonItem">
            <a href="#" class="sw-MediaA_Button">お問い合わせする<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
          </li>
          <li class="sw-MediaA_ButtonItem">
            <a href="#" class="sw-MediaA_Button">よくあるご質問<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
        <ul class="sw-MediaA_ImageButtonArea sw-MediaA_ImageButtonArea-center">
          <li class="sw-MediaA_ImageButtonItem">
            <a href="#" class="sw-MediaA_Button sw-MediaA_Button-auto">ガイドブック一覧はこちら<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col8to4Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Text">KRPのFacebookに「いいね!」いただくか、メールマガジンに登録いただくと、4号館の「Biz　NEXT」のビジネスラウンジを1日無料で利用できます。<br>
        Facebookの画面か、メルマガ登録時の完了画面を、受付にご提示ください。<br>
        ※お一人様、1回限り。</p>
        <ul class="sw-MediaA_Links">
          <li class="sw-MediaA_LinksItem">
            <span class="sw-MediaA_LinksIcon" aria-hidden="true"></span>
            <a class="sw-MediaA_LinksLink" href="#">Facebook<span class="sw-MediaA_LinkPdf" aria-hidden="true"></span></a>
          </li>
          <li class="sw-MediaA_LinksItem">
            <span class="sw-MediaA_LinksIcon" aria-hidden="true"></span>
            <a class="sw-MediaA_LinksLink" href="#">X<span class="sw-MediaA_LinkExternal" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
        <ul class="sw-MediaA_ImageButtonArea sw-MediaA_ImageButtonArea-center">
          <li class="sw-MediaA_ImageButtonItem">
            <a href="#" class="sw-MediaA_Button sw-MediaA_Button-auto">ガイドブック一覧はこちら<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-8B</span>

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col8to4Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Text">KRPのFacebookに「いいね!」いただくか、メールマガジンに登録いただくと、4号館の「Biz　NEXT」のビジネスラウンジを1日無料で利用できます。<br>
        Facebookの画面か、メルマガ登録時の完了画面を、受付にご提示ください。<br>
        ※お一人様、1回限り。</p>
        <ul class="sw-MediaA_ButtonArea">
          <li class="sw-MediaA_ButtonItem">
            <a href="#" target="_blank" class="sw-MediaA_Button">周辺マップ<span class="sw-MediaA_External" aria-hidden="true"></span></a>
          </li>
          <li class="sw-MediaA_ButtonItem">
            <a href="#" target="_blank" class="sw-MediaA_Button">facebook　#krpランチ<span class="sw-MediaA_External" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-8C</span>

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-col8to4Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Text">TH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないものTH-7を応用して、左ボアンのないもの</p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-MediaA_Image">
        <ul class="sw-MediaA_ImageButtonArea sw-MediaA_ImageButtonArea-center">
          <li class="sw-MediaA_ImageButtonItem">
            <a href="#" class="sw-MediaA_Button sw-MediaA_Button-auto">ガイドブック一覧はこちら<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-9</span>

- モジュール間に罫線をつけたバージョン。

```html
<div class="st-Wrapper">
  <div class="st-Block st-Block-divider">
    <div class="sw-MediaA sw-MediaA-col9to3Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">CORESCOPE株式会社</p>
        <p class="sw-MediaA_Text">「物事の核心（コア）を詳しく観察（スコープ）」<br>
        メカトロニクス（機械工学、電子工学および情報工学の融<br>合分野）の技術を応用し、電子機器、理化学機器、福祉機器およびロボットの企画、研究、開発、製造および販売に取り組んでおります。</p>
        <p class="sw-MediaA_Text">代表者：大久保　康<br>
        業種：製造業<br>
        <a class="sw-MediaA_Link" href="#">http://www.corescope.co.jp</a></p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/270x174.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
  <div class="st-Block st-Block-divider">
    <div class="sw-MediaA sw-MediaA-col9to3Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">株式会社Yumegurashi</p>
        <p class="sw-MediaA_Text">京町屋を活用した欧米富裕層向け旅館</p>
        <p class="sw-MediaA_Text">代表者：大久保　康<br>
        業種：製造業<br>
        <a class="sw-MediaA_Link" href="#" target="_blank">http://www.nijo-nazuna.jp/<span class="sw-MediaA_LinkExternal" aria-hidden="true"></span></a></p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/270x174.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">TH-13</span>

- 上下中央に表示。`.sw-MediaA-middle`

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw-MediaA sw-MediaA-middle sw-MediaA-col9to3Lg">
      <div class="sw-MediaA_Body">
        <p class="sw-MediaA_Heading">CORESCOPE株式会社</p>
        <p class="sw-MediaA_Text">「物事の核心（コア）を詳しく観察（スコープ）」<br>
        メカトロニクス（機械工学、電子工学および情報工学の融<br>合分野）の技術を応用し、電子機器、理化学機器、福祉機器およびロボットの企画、研究、開発、製造および販売に取り組んでおります。</p>
        <p class="sw-MediaA_Text">代表者：大久保　康<br>
        業種：製造業<br>
        <a class="sw-MediaA_Link" href="#">http://www.corescope.co.jp</a></p>
      </div>
      <div class="sw-MediaA_ImageArea">
        <img src="https://placehold.jp/270x174.png" alt="" class="sw-MediaA_Image">
      </div>
    </div>
  </div>
</div>
```

*/
.sw-MediaA {
  margin-left: -15px;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.sw-MediaA-col6to6Lg {
  @include mq-up(lg) {
    & > .sw-MediaA_ImageArea,
    & > .sw-MediaA_Body {
      width: percentage(div(1, 2));
    }
  }
}

.sw-MediaA-col3to9Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(3, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(9, 12)); }
  }
}
.sw-MediaA-col9to3Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(9, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(3, 12)); }
  }
}

.sw-MediaA-col4to8Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(4, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(8, 12)); }
  }
}
.sw-MediaA-col8to4Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(8, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(4, 12)); }
  }
}

.sw-MediaA-col5to7Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(5, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(7, 12)); }
  }
}
.sw-MediaA-col7to5Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(7, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(5, 12)); }
  }
}

.sw-MediaA-reverse {
  text-align: left;
  direction: rtl;

  & > .sw-MediaA_ImageArea,
  & > .sw-MediaA_Body {
    text-align: left;
    direction: ltr;
  }
}
.sw-MediaA-reverseLg {
  @include mq-up(lg) {
    text-align: left;
    direction: rtl;

    & > .sw-MediaA_ImageArea,
    & > .sw-MediaA_Body {
      text-align: left;
      direction: ltr;
    }
  }
}

.sw-MediaA_ImageArea {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  .sw-MediaA_Body + & {
    margin-top: 30px;
  }

  @include mq-up(lg) {
    padding-left: 30px;

    .sw-MediaA_Body + & {
      margin-top: 0;
    }
  }
}

.sw-MediaA_Image {
  max-width: none;
  width: 100%;
}

.sw-MediaA_ImageButtonArea {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    margin-top: 40px;
  }
}

.sw-MediaA_ImageButtonArea-center {
  text-align: center;
}

.sw-MediaA_ImageButtonItem {

}

.sw-MediaA_Body {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;

    & > :first-child {
      margin-top: 0;
    }
  }
}

.sw-MediaA_LabelArea {
  margin-top: 14px;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    margin-top: 0;
  }
}

.sw-MediaA_Label {
  @include sw-Label();
  margin-right: 7px;
  margin-bottom: 5px;

  @include mq-up(lg) {
    margin-right: 18px;
    margin-bottom: 10px;
  }
}

.sw-MediaA_Startup {
  margin-top: 10px;
  font-family: $font-family-en;
  font-size: rem(16);
  line-height: 1.5;
  color: #999;

  @include mq-up(lg) {
    font-size: rem(20, lg);
  }
}

.sw-MediaA_Heading {
  position: relative;
  margin-top: 12px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    margin-top: 12px;
    font-size: rem(20, lg);
  }
}
a.sw-MediaA_Heading {
  display: block;
  padding-left: 20px;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    color: #d02425;
    text-decoration: none;
  }
}

.sw-MediaA_HeadingIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  color: $color-brand;
  font-size: 0.7em;
}

.sw-MediaA_Text {
  margin-top: 12px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    margin-top: 20px;
    font-size: rem(16, lg);
  }
}

.sw-MediaA_Caution {
  font-weight: bold;
}


.sw-MediaA_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-MediaA_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.sw-MediaA_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-MediaA_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}


.sw-MediaA_Note {
  margin-left: 1.5em;
  padding-left: 0;
  font-size: rem(11);
  line-height: 1.6;
  list-style-type: none;

  @include mq-up(lg) {
    font-size: rem(11, lg);
  }

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1em;
  }
}

.sw-MediaA_NoteItem {
  position: relative;

  &:before {
    content: "※";
    position: absolute;
    top: 0;
    left: -1.5em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*";
    left: -1em;
  }
}

.sw-MediaA_ButtonArea {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
  text-align: center;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq-up(lg) {
    margin-top: 30px;
    text-align: left;
  }
}

.sw-MediaA_ButtonItem {
  display: block;

  & + & {
    margin-top: 20px;
  }

  @include mq-up(lg) {
    float: left;
    & + & {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.sw-MediaA_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.sw-MediaA_Button-auto {
  width: auto;
}

.sw-MediaA_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-MediaA_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-MediaA_Link {
  @include sw-Link();
}

.sw-MediaA_LinkExternal:before {
  @include sw-LinkExternal();
}

.sw-MediaA_LinkPdf:before {
  @include sw-LinkPdf();
}

.sw-MediaA-middle {
  & > .sw-MediaA_Body,
  & > .sw-MediaA_ImageArea {
    vertical-align: middle;
  }
}
