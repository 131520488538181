/*
---
name: st2-FooterSitemap
namespace: Structure2
category: Global
---

グローバルフッターにあるサイトマップエリアです。

```html
<div class="st2-FooterSitemap">
  <div class="st2-FooterSitemap_Inner">
    <div class="st2-FooterSitemap_Grid">
      <div class="st2-FooterSitemap_GridItem">
        <ul class="st2-FooterSitemap_links">
          <li><a href="#">オフィス・ラボ</a>
            <ul class="st2-FooterSitemap_Sublinks">
              <li><a href="#">オフィス</a></li>
              <li><a href="#">ラボ</a></li>
              <li><a href="#">サービスオフィス KRP BIZ NEXT</a></li>
            </ul>
          </li>
          <li><a href="#">ホール・会議室</a></li>
          <li><a href="#">イノベーション創発活動</a></li>
        </ul>
      </div>
      <div class="st2-FooterSitemap_GridItem">
        <ul class="st2-FooterSitemap_links">
          <li><a href="#">イベント</a></li>
          <li><a href="#">アクセスマップ</a></li>
        </ul>
      </div>
      <div class="st2-FooterSitemap_GridItem">
        <ul class="st2-FooterSitemap_links">
          <li><a href="#">KRPの特長</a></li>
          <li><a href="#">メール会員登録</a></li>
          <li><a href="#">ニュースルーム</a></li>
          <li><a href="#">KRP PRESS</a></li>
        </ul>
      </div>
      <div class="st2-FooterSitemap_GridItem">
        <div class="st2-FooterSitemap_SnsHeading">公式SNS</div>
        <ul class="st2-FooterSitemap_Snslinks">
          <li>
            <a href="#" target="_blank" rel="noopener">
              <span class="st2-FooterSitemap_Snslinks_FacebookIcon" aria-hidden="true"></span>
              <span class="st2-FooterSitemap_Snslinks_Text">Facebook</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener">
              <span class="st2-FooterSitemap_Snslinks_TwitterIcon" aria-hidden="true"></span>
              <span class="st2-FooterSitemap_Snslinks_Text">X</span>
            </a>
          </li>
          <li>
            <a href="#" target="_blank" rel="noopener">
              <span class="st2-FooterSitemap_Snslinks_InstagramIcon" aria-hidden="true"></span>
              <span class="st2-FooterSitemap_Snslinks_Text">Instagram</span>
            </a>
          </li>
        </ul>
        <div class="st2-FooterSitemap_SnsHeading -is-Language">言語切り替え</div>
        <ul class="st2-FooterSitemap_Snslinks">
          <li class="-is-Language">
            <a href="#" target="_blank">
              <span class="st2-FooterSitemap_Snslinks_LanguageIcon" aria-hidden="true"></span>
              <span class="st2-FooterSitemap_Snslinks_Text">English</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="st2-FooterSitemap_FootLink">
      <ul>
        <li><a href="#">会社案内</a></li>
        <li><a href="#">サイトマップ</a></li>
        <li><a href="#">プライバシーポリシー</a></li>
        <li><a href="#">サイトのご利用にあたって</a></li>
      </ul>
    </div>
  </div>
</div>
```
*/
.st2-FooterSitemap {
  background-color: #EDEFF0;
  padding: 0 rem(20);
  @include mq-up(lg) {
    padding: 0 rem(20, lg);
  }
}
.st2-FooterSitemap_Inner {
  @include mq-up(lg) {
    border-top: 1px solid #D3D8DA;
    padding-top: rem(50);
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
  }
}
.st2-FooterSitemap_Grid {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.st2-FooterSitemap_GridItem{ 
  border-top: 1px solid #D3D8DA;

  @include mq-up(lg) {
    width: 25%;
    border-top: none;
  }

  &:first-child {
    border-top: none;
  }
}
.st2-FooterSitemap_links {
  margin: rem(0);
  padding: rem(0);
  & > li {
    list-style: none;
    border-top: 1px solid #D3D8DA;
    line-height: rem(18);
    @include mq-up(lg) {
      border-top: none;
    }
    &:first-child {
      border-top: none;
      & > a {
        margin-top: 0;
      }
    }
    & > a {
      display: block;
      position: relative;
      text-decoration: none;
      font-weight: 700;
      font-size: rem(14);
      line-height: rem(18);
      padding: rem(20) 0;
      color: #333;
      @include mq-up(lg) {
        display: inline-block;
        padding: 0;
        margin-top: rem(24, lg);
        transition-duration: $transition-duration;
        transition-timing-function: $transition-timing-function;
      }

      &:hover {
        @include mq-up(lg) {
          color: $color-krp;
        }
      }

      &::after {
        @include Icon("icon_arrow-right");
        font-size: rem(14);
        position: absolute;
        color: $color-krp;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        @include mq-up(lg) {
          display: none;
        }
      }
    }
  }
}
.st2-FooterSitemap_links-pc {
  display: none !important;
  @include mq-up(lg) {
    display: inline-block !important;
  }
}
.st2-FooterSitemap_Sublinks {
  margin: rem(0);
  padding: rem(0);
  @include mq-up(lg) {
    margin-left: rem(16, lg);
  }
  & > li {
    list-style: none;
    border-top: 1px solid #D3D8DA;
    line-height: rem(18);
    @include mq-up(lg) {
      border-top: none;
    }

    &:first-child {
      border-top: none;
      & > a {
        @include mq-up(lg) {
          margin-top: rem(20, lg);
        }
      }
    }
    & > a {
      display: block;
      position: relative;
      text-decoration: none;
      font-weight: 700;
      font-size: rem(14);
      line-height: rem(18);
      padding: rem(20) 0;
      color: #333;
      @include mq-up(lg) {
        display: inline-block;
        padding: 0;
        font-weight: 400;
        font-size: rem(13, lg);
        margin-top: rem(10, lg);
        color: #686F73;
        transition-duration: $transition-duration;
        transition-timing-function: $transition-timing-function;
      }

      &:hover {
        @include mq-up(lg) {
          color: $color-krp;
        }
      }

      &::after {
        @include Icon("icon_arrow-right");
        font-size: rem(14);
        position: absolute;
        color: $color-krp;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        @include mq-up(lg) {
          display: none;
        }
      }
    }
  }
}
.st2-FooterSitemap_SnsHeading {
  display: none;
  @include mq-up(lg) {
    display: block;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(18);
  }
  &.-is-Language {
    @include mq-up(lg) {
      margin-top: rem(20, lg);
    }
  }
}
.st2-FooterSitemap_Snslinks {
  margin: rem(0);
  padding: rem(0);
  @include mq-up(lg) {
    margin-top: rem(20, lg);
  }
  & > li {
    list-style: none;
    border-top: 1px solid #D3D8DA;

    @include mq-up(lg) {
      border-top: none;
      margin-top: rem(12, lg);
    }

    &:first-child {
      border-top: none;
      @include mq-up(lg) {
        margin-top: 0;
      }
    }
    &.-is-Language {
      border-top: 1px solid #D3D8DA;
      @include mq-up(lg) {
        border-top: none;
        padding-top: rem(20, lg);
      }
    }
    & > a {
      text-decoration: none;
      @include mq-up(lg) {
        display: inline-flex;
        flex-flow: nowrap;
        justify-content: flex-start;
        align-items: center;
      }

      &:hover {
        .st2-FooterSitemap_Snslinks_Text {
          @include mq-up(lg) {
            color: $color-krp;
          }
        }
      }
    }
  }
}
.st2-FooterSitemap_Snslinks_Text {
  display: block;
  position: relative;
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(18);
  padding: rem(20) 0;
  color: #333;
  @include mq-up(lg) {
    font-family: $font-family-lato;
    font-size: rem(13, lg);
    padding: 0;
    display: inline-block;
    color: #686F73;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }

  &::after {
    @include Icon("icon_arrow-right");
    font-size: rem(14);
    position: absolute;
    color: $color-krp;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    @include mq-up(lg) {
      display: none;
    }
  }
}

.st2-FooterSitemap_Snslinks_FacebookIcon {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-right: rem(10, lg);
  }
  &::before {
    @include Icon("icon_facebook");
    @include mq-up(lg) {
      font-size: rem(32, lg);
      color: #3D6CB5;
    }
  }
}
.st2-FooterSitemap_Snslinks_TwitterIcon {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-right: rem(10, lg);
  }
  &::before {
    @include Icon("icon_twitter");
    @include mq-up(lg) {
      font-size: rem(32, lg);
      color: #000000;
    }
  }
}
.st2-FooterSitemap_Snslinks_InstagramIcon {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-right: rem(10, lg);
  }
  &::before {
    @include Icon("icon_instagram");
    @include mq-up(lg) {
      font-size: rem(32, lg);
      height: rem(35, lg);
      padding-top: rem(2, lg);
      background: linear-gradient(45deg, #FEC91E 0%, #FB8314 25%, #F61E04 50%, #DD0049 75%, #C0009E 100%);
      background-clip: text;
      color: transparent;
    }
  }
}
.st2-FooterSitemap_Snslinks_LanguageIcon {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-right: rem(10, lg);
  }
  &::before {
    @include Icon("icon_sphere");
    @include mq-up(lg) {
      font-size: rem(32, lg);
      height: rem(35, lg);
      padding-top: rem(2, lg);
      color: #9CA7AC;
    }
  }
}

.st2-FooterSitemap_FootLink {
  border-top: 1px solid #D3D8DA;
  padding: rem(15) 0;

  @include mq-up(lg) {
    border-top: none;
    padding: rem(80, lg) 0 rem(20, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding: rem(100, lg) 0 rem(20, lg);
  }
  & > ul {
    margin: rem(0);
    padding: rem(0);
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    @include mq-up(lg) {
      flex-flow: nowrap;
      justify-content: center;
    }
    & > li {
      list-style: none;
      width: 50%;
      @include mq-up(lg) {
        width: auto;
        padding: 0 rem(16, lg);
        border-left: 1px solid #D3D8DA;
        line-height: rem(18, lg);
      }
      &:first-child {
        @include mq-up(lg) {
          border-left: none;
        }
      }
      & > a {
        display: block;
        text-align: center;
        text-decoration: none;
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(12);
        color: #686F73;
        padding: rem(10) 0;
        @include mq-up(lg) {
          padding: 0;
          font-size: rem(11, lg);
          line-height: rem(18, lg);
          display: inline-block;
          transition-duration: $transition-duration;
          transition-timing-function: $transition-timing-function;
        }

        &:hover {
          @include mq-up(lg) {
            color: $color-krp;
          }
        }

      }
    }
  }
}
