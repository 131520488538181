/*
---
name: st2-ContainerA
namespace: Structure2
category: Layout
---

コンテンツ内の上下に大きめの余白をとります。

レイアウト例です。

```html
<div class="st2-ContainerA">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st2-ContainerA-gray`を追加すると背景色がつきます。

```html
<div class="st2-ContainerA st2-ContainerA-gray">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st2-ContainerA-grayright`を追加すると右背景にグレーがつきます。

```html
<div class="st2-ContainerA st2-ContainerA-grayright">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st2-ContainerA-grayleft`を追加すると左背景にグレーがつきます。

```html
<div class="st2-ContainerA st2-ContainerA-grayleft">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

*/
.st2-ContainerA {
  padding: rem(60) rem(0);

  @include mq-up(lg) {
    padding:  rem(100, lg) rem(0);
  }

  &.st2-ContainerA-gray {
    background-color: #f5f5f5;
  }
  
  &.st2-ContainerA-grayright {
    background: linear-gradient(90deg, #fff 0%, #fff 75%, #f5f5f5 75%, #f5f5f5 100%);
  }
  
  &.st2-ContainerA-grayleft {
    background: linear-gradient(90deg, #f5f5f5 0%, #f5f5f5 25%, #fff 25%, #fff 100%);
  }
  &.st2-ContainerA-Offset {
    padding-top: rem(0);
  }
  &.st2-ContainerA-Offset-20 {
    padding-top: rem(20);
  }
  &.st2-ContainerA-OffsetBottom {
    padding-bottom: rem(0);
  }
  &.st2-ContainerA-TopA {
    padding: rem(45) rem(0) rem(100);
    @include mq-up(lg) {
      padding:  rem(80, lg) rem(0) rem(160, lg);
    }
  }
  &.st2-ContainerA-TopB {
    padding-bottom: rem(100);
    @include mq-up(lg) {
      padding-bottom: rem(160, lg);
    }
  }
}


