/*
---
name: sw2-VisualNavC
namespace: SiteWide2
category: Navigation
---

sw-VisualNavCを要素選別するためのパーツです。

*/
.sw2-VisualNavC_List {
  overflow-x: auto;
  margin-right: rem(-20);
  margin-bottom: rem(35);
  @include mq-up(lg) {
    margin-right: rem(0);
    margin-bottom: rem(50);
  }
  & > ul {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    & > li {
      list-style: none;
      margin-right: 1px;
      @include mq-up(lg) {
        width: 25%;
      }
      & > a {
        display: block;
        min-width: rem(110);
        white-space: nowrap;
        text-decoration: none;
        font-weight: 700;
        font-size: rem(12);
        line-height: rem(12);
        text-align: center;
        padding: rem(18) rem(10);
        color: #fff;
        background-color: $color-krp;
        transition-duration: $transition-duration;
        transition-timing-function: $transition-timing-function;
        @include mq-up(lg) {
          min-width: auto;
          font-size: rem(14, lg);
          line-height: rem(20, lg);
          padding: rem(20, lg) rem(10, lg);
        }
        &.-is-active {
          background-color: #6A0012;
        }
        &:hover {
          @include mq-up(lg) {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
.sw2-VisualNavC_FootBnrArea {
  & > ul {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin-left: rem(-2);
    @include mq-up(lg) {
      margin-left: rem(-2, lg);
    }
    & > li {
      list-style: none;
      width: calc(50% - rem(2));
      font-size: rem(11);
      line-height: rem(13);
      text-align: center;
      color: #fff;
      background-color: #333;
      margin-left: rem(2);
      padding: rem(4) 0;
      @include mq-up(lg) {
        width: calc(50% - rem(2, lg));
        font-size: rem(11, lg);
        line-height: rem(13, lg);
        margin-left: rem(2, lg);
        padding: rem(4, lg) 0;
      }
      &.-is-vnavc-01 {
        background-color: #00896C;
        letter-spacing: -0.1rem;
        @include mq-up(lg) {
          letter-spacing: 0;
        }
      }
      &.-is-vnavc-02 {
        background-color: #005CAF;
      }
      &.-is-vnavc-03 {
        background-color: #AE2135;
      }
      &.-is-vnavc-04 {
        background-color: #D19826;
      }
    }
  }
}
.sw2-VisualNavC_Items {
  @include mq-up(lg) {
    margin-top: rem(-50, lg);
  }
  .sw-VisualNavC_Item {
    @include mq-up(lg) {
      margin-top: rem(50, lg);
    }
  }
  .sw-VisualNavC_Item + .sw-VisualNavC_Item {
    @include mq-up(lg) {
      margin-top: rem(50, lg);
    }
  }
}
