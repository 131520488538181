/*
---
name: st-ContentHeaderA
namespace: Structure
category: Navigation
id: [K1, PT, PT1, PT1-2]
---

<span class="sw-Label">PT</span>

個別に特色を持つページの2階層・TOP用のコンテンツヘッダーです。

- グループＡ
  - ジャンルTOP　イメージ打ち出し（ナビなしは「入居企業」「プラットフォームTOP」のみ）

```html
<header class="st-ContentHeaderA">
  <div class="st-ContentHeaderA_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderA_Block">
        <div class="st-ContentHeaderA_Title">
          <p class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false" role="heading">入居企業・コラボ事例<span class="st-LocalTitleA_Small">Voice・Collaboration</span><span class="st-LocalTitleA_Icon" aria-hidden="true"></span></p>
        </div>
        <div class="st-ContentHeaderA_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/" class="st-LocalNav1_Link">TOP<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">オフィス紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">部屋の広さ・<span class="st-LocalNav1_Delimiter">人数別</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">建物紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">レンタルラボ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">魅力ある<span class="st-LocalNav1_Delimiter">環境・サービス</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">料金・<span class="st-LocalNav1_Delimiter">ご契約の流れ</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">よくあるご質問<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">お問い合わせ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>
        </div>
        <p class="st-ContentHeaderA_Text">京都から世界を変える。<br>
        挑戦する人たちの新たな拠点。</p>
      </div>
      <div class="st-ContentHeaderA_Hero">
        <div class="st-LocalHeroA">
          <picture class="st-LocalHeroA_Image">
            <!--[if IE 9]><video style="display: none;"><![endif]-->
            <source media="(min-width: 980px)" srcset="https://placehold.jp/1600x494.png">
              <!--[if IE 9]></video><![endif]-->
              <img src="https://placehold.jp/750x232.png" alt="alt属性値を指定します。">
          </picture>
          <p class="st-LocalHeroA_Text">京都から世界を変える。<br>
        挑戦する人たちの新たな拠点。</p>
        </div>
      </div>
    </div>
  </div>
</header>
```

<span class="sw-Label">PT1</span>

- グループＡ
  - ジャンルTOP　イメージ打ち出し

```html
<header class="st-ContentHeaderA">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderA_Block">
      <div class="st-ContentHeaderA_Title">
        <p class="st-LocalTitleA">入居企業・コラボ事例<span class="st-LocalTitleA_Small">Voice・Collaboration</span></p>
      </div>
      <p class="st-ContentHeaderA_Text">京都から世界を変える。<br>
      挑戦する人たちの新たな拠点。</p>
    </div>
    <div class="st-ContentHeaderA_Hero">
      <div class="st-LocalHeroA">
        <picture class="st-LocalHeroA_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1600x494.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x232.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroA_Text">京都から世界を変える。<br>
      挑戦する人たちの新たな拠点。</p>
      </div>
    </div>
  </div>
</header>
```

<span class="sw-Label">PT1-2</span>

- グループＡ
  - ジャンルTOP　イメージ打ち出し（3つのイメージ配置は、オフィス•ラボTOP、会議室TOPのみ）

```html
<header class="st-ContentHeaderA">
  <div class="st-ContentHeaderA_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderA_Block">
        <div class="st-ContentHeaderA_Title">
          <p class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false" role="heading">オフィス・ラボ
            <!-- <span class="st-LocalTitleA_Small">About</span> -->
            <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
          </p>
        </div>
        <div class="st-ContentHeaderA_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/" class="st-LocalNav1_Link">オフィス紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/" class="st-LocalNav1_Link">魅力<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="/" class="st-LocalNav1_Link">環境・サービス<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">料金・<span class="st-LocalNav1_Delimiter">契約の流れ</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">よくあるご質問<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">お問い合わせ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>
        </div>
        <p class="st-ContentHeaderA_Text">挑戦する人のネットワークが広がる場所</p>
      </div>
      <div class="st-ContentHeaderA_Hero">
        <div class="st-LocalHeroA">
          <picture class="st-LocalHeroA_Image">
            <!--[if IE 9]><video style="display: none;"><![endif]-->
            <source media="(min-width: 980px)" srcset="https://placehold.jp/1600x494.png">
              <!--[if IE 9]></video><![endif]-->
              <img src="https://placehold.jp/750x232.png" alt="alt属性値を指定します。">
          </picture>
          <p class="st-LocalHeroA_Text">挑戦する人のネットワークが広がる場所</p>
        </div>
      </div>
    </div>
  </div>

  <div class="st-ContentHeaderA_SubNav">
    <ul class="st-LocalSubNavA">
      <li class="st-LocalSubNavA_Item">
        <a href="#" class="st-LocalSubNavA_Link">
          <img class="st-LocalSubNavA_Image" src="https://placehold.jp/cfcece/ffffff/553x229.png" alt="alt属性値を指定します。">
          <span class="st-LocalSubNavA_Text">交流会</span>
        </a>
      </li>
      <li class="st-LocalSubNavA_Item">
        <a href="#" class="st-LocalSubNavA_Link">
          <img class="st-LocalSubNavA_Image" src="https://placehold.jp/9d9d9d/ffffff/553x229.png" alt="alt属性値を指定します。">
          <span class="st-LocalSubNavA_Text">サークル活動</span>
        </a>
      </li>
      <li class="st-LocalSubNavA_Item">
        <a href="#" class="st-LocalSubNavA_Link">
          <img class="st-LocalSubNavA_Image" src="https://placehold.jp/cfcece/ffffff/553x229.png" alt="alt属性値を指定します。">
          <span class="st-LocalSubNavA_Text">イベント</span>
        </a>
      </li>
    </ul>
  </div>
</header>
```
*/
.st-ContentHeaderA {
}

.st-ContentHeaderA_Inner {
  position: relative;

  @include mq-up(lg) {
    height: 494px;
    overflow: hidden;
  }
}

.st-ContentHeaderA_Block {
  @include mq-up(lg) {
    padding-top: 34px;
  }
}

.st-ContentHeaderA_Block-toFix {
}

.st-ContentHeaderA_Block-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background-color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}

.st-ContentHeaderA_Title {
  @include mq-up(lg) {
    .st-ContentHeaderA_Block-fixed & {
      display: inline-block;
      vertical-align: middle;
      margin-right: 27px;
    }
  }
}

.st-ContentHeaderA_Nav {
  .st-ContentHeaderA_Block-fixed & {
    padding: 0 20px;
  }

  @include mq-up(lg) {
    margin-top: 34px;

    .st-ContentHeaderA_Block-fixed & {
      margin-top: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.st-ContentHeaderA_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    z-index: -1;
  }
}

.st-ContentHeaderA_Text {
  display: none;
  font-weight: 500;
  font-size: rem(42, lg);
  line-height: 1.4;
  letter-spacing: 0.25em;
  color: #fff;
  text-align: center;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    display: block;

    & br {
      display: inline;
    }

    .st-ContentHeaderA_Title + & {
      margin-top: 110px;
    }

    .st-ContentHeaderA_Nav + & {
      margin-top: 86px;
    }

    .st-ContentHeaderA_Block-fixed & {
      display: none;
    }
  }
}

// .st-ContentHeaderA_SubNav {
//   @include mq-up(lg) {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//   }
// }
