/*
---
name: sw-FromLabel
namespace: SiteWide
category: Form
id: FO-1
---

<span class="sw-Label">FO-1</span>

`input`や`textarea`などと一緒に使用するテキストです。`label`や`legend`に指定します。

```html
<div class="st-Wrapper">
<label for="desired1" class="sw-FromLabel">お問い合わせ内容、予算などのご希望についてご記入ください。<span class="sw-FormLabel_Require">（必須）</span></label>
<textarea class="sw-FormTextarea" id="desired1" required></textarea>

<fieldset class="inquiry-Layout_Item">
  <legend class="sw-FromLabel">タイプ</legend>
  <ul class="inquiry-Layout_Stack inquiry-Layout_Stack-checkbox">
    <li class="inquiry-Layout_StackItem">
      <input type="checkbox" id="type10" class="sw-FormCheckbox" name="type10">
      <label for="type10">オフィススペース</label>
    </li>
    <li class="inquiry-Layout_StackItem">
      <input type="checkbox" id="type12" class="sw-FormCheckbox" name="type12">
      <label for="type12">実験研究スペース（ウェットラボ仕様）</label>
    </li>
  </ul>
</fieldset>
</div>
```
*/
.sw-FromLabel {
  display: block;
  margin-bottom: 10px;
  font-size: rem(17, lg);
  font-weight: bold;
  line-height: 1.8;

  @include mq-up(lg) {
    margin-bottom: 10px;
    font-size: rem(17, lg);
  }
}

.sw-FormLabel_Require {
  font-size: rem(16, lg);
  font-weight: normal;
  color: $color-brand;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}
