/*
---
name: sw2-LocalNavi
namespace: SiteWide2
category: Navigation
---

ローカルナビゲーションです。

「sw2-LocalNavi-current-01」～「sw2-LocalNavi-current-10」のクラスを「main」タグに記載することで
PC時1～10番目のローカルナビのカレントを制御します。

```html
<main id="main" class="sw2-LocalNavi-current-01">
<div class="sw2-LocalNavi">
  <div class="st2-LocalNav" id="st2-LocalNav" aria-hidden="true">
    <div class="st2-LocalNav_Inner">
      <div class="st2-LocalNav_Heading">オフィス</div>
      <div class="st2-LocalNav_Menu">
        <ul class="st2-LocalNav_Items">
          <li class="st2-LocalNav_Item">
            <a href="#">TOP</a>
          </li>
          <li class="st2-LocalNav_Item">
            <a href="#">オフィス紹介</a>
          </li>
          <li class="st2-LocalNav_Item">
            <a href="#">選ばれる理由</a>
          </li>
          <li class="st2-LocalNav_Item">
            <a href="#">環境・サービス</a>
            <ul class="st2-LocalNav_SubItems">
              <li class="st2-LocalNav_SubItem"><a href="#">職場環境</a></li>
              <li class="st2-LocalNav_SubItem"><a href="#">インフラ・セキュリティ</a></li>
              <li class="st2-LocalNav_SubItem"><a href="#">データセンター</a></li>
              <li class="st2-LocalNav_SubItem"><a href="#">企業集積・交流</a></li>
              <li class="st2-LocalNav_SubItem"><a href="#">PR支援・企業サポート</a></li>
            </ul>
          </li>
          <li class="st2-LocalNav_Item">
            <a href="#">よくあるご質問</a>
          </li>
          <li class="st2-LocalNav_Item">
            <a href="#">お問い合わせ</a>
          </li>
        </ul>
        <ul class="st2-LocalNav_OtherItems">
          <li class="st2-LocalNav_OtherItem"><a href="#">レンタルラボはこちら</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
</main>
```
*/

.sw2-LocalNavi-current-01 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(1) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
  &.-is-mainMv {
    .st2-ContentHeaderA_Heading {
      @include mq-up(md) {
        margin-bottom: 0;
      }
      @include mq-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}
.sw2-LocalNavi-current-02 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(2) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-03 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(3) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-04 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(4) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-05 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(5) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-06 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(6) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-07 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(7) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-08 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(8) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-09 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(9) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}
.sw2-LocalNavi-current-10 {
  .sw2-LocalNavi {
    .st2-LocalNav_Item:nth-child(10) {
      a {
        @include mq-up(lg) {
          opacity: 0.5;
        }
      }
    }
  }
}