@mixin sw-FormCheckbox() {
  /* デフォルトのチェックボックスを非表示にする。 */
  @include sr-only();

  & + label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    line-height: 1.2;
    cursor: pointer;
  }

  & + label:before {
  $size: 20px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: $size;
  height: $size;
  margin: auto;
  border: 2px solid #d6d6d6;
  border-radius: $form-border-radius;
  vertical-align: text-bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

  &:focus + label:before {
    border-color: $form-focus-border-color;
    box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) + label:before {
    border-color: $form-boder-color;
    box-shadow: none;
  }

  &:checked + label:before {
    border: 0;
    background-image: url("/assets/img/sitewide/Icon/FormCheckbox_on.svg");
  }

  &:disabled,
  & + label[disabled] {
    cursor: $form-disabled-cursor;
    opacity: $form-disabled-opacity;
  }
}
