/*
---
name: sw-Label
namespace: SiteWide
category: [Text, Link, Form]
id: LB
---

<span class="sw-Label">LB</span>

インラインで表示するラベルコンポーネントです。

```html
<div class="st-Wrapper">
<span class="sw-Label">spanタグのラベルです。</span>
<label class="sw-Label">labelタグのラベルです。</label>
<a href="#" class="sw-Label">aタグのラベルです。</a>
</div>
```
*/
.sw-Label {
  @include sw-Label();

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}
