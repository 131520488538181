/*
---
name: st-FooterRegistration
namespace: Structure
category: Global
id: K1
---

メールやFacebookなどの登録を促すエリアです。

```html
<section class="st-FooterRegistration">
  <h3 class="st-FooterRegistration_Heading">KRPからのお知らせを受け取る</h3>
  <p class="st-FooterRegistration_Description">KRPで開催するイノベーション創出と交流のための情報や、<br>
  コラボレーション事例などをお届けしています。</p>
  <div class="st-FooterRegistration_Inner">
    <ul class="st-FooterRegistration_Items">
      <li class="st-FooterRegistration_Item">
        <a class="st-FooterRegistration_Link st-FooterRegistration_Linkpc" href="/pub/maga.html">
          <span class="st-FooterRegistration_MailIcon" aria-hidden="true"></span><span class="st-FooterRegistration_Mail">＼詳しく知る／</span><br>メール会員登録
        </a>
        <a class="st-FooterRegistration_Link st-FooterRegistration_Linksp" href="/pub/maga.html">
          <span class="st-FooterRegistration_MailIcon" aria-hidden="true"></span>詳しく知る／メール会員登録
        </a>
      </li>
      <li class="st-FooterRegistration_Item">
        <a class="st-FooterRegistration_Link" href="https://www.facebook.com/krp.corp/" target="_blank">
          <span class="st-FooterRegistration_FacebookIcon" aria-hidden="true"></span>Facebook
        </a>
      </li>
      <li class="st-FooterRegistration_Item">
        <a class="st-FooterRegistration_Link" href="https://twitter.com/krp_innovation" target="_blank">
          <span class="st-FooterRegistration_TwitterIcon" aria-hidden="true"></span>X
        </a>
      </li>
    </ul>
  </div>
</section>
```
*/
.st-FooterRegistration {
  padding: 30px 0 32px;
  border-top: 3.5px solid $color-brand;
  color: #fff;
  background-color: #2b2b2b;
  background-image: url("/assets/img/structure/bg_footer-registration.png");
  background-repeat: repeat;
  background-size: auto;
  text-align: center;

  @include mq-up(lg) {
    padding: 80px 0;
    border-top-width: 7px;
  }
}

.st-FooterRegistration_Heading {
  font-size: rem(17);
  font-weight: bold;
  line-height: 1.2;

  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: 1.4;
  }
}

.st-FooterRegistration_Description {
  margin-top: 24px;
  font-size: rem(12);
  line-height: 1.5;

  @include mq-up(lg) {
    margin-top: 26px;
    font-size: rem(22, lg);
    line-height: 1.6;
  }
}

.st-FooterRegistration_Inner {
  max-width: $max-width;
  margin: 30px auto 0;

  @include mq-up(lg) {
    margin-top: 62px;
  }
}

.st-FooterRegistration_Items {
  padding: 0 45px 0;
  list-style-type: none;

  @include mq-up(lg) {
    margin-left: -34px;
    padding: 0 0 0;
    font-size: 0;
  }
}

.st-FooterRegistration_Item {
  font-size: rem(12);

  & + & {
    margin-top: 18px;

    @include mq-up(lg) {
      margin-top: 0;
      font-size: rem(24, lg);
    }
  }
  
  .st-FooterRegistration_Linkpc{
    display: none;
   }
   .st-FooterRegistration_Linksp{
    display: block;
   }

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    padding-left: 34px;
    font-size: rem(24, lg);
    .st-FooterRegistration_Linkpc{
      font-size: rem(20, lg);
      display: block;
      padding: 17px 55px 17px 100px;
     }
    .st-FooterRegistration_Linksp{
      display: none;
     }
  }
}

.st-FooterRegistration_Link {
  position: relative;
  display: block;
  padding: 14px 34px;
  line-height: 1.5;
  font-weight: normal;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    color: #2b2b2b;
    background-color: #fff;
  }

  @include mq-up(lg) {
    padding: 29px 65px 29px 95px;
    line-height: 1.4;
  }
}

.st-FooterRegistration_MailIcon {
  font-size: 1.6em;
  height: 1em;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;

  &:before {
    @include Icon("icon_mail");
    position: relative;
    top: -0.35em;
  }

  @include mq-up(lg) {
    left: 28px;
    height: 52px;
    font-size: 2.5em;
  }
}

.st-FooterRegistration_Mail {
  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.st-FooterRegistration_FacebookIcon {
  font-size: 1.5em;
  height: 1em;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;

  &:before {
    @include Icon("icon_facebook");
    position: relative;
    top: -0.3em;
  }

  @include mq-up(lg) {
    left: 28px;
    height: 52px;
    font-size: 2.0em;
  }
}

.st-FooterRegistration_TwitterIcon {
  font-size: 1.5em;
  height: 1em;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;

  &:before {
    @include Icon("icon_twitter");
    position: relative;
    top: -0.3em;
  }

  @include mq-up(lg) {
    left: 28px;
    height: 52px;
    font-size: 2.0em;
  }
}
