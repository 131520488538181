/*
---
name: st-LocalTitleA
namespace: Structure
category: Heading
id: [K1]
---

<span class="sw-Label"></span>

- PC未満では`<br>`は適応されません。

```html
<div class="st-Wrapper">
<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_sangaku.png" alt="">
  </span>
  再生医療サポートプラットフォーム
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_tsa.png" alt="">
  </span>
  Technology Startup Accelerator
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_ctable.png" alt="">
  </span>
  クリエイティブ・テーブル
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_hvckyoto.png" alt="">
  </span>
  HVC KYOTO
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_e2plat.png" alt="">
  </span>
  E2<span class="st-LocalTitleA_Small">（エレクトロニクス＆エネルギー）</span>プラットフォーム
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_onecoin.png" alt="">
  </span>
  ワンコイン・ミキサー
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  <span class="st-LocalTitleA_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_dschool.png" alt="">
  </span>
  京都大学デザインスクール
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleA">
  スタートアップ・ゼミナール
  <span class="st-LocalTitleA_Icon" aria-hidden="true"></span>
</p>

</div>
```
*/
.st-LocalTitleA {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 14px 40px 14px 20px;
  border-bottom: 1px solid #cdcdcd;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  color: $color-brand;

  .st-ContentHeaderA &,
  .st-ContentHeaderE & {
    font-weight: 500;
  }

  & br {
    display: none;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  .st-ContentHeaderA_Block-fixed &,
  .st-ContentHeaderB_Block-fixed &,
  .st-ContentHeaderE_Block-fixed & {
    padding-left: 20px;
  }

  @include mq-up(lg) {
    margin: 0;
    padding: 0;
    border-bottom: 0;
    font-size: rem(36, lg);
    line-height: 1.4;
    text-align: center;

    & br {
      display: inline;
    }

    .st-ContentHeaderA &,
    .st-ContentHeaderE & {
      color: #fff;
    }

    .st-ContentHeaderA_Block-fixed &,
    .st-ContentHeaderB_Block-fixed &,
    .st-ContentHeaderE_Block-fixed & {
      font-size: rem(14, lg);
      color: $color-text;
    }
  }
}

.st-LocalTitleA_Small {
  display: none;

  @include mq-up(lg) {
    margin-top: 7px;
    display: block;
    font-size: rem(14, lg);
    line-height: 1.4;
    text-transform: uppercase;

    .st-ContentHeaderA_Block-fixed &,
    .st-ContentHeaderB_Block-fixed &,
    .st-ContentHeaderE_Block-fixed & {
      display: none;
    }
  }
}

.st-LocalTitleA_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  color: $color-brand;
  transition-duration: 0.3s;
  transition-property: transform;

  [aria-expanded="true"] & {
    transform: rotate(-180deg);
  }

  .st-ContentHeaderA_Block-fixed &,
  .st-ContentHeaderB_Block-fixed &,
  .st-ContentHeaderE_Block-fixed & {
    right: 20px;
  }

  @include mq-up(lg) {
    display: none;
  }
}
