.st-HeaderToggleMenu {
  position: relative;
  display: inline-block;
  width: 100%;
  height: (div(107, 2)) * 1px;
  margin: 0;
  padding: 0;
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 1em;
  font-weight: normal;
  line-height: 1;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  background: transparent;
  background-color: $color-krp;
  appearance: none;
  cursor: pointer;

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: $color-krp;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: 2px;
    left: 0;
    display: block;
    margin: auto;
    content: "Menu";
    font-size: rem(8);
    text-transform: uppercase;
  }

  &[aria-expanded="true"] {
    color: #fff;
    background-color: #000;
    
    &:after {
      content: "Close";
    }
  }
}

.st-HeaderToggleMenu_Icon {
  position: absolute;
  top: 8.5px;
  right: 0;
  left: 0;
  margin: auto;
  font-size: 1.7em;
  line-height: 1;

  .st-HeaderToggleMenu[aria-expanded="true"] & {
    font-size: 1.6em;
  }

  &:before {
    @include Icon("icon_menu");

    .st-HeaderToggleMenu[aria-expanded="true"] & {
      @include Icon("icon_close");
    }
  }
}
