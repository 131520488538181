.sw-FormLead {
  font-family: $font-family-heading;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.6;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    font-size: rem(20, lg);
    text-align: center;

    & br {
      display: inline;
    }
  }
}
