/*
---
name: news-ListA
namespace: news
category: Navigation
id: [L1, NL-1]
---

<span class="sw-Label">NL-1</span>

ニュース一覧です。カテゴリーのラベルがつきます。

- ニュース系で「サムネイルなし」の表示
  - TSA
  - HVCで利用

```html
<div class="st-Wrapper">
<ol class="news-ListA">
  <li class="news-ListA_Item">
    <a href="#" class="news-ListA_Link">
      <div class="news-ListA_DateItem">
        <span class="news-ListA_Date">2017/12/31</span>
      </div>
      <div class="news-ListA_LabelItem">
        <span class="news-ListA_Label">ニュース</span>
      </div>
      <div class="news-ListA_TextItem">
        <span class="news-ListA_Text">ものレボ株式会社様が京都銀行（京都未来ファンド）を引受先とする第三者割当増資を実施されました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListA_Item">
    <a href="#" class="news-ListA_Link">
      <div class="news-ListA_DateItem">
        <span class="news-ListA_Date">2017/10/01</span>
      </div>
      <div class="news-ListA_LabelItem">
        <span class="news-ListA_Label">プレスリリース</span>
      </div>
      <div class="news-ListA_TextItem">
        <span class="news-ListA_Text">株式会社京都マイクロシステムズ様＜ASTEM棟＞が読売テレビ「関西情報ネット ten.」にて紹介されました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListA_Item">
    <a href="#" class="news-ListA_Link">
      <div class="news-ListA_DateItem">
        <span class="news-ListA_Date">2017/01/10</span>
      </div>
      <div class="news-ListA_LabelItem">
        <span class="news-ListA_Label">メディア掲載</span>
      </div>
      <div class="news-ListA_TextItem">
        <span class="news-ListA_Text">ものレボ株式会社様が京都府のホームページに掲載されました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListA_Item">
    <a href="#" class="news-ListA_Link">
      <div class="news-ListA_DateItem">
        <span class="news-ListA_Date">2017/01/01</span>
      </div>
      <div class="news-ListA_LabelItem">
        <span class="news-ListA_Label">ご入居企業さま</span>
      </div>
      <div class="news-ListA_TextItem">
        <span class="news-ListA_Text">ものレボ株式会社様が京都ビジネス交流フェアに出展されました。<br>
        ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</span>
      </div>
    </a>
  </li>
</ol>
</div>
```
*/
.news-ListA {
  padding-left: 0;
  list-style-type: none;
}

.news-ListA_Item {
  &:before,
  &:last-of-type:after {
    content: "";
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    display: block;
    height: 1px;
    border-top: 1px solid #d2d2d2;

    @include mq-up(lg) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.news-ListA_Link {
  display: block;
  width: 100%;
  padding: 10px 0;
  font-size: rem(14);
  line-height: 1.6;
  text-decoration: none;
  color: $color-text;

  &:hover {
    text-decoration: none;
  }

  @include mq-up(lg) {
    display: table;
    padding: 14px 0;
    font-size: rem(13, lg);
  }
}

.news-ListA_DateItem {
  float: left;
  width: auto;
  padding: 0 12px 0 0;

  @include mq-up(lg) {
    float: none;
    display: table-cell;
    vertical-align: middle;
    width: 112px;
    padding: 0 12px 0 10px;
  }
}

.news-ListA_Date {

}

.news-ListA_LabelItem {
  float: left;
  width: calc(100% - 112px);
  width: auto;

  @include mq-up(lg) {
    float: none;
    display: table-cell;
    vertical-align: middle;
    width: 108px;
  }
}

.news-ListA_Label {
  display: inline-block;
  width: 100%;
  padding: 2px 10px;
  line-height: 1;
  border: 1px solid $color-brand;
  color: $color-brand;
  text-align: center;

  @include mq-up(lg) {
    padding: 4px 0;
  }
}

.news-ListA_TextItem {
  display: block;
  width: 100%;
  padding-top: 10px;
  overflow: hidden;

  @include mq-up(lg) {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    padding-top: 0;
    padding-left: 26px;
  }
}

.news-ListA_Text {
  .news-ListA_Link:hover & {
    color: #c84747;
  }
}
