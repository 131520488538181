/*
---
name: sw2-MvSlider
namespace: SiteWide2
category: InteractiveComponent
---

メインビジュアル用のスライダーです。

- 「[jQuery slick](https://kenwheeler.github.io/slick/)」を使用しています。

```html
<div class="sw2-MvSlider">
  <div class="sw2-MvSlider_In">
    <div>
      <picture>
        <source srcset="/assets/img/rentaloffice/index/slider_01.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/rentaloffice/index/slider_01_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
    <div>
      <picture>
        <source srcset="/assets/img/rentaloffice/index/slider_02.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/rentaloffice/index/slider_02_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
    <div>
      <picture>
        <source srcset="/assets/img/rentaloffice/index/slider_03.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/rentaloffice/index/slider_03_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
    <div>
      <picture>
        <source srcset="/assets/img/rentaloffice/index/slider_04.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/rentaloffice/index/slider_04_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
    <div>
      <picture>
        <source srcset="/assets/img/rentaloffice/index/slider_05.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/rentaloffice/index/slider_05_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
  </div>
</div>
```
*/
.sw2-MvSlider {

  @keyframes zoomImg {
    0% {
    transform: scale(1);
    }
    100% {
    transform: scale(1.1);
    }
  }

  // slick CORE
  .slick-slider { position: relative; display: block; box-sizing: border-box; user-select: none; touch-action: pan-y; -webkit-touch-callout: none; -khtml-user-select: none; -webkit-tap-highlight-color: transparent;}
  .slick-list { position: relative; display: block; overflow: hidden; margin: 0; padding: 0;}
  .slick-list:focus {outline: none;}
  .slick-list.dragging {cursor: pointer;cursor: hand;}
  .slick-slider .slick-track,.slick-slider .slick-list {transform: translate3d(0, 0, 0);}
  .slick-track {position: relative;top: 0;left: 0;display: block;margin-left: auto;margin-right: auto;}
  .slick-track:before,.slick-track:after {display: table;content: '';}
  .slick-track:after {clear: both;}
  .slick-loading .slick-track {visibility: hidden;}
  .slick-slide {display: none;float: left;height: 100%;min-height: 1px;}
  [dir='rtl'] .slick-slide {float: right;}
  .slick-slide img {display: block;}
  .slick-slide.slick-loading img {display: none;}
  .slick-slide.dragging img {pointer-events: none;}
  .slick-initialized .slick-slide {display: block;}
  .slick-loading .slick-slide {visibility: hidden;}
  .slick-vertical .slick-slide {display: block;height: auto;border: 1px solid transparent;}
  .slick-arrow.slick-hidden {display: none;}
  // -----------------------------------------------
}

.sw2-MvSlider_In {
  counter-reset: slick-number 0;
  position: relative;

  img {
    width: 100%;

    @include mq-up(md) {
      height: rem(494, md);
      object-fit: cover;
    }
    @include mq-up(lg) {
      height: rem(494, lg);
    }
  }
  .slick-slide {
    &.slick-moving {
      animation: zoomImg 11s;
    }
  }

  .slick-nav {
    position: absolute;
    z-index: 5;
    top: 50%;
    right: rem(20);
    transform: translate(0, -50%);
    @include mq-up(md) {
      bottom: auto;
      top: 50%;
      right: rem(40, lg);
    }
  }

  .slick-dots {
    margin: rem(0);
    padding: rem(0) rem(0) rem(21, lg);
    position: relative;
    li {
      width: rem(12, lg);
      height: rem(1, lg);
      list-style: none;
      background-color: #fff;
      opacity: 0.2;
      margin: rem(6, lg) rem(0) rem(0);
      padding: rem(0);
      &.slick-active {
        opacity: 1;
        button {
          display: block;
        }
      }
      &:first-child {
        margin: rem(0);
      }
      button {
        display: none;
        border: none;
        margin: rem(0);
        padding: rem(0);
        background: none;
        font-size: rem(13 ,lg);
        line-height: rem(13 ,lg);
        font-family: $font-family-shippori-mincho;
        font-weight: 400;
        position: absolute;
        bottom: rem(0);
        left: rem(0);
        color: #fff;
        &:before {
          content: "0";
        }
      }
    }
  }
}