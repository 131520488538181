/*
---
name: sw2-CatchImageList
namespace: SiteWide2
category: InteractiveComponent
---

トップで使用するコンテンツキャッチ用です。
`st2-TitleBox`と一緒に使用します。

```html
<div class="st-Block">
  <div class="st2-TitleBox">
    <div class="st2-TitleBox_Sub">
      <div class="st2-TitleBox_SubHeading">FEATURES</div>
      <div class="st2-TitleBox_Heading">KRPの特長</div>
    </div>
    <div class="st2-TitleBox_Main">
      <div class="sw2-CatchImageList">
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch01">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_01.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_01_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">集</h2>
                <div class="sw2-CatchImageList_Data_Text">様々なプレイヤーが集まるため<br>の上質で充実したビジネス空間</div>
              </div>
            </div>
          </a>
        </div>
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch02">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_02.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_02_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">交</h2>
                <div class="sw2-CatchImageList_Data_Text">プレイヤー同士の新結合に<br>必要な様々な交流機会</div>
              </div>
            </div>
          </a>
        </div>
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch03">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_03.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_03_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">創</h2>
                <div class="sw2-CatchImageList_Data_Text">創発を生み出す<br class="-is-lg">多様で 魅力的なプレイヤー</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-CatchImageList {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-30, lg);
  }
}
.sw2-CatchImageList_Item {
  margin-top: rem(8);
  &:first-child {
    margin-top: rem(0);
  }
  @include mq-up(lg) {
    margin-top: rem(0);
    padding-left: rem(30, lg);
  }
  a {
    display: block;
    text-decoration: none;
    color: #fff;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;

    &:hover {
      @include mq-up(lg) {
        opacity: 0.7;
      }
    }
  }
}
.sw2-CatchImageList_ItemIn {
  position: relative;
}
.sw2-CatchImageList_Data {
  position: absolute;
  left: rem(0);
  bottom: rem(0);
  padding: rem(20);
  @include mq-up(lg) {
    padding: rem(20, lg);
  }
}
.sw2-CatchImageList_Image {
  img {
    width: 100%;
  }
}
.sw2-CatchImageList_Data_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  font-size: rem(80);
  line-height: rem(80);
  @include mq-up(lg) {
    font-size: rem(80, lg);
    line-height: rem(80, lg);
  }
}
.sw2-CatchImageList_Data_Text {
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(26);
  margin-top: rem(16);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(26, lg);
    margin-top: rem(16, lg);
  }
  & > br {
    @include mq-up(lg) {
      display: none;
    }
    &.-is-lg {
      display: initial;
      @media print, screen and (min-width: $max-width) {
        display: none;
      }
    }
  }
}
