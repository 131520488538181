/*
---
name: news-ListB
namespace: news
category: Navigation
id: [L1, NL-2]
---

<span class="sw-Label">NL-2</span>

ニュース一覧です。カテゴリーのラベルがつきません。

- ニュース系で「サムネイルなし」の表示
  - 入居企業＞入居企業のNEWS

```html
<div class="st-Wrapper">
<ol class="news-ListB">
  <li class="news-ListB_Item">
    <a href="#" class="news-ListB_Link">
      <div class="news-ListB_DateItem">
        <span class="news-ListB_Date">2017/12/31</span>
      </div>
      <div class="news-ListB_TextItem">
        <span class="news-ListB_Text">ものレボ株式会社様が京都銀行（京都未来ファンド）を引受先とする第三者割当増資を実施さBました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListB_Item">
    <a href="#" class="news-ListB_Link">
      <div class="news-ListB_DateItem">
        <span class="news-ListB_Date">2017/10/01</span>
      </div>
      <div class="news-ListB_TextItem">
        <span class="news-ListB_Text">株式会社京都マイクロシステムズ様＜ASTEM棟＞が読売テレビ「関西情報ネット ten.」にて紹介さBました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListB_Item">
    <a href="#" class="news-ListB_Link">
      <div class="news-ListB_DateItem">
        <span class="news-ListB_Date">2017/01/10</span>
      </div>
      <div class="news-ListB_TextItem">
        <span class="news-ListB_Text">ものレボ株式会社様が京都府のホームページに掲載さBました。</span>
      </div>
    </a>
  </li>
  <li class="news-ListB_Item">
    <a href="#" class="news-ListB_Link">
      <div class="news-ListB_DateItem">
        <span class="news-ListB_Date">2017/01/01</span>
      </div>
      <div class="news-ListB_TextItem">
        <span class="news-ListB_Text">ものレボ株式会社様が京都ビジネス交流フェアにB展されました。<br>
        ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</span>
      </div>
    </a>
  </li>
</ol>
</div>
```
*/
.news-ListB {
  padding-left: 0;
  list-style-type: none;
}

.news-ListB_Item {
  &:before,
  &:last-of-type:after {
    content: "";
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    display: block;
    height: 1px;
    border-top: 1px solid #d2d2d2;

    @include mq-up(lg) {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.news-ListB_Link {
  display: table;
  width: 100%;
  padding: 10px 0;
  font-size: rem(14);
  line-height: 1.6;
  text-decoration: none;
  color: $color-text;

  &:hover {
    text-decoration: none;
  }

  @include mq-up(lg) {
    padding: 14px 0;
    font-size: rem(13, lg);
  }
}

.news-ListB_DateItem {
  display: block;
  width: 100%;

  @include mq-up(lg) {
    display: table-cell;
    vertical-align: middle;
    width: 112px;
    padding: 0 24px 0 20px;
  }
}

.news-ListB_TextItem {
  display: block;
  width: 100%;
  padding-top: 10px;
  overflow: hidden;

  @include mq-up(lg) {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    padding-left: 26px;
  }
}

.news-ListB_Text {
  .news-ListB_Link:hover & {
    color: #c84747;
  }
}
