.st-HeaderSocial {
  padding: 20px;

  @include mq-up(lg) {
    display: none;
  }
}

.st-HeaderSocial_Items {
  margin-left: -10px;
  padding-left: 0;
  list-style-type: none;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.st-HeaderSocial_Item {
  float: left;
  width: 50%;
  padding-left: 10px;
}

.st-HeaderSocial_Item:nth-child(3) {
  margin-top: 10px;
}

.st-HeaderSocial_Link {
  position: relative;
  display: block;
  height: 42px;
  line-height: 42px;
  padding-left: 33px;
  font-size: rem(13);
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
}

.st-HeaderSocial_RightIcon {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 14px;
  height: 14px;
  margin: auto;
  line-height: 1;

  &:before {
    @include Icon("icon_arrow-right");
  }
}

.st-HeaderSocial_Mail {
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 0;
  width: 42px;
  height: 42px;
  margin: auto;

  &:before {
    @include Icon("icon_mail");
  }
}

.st-HeaderSocial_Facebook {
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 0;
  width: 42px;
  height: 42px;
  margin: auto;
  
  &:before {
    @include Icon("icon_facebook");
  }
}

.st-HeaderSocial_Twitter {
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 0;
  width: 42px;
  height: 42px;
  margin: auto;
  
  &:before {
    @include Icon("icon_twitter");
  }
}
