/*
---
name: st2-TitleBox
namespace: Structure2
category: Layout
---

タイトルが左にあるコンテンツです。

`sw2-CatchImageList`等のコンテンツと使用します。

```html
<div class="st-Block">
  <div class="st2-TitleBox">
    <div class="st2-TitleBox_Sub">
      <div class="st2-TitleBox_Heading">KRPの特長</div>
      <div class="st2-TitleBox_Button"><a href="#" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a></div>
    </div>
    <div class="st2-TitleBox_Main">
      <div class="sw2-CatchImageList">
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch01">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_01.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_01_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">集</h2>
                <div class="sw2-CatchImageList_Data_Text">様々なプレイヤーが集まるため<br>の上質で充実したビジネス空間</div>
              </div>
            </div>
          </a>
        </div>
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch02">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_02.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_02_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">交</h2>
                <div class="sw2-CatchImageList_Data_Text">プレイヤー同士の新結合に<br>必要な様々な交流機会</div>
              </div>
            </div>
          </a>
        </div>
        <div class="sw2-CatchImageList_Item">
          <a href="/about/#catch03">
            <div class="sw2-CatchImageList_ItemIn">
              <div class="sw2-CatchImageList_Image">
                <picture>
                  <source srcset="/assets/img/index/catch_03.jpg" media="(min-width: 980px)"/>
                  <img src="/assets/img/index/catch_03_sp.jpg" alt="" loading="lazy">
                </picture>
              </div>
              <div class="sw2-CatchImageList_Data">
                <h2 class="sw2-CatchImageList_Data_Heading">創</h2>
                <div class="sw2-CatchImageList_Data_Text">創発を生み出す<br class="-is-lg">多様で 魅力的なプレイヤー</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.st2-TitleBox {
  position: relative;
  padding-bottom: rem(75);
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: rem(0) !important;
  }
  &.-is-show-btn {
    padding-bottom: rem(0);
  }
  &.-is-innovation {
    .st2-TitleBox_Main {
      background-color: #fff;
      @include mq-up(lg) {
        width: rem(778, lg);
        align-self: stretch;
      }
      @media print, screen and (min-width: $max-width) {
        width: rem(778, lg);
      }
    }
    .st2-TitleBox_Sub {
      @include mq-up(lg) {
        width: rem(306, lg);
      }
    }
    .st2-TitleBox_Button {
      @include mq-up(lg) {
        padding-left: 0;
      }
      & > a {
        @include mq-up(lg) {
          width: rem(190, lg);
        }
      }
    }
    .sw-VisualNavC_Item {
      @include mq-up(lg) {
        width: 50%;
      }
    }
  }
}
.st2-TitleBox_SubHeading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(16);
  color: #9CA7AC;
  position: relative;
  padding-left: rem(18);
  margin-bottom: rem(24);
  &::before {
    content: "";
    display: block;
    width: rem(6);
    height: rem(6);
    border-radius: 100%;
    background-color: #9CA7AC;
    position: absolute;
    top: rem(6);
    left: 0;
  }
}
.st2-TitleBox_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(28);
  line-height: rem(39);
  position: relative;
  padding-left: rem(24);
  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(45, lg);
    padding-left: rem(30, lg);
  }
  &::before {
    content: "";
    display: block;
    width: rem(8);
    height: rem(8);
    border-radius: 100%;
    background-color: $color-krp;
    position: absolute;
    top: rem(17);
    left: 0;
    @include mq-up(lg) {
      width: rem(10, lg);
      height: rem(10, lg);
    }
  }
  & > br {
    display: none;
    @include mq-up(lg) {
      display: initial;
    }
  }
}
.st2-TitleBox_Button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  @include mq-up(lg) {
    position: static;
    margin-top: rem(40, lg);
    padding-left: rem(30, lg);
    text-align: left;
    display: block !important;
  }
  &.-is-solo {
    @include mq-up(lg) {
      padding-left: rem(0, lg);
      width: 190px;
    }
  }
}
.st2-TitleBox_Sub {
  @include mq-up(lg) {
    flex-shrink: 0;
  }
}
.st2-TitleBox_Main {
  margin-top: rem(25);
  @include mq-up(lg) {
    width: rem(700, lg);
    flex-shrink: 0;
    margin-top: rem(0);
  }
  @media print, screen and (min-width: $max-width) {
    width: rem(870, lg);
  }
}

/* ニュース部分の特別処理 */
.st2-TitleBox_Main {
  .sw-CardNavA_Body {
    @include mq-up(lg) {
      margin-top: rem(12, lg);
    }
  }
  .sw-CardNavA_Text {
    @include mq-up(lg) {
      font-size: rem(13, lg);
      line-height: rem(23, lg);
    }
  }
  .sw-CardNavA_Icon::before {
    @include mq-up(lg) {
      top: rem(5, lg);
      font-size: rem(17, lg);
    }
  }
}
.st2-TitleBox {
  &.-is-show-btn {
    .st-Grids_Item {
      &:nth-child(n + 4) {
        display: none;
        @include mq-up(lg) {
          display: inline-block;
        }
      }
    }
  }
}
.st2-TitleBox_ShowBtn {
  margin-top: rem(45);
  background: #FFFFFF;
  border: 1px solid $color-krp;
  padding: rem(11);
  text-align: center;
  font-size: rem(12);
  line-height: rem(12);
  color: $color-krp;

  span {
    font-size: rem(12);
    line-height: rem(12);
    &::after {
      @include Icon("icon_open-plus");
      font-size: rem(10);
      vertical-align: middle;
      margin-left: rem(8);
      margin-top: rem(-3);
    }
  }

  @include mq-up(lg) {
    display: none;
  }
}