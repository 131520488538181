.companySearchResult {
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #aaaaaa;
  @include mq-up(lg) {
    padding-top: 50px;
    padding-bottom: 0;
    border: none;
  }
}

.companySearchResult-wrapper {
  @include mq-up(lg) {
    display: flex;
  }
}

.companySearchResult-head {
  margin-bottom: 10px;
  @include mq-up(lg) {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.companySearchResult-title {
  padding: 5px 10px;
  width: 6em;
  text-align: center;
  color: #FFF;
  background-color: #9B9B9B;
  font-size: rem(10);
  @include mq-up(lg) {
    font-size: rem(16);
    width: 100px;
  }
}

.companySearchResult-list {
  padding: 0;
  list-style: none;
  @include mq-up(lg) {
  }
  & > li {
    margin-right: 10px;
    display: inline-block;
    font-size: rem(12);
    font-weight: bold;
    @include mq-up(lg) {
      font-size: rem(16);
    }
    &:before {
      display: inline-block;
      content: "“ ";
    }
    &:after {
      display: inline-block;
      content: " ”";
    }
  }
}


