.st-ContentHeaderH {/*220927 add*/
  background-color:#f8f8f8;
  width:100%;
  height:auto;
}
.movie-title {
  width:100%;
  height:auto;
  margin: 0 auto;
}
.movie-box,
.movie-img {
  width:100%;
  height:auto;
  z-index: 1;
}
.movie-img img{
  width:100%;
  height:auto;
}
.movie-box {
  background: url(/assets/img/hvckyoto/image_ttl_bk.png) no-repeat left top;
  background-size: 100% auto;
}
.movie-box video{
  display: block;
  padding: 5.75vw 5.75vw;
  width:100%;
  height:auto;
}
@media (min-width:980px) and (max-width:1169px) { 
  .st-ContentHeaderH {
    background-color:#f8f8f8;
    width:100%;
    height:auto;
    margin-top: 20px;
  }
  .movie-title {
    display: flex;
    flex-wrap: wrap;
    width:100%;
    max-width: 1170px;
    height: auto;
    margin: 0 auto;
  }
  .movie-box,
  .movie-img {
    width:50%;
    height:auto;
    z-index: 1;
  }
  .movie-box {
    background: url(/assets/img/hvckyoto/image_ttl_bk.png) no-repeat left top;
    background-size: 50% auto;
  }
  .movie-box video{
    display: block;
    padding: 5.75vw 5.75vw;
    width:50%;
    height:auto;
  }
}
@media print,screen and (min-width:1170px){
  .st-ContentHeaderH {
    background-color:#f8f8f8;
    width:100%;
    height:365px;
    margin-top: 20px;
  }
  .movie-title {
    display: flex;
    flex-wrap: wrap;
    width: 1170px;
    height: 365px;
    margin: 0 auto;
  }
  .movie-box,
  .movie-img {
    width: 585px;
    height: 365px;
    z-index: 1;
  }
  .movie-box {
    background: url(/assets/img/hvckyoto/image_ttl_bk.png) no-repeat left top;
    background-size: 585px 365px;
  }
  .movie-box video{
    display: block;
    padding: 40px 40px;
    width: 585px;
    height: 365px;
  }
}
