/*
---
name: sw-LinkMap
namespace: SiteWide
category: Link
id: [K2, IMG-6]
---

<span class="sw-Label">IMG-6</span>

レスポンシブに対応したクリッカブルマップです。

- 「[jQuery RWD Image Maps](https://github.com/stowball/jQuery-rwdImageMaps)」を使用しています。
- 「[HTML Imagemap Generator](https://labs.d-s-b.jp/ImagemapGenerator/)」などを使ってクリッカブルマップを作成してください。
- 一番大きい画像の`width`と`height`を指定してください。
- PC以上でリンクが有効になります。

```html
<div class="st-Wrapper">
<picture class="sw-LinkMap">
  <!--[if IE 9]><video style="display: none;"><![endif]-->
  <source media="(min-width: 980px)" srcset="/assets/img/index/map01_pc.png">
  <!--[if IE 9]></video><![endif]-->
  <img src="/assets/img/index/map01_sp.png" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="1600" height="748">
</picture>
<map name="ImageMap" class="sw-LinkMap_Link">
  <area shape="rect" coords="254,175,485,202" href="#" alt="" />
  <area shape="rect" coords="253,212,484,237" href="#" alt="" />
  <area shape="rect" coords="253,249,483,273" href="#" alt="" />
  <area shape="rect" coords="254,284,483,307" href="#" alt="" />
  <area shape="rect" coords="193,572,439,596" href="#" alt="" />
  <area shape="rect" coords="558,628,844,651" href="#" alt="" />
  <area shape="rect" coords="557,662,846,688" href="#" alt="" />
  <area shape="rect" coords="1042,232,1290,260" href="#" alt="" />
</map>
</div>
```

横幅をコンテンツ幅に制限します。

```html
<div class="st-Wrapper">
  <div class="st-Wrapper_FullWrap">
    <picture class="sw-LinkMap">
      <!--[if IE 9]><video style="display: none;"><![endif]-->
      <source media="(min-width: 980px)" srcset="/assets/img/index/map01_pc.png">
      <!--[if IE 9]></video><![endif]-->
      <img src="/assets/img/index/map01_sp.png" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="1600" height="748">
    </picture>
    <map name="ImageMap" class="sw-LinkMap_Link">
      <area shape="rect" coords="254,175,485,202" href="#" alt="" />
      <area shape="rect" coords="253,212,484,237" href="#" alt="" />
      <area shape="rect" coords="253,249,483,273" href="#" alt="" />
      <area shape="rect" coords="254,284,483,307" href="#" alt="" />
      <area shape="rect" coords="193,572,439,596" href="#" alt="" />
      <area shape="rect" coords="558,628,844,651" href="#" alt="" />
      <area shape="rect" coords="557,662,846,688" href="#" alt="" />
      <area shape="rect" coords="1042,232,1290,260" href="#" alt="" />
    </map>
  </div>
</div>
```

2枚の画像をスマホで1カラム、PCで2カラムにした場合です。

```html
<div class="st-Wrapper">
  <div class="st-Wrapper_Full">
    <div class="st-Grids st-Grids-col2Md">
      <div class="st-Grids_Item">
        <picture class="sw-LinkMap">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="/assets/img/kaigi/room/map/image01.jpg">
          <!--[if IE 9]></video><![endif]-->
          <img src="/assets/img/kaigi/room/map/image01.jpg" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="720" height="1072">
        </picture>
        <map name="ImageMap" class="sw-LinkMap_Link">
          <area shape="rect" coords="220,151,321,231" href="/kaigi/room/detail/sciencehall.html" alt="サイエンスホール" />
          <area shape="rect" coords="215,236,311,258" href="/kaigi/room/detail/roomdef.html" alt="小会議室DEF" />
          <area shape="rect" coords="96,149,191,247" href="#" alt="" />
          <area shape="rect" coords="79,271,139,345" href="#" alt="" />
          <area shape="rect" coords="147,272,201,366" href="#" alt="" />
          <area shape="rect" coords="354,834,435,944" href="#" alt="" />
        </map>
      </div>
      <div class="st-Grids_Item">
        <picture class="sw-LinkMap">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="/assets/img/kaigi/room/map/image02.jpg">
          <!--[if IE 9]></video><![endif]-->
          <img src="/assets/img/kaigi/room/map/image02.jpg" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="720" height="1068">
        </picture>
        <map name="ImageMap" class="sw-LinkMap_Link">
          <area shape="rect" coords="220,151,321,231" href="/kaigi/room/detail/sciencehall.html" alt="サイエンスホール" />
          <area shape="rect" coords="215,236,311,258" href="/kaigi/room/detail/roomdef.html" alt="小会議室DEF" />
          <area shape="rect" coords="96,149,191,247" href="#" alt="" />
          <area shape="rect" coords="79,271,139,345" href="#" alt="" />
          <area shape="rect" coords="147,272,201,366" href="#" alt="" />
          <area shape="rect" coords="354,834,435,944" href="#" alt="" />
        </map>
      </div>
    </div>
  </div>
</div>
```

横幅をコンテンツ幅に制限します。

```html
<div class="st-Wrapper">
  <div class="st-Wrapper_FullWrap">
    <div class="st-Grids st-Grids-col2Md">
      <div class="st-Grids_Item">
        <picture class="sw-LinkMap">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="/assets/img/kaigi/room/map/image01.jpg">
          <!--[if IE 9]></video><![endif]-->
          <img src="/assets/img/kaigi/room/map/image01.jpg" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="720" height="1072">
        </picture>
        <map name="ImageMap" class="sw-LinkMap_Link">
          <area shape="rect" coords="220,151,321,231" href="/kaigi/room/detail/sciencehall.html" alt="サイエンスホール" />
          <area shape="rect" coords="215,236,311,258" href="/kaigi/room/detail/roomdef.html" alt="小会議室DEF" />
          <area shape="rect" coords="96,149,191,247" href="#" alt="" />
          <area shape="rect" coords="79,271,139,345" href="#" alt="" />
          <area shape="rect" coords="147,272,201,366" href="#" alt="" />
          <area shape="rect" coords="354,834,435,944" href="#" alt="" />
        </map>
      </div>
      <div class="st-Grids_Item">
        <picture class="sw-LinkMap">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="/assets/img/kaigi/room/map/image02.jpg">
          <!--[if IE 9]></video><![endif]-->
          <img src="/assets/img/kaigi/room/map/image02.jpg" usemap="#ImageMap" class="sw-LinkMap_Image" alt="" width="720" height="1068">
        </picture>
        <map name="ImageMap" class="sw-LinkMap_Link">
          <area shape="rect" coords="220,151,321,231" href="/kaigi/room/detail/sciencehall.html" alt="サイエンスホール" />
          <area shape="rect" coords="215,236,311,258" href="/kaigi/room/detail/roomdef.html" alt="小会議室DEF" />
          <area shape="rect" coords="96,149,191,247" href="#" alt="" />
          <area shape="rect" coords="79,271,139,345" href="#" alt="" />
          <area shape="rect" coords="147,272,201,366" href="#" alt="" />
          <area shape="rect" coords="354,834,435,944" href="#" alt="" />
        </map>
      </div>
    </div>
  </div>
</div>
```
*/
.sw-LinkMap {
  display: block;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);

  .st-Wrapper_Full &,
  .st-Wrapper_FullWrap & {
    margin-right: auto;
    margin-left: auto;
  }
}

.sw-LinkMap_Image {
  max-width: none;
  width: 100%;
}

.sw-LinkMap_Link {
  display: none;

  @include mq-up(lg) {
    display: block;
  }
}
