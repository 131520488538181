/*
---
name: sw2-Newstop
namespace: SiteWide2
category: Block
---

ニュース表示用のコンテンツです。主にトップで使用します。

```html
<div class="sw2-Newstop">
  <div class="sw2-Newstop_In">
    <div class="sw2-Newstop_Box">
      <div class="sw2-Newstop_Title">
        <h2 class="sw2-Newstop_Heading">NEWS ROOM</h2>
        <div class="sw2-Newstop_Button"><a href="#" class="sw-Button">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a></div>
      </div>
      <div class="sw2-Newstop_Data">
        <div class="sw2-Newstop_List">
          <dl>
            <dt>
              <div class="sw2-Newstop_DateBox">
                <div class="sw2-Newstop_Date">2022.08.31</div>
                <div class="sw2-Newstop_Category"><span>NEWS</span></div>
              </div>
            </dt>
            <dd>
              <a href="#">サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト</a>
            </dd>
          </dl>
          <dl>
            <dt>
              <div class="sw2-Newstop_DateBox">
                <div class="sw2-Newstop_Date">2022.08.31</div>
                <div class="sw2-Newstop_Category"><span>NEWS</span></div>
              </div>
            </dt>
            <dd>
              <a href="#">サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト</a>
            </dd>
          </dl>
          <dl>
            <dt>
              <div class="sw2-Newstop_DateBox">
                <div class="sw2-Newstop_Date">2022.08.31</div>
                <div class="sw2-Newstop_Category"><span>NEWS</span></div>
              </div>
            </dt>
            <dd>
              <a href="#">サンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキストサンプルテキスト</a>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-Newstop {
  padding: 0 rem(20);
  background-color: $color-krp;
}
.sw2-Newstop_In {
  position: relative;
  padding: rem(32) 0 rem(83);
  @include mq-up(lg) {
    padding: rem(60, lg) 0;
  }
  @media print, screen and (min-width: $max-width) {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }
}
.sw2-Newstop_Box {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.sw2-Newstop_Title {
  @include mq-up(lg) {
    width: rem(208, lg);
    flex-shrink: 0;
    align-self: stretch;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
}
.sw2-Newstop_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.05em;
  text-align: center;
  font-size: rem(22);
  line-height: rem(22);
  margin-bottom: rem(28);
  @include mq-up(lg) {
    text-align: left;
    font-size: rem(24, lg);
    line-height: rem(24, lg);
    margin-bottom: rem(40, lg);
  }
}
.sw2-Newstop_Button {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: rem(23);
  left: 0;
  @include mq-up(lg) {
    position: static;
    text-align: left;
  }
  a {
    color: #fff;
    font-weight: 400;
    font-size: rem(12);
    line-height: rem(12);
    width: rem(140);
    padding: rem(10) 0;
    border-radius: 0;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.2);
    @include mq-up(lg) {
      background: rgba(255, 255, 255, 0);
    }
    &:hover {
      @include mq-up(lg) {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
.sw2-Newstop_Data {
  @include mq-up(lg) {
    align-self: stretch;
    padding-left: rem(35, lg);
  }
}
.sw2-Newstop_List {
  & > dl {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: rem(20);

    &:first-child {
      margin-top: rem(0);
    }

    & > dt {
      width: rem(90);
      flex-shrink: 0;
      @include mq-up(lg) {
        width: rem(188, lg);
        margin-right: rem(30, lg);
      }
    }
    & > dd {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #fff;
      line-height: rem(21);
      @include mq-up(lg) {
        -webkit-line-clamp: 1;
        margin-top: rem(-4);
      }
      a {
        font-style: normal;
        color: #fff;
        text-decoration: underline;
        font-weight: 400;
        font-size: rem(13);
        line-height: rem(21);
        transition-duration: $transition-duration;
        transition-timing-function: $transition-timing-function;
        @include mq-up(lg) {
          font-size: rem(15, lg);
          line-height: rem(15, lg);
          text-decoration: none;
        }
        &:hover {
          @include mq-up(lg) {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.sw2-Newstop_DateBox {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.sw2-Newstop_Date {
  font-family: $font-family-lato;
  font-weight: 400;
  font-size: rem(12);
  line-height: rem(12);
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
  @include mq-up(lg) {
    font-size: rem(15, lg);
    line-height: rem(15, lg);
    flex-shrink: 0;
  }
}
.sw2-Newstop_Category {
  display: none;
  @include mq-up(lg) {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #333;
    letter-spacing: 0.05em;
    width: rem(80, lg);
    font-family: $font-family-shippori-mincho;
    font-weight: 500;
    font-size: rem(11, lg);
    line-height: rem(11, lg);
    padding: rem(6, lg) 0;
    margin-top: rem(-3, lg);
    flex-shrink: 0;
  }
}
