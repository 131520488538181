/*
---
name: sw2-Held
namespace: SiteWide2
category: InteractiveComponent
---

リアル開催およびオンライン開催表示用のパーツです。

```html
<div calss="sw2-Held">
  <div calss="sw2-Held_Inner">
    <ul>
      <li class="sw2-Held_Real">リアル開催</li>
      <li class="sw2-Held_Online">オンライン開催</li>
    </ul>
  </div>
</div>
```

*/
.sw2-Held {
  margin-bottom: rem(20);
  text-align: right;
  &.-is-left {
    text-align: left;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  &.-is-home {
    margin-top: rem(27);
    text-align: left;
    @include mq-up(lg) {
      margin-top: rem(40, lg);
      margin-left: rem(30, lg);
    }
    ul {
      @include mq-up(lg) {
        display: block;
      }
    }
    .sw2-Held_Online {
      @include mq-up(lg) {
        margin-left: 0 !important;
        margin-top: rem(16, lg);
      }
    }
  }
}
.sw2-Held_Inner {
  display: inline-block;
  padding: rem(8) rem(12);
  background: #f5f5f5;
  border: 1px solid #E4E7E8;
  &.-is-white {
    background: #fff;
    border: 1px solid #f5f5f5;
  }
  @include mq-up(lg) {
    padding: rem(13, lg) rem(16, lg) rem(10, lg);
  }
}
.sw2-Held_Real {
  font-weight: 700;
  font-size: rem(10);
  line-height: rem(15);
  color: #686F73;
  @include mq-up(lg) {
    font-size: rem(11, lg);
    line-height: rem(15, lg);
  }
  &::before {
    @include Icon("icon_real");
    font-size: rem(16);
    vertical-align: middle;
    margin-right: rem(8);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(18, lg);
      margin-right: rem(8, lg);
    }
  }
}
.sw2-Held_Online {
  font-weight: 700;
  font-size: rem(10);
  line-height: rem(15);
  color: #686F73;
  margin-left: rem(12) !important;
  @include mq-up(lg) {
    font-size: rem(11, lg);
    line-height: rem(15, lg);
    margin-left: rem(24, lg) !important;
  }
  &::before {
    @include Icon("icon_online");
    font-size: rem(16);
    vertical-align: middle;
    margin-right: rem(8);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(18, lg);
      margin-right: rem(8, lg);
    }
  }
}
.sw2-Held_Labels {
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  padding: rem(8) rem(12);
  display: inline-block;
  position: absolute;
  top: rem(10);
  right: rem(10);
  z-index: 5;
  @include mq-up(lg) {
    padding: rem(8, lg) rem(10, lg) rem(5, lg);
    top: rem(10, lg);
    right: rem(10, lg);
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      span {
        display: none;
      }
    }
  }
  .sw2-Held_Real {
    &::before {
      margin-right: rem(0);
      opacity: 0.4;
      color: #686f73;
    }
    &.-is-active {
      &::before {
        opacity: 1;
        color: $color-krp;
      }
    }
  }
  .sw2-Held_Online {
    @include mq-up(lg) {
      margin-left: rem(10, lg) !important;
    }
    &::before {
      margin-right: rem(0);
      opacity: 0.4;
      color: #686f73;
    }
    &.-is-active {
      &::before {
        opacity: 1;
        color: $color-krp;
      }
    }
  }
}




