/*
---
name: sw-FormTextarea
namespace: SiteWide
category: Form
id: [FO-2, FO-7]
---

<span class="sw-Label">FO-2</span>

複数行のテキストエリア`textarea`です。

```html
<div class="st-Wrapper">
<label for="desired2" class="sw-FromLabel">お問い合わせ内容、予算などのご希望についてご記入ください。<span class="sw-FormLabel_Require">（必須）</span></label>
<textarea class="sw-FormTextarea" id="desired2" required></textarea>
</div>
```

`.sw-FormTextarea-large`を追加すると高さが大きくなります。

```html
<div class="st-Wrapper">
<label for="desired3" class="sw-FromLabel">お問い合わせ内容、予算などのご希望についてご記入ください。<span class="sw-FormLabel_Require">（必須）</span></label>
<textarea class="sw-FormTextarea sw-FormTextarea-large" id="desired3" required></textarea>
</div>
```

<span class="sw-Label">FO-7</span>

必須項目が入力されていない時の表示例です。

```html
<div class="st-Wrapper">
<label for="desired4" class="sw-FromLabel">お問い合わせ内容、予算などのご希望についてご記入ください。<span class="sw-FormLabel_Require">（必須）</span></label>
<label for="desired4" class="sw-FormTextarea_Require">会社名は入力必須です</label>
<textarea class="sw-FormTextarea sw-FormTextarea-error" id="desired4" required></textarea>
</div>
```
*/
.sw-FormTextarea {
  @include sw-FormTextarea();
}

.sw-FormTextarea-error {
  background-color: #fceded;
}

.sw-FormTextarea-large {
  height: 14em;
}

.sw-FormTextarea_Require {
  display: block;
  margin-bottom: 6px;
  font-size: rem(14, lg);
  line-height: 1.4;
  color: #ec0000;
}
