.st-LocalNav7 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: 4.8vw;
  padding-bottom: 15px;
  padding-left: 4.8vw;
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    position: fixed;
    right: 0;
    top: 206px;
    z-index: 3;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav7_Items {
  display: block;
  margin-left: -2.5px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  line-height: 1.2;

  @include mq-up(lg) {
    padding-top: 0;
    text-orientation: mixed;
  }
}

.st-LocalNav7_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  padding-left: 2.5px;
  font-size: rem(9);
  line-height: 1.2;
  text-align: center;

  @include mq-up(lg) {
    width: 100px;
    height: 100px;
    padding-left: 0;
    font-size: rem(13, lg);
    display: list-item;
  }

  & .st-LocalNav7_Link_Img01 {
    display: none;

    @include mq-up(lg) {
      display: block;
      position: absolute;
      top: 20px;
      left: 33px;
      width: 32px;
      height: 40px;
      z-index: 4;
    }
  }

  & .st-LocalNav7_Link_Img02 {
    display: none;

    @include mq-up(lg) {
      display: block;
      position: absolute;
      top: 15px;
      left: 33px;
      width: 36px;
      height: 38px;
      z-index: 4;
    }
  }

  & .st-LocalNav7_Link_Img03 {
    display: none;

    @include mq-up(lg) {
      display: block;
      position: absolute;
      top: 15px;
      left: 33px;
      width: 38px;
      height: 36px;
      z-index: 4;
    }
  }

  & .st-LocalNav7_Link_Text01 {
    @include mq-up(lg) {
      position: absolute;
      top: 65px;
      left: 25px;
    }
  }

  & .st-LocalNav7_Link_Text02 {
    @include mq-up(lg) {
      position: absolute;
      top: 58px;
      left: 12px;
    }
  }

  & .st-LocalNav7_Link_Text03 {
    @include mq-up(lg) {
      position: absolute;
      top: 58px;
      left: 5px;
    }
  }

  &:nth-of-type(n + 4) {
    margin-top: 10px;
  }

  &:nth-child(1) {
    @include mq-up(lg) {
      border-bottom: 1px solid #fff;
    }
  }

  &:nth-child(2) {
    @include mq-up(lg) {
      border-bottom: 1px solid #fff;
    }
  }
}

.st-LocalNav7_Link {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    padding: 0;
    position: relative;
    display: block;
    color: #fff;
    background-color: #222;
  }

  .st-LocalNav7_Item & {
    background-color: #a81d34;

    @include mq-up(lg) {
      background-color: #c5383b;
      width: 100%;
      height: 100%;
    }
  }

  & br {
    display: none;

    @include mq-up(lg) {
      display: block;
    }
  }
}

.st-ContentHeaderD_SubNav_goconc {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;

  @include mq-up(lg) {
    position: initial;
    width: auto;
    left: auto;
    bottom: auto;
    margin: 0;
  }
}
