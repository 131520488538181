.st-FooterCopyright {
  padding: 25px 0 32px;
  text-align: center;
  line-height: 1.2;
  color: #fff;
  background-color: $color-krp;

  @include mq-up(lg) {
    padding: 50px 0;
  }
}

.st-FooterCopyright_Copyright {
  font-size: rem(10.5);
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}
