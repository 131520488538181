// @desc - tableに`border-collapse: collapse;`を指定したような見せ方ができます。`border-radius`を併用するときは表示が崩れるので使用できません。
// @param {String} $color [currentColor] - ボーダーの色を指定します。
// @param {String} $size [1px] - ボーダーのサイズを指定します。
// @example scss - Usage
// .foo { @include border-collapse(#888, 2px); }
//
// @example css - CSS output
// .foo {
//   box-shadow:
//     2px 2px 0 0 #888,
//     inset 2px 2px 0 0 #888;
// }
@mixin border-collapse($color: currentColor, $size: 1px) {
  box-shadow:
    $size $size 0 0 $color,
    inset $size $size 0 0 $color;
}
