/*
---
name: sw-BlockB
namespace: SiteWide
category: Block
id: [C1, TH-2]
---

<span class="sw-Label">TH-2</span>

PC幅で3カラムや4カラムになる場合は`.sw-BlockB`を使用します（2カラム以上の場合は`.sw-BlockA`を使用してください）。仕様は`.sw-BlockA`と同じです。

- 見出しにリンクあり
- ラベルあり
- PC幅で3カラム

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>情報提供・PR</a>
      <p class="sw-BlockB_Text">すそ野の拡大とビジネスマッチングの機会の提供</p>
      <ul class="sw-BlockB_LabelArea">
        <li class="sw-BlockB_Label">オフィススペース</li>
        <li class="sw-BlockB_Label">実験研究スペース</li>
        <li class="sw-BlockB_Label">貸会議室</li>
        <li class="sw-BlockB_Label">飲食店</li>
      </ul>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>事業創出プロデュース<br>
      改行した場合のレイアウトです</a>
      <p class="sw-BlockB_Text">将来の事業化・産業化、プロジェクト創出や商品化をプロデュース</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>モノづくりサポート</a>
      <p class="sw-BlockB_Text">ニーズやアイデアから付加価値のあるモノづくりへ</p>
    </div>
  </li>
</ul>
</div>
```

- 見出しのリンクなし
- 画像のリンクなし
- PC幅で4カラム

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">情報提供・PR</p>
      <p class="sw-BlockB_Text">すそ野の拡大とビジネスマッチングの機会の提供</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">事業創出プロデュース<br>
      改行した場合のレイアウトです</p>
      <p class="sw-BlockB_Text">将来の事業化・産業化、プロジェクト創出や商品化をプロデュース</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">モノづくりサポート</p>
      <p class="sw-BlockB_Text">ニーズやアイデアから付加価値のあるモノづくりへ</p>
    </div>
  </li>
</ul>
</div>
```

- 見出しにリンクあり
- PC幅で4カラム

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col4Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>学会プラン</a>
      <p class="sw-BlockB_Text">学会運営を熟知したスタッフがご準備から開催当日までトータルにサポートいたします。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>事務局代行サービス</a>
      <p class="sw-BlockB_Text">煩雑な事務業務を経験豊富なスタッフが代行、スムーズな運営をサポートいたします。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>インターネット・通信環境<br>
      改行した場合のレイアウトです</a>
      <p class="sw-BlockB_Text">無線LAN（Wi-Fi）/有線LANによるインターネット接続サービスを無料でご利用いただけます。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <a class="sw-BlockB_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </a>
      <a class="sw-BlockB_Heading" href="#"><span class="sw-BlockB_HeadingIcon" aria-hidden="true"></span>AV機器・同時通訳<br>
      改行した場合のレイアウトです</a>
      <p class="sw-BlockB_Text">プレゼンテーションに必要な機器を多種取り揃えております。</p>
    </div>
  </li>
</ul>
</div>
```

- 見出しにリンクなし
- 画像にリンクなし
- PC幅で4カラム

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col4Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">学会プラン</p>
      <p class="sw-BlockB_Text">学会運営を熟知したスタッフがご準備から開催当日までトータルにサポートいたします。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">事務局代行サービス</p>
      <p class="sw-BlockB_Text">煩雑な事務業務を経験豊富なスタッフが代行、スムーズな運営をサポートいたします。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">インターネット・通信環境<br>
      改行した場合のレイアウトです</p>
      <p class="sw-BlockB_Text">無線LAN（Wi-Fi）/有線LANによるインターネット接続サービスを無料でご利用いただけます。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockB">
      <div class="sw-BlockB_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockB_Image">
      </div>
      <p class="sw-BlockB_Heading">AV機器・同時通訳<br>
      改行した場合のレイアウトです</p>
      <p class="sw-BlockB_Text">プレゼンテーションに必要な機器を多種取り揃えております。</p>
    </div>
  </li>
</ul>
</div>
```
*/
.sw-BlockB {

}

.sw-BlockB_ImageArea {
  display: block;
}
a.sw-BlockB_ImageArea {
  &:hover {
    opacity: $opacity;
  }
}

.sw-BlockB_Image {
  display: block;
  width: 100%;
  max-width: none;
}

.sw-BlockB_Heading {
  position: relative;
  margin-top: 16px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;
}

a.sw-BlockB_Heading {
  display: block;
  padding-left: 20px;
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: #d02425;
    text-decoration: none;
  }
}

.sw-BlockB_HeadingIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  color: $color-brand;
  font-size: 0.7em;
}

.sw-BlockB_HeadingIcon_External:before {
  @include Icon("icon_windows");
  font-size: 1em;
  margin-left: 8px;
  color: $color-brand;
}

.sw-BlockB_Text {
  margin-top: 8px;
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(15, lg);
  }
}

.sw-BlockB_ButtonArea {
  margin-top: 30px;
}

.sw-BlockB_Button {
  @include sw-Button;
  position: relative;
  width: auto;
  max-width: 100%;
}

.sw-BlockB_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-Button_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-BlockB_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(16, lg);
  line-height: 1.4;
}

.sw-BlockB_LinksItem {
  position: relative;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }
}

.sw-BlockB_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-BlockB_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}

.sw-BlockB_LabelArea {
  margin-top: 8px;
  padding-left: 0;
  list-style-type: none;
}

.sw-BlockB_Label {
  @include sw-Label();
  margin-right: 7px;
  margin-bottom: 5px;

  @include mq-up(lg) {
    margin-right: 18px;
    margin-bottom: 10px;
  }
}
