/*
---
name: krpweek-News
namespace: krpweek
category: Block
id: NL-3
---

<span class="sw-Label">NL-3</span>

KRPWEEKで使用する新着情報モジュールです。CMSから最大3つを選択・表示します。

- 日付とタイトルと本文が入ります。
- 画像は任意です。

```html
<div class="st-Wrapper">
  <div class="krpweek-News">
    <div class="krpweek-News_Main">
      <ol class="krpweek-News_Items">
        <li class="krpweek-News_Item">
          <div class="krpweek-News_ImageArea">
            <img src="https://placehold.jp/180x136.png" alt="" class="krpweek-News_Image">
          </div>
          <div class="krpweek-News_Body">
            <p class="krpweek-News_PostDate">2018/00/00</p>
            <h3 class="krpweek-News_Heading">KRP PUBLIC ART-PJ 優勝者作品展示中！</h3>
            <div class="krpweek-News_Text">KRP地区を彩るアートコンペティションを「KRP-WEEK2017」期間中に開催し、KRP地区ご利用者さまの一般投票にて、5作品から優秀賞を決定しました！<br>
            現在、優秀賞受賞者の安成 晶（やすなり あき）氏の作品をKRP4号館１Fにて展示中！KRPにお越しの際は、ぜひ作品をご覧ください。（12月22日（金）まで。）</div>
          </div>
        </li>
        <li class="krpweek-News_Item">
          <div class="krpweek-News_Body">
            <p class="krpweek-News_PostDate">2018/00/00</p>
            <h3 class="krpweek-News_Heading">メディアに掲載されました Vol.9</h3>
            <div class="krpweek-News_Text">08.08　7/24（月）<a href="#">京都市ベンチャー企業目利き委員会20周年記念事業</a><br>
            ”人の倍働く”ハードワークこそベンチャー成功の秘訣 ＜日刊ケイザイ新聞 8/7（月）＞</div>
          </div>
        </li>
        <li class="krpweek-News_Item">
          <div class="krpweek-News_Body">
            <p class="krpweek-News_PostDate">2018/00/00</p>
            <h3 class="krpweek-News_Heading">メディアに掲載されました Vol.8</h3>
            <div class="krpweek-News_Text">08.08　7/28（金）-7/29（土）<a href="#">七本松陶器市</a><br>
            若手職人応援事業　学生ら陶器市出品 ＜読売新聞 8/6（日）＞</div>
          </div>
        </li>
      </ol>
    </div>
    <div class="krpweek-News_Foot">
      <a href="#" class="sw-Button sw-Button-invert sw-Button-auto">NEWS一覧<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
    </div>
  </div>
</div>
```
*/
.krpweek-News {

}

.krpweek-News_Main {
  
}

.krpweek-News_Foot {
  margin-top: 50px;
  text-align: center;
}

.krpweek-News_Items {
  padding-left: 0;
  list-style-type: none;
}

.krpweek-News_Item {
  display: block;
  padding: 20px 0 30px;
  border-bottom: 1px solid #e8e8e8;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.krpweek-News_ImageArea {
  float: right;
  margin-left: 2em;
}

.krpweek-News_Image {
  width: 100px;

  @include mq-up(lg) {
    width: 180px;
  }
}


.krpweek-News_Body {
  @include mq-up(lg) {
    overflow: hidden;
  }
}

.krpweek-News_PostDate {
  font-size: rem(20, lg);
  font-weight: bold;
  line-height: 1.8;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
  }
}

.krpweek-News_Heading {
  font-size: rem(20, lg);
  line-height: 1.8;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 25px;
  }
}

.krpweek-News_Text {
  margin-top: 10px;
  font-size: rem(16, lg);
  line-height: 1.6;

  & a {
    @include sw-Link();
  }
}
