.inquiry-Layout {

}

.inquiry-Layout_Container {
  @include mq-up(lg) {
    width: percentage(div(10, 12));
    margin: auto;
  }
}

.inquiry-Layout_Item {
  margin: 0;
  padding: 0;
  border: 0;

  & + &,
  :not(#{&}) + & {
    margin-top: 20px;

    @include mq-up(lg) {
      margin-top: 30px;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.inquiry-Layout_Item-Half {
  @include mq-up(lg) {
    padding-right: 50%;
  }
}

.inquiry-Layout_Stack {
  display: block;
  padding-left: 0;
  list-style-type: none;
} 

.inquiry-Layout_StackItem {
  display: block;

  .inquiry-Layout_Stack-radio &:nth-of-type(n+2) {
    margin-top: 12px;

    @include mq-up(lg) {
      margin-top: 12px;
    }
  }

  .inquiry-Layout_Stack-checkbox &:nth-of-type(n+2) {
    margin-top: 8px;

    @include mq-up(lg) {
      margin-top: 8px;
    }
  }
}

.inquiry-Layout_Horizontal {
  display: block;
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.inquiry-Layout_HorizontalItem {
  display: inline-block;
  vertical-align: top;
  width: percentage(div(1, 2));
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    width: percentage(div(1, 5));
    padding-left: 30px;

    /* 4の倍数のカラムは2/5の幅にする。 */
    &:nth-of-type(4n) {
      margin-right: percentage(div(1, 5));
    }
  }

  .inquiry-Layout_Horizontal-radio &:nth-of-type(n+3) {
    margin-top: 12px;
  }

  @include mq-up(lg) {
    .inquiry-Layout_Horizontal-radio &:nth-of-type(n+3) {
      margin-top: 0;
    }
    
    .inquiry-Layout_Horizontal-radio &:nth-of-type(n+5) {
      margin-top: 12px;
    }
  }

  .inquiry-Layout_Horizontal-checkbox &:nth-of-type(n+3) {
    margin-top: 8px;
  }

  @include mq-up(lg) {
    .inquiry-Layout_Horizontal-checkbox &:nth-of-type(n+3) {
      margin-top: 0;
    }

    .inquiry-Layout_Horizontal-checkbox &:nth-of-type(n+5) {
      margin-top: 8px;
    }
  }
}

.inquiry-Layout_Message {

}
