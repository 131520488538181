@font-face {
  font-family: "iconfont";
  src: url('../font/iconfont.eot');
  src: url('../font/iconfont.eot?#iefix') format('eot'),
    url('../font/iconfont.woff') format('woff'),
    url('../font/iconfont.ttf') format('truetype'),
    url('../font/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// アイコンフォントのファイル名で呼び出せるように変数化します。
$icon: (
  icon_access-bus: "\\EA01",
  icon_access-pin: "\\EA02",
  icon_access-pin2: "\\EA03",
  icon_access-taxi: "\\EA04",
  icon_access-train: "\\EA05",
  icon_access-walk: "\\EA06",
  icon_access: "\\EA07",
  icon_areamap: "\\EA08",
  icon_arrow-circle-right: "\\EA09",
  icon_arrow-down: "\\EA0A",
  icon_arrow-down02: "\\EA0B",
  icon_arrow-left: "\\EA0C",
  icon_arrow-right: "\\EA0D",
  icon_arrow-up: "\\EA0E",
  icon_build-facility: "\\EA0F",
  icon_build-public: "\\EA10",
  icon_circle-facebook: "\\EA11",
  icon_circle-twitter: "\\EA12",
  icon_close: "\\EA13",
  icon_download: "\\EA14",
  icon_excel: "\\EA15",
  icon_facebook: "\\EA16",
  icon_flow-arrow: "\\EA17",
  icon_instagram: "\\EA18",
  icon_mail: "\\EA19",
  icon_mail2: "\\EA1A",
  icon_menu: "\\EA1B",
  icon_menu2: "\\EA1C",
  icon_online: "\\EA1D",
  icon_open-minus: "\\EA1E",
  icon_open-plus: "\\EA1F",
  icon_pdf: "\\EA20",
  icon_people: "\\EA21",
  icon_price: "\\EA22",
  icon_print: "\\EA23",
  icon_real: "\\EA24",
  icon_search: "\\EA25",
  icon_sitemap-close: "\\EA26",
  icon_space: "\\EA27",
  icon_sphere: "\\EA28",
  icon_strengt: "\\EA29",
  icon_ttl-deco: "\\EA2A",
  icon_twitter: "\\EA2B",
  icon_voice: "\\EA2C",
  icon_windows: "\\EA2D",
) !default;

// @desc アイコンフォントを呼び出します。
// @param {String} $name [null] - 呼び出したいアイコンフォントのファイル名（拡張子なし）。
// @see $icon
// @example scss - Usage
// .foo:before { @include Icon(arrow) };
//
// @example css - CSS output
// .foo:before {
//   content: "\EA01";
//   font-family: "iconfont";
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   display: inline-block;
//   vertical-align: middle;
//   font-style: normal;
//   font-variant: normal;
//   font-weight: normal;
//   line-height: 1;
//   text-decoration: none;
//   text-transform: none;
//   speak: none;
// }
@mixin Icon($name: null) {
  @if map-has-key($icon, $name) {
    content: unquote("\"") + map-get($icon, $name) + unquote("\"");
  } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$name}`. " + "Please make sure it is defined in `$icon` map.";
  }

  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
}