/*
---
name: st2-Header
namespace: Structure2
category: Global
---

グローバルヘッダーです。（トップと中面でスタイルが変更になります。）

```html
<header class="st2-Header" id="st2-Header">
  <div class="st2-Header_Outer">
    <div class="st2-Header_Logo">
      <a class="st2-Header_LogoLink" href="/">
        <img src="/assets/img/common/logo_krp-red.svg" class="st2-Header_Logo_SiteLogoImage" alt="KRP">
        <img src="/assets/img/common/logo_red-krp-txt.svg" class="st2-Header_Logo_SiteNameImage" alt="KYOTO RESEARCH PARK">
      </a>
    </div>
    <div class="st2-Header_Menu">
      <nav class="st2-Header_Nav">
        <ul class="st2-Header_NavList">
          <li><a href="#">イベント</a></li>
          <li><a href="/rentaloffice/">オフィス</a></li>
          <li><a href="/rentaloffice/labo/">ラボ</a></li>
          <li><a href="/kaigi/">ホール・会議室</a></li>
          <li><a href="/sangaku/">イノベーション創発活動</a></li>
          <li><a href="/access/">アクセス</a></li>
          <li><a href="/company/">入居企業</a></li>
        </ul>
      </nav>
      <div class="st2-Header_MenuInner">
        <div class="st2-Header_Button">
          <div class="st2-Header_AccessButton"><a href="/access/">ACCESS</a></div>
          <div class="st2-Header_MenuButton"><span>MENU</span></div>
        </div>
      </div>
    </div>
  </div>
</header>
```
*/
.st2-Header {
  width: 100%;
}
.st2-Header_Outer {
  width: 100%;
  background-color: #fff;

  &.-is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
  }
}
.st2-Header_Inner {
  width: 100%;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
body.-is-nomv #main {
  border-top: 1px solid #E4E7E8;
}
body.-is-nomv.-is-home #main {
  border-top: none;
}
.st2-Header_Logo {

}
.st2-Header_LogoLink {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: rem(16) rem(10) rem(16) rem(20);
  @include mq-up(md) {
    padding: rem(16, md) rem(10, md) rem(16, md) rem(20, md);
  }
  @include mq-up(lg) {
    display: block;
    padding: rem(11, lg) rem(19, lg) rem(10, lg) rem(30, lg);
  }
  @media print, screen and (min-width: $max-width) {
    display: flex;
    padding: rem(19, lg) rem(19, lg) rem(19, lg) rem(30, lg);
  }
}
.st2-Header_Logo_SiteLogoImage {
  width: rem(51);
  vertical-align: bottom;
  margin-right: rem(13);
  display: block;
  @include mq-up(md) {
    width: rem(51, md);
    margin-right: rem(13, md);
  }
  @include mq-up(lg) {
    width: rem(60, lg);
    margin-right: rem(16, lg);
  }
}
.st2-Header_Logo_SiteNameImage {
  width: rem(102);
  vertical-align: bottom;
  display: block;
  @include mq-up(md) {
    width: rem(102, md);
  }
  @include mq-up(lg) {
    width: rem(120, lg);
    margin-top: rem(8, lg);
  }
  @media print, screen and (min-width: $max-width) {
    margin-top: rem(0, lg);
  }
}
.st2-Header_Menu {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.st2-Header_Nav {
  display: none;
  @include mq-up(lg) {
    display: block;
    margin-right: rem(18, lg);
  }
}
.st2-Header_NavList {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;
  }

  & > li {
    @include mq-up(lg) {
      list-style: none;
    }
    & > a {
      @include mq-up(lg) {
        display: block;
        font-weight: 500;
        font-size: rem(12, lg);
        line-height: rem(12, lg);
        text-decoration: none;
        color: #9CA7AC;
        padding: rem(22.5, lg) rem(8, lg);
        transition-duration: $transition-duration;
        transition-timing-function: $transition-timing-function;
      }
      @media print, screen and (min-width: $max-width) {
        padding: rem(22.5, lg) rem(12, lg);
      }
      &:hover {
        @include mq-up(lg) {
          color: $color-krp;
        }
      }
    }
  }
}
.st2-Header_MenuInner {

}
.st2-Header_Button {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}
.st2-Header_AccessButton {
  margin-right: rem(1);
  @include mq-up(lg) {
    display: none;
  }
  & > a {
    display: block;
    font-family: $font-family-lato;
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(10);
    text-decoration: none;
    color: $color-krp;
    position: relative;
    padding: rem(30) rem(6) rem(8);
    background-color: #EDEFF0;
    @include mq-up(md) {
      font-size: rem(10, md);
      line-height: rem(10, md);
      padding: rem(30, md) rem(6, md) rem(8, md);
    }
    &::before {
      @include Icon("icon_access-pin2");
      font-size: rem(23);
      position: absolute;
      top: rem(5);
      left: 50%;
      transform: translate(-50%, 0);
      @include mq-up(md) {
        font-size: rem(23, md);
        top: rem(5, md);
      }
    }
  }
}
.st2-Header_MenuButton {
  & > span {
    display: block;
    font-family: $font-family-lato;
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(10);
    text-decoration: none;
    color: $color-krp;
    position: relative;
    padding: rem(30) rem(7) rem(8);
    background-color: #EDEFF0;
    letter-spacing: 0.1em;
    @include mq-up(md) {
      font-size: rem(10, md);
      line-height: rem(10, md);
      padding: rem(30, md) rem(7, md) rem(8, md);
    }
    @include mq-up(lg) {
      cursor: pointer;
      font-size: rem(10, lg);
      line-height: rem(10, lg);
      padding: rem(36, lg) rem(9, lg) rem(10, lg);
      letter-spacing: 0.2em;
    }

    &::before {
      @include Icon("icon_menu2");
      font-size: rem(26);
      position: absolute;
      top: rem(3);
      left: 50%;
      transform: translate(-50%, 0);
      @include mq-up(md) {
        font-size: rem(26, md);
        top: rem(3, md);
      }
      @include mq-up(lg) {
        font-size: rem(30, lg);
        top: rem(5, lg);
      }
    }
  }
}

.st2-Header_MegaMenu {
  display: none;
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  position: absolute;
  top: 57px;
  left: 0;
  z-index: 600;
  @include mq-up(lg) {
    display: none;
  }
}
.st2-Header_MegaMenuInner {
  padding: 70px 0 50px;
}
.st2-Header_MegaMenuItem {
  max-width: 1200px;
  margin: 0 auto;
}
.st2-Header_MegaMenuItemInner {
  display: none;
  padding: 0 20px;
}
.st2-Header_MegaMenuItemLogo {
  margin-left: 30px;
  a {
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }
  a:hover {
    @include mq-up(lg) {
      opacity: 0.5;
    }
  }
}
.st2-Header_MegaMenuItemInnerItem {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    flex-shrink: 0;
    .st-HeaderNavChild_ImageA.st-HeaderNavChild_ImageA-col2 {
      width: 100%;
    }
    
    &:first-child {
      width: 50%;
      margin-right: 50px;
    }
    &:nth-child(3) {
      margin-left: auto;
    }
  }
}
.st2-Header_MegaMenuListLink-col2 {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  & > div {
    margin-right: 30px;
  }
}
.st2-HeaderNavChild_ImageA-col1 {
  width: auto !important;
  .st-HeaderNavChild_ImageA {
    margin-right: 0;
  }
  .st-HeaderNavChildImageA_Head {
    width: 426px;
  }
}
.st2-HeaderNavChild_List-col1 {
  width: calc(100% - 516px);
  .st-HeaderNavChild_List {
    max-width: 579px;
    width: auto;
  }
}
body.-is-sitemap-open {
  overflow: hidden;
  .st2-Header_SiteMap_Wrap {
    animation: SiteMapslideIn 1.5s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
  }
}
body.-is-sitemap-close {
  .st2-Header_SiteMap_Wrap {
    animation: SiteMapslideOut 1.5s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
  }
}
.st2-Header_SiteMap {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 700;
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  height: 100vh;

  &.-is-open {
    display: block;
  }
  &.-is-close {
    display: none;
  }
}
.st2-Header_SiteMap_Close {
  position: fixed;
  top: rem(4);
  right: rem(2);
  width: rem(56);
  height: rem(56);
  z-index: 720;
  background-color: $color-krp;
  cursor: pointer;
  @include mq-up(lg) {
    top: rem(8, lg);
    right: rem(8, lg);
    width: rem(80, lg);
    height: rem(80, lg);
  }
  &::before {
    @include Icon("icon_sitemap-close");
    color: #fff;
    font-size: rem(22);
    position: absolute;
    top: rem(9);
    left: 50%;
    transform: translate(-50%, 0);
    @include mq-up(lg) {
      top: rem(15, lg);
      font-size: rem(28, lg);
    }
  }
  & > span {
    display: block;
    position: absolute;
    bottom: rem(8);
    left: 50%;
    transform: translate(-50%, 0);
    font-family: $font-family-lato;
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(10);
    letter-spacing: 0.1em;
    color: #fff;
    @include mq-up(lg) {
      font-size: rem(12, lg);
      line-height: rem(12, lg);
      bottom: rem(16, lg);
    }
  }
}
.gsc-overflow-hidden {
  .st2-Header_SiteMap_Close {
    display: none;
  }
}

.st2-Header_SiteMap_Wrap {
  background-color: #F6F8F9;
  width: calc(100% - 8px);
  height: calc(100vh - 8px);
  position: fixed;
  top: rem(4);
  left: rem(4);
  padding: rem(40) rem(16);
  @include mq-up(lg) {
    width: calc(100% - 104px);
    height: calc(100vh - 16px);
    top: rem(8, lg);
    left: rem(8, lg);
    padding: rem(32, lg) rem(10, lg);
  }
}
@keyframes SiteMapslideIn {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes SiteMapslideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(20%);
  }
  100% {
    opacity: 0;
  }
}
.st2-Header_SiteMap_Box {
  @include mq-up(lg) {
    max-width: 1170px;
    margin: 0 auto;
  }
}
.st2-Header_SiteMap_Header {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.st2-Header_SiteMap_Header_Main {
  @include mq-up(lg) {
    margin-right: rem(60, lg);
    padding: rem(15, lg) 0;
  }
}
.st2-Header_SiteMap_Header_Main_Inner {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.st2-Header_SiteMap_Header_Logo {
  margin-right: rem(12);
  @include mq-up(lg) {
    margin-right: rem(16, lg);
  }
  img {
    display: block;
    width: rem(75);
    @include mq-up(lg) {
      width: rem(75, lg);
    }
  }
}
.st2-Header_SiteMap_Header_LogoText {
  margin-right: rem(16);
  font-family: $font-family-montserrat;
  font-weight: 700;
  font-size: rem(14);
  line-height: rem(14);
  color: $color-krp;
  @include mq-up(lg) {
    margin-right: rem(16, lg);
    font-size: rem(14, lg);
    line-height: rem(14, lg);
  }
}
.st2-Header_SiteMap_Header_EnglishBtn {
  a {
    display: block;
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(11);
    text-decoration: none;
    color: #fff;
    background-color: $color-krp;
    padding: rem(8) rem(13);
    border-radius: 100px;
    @include mq-up(lg) {
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;
      font-size: rem(11, lg);
      line-height: rem(11, lg);
      padding: rem(8, lg) rem(13, lg);
    }
    &:hover {
      @include mq-up(lg) {
        opacity: 0.5;
      }
    }
  }
}

.st2-Header_SiteMap_Header_Sub {
  margin-top: rem(24);
  position: relative;
  overflow: hidden;
  @include mq-up(lg) {
    width: calc(100% - 303px);
    margin-top: rem(0);
    padding: rem(15, lg) 0;
  }
}
.st2-Header_SiteMap_Header_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(24);
  line-height: rem(36);
  @include mq-up(lg) {
    font-size: rem(24, lg);
    line-height: rem(24, lg);
  }
  & > br {
    @include mq-up(lg) {
      display: none;
    }
  }
}
.st2-Header_SiteMap_Header_Search {

  &.-is-open {
    .st2-Header_SiteMap_Header_Search_Input {
      animation: SearchInputShow 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
      display: block;
      @include mq-up(lg) {
        animation: SearchInputShowPc 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
      }
    }
    .st2-Header_SiteMap_Header_Search_Button {
      border-left: none;
      border-radius: 0 5px 5px 0;
    }
  }
  &.-is-close {
    .st2-Header_SiteMap_Header_Search_Input {
      animation: SearchInputHide 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
      display: block;
      @include mq-up(lg) {
        animation: SearchInputHidePc 0.5s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
      }
    }
    .st2-Header_SiteMap_Header_Search_Button {
      border-left: none;
      border-radius: 0 5px 5px 0;
    }
  }
}
.st2-Header_SiteMap_Header_Search_Input {
  display: none;
  position: absolute;
  top: rem(10);
  right: 0;
  width: 0%;
  height: 54px;
  @include mq-up(lg) {
    top: rem(0);
  }
  .gsc-control-cse {
    padding: 0;
    background-color: transparent;
    border: none;
  }
  .gsc-search-box {
    margin: 0;
  }
  .gsc-input-box {
    height: 54px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    border: 1px solid #E4E7E8;
    border-right: none;
  }
  .gsc-search-button {
    display: none;
  }
  table.gsc-search-box td.gsc-input {
    padding-right: 0;
  }
}
@keyframes SearchInputShow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes SearchInputShowPc {
  0% {
    width: 0%;
  }
  100% {
    width: 72%;
  }
}
@keyframes SearchInputHide {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes SearchInputHidePc {
  0% {
    width: 72%;
  }
  100% {
    width: 0%;
  }
}

.st2-Header_SiteMap_Header_Search_Button {
  position: absolute;
  top: rem(10);
  right: 0;
  width: 54px;
  height: 54px;
  background: #FFFFFF;
  border: 1px solid #E4E7E8;
  border-radius: 5px;
  @include mq-up(lg) {
    top: rem(0);
  }
  &.-is-close {
    border-left: 1px solid #E4E7E8 !important;
    border-radius: 5px !important;
  }
}

.st2-Header_SiteMap_Header_Search_Icon {
  &::before {
    @include Icon("icon_search");
    font-size: rem(22);
    line-height: rem(22);
    color: $color-krp;
  }
}

.st2-Header_SiteMap_Inner {
  margin-top: rem(24);
  overflow-y: auto;
  height: calc(100vh - 226px);
  @include mq-up(lg) {
    margin-top: rem(25, lg);
    height: calc(100vh - 162px);
  }
}
.st2-Header_SiteMap_Contents {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.st2-Header_SiteMap_Contents_Main {
  @include mq-up(lg) {
    max-width: rem(870, lg);
  }
}
.st2-Header_SiteMap_Contents_ViewLink {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: rem(-15);
  @include mq-up(lg) {
    margin-top: rem(-8, lg);
    margin-left: rem(-30, lg);
  }
}
.st2-Header_SiteMap_Contents_ViewLink_Item {
  width: 50%;
  margin-top: rem(8);
  padding-left: rem(15);
  @include mq-up(lg) {
    width: percentage(div(1, 3));
    margin-top: rem(8, lg);
    padding-left: rem(30, lg);
  }
  a {
    display: block;
    text-decoration: none;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
    &:hover {
      @include mq-up(lg) {
        opacity: 0.5;
      }
    }
  }
  &:nth-child(1),&:nth-child(2) {
    margin-top: rem(0);
    @include mq-up(lg) {
      margin-top: rem(8, lg);
    }
  }
  &:nth-child(1) {
    @include mq-up(lg) {
      order: 1;
    }
  }
  &:nth-child(2) {
    @include mq-up(lg) {
      order: 4;
    }
  }
  &:nth-child(3) {
    @include mq-up(lg) {
      order: 7;
    }
  }
  &:nth-child(4) {
    @include mq-up(lg) {
      order: 2;
    }
  }
  &:nth-child(5) {
    @include mq-up(lg) {
      order: 5;
    }
  }
  &:nth-child(6) {
    @include mq-up(lg) {
      order: 8;
    }
  }
  &:nth-child(7) {
    @include mq-up(lg) {
      order: 3;
    }
  }
  &:nth-child(8) {
    @include mq-up(lg) {
      order: 6;
    }
  }
  &:nth-child(9) {
    @include mq-up(lg) {
      order: 9;
    }
  }
}
.st2-Header_SiteMap_Contents_ViewLink_ItemIn {
  border: 1px solid #E4E7E8;
}
.st2-Header_SiteMap_Contents_ViewLink_ItemImg {
  img {
    width: 100%;
  }
}
.st2-Header_SiteMap_Contents_ViewLink_ItemText {
  position: relative;
  background: #FFFFFF;
  font-weight: 700;
  font-size: rem(11);
  line-height: rem(11);
  text-decoration: none;
  color: #333;
  padding: rem(16) rem(12);
  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: rem(13, lg);
    padding: rem(16, lg) rem(12, lg);
  }
  @media print, screen and (min-width: $max-width) {
    font-size: rem(14, lg);
    line-height: rem(14, lg);
    padding: rem(20, lg) rem(16, lg);
  }
  &::after {
    @include Icon("icon_arrow-right");
    font-size: rem(9);
    line-height: rem(9);
    color: $color-krp;
    position: absolute;
    top: 50%;
    right: rem(5);
    transform: translate(0, -50%);
    @include mq-up(lg) {
      font-size: rem(11, lg);
      line-height: rem(11, lg);
      right: rem(9, lg);
    }
  }
}

.st2-Header_SiteMap_Contents_Sub {
  margin-top: rem(32);
  @include mq-up(lg) {
    margin-top: rem(0);
    margin-left: rem(30, lg);
    min-width: rem(190, lg);
    flex: 1;
  }
}
.st2-Header_SiteMap_Contents_InquiryLink {
  a {
    display: block;
    position: relative;
    background: $color-krp;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(14);
    text-decoration: none;
    color: #fff;
    padding: rem(25) rem(12) rem(25) rem(54);
    @include mq-up(lg) {
      font-size: rem(15, lg);
      line-height: rem(15, lg);
      padding: rem(24, lg) rem(24, lg) rem(24, lg) rem(56, lg);
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;
    }
    @media print, screen and (min-width: $max-width) {
      font-size: rem(16, lg);
      line-height: rem(16, lg);
      padding: rem(26, lg) rem(26, lg) rem(26, lg) rem(64, lg);
    }
    &::after {
      @include Icon("icon_arrow-right");
      font-size: rem(11);
      line-height: rem(11);
      color: #fff;
      position: absolute;
      top: 50%;
      right: rem(4);
      transform: translate(0, -50%);
      @include mq-up(lg) {
        font-size: rem(11, lg);
        line-height: rem(11, lg);
        right: rem(9, lg);
      }
    }
    &:hover {
      @include mq-up(lg) {
        opacity: 0.5;
      }
    }
  }
}
.st2-Header_SiteMap_Contents_InquiryLink_Mail {
  display: block;
  position: absolute;
  top: 50%;
  left: rem(16);
  transform: translate(0, -50%);
  @include mq-up(lg) {
    left: rem(16, lg);
  }
  &::before {
    @include Icon("icon_mail2");
    font-size: rem(26);
    line-height: rem(26);
    color: #fff;
    @include mq-up(lg) {
      font-size: rem(30, lg);
      line-height: rem(30, lg);
    }
  }
}

.st2-Header_SiteMap_Contents_SoloLink {
  margin-top: rem(8);
  @include mq-up(lg) {
    margin-top: rem(8, lg);
  }
  a {
    display: block;
    position: relative;
    background-image: url(/assets/img/structure/bg_footer-registration.png);
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(11);
    text-decoration: none;
    color: #fff;
    padding: rem(19) rem(12);
    @include mq-up(lg) {
      font-size: rem(13, lg);
      line-height: rem(13, lg);
      padding: rem(16, lg) rem(12, lg);
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;
    }
    @media print, screen and (min-width: $max-width) {
      font-size: rem(14, lg);
      line-height: rem(14, lg);
      padding: rem(20, lg) rem(16, lg);
    }
    &::after {
      @include Icon("icon_arrow-right");
      font-size: rem(9);
      line-height: rem(9);
      color: #fff;
      position: absolute;
      top: 50%;
      right: rem(5);
      transform: translate(0, -50%);
      @include mq-up(lg) {
        font-size: rem(11, lg);
        line-height: rem(11, lg);
        right: rem(9, lg);
      }
    }
    &:hover {
      @include mq-up(lg) {
        opacity: 0.5;
      }
    }
  }
}

.st2-Header_SiteMap_Contents_IconLink {
  margin-top: rem(24);
  @include mq-up(lg) {
    margin-top: rem(32, lg);
  }
  ul {
    padding: 0;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-15);
    @include mq-up(lg) {
      display: block;
      margin-left: 0;
    }
    li {
      margin-top: rem(8);
      padding: 0 0 0 rem(15);
      list-style: none;
      width: 50%;
      @include mq-up(lg) {
        margin-top: rem(8, lg);
        padding: 0;
        width: 100%;
      }
      a {
        display: block;
        position: relative;
        background: #FFFFFF;
        border: 1px solid #E4E7E8;
        font-weight: 700;
        font-size: rem(11);
        line-height: rem(11);
        text-decoration: none;
        color: #333;
        padding: rem(19) rem(12) rem(19) rem(40);
        @include mq-up(lg) {
          font-size: rem(13, lg);
          line-height: rem(13, lg);
          padding: rem(20, lg) rem(20, lg) rem(20, lg) rem(44, lg);
          transition-duration: $transition-duration;
          transition-timing-function: $transition-timing-function;
        }
        @media print, screen and (min-width: $max-width) {
          font-size: rem(14, lg);
          line-height: rem(14, lg);
          padding: rem(20, lg) rem(20, lg) rem(20, lg) rem(52, lg);
        }
        &::after {
          @include Icon("icon_arrow-right");
          font-size: rem(9);
          line-height: rem(9);
          color: $color-krp;
          position: absolute;
          top: 50%;
          right: rem(5);
          transform: translate(0, -50%);
          @include mq-up(lg) {
            font-size: rem(11, lg);
            line-height: rem(11, lg);
            right: rem(9, lg);
          }
        }
        &:hover {
          @include mq-up(lg) {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.st2-Header_SiteMap_Contents_IconLink_Access {
  display: block;
  position: absolute;
  top: 50%;
  left: rem(12);
  transform: translate(0, -50%);
  @include mq-up(lg) {
    left: rem(12, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: rem(16, lg);
  }
  &::before {
    @include Icon("icon_access");
    font-size: rem(20);
    line-height: rem(20);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(24, lg);
      line-height: rem(24, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_IconLink_Shop {
  display: block;
  position: absolute;
  top: 50%;
  left: rem(12);
  transform: translate(0, -50%);
  @include mq-up(lg) {
    left: rem(12, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: rem(16, lg);
  }
  &::before {
    @include Icon("icon_build-facility");
    font-size: rem(20);
    line-height: rem(20);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(24, lg);
      line-height: rem(24, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_IconLink_Map {
  display: block;
  position: absolute;
  top: 50%;
  left: rem(12);
  transform: translate(0, -50%);
  @include mq-up(lg) {
    left: rem(12, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: rem(16, lg);
  }
  &::before {
    @include Icon("icon_areamap");
    font-size: rem(20);
    line-height: rem(20);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(24, lg);
      line-height: rem(24, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_IconLink_Kikan {
  display: block;
  position: absolute;
  top: 50%;
  left: rem(12);
  transform: translate(0, -50%);
  @include mq-up(lg) {
    left: rem(12, lg);
  }
  @media print, screen and (min-width: $max-width) {
    left: rem(16, lg);
  }
  &::before {
    @include Icon("icon_build-public");
    font-size: rem(20);
    line-height: rem(20);
    color: $color-krp;
    @include mq-up(lg) {
      font-size: rem(24, lg);
      line-height: rem(24, lg);
    }
  }
}


.st2-Header_SiteMap_Contents_BasicLink {
  margin-top: rem(24);
  @include mq-up(lg) {
    margin-top: rem(32, lg);
  }
  ul {
    padding: 0;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-15);
    @include mq-up(lg) {
      display: block;
      margin-left: rem(0);
    }
    li {
      margin: rem(8) 0 0;
      padding: 0 0 0 rem(15);
      list-style: none;
      width: 50%;
      @include mq-up(lg) {
        margin: rem(8, lg) 0 0;
        padding: 0;
        width: 100%;
      }
      a {
        display: block;
        position: relative;
        background: #FFFFFF;
        border: 1px solid #E4E7E8;
        font-weight: 700;
        font-size: rem(11);
        line-height: rem(11);
        text-decoration: none;
        color: #333;
        padding: rem(19) rem(12);
        @include mq-up(lg) {
          font-size: rem(13, lg);
          line-height: rem(13, lg);
          padding: rem(16, lg) rem(12, lg);
          transition-duration: $transition-duration;
          transition-timing-function: $transition-timing-function;
        }
        @media print, screen and (min-width: $max-width) {
          font-size: rem(14, lg);
          line-height: rem(14, lg);
          padding: rem(20, lg) rem(16, lg);
        }
        &::after {
          @include Icon("icon_arrow-right");
          font-size: rem(9);
          line-height: rem(9);
          color: $color-krp;
          position: absolute;
          top: 50%;
          right: rem(5);
          transform: translate(0, -50%);
          @include mq-up(lg) {
            font-size: rem(11, lg);
            line-height: rem(11, lg);
            right: rem(9, lg);
          }
        }
        &:hover {
          @include mq-up(lg) {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.st2-Header_SiteMap_Contents_SnsLink {
  margin-top: rem(32);
  @include mq-up(lg) {
    margin-top: rem(20, lg);
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: nowrap;
    justify-content: center;
    align-items: flex-start;
    @include mq-up(lg) {
      justify-content: flex-start;
      margin-left: rem(-15, lg)
    }
    li {
      margin: 0 rem(18);
      padding: 0;
      list-style: none;
      @include mq-up(lg) {
        margin: 0;
        padding-left: rem(15, lg);
      }
      a {
        display: block;
        @include mq-up(lg) {
          transition-duration: $transition-duration;
          transition-timing-function: $transition-timing-function;
        }
        &:hover {
          @include mq-up(lg) {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.st2-Header_SiteMap_Contents_SnsLink_MailIcon {
  display: block;
  &::before {
    @include Icon("icon_mail");
    font-size: rem(36);
    line-height: rem(36);
    color: #1878F3;
    @include mq-up(lg) {
      font-size: rem(36, lg);
      line-height: rem(36, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_SnsLink_FacebookIcon {
  display: block;
  &::before {
    @include Icon("icon_facebook");
    font-size: rem(36);
    line-height: rem(36);
    color: #3D6CB5;
    @include mq-up(lg) {
      font-size: rem(36, lg);
      line-height: rem(36, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_SnsLink_TwitterIcon {
  display: block;
  &::before {
    @include Icon("icon_twitter");
    font-size: rem(36);
    line-height: rem(36);
    color: #000000;
    @include mq-up(lg) {
      font-size: rem(36, lg);
      line-height: rem(36, lg);
    }
  }
}
.st2-Header_SiteMap_Contents_SnsLink_InstagramIcon {
  display: block;
  &::before {
    @include Icon("icon_instagram");
    font-size: rem(36);
    line-height: rem(36);
    height: rem(38);
    padding-top: rem(2);
    background: linear-gradient(45deg, #FEC91E 0%, #FB8314 25%, #F61E04 50%, #DD0049 75%, #C0009E 100%);
    background-clip: text;
    color: transparent;
    @include mq-up(lg) {
      font-size: rem(36, lg);
      line-height: rem(36, lg);
      height: rem(38, lg);
      padding-top: rem(2, lg);
    }
  }
}
