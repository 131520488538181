/*
---
name: sw2-EventLabel
namespace: SiteWide2
category: InteractiveComponent
---

メインビジュアル用のイベント表示用パーツです。

*/
.sw2-EventLabel {
  margin: 0 rem(40);
  position: absolute;
  left: 0;
  bottom: rem(19);
  width: calc(100% - 80px);
  @include mq-up(lg) {
    margin: 0 0;
    width: rem(212, lg);
    left: auto;
    right: rem(40, lg);
    bottom: rem(40, lg);
  }
}
.sw2-EventLabel_Inner {
  background: rgba(255, 255, 255, 0.5);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  padding: rem(16) rem(20);
  border-radius: rem(5);
  backdrop-filter: blur(10px);
  @include mq-up(lg) {
    padding: rem(16, lg);
    border-radius: rem(5, lg) rem(5, lg) 0 0;
  }
}

.sw2-EventLabel_Title {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(9);
  line-height: rem(9);
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  width: rem(56);
  height: rem(56);
  line-height: rem(56);
  border-radius: 100%;
  background: #333333;
  position: absolute;
  top: rem(-28);
  left: rem(-32);
  z-index: 2;
  @include mq-up(lg) {
    font-size: rem(11, lg);
    line-height: rem(11, lg);
    width: rem(68, lg);
    height: rem(68, lg);
    line-height: rem(68, lg);
    top: rem(-20, lg);
    left: rem(-20, lg);
  }
}
.sw2-EventLabel_Img {
  display: none;
  @include mq-up(lg) {
    display: block;
  }
  a {
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }
  a:hover {
    @include mq-up(lg) {
      opacity: 0.5;
    }
  }
}
.sw2-EventLabel_Date {
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(16);

  span {
    font-size: rem(13);
    @include mq-up(lg) {
      font-size: rem(14);
    }
  }
  a {
    text-decoration: none;
    color: #333;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }
  a:hover {
    @include mq-up(lg) {
      color: $color-krp;
    }
  }
  @include mq-up(lg) {
    margin-top: rem(16, lg);
    font-size: rem(18, lg);
    line-height: rem(18, lg);
  }
}
.sw2-EventLabel_Text {
  font-weight: 400;
  font-size: rem(11);
  line-height: rem(19);
  margin-top: rem(8);
  color: #555555;
  a {
    text-decoration: none;
    color: #555555;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }
  a:hover {
    @include mq-up(lg) {
      color: $color-krp;
    }
  }
  @include mq-up(lg) {
    font-size: rem(12, lg);
    line-height: rem(21, lg);
    margin-top: rem(12, lg);
  }
}

.sw2-EventLabel_Link {
  display: none;
  @include mq-up(lg) {
    display: block;
    background: rgba(255, 255, 255, 0.4);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    padding: rem(13, lg) rem(16, lg);
    border-radius: 0 0 rem(5, lg) rem(5, lg);
    backdrop-filter: blur(10px);
    font-weight: 400;
    font-size: rem(11, lg);
    line-height: rem(11, lg);
  }
  a {
    display: block;
    position: relative;
    text-decoration: none;
    color: #333;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;

    &::before {
      content: "";
      display: inline-block;
      width: rem(9, lg);
      height: rem(9, lg);
      border-top: 1px solid $color-krp;
      border-right: 1px solid $color-krp;
      transform: rotate(45deg) translate(0, -50%);
      position: absolute;
      top: 50%;
      right: rem(0, lg);
    }
    &::after {
      content: "";
      display: inline-block;
      width: rem(40, lg);
      height: rem(1, lg);
      border-top: 1px solid $color-krp;
      position: absolute;
      top: 50%;
      margin-top: rem(1, lg);
      right: rem(-4, lg);
    }
  }
  a:hover {
    @include mq-up(lg) {
      color: $color-krp;
    }
  }
}




