@mixin sw-FormInput() {
  box-sizing: border-box;
  width: 100%;
  width: percentage(div(17, 14));
  margin-left: (((div(17, 14) * 100) - (100)) * 0.5) * -1%;
  padding: 15px 12px;
  border: 1px solid #d6d6d6;
  border-radius: $form-border-radius;
  font-size: 17px; // デザイン上は14px
  transform: scale((div(14, 17)));
  line-height: 1.8;
  vertical-align: middle;
  background-color: #fff;
  appearance: none;
  box-shadow: inset 0px 1px 2px 0px rgba(#000, 0.05);

  @include mq-up(lg) {
    padding: 10px 12px;
    font-size: rem(16, lg);
  }

  /**
   * Normalize.cssのリセットをします。
   */
  &[type="search"] {
    box-sizing: border-box;
    appearance: none;
  }

  &::placeholder {
    color: $form-placeholder-color;
    font-size: 1em;
  }

  &:focus {
    outline-width: 0;
    border-color: $form-focus-border-color;
    box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
  }

  &:disabled {
    opacity: $form-disabled-opacity;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;
  }
}
