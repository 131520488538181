/*
---
name: st-ContainerF
namespace: events
category: Layout
id: []
---
*/
.st-ContainerF {
  padding: 25px 0;
  margin-top: 40px;
  background-color: #fff;

  @include mq-up(md) {
    padding: 50px 0;
    margin-top: 80px;
  }
}

.st-ContainerF .sw-BlockA_Heading {
  margin-top: 0;
}

.st-ContainerF .st-ContainerE_Foot {
  margin-top: 12px;

  @include mq-up(md) {
    margin-top: 25px;
  }
}

.goconc .instagram-media {
  margin: auto !important;
}
