.sw-Lead_Sub {
  font-size: 1.71429rem;
  margin-bottom: 25px;
  font-weight: bold;
  color: #be3134;
  text-align: center;

  @include mq-up(md) {
    font-size: 1.875rem;
    margin-bottom: 40px;
  }
}
