/*
---
name: st-Label
namespace: Structure
category: Layout
---

ラベルモジュールを横並びにします。

```html
<div class="st-Wrapper">
<ul class="st-Label">
  <li class="st-Label_Item"><span class="sw-Label">イベント</span></li>
  <li class="st-Label_Item"><span class="sw-Label">サイエンス</span></li>
</ul>
</div>
```

ラベルを中央揃えするには`.st-Label-center`を追加します。

```html
<div class="st-Wrapper">
<ul class="st-Label st-Label-center">
  <li class="st-Label_Item"><span class="sw-Label">イベント</span></li>
  <li class="st-Label_Item"><span class="sw-Label">サイエンス</span></li>
</ul>
</div>
```

ラベルをPC以上で中央揃えするには`.st-Label-centerLg`を追加します。

```html
<div class="st-Wrapper">
<ul class="st-Label st-Label-centerLg">
  <li class="st-Label_Item"><span class="sw-Label">イベント</span></li>
  <li class="st-Label_Item"><span class="sw-Label">サイエンス</span></li>
</ul>
</div>
```
*/
.st-Label {
  display: block;
  margin-left: -7px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -10px;
  }
}

.st-Label_Item {
  display: inline-block;
  padding-left: 7px;
  vertical-align: top;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 10px;
  }
}

.st-Label-center {
  text-align: center;
}


.st-Label-centerLg {
  @include mq-up(lg) {
    text-align: center;
  }
}
