/*
---
name: st2-Wrapper
namespace: Structure2
category: Layout
---

横幅を制限します。横幅によって左右の余白がつきます。

`st2-Wrapper`の初回の子要素の上の隙間を削除します。
`-is-middle`を追加でmax-widthが970になります。

*/
.st2-Wrapper {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;

  &.-is-middle {
    max-width: 970px;
  }

  @media print, screen and (min-width: $max-width) {
    padding: 0;
  }

  & > * {
    margin-top: 0;
  }
}
