/*
---
name: sw-ImageFull
namespace: SiteWide
category: Image
id: [K2, IMG-1]
---

<span class="sw-Label">IMG-1</span>

クラス指定なしでフルードイメージになります。

```html
<div class="st-Wrapper">
<div class="st-Image">
  <img src="https://placehold.jp/300x200.png" alt="" class="">
</div>
</div>
```

画像を100%表示にします。

```html
<div class="st-Wrapper">
<div class="st-Image">
  <img src="https://placehold.jp/300x200.png" alt="" class="sw-ImageFull">
</div>
</div>
```
*/
.sw-ImageFull {
  max-width: none;
  width: 100%;
}
