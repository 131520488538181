/*
---
name: st2-LocalNav
namespace: Structure2
category: Navigation
---

ローカルナビゲーションです。

```html
<div class="st2-LocalNav" id="st2-LocalNav" aria-hidden="true">
  <div class="st2-LocalNav_Inner">
    <div class="st2-LocalNav_Heading">オフィス</div>
    <div class="st2-LocalNav_Menu">
      <ul class="st2-LocalNav_Items">
        <li class="st2-LocalNav_Item">
          <a href="#">TOP</a>
        </li>
        <li class="st2-LocalNav_Item">
          <a href="#">オフィス紹介</a>
        </li>
        <li class="st2-LocalNav_Item">
          <a href="#">選ばれる理由</a>
        </li>
        <li class="st2-LocalNav_Item">
          <a href="#">環境・サービス</a>
          <ul class="st2-LocalNav_SubItems">
            <li class="st2-LocalNav_SubItem"><a href="#">職場環境</a></li>
            <li class="st2-LocalNav_SubItem"><a href="#">インフラ・セキュリティ</a></li>
            <li class="st2-LocalNav_SubItem"><a href="#">データセンター</a></li>
            <li class="st2-LocalNav_SubItem"><a href="#">企業集積・交流</a></li>
            <li class="st2-LocalNav_SubItem"><a href="#">PR支援・企業サポート</a></li>
          </ul>
        </li>
        <li class="st2-LocalNav_Item">
          <a href="#">よくあるご質問</a>
        </li>
        <li class="st2-LocalNav_Item">
          <a href="#">お問い合わせ</a>
        </li>
      </ul>
      <ul class="st2-LocalNav_OtherItems">
        <li class="st2-LocalNav_OtherItem"><a href="#">レンタルラボはこちら</a></li>
      </ul>
    </div>
  </div>
</div>
```
*/
@keyframes st2-LocalNavshow {
  0%{
      opacity: 0;
  }  
  100%{
      opacity: 1;
  }
}
.st2-LocalNav {

  &.-is-fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow-y: auto;
    animation: st2-LocalNavshow 0.5s linear alternate;
    @include mq-up(lg) {
      overflow: visible;
    }

    .st2-LocalNav_Inner {
      &.-is-open {
        height: 100vh;
        @include mq-up(lg) {
          height: auto;
        }
      }
      @include mq-up(lg) {
        height: auto;
      }
    }

    .st2-LocalNav_SubContents {
      @include mq-up(lg) {
        top: rem(116, lg);
      }
    }
  }
}
.st2-LocalNav_Inner {
  @include mq-up(lg) {
    background-color: $color-krp;
  }
}
.st2-LocalNav_Heading {
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(16);
  padding: rem(16) rem(20);
  background-color: $color-krp;
  color: #fff;
  position: relative;
  @include mq-up(md) {
    font-size: rem(16, md);
    line-height: rem(16, md);
    padding: rem(16, md) rem(20, md);
  }
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(16, lg);
    padding: rem(20, lg) rem(30, lg);
    white-space: nowrap;
  }
  &::after {
    @include Icon("icon_arrow-down");
    font-size: rem(12);
    position: absolute;
    top: 50%;
    right: rem(20);
    transform: translate(0, -50%);
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
    @include mq-up(md) {
      font-size: rem(12, md);
      right: rem(20, md);
    }
    @include mq-up(lg) {
      display: none;
    }
  }
  &.-is-open {
    &::after {
      @include Icon("icon_arrow-down");
      transform: translate(0, -50%) rotate(-180deg);
      @include mq-up(lg) {
        display: none;
      }
    }
  }
}
.st2-LocalNav_Menu {
  display: none;
  position: relative;
  z-index: 4;
  @include mq-up(lg) {
    display: block !important;
    width: 100%;
  }
}
.st2-LocalNav_Items {
  margin: 0;
  padding: 0;
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: rem(-56, lg);
  }
  @media print, screen and (min-width: $max-width) {
    justify-content: center;
  }
}
.st2-LocalNav_Item {
  list-style: none;
  & > a {
    display: block;
    font-weight: 400;
    font-size: rem(15);
    line-height: rem(21);
    text-decoration: none;
    background-color: $color-text;
    background-image: url(/assets/img/structure/bg_local-nav3.png);
    color: #fff;
    padding: rem(14) rem(20);
    position: relative;
    border-bottom: 1px solid #393939;
    @include mq-up(md) {
      font-size: rem(15, md);
      line-height: rem(21, md);
      padding: rem(14, md) rem(20, md);
    }
    @include mq-up(lg) {
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;
      font-size: rem(13, lg);
      line-height: rem(13, lg);
      padding: rem(22, lg) rem(12, lg);
      background-color: transparent;
      background-image: none;
      border-bottom: none;
    }
    @media print, screen and (min-width: $max-width) {
      padding: rem(22, lg) rem(20, lg);
    }
    &:hover {
      @include mq-up(lg) {
        opacity: 0.5;
      }
    }
    &::after {
      @include Icon("icon_arrow-right");
      font-size: rem(12);
      position: absolute;
      top: 50%;
      right: rem(20);
      transform: translate(0, -50%);
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;
      @include mq-up(md) {
        font-size: rem(12, md);
        right: rem(20, md);
      }
      @include mq-up(lg) {
        display: none;
      }
    }

    &.st2-LocalNav_SubItems_Heading {
      &::after {
        @include Icon("icon_open-plus");
        @include mq-up(lg) {
          display: none;
        }
      }
      &.-is-open {
        &::after {
          @include Icon("icon_open-minus");
          transform: translate(0, -50%) rotate(-180deg);
          @include mq-up(lg) {
            display: none;
          }
        }
      }
    }
  }
}
.st2-LocalNav_SubItems {
  margin: 0;
  padding: 0;
  display: none;
  @include mq-up(lg) {
    display: none !important;
  }
}
.st2-LocalNav_SubItem {
  list-style: none;
  & > a {
    display: block;
    font-weight: 400;
    font-size: rem(15);
    line-height: rem(21);
    text-decoration: none;
    color: #fff;
    padding: rem(14) rem(20);
    position: relative;
    border-bottom: 1px solid #505050;
    background-color: rgba(51, 51, 51, 1);
    background-image: url(/assets/img/structure/bg_footer-registration.png);
    @include mq-up(md) {
      font-size: rem(15, md);
      line-height: rem(21, md);
      padding: rem(14, md) rem(20, md);
    }
    &::after {
      @include Icon("icon_arrow-right");
      font-size: rem(12);
      position: absolute;
      top: 50%;
      right: rem(20);
      transform: translate(0, -50%);
      @include mq-up(md) {
        font-size: rem(12, md);
        right: rem(20, md);
      }
    }
  }
}
.st2-LocalNav_OtherItems {
  margin: 0;
  padding: 0;
  @include mq-up(lg) {
    display: none !important;
  }
}
.st2-LocalNav_OtherItem {
  list-style: none;
  & > a {
    display: block;
    font-weight: 400;
    font-size: rem(15);
    line-height: rem(21);
    text-decoration: none;
    color: $color-krp;
    padding: rem(14) rem(20);
    position: relative;
    background-color: #fff;
    @include mq-up(md) {
      font-size: rem(15, md);
      line-height: rem(21, md);
      padding: rem(14, md) rem(20, md);
    }
    &::after {
      @include Icon("icon_arrow-right");
      font-size: rem(12);
      position: absolute;
      top: 50%;
      right: rem(20);
      transform: translate(0, -50%);
      @include mq-up(md) {
        font-size: rem(12, md);
        right: rem(20, md);
      }
    }
  }
}
.st2-LocalNav_SubContents {
  position: fixed;
  z-index: 3;
  left: rem(0);
  bottom: rem(0);
  width: 100%;
  @include mq-up(lg) {
    width: rem(35, lg);
    left: auto;
    bottom: auto;
    right: rem(-1, lg);
    top: rem(172, lg);
    z-index: 6;
  }
}
.-is-inquiry .st2-LocalNav_SubContents {
  display: none;
}
.-is-inquiry .st-FooterInquiry_Button {
  display: none;
}
.st2-LocalNav_SubContents_Inner {
  background-color: $color-text;
  background-image: url(/assets/img/structure/bg_local-nav3.png);
  padding: rem(16) rem(20);
  @include mq-up(lg) {
    background: none;
    padding: rem(0);
  }
}
.st2-LocalNav_SubContents_Button {
  & > a {
    display: block;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    color: $color-krp;
    font-weight: 700;
    font-size: rem(12);
    line-height: rem(12);
    padding: rem(10) rem(0);
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
    @include mq-up(lg) {
      color: #fff;
      background-color: #222;
      font-weight: 400;
      font-size: rem(14, lg);
      line-height: rem(14, lg);
      writing-mode: vertical-rl;
      padding: rem(24, lg) rem(10, lg);
    }
    &.-reverse {
      color: #fff;
      background-color: $color-krp;
    }
    &:hover {
      @include mq-up(lg) {
        opacity: 0.7;
      }
    }
  }
}
.st2-LocalNav_SubContents_Button_List {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: rem(-3);
  @include mq-up(lg) {
    display: block;
    margin-left: rem(0);
  }

  & > div {
    width: 33.333%;
    padding-left: rem(3);
    @include mq-up(lg) {
      width: auto;
      padding-left: rem(0);
    }

    &:first-child {
      a {
        @include mq-up(lg) {
          border-top: none;
        }
      }
    }

    a {
      display: block;
      background-color: #393939;
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-weight: 700;
      font-size: rem(10);
      line-height: rem(10);
      padding: rem(10) rem(0);
      transition-duration: $transition-duration;
      transition-timing-function: $transition-timing-function;

      @include mq-up(lg) {
        color: #fff;
        background-color: #222;
        font-weight: 400;
        font-size: rem(14, lg);
        line-height: rem(14, lg);
        writing-mode: vertical-rl;
        padding: rem(24, lg) rem(10, lg);
        border-top: 1px solid #fff;
      }

      &.-reverse {
        background-color: $color-krp;
      }
      &:hover {
        @include mq-up(lg) {
          opacity: 0.7;
        }
      }
    }
  }
}

