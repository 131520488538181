.st-LocalHeroA {
  position: relative;
}

.st-LocalHeroA_Image {
  display: block;
  margin-right: -20px;
  margin-left: -20px;

  & img {
    display: block;
    width: 100%;
    max-width: none;
  }

  @include mq-up(lg) {
    position: relative;
    padding-top: 494px;
    padding-top: percentage(div(494, 1600));

    & img {
      position: absolute;
      top: 0;
      left: 50%;
      min-width: 1600px;
      min-height: 494px;
      margin-left: -800px;
    }
  }

  @media print, screen and (min-width: 1600px) {
    margin-right: 0;
    margin-left: 0;

    & img {
      left: 0;
      margin-left: 0;
    }
  }
}

.st-LocalHeroA_Text {
  display: block;
  position: absolute;
  bottom: 50%;
  right: 50%;
  width: calc(100% + 30px);
  transform: translate(50%, 50%);
  font-size: rem(21);
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
  letter-spacing: 0.2em;
  color: #fff;

  html[lang="en"] & {
    letter-spacing: 0.1em;
  }

  & br {
    display: none;
  }

  @include mq-up(lg) {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    font-size: rem(38, lg);
    line-height: 1.6;
    background-color: transparent;
    background-image: none;

    & br {
      display: inline;
    }

    .st-ContentHeaderA & {
      display: none;
    }
  }
}
