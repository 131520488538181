/*
---
name: st-ButtonB
namespace: Structure
category: Layout
---

ボタンを常に1カラムでレイアウトします。

```html
<div class="st-Wrapper">
<ul class="st-ButtonB">
  <li class="st-ButtonB_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonB_Item">
    <a href="#" class="sw-Button">固定幅ボタン<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
</ul>
</div>
```
*/
.st-ButtonB {
  display: block;
  padding-left: 0;
  list-style-type: none;

  & + &,
  :not(#{&}) + & {
    margin-top: $margin-text;

    @include mq-up(lg) {
      margin-top: $margin-text-lg;
    }
  }

  .st-Heading2 + &,
  .st-Heading3 + &,
  .st-Heading4 + &,
  .st-Heading5 + &,
  .st-Lead + &,
  .st-Title + & {
    margin-top: 0;
  }

  @include mq-up(lg) {

  }
}

.st-ButtonB_Item {
  display: block;

  & + & {
    margin-top: 20px;
  }
}
