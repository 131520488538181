/*
---
name: sw-BlockA
namespace: SiteWide
category: Block
id: [C1, TH-1A, TH-1B, TH-1D, TH-1E]
---

<span class="sw-Label">TH-1A</span>

PC幅で2カラムになる場合は`.sw-BlockA`を使用します（3カラム以上の場合は`.sw-BlockB`を使用してください）。

- すべての要素を含めたバージョンです。不必要な要素を削除できます。
- 画像、見出し、ボタン、リストにリンクを設定できます。
- ボタンは`.sw-BlockA_Button-auto`を追加するとなりゆきになります。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>学会の実施事例</a>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
      <div class="sw-BlockA_ButtonArea">
        <a href="#" target="_blank" class="sw-BlockA_Button">詳細をみる<span class="sw-BlockA_External" aria-hidden="true"></span></a>
      </div>
      <ul class="sw-BlockA_Links">
        <li class="sw-BlockA_LinksItem">
          <span class="sw-BlockA_LinksIcon" aria-hidden="true"></span>
          <a class="sw-BlockA_LinksLink" href="#">テキストリンク<span class="sw-BlockA_IconExternal" aria-hidden="true"></span></a>
        </li>
        <li class="sw-BlockA_LinksItem">
          <span class="sw-BlockA_LinksIcon" aria-hidden="true"></span>
          <a class="sw-BlockA_LinksLink" href="#">テキストリンク<span class="sw-BlockA_IconPdf" aria-hidden="true"></span></a>
        </li>
      </ul>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>学会の実施事例</a>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
      <ul class="sw-BlockA_ButtonArea sw-BlockA_ButtonArea-stack">
        <li class="sw-BlockA_ButtonItem">
          <a href="#" class="sw-BlockA_Button">PDF（日本語版）<span class="sw-BlockA_RightArrow" aria-hidden="true"></span></a>
        </li>
        <li class="sw-BlockA_ButtonItem">
          <a href="#" class="sw-BlockA_Button">PDF（English版）<span class="sw-BlockA_RightArrow" aria-hidden="true"></span></a>
        </li>
      </ul>
      <ul class="sw-BlockA_Links">
        <li class="sw-BlockA_LinksItem">
          <span class="sw-BlockA_LinksIcon" aria-hidden="true"></span>
          <a class="sw-BlockA_LinksLink" href="#">テキストリンク</a>
        </li>
      </ul>
    </div>
  </li>
</ul>
</div>
```

<span class="sw-Label">TH-1B</span>

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg">
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>学会の実施事例</a>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>展示会・懇親会<br>
      改行した場合のレイアウトです</a>
      <p class="sw-BlockA_Text">お招きいただいたお客様が、満足していただける環境が揃っています。</p>
    </div>
  </li>
</ul>
</div>
```

<span class="sw-Label">TH-1C</span>

- 見出しのリンクなし
- 画像のリンクなし

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg">
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <div class="sw-BlockA_ImageArea">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </div>
      <p class="sw-BlockA_Heading">学会の実施事例</p>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
    </div>
  </li>
</ul>
</div>
```

<span class="sw-Label">TH-1D</span>

- 見出しなし
- 注釈あり

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg">
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
      <ul class="sw-BlockA_ListNote">
        <li>柔軟なレイアウト変更や、防火・防災管理のアドバイスもOK</li>
      </ul>
    </div>
  </li>
</ul>
</div>
```

<span class="sw-Label">TH-1E</span>

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg">
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>学会の実施事例</a>
      <p class="sw-BlockA_Text">手厚いサポート力で、多数の学会・展示会が実施されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
      <ul class="sw-BlockA_LabelArea">
        <li class="sw-BlockA_Label">オフィススペース</li>
        <li class="sw-BlockA_Label">実験研究スペース</li>
        <li class="sw-BlockA_Label">貸会議室</li>
        <li class="sw-BlockA_Label">飲食店</li>
      </ul>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockA">
      <a class="sw-BlockA_ImageArea" href="#">
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockA_Image">
      </a>
      <a class="sw-BlockA_Heading" href="#"><span class="sw-BlockA_HeadingIcon" aria-hidden="true"></span>展示会・懇親会<br>
      改行した場合のレイアウトです</a>
      <p class="sw-BlockA_Text">お招きいただいたお客様が、満足していただける環境が揃っています。</p>
      <ul class="sw-BlockA_LabelArea">
        <li class="sw-BlockA_Label">オフィススペース</li>
        <li class="sw-BlockA_Label">実験研究スペース</li>
        <li class="sw-BlockA_Label">貸会議室</li>
        <li class="sw-BlockA_Label">飲食店</li>
      </ul>
    </div>
  </li>
</ul>
</div>
```
*/
.sw-BlockA {

}

.sw-BlockA_ImageArea {
  display: block;
}
a.sw-BlockA_ImageArea {
  &:hover {
    opacity: $opacity;
  }
}

.sw-BlockA_Image {
  display: block;
  width: 100%;
  max-width: none;
}

.sw-BlockA_Heading {
  position: relative;
  margin-top: 10px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    margin-top: 16px;
    font-size: rem(20, lg);
  }
}

a.sw-BlockA_Heading {
  display: block;
  padding-left: 16px;
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: #d02425;
    text-decoration: none;
  }

  @include mq-up(lg) {
    padding-left: 20px;
  }
}

.sw-BlockA_HeadingIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  color: $color-brand;
  font-size: 0.7em;
}

.sw-BlockA_Text {
  margin-top: 8px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-BlockA_ButtonArea {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
  text-align: center;

  @include mq-up(lg) {
    margin-top: 30px;
    text-align: left;
  }
}

.sw-BlockA_ButtonArea-stack {

}

.sw-BlockA_ButtonItem {
  .sw-BlockA_ButtonArea-stack & + & {
    margin-top: 15px;
  }
}

.sw-BlockA_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.sw-BlockA_Button-auto {
  width: auto;
}

.sw-BlockA_External:before {
  @include sw-LinkExternal();
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-BlockA_IconPdf:before {
  @include sw-LinkPdf();
}

.sw-BlockA_IconExternal:before {
  @include sw-LinkExternal();
}

.sw-BlockA_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-BlockA_Links {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    margin-top: 10px;
    font-size: rem(16, lg);
  }
}

.sw-BlockA_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.sw-BlockA_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-BlockA_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}

.sw-BlockA_ListNote {
  margin-top: 8px;
  margin-left: 1.5em;
  padding-left: 0;
  font-size: rem(11);
  line-height: 1.6;
  list-style-type: none;

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1em;
  }

  & > li {
    position: relative;
  }

  & > li:before {
    content: "※";
    position: absolute;
    top: 0;
    left: -1.5em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*";
    left: -1em;
  }

  @include mq-up(lg) {
    font-size: rem(11, lg);
  }
}

.sw-BlockA_LabelArea {
  margin-top: 8px;
  padding-left: 0;
  list-style-type: none;
}

.sw-BlockA_Label {
  @include sw-Label();
  margin-right: 7px;
  margin-bottom: 5px;

  @include mq-up(lg) {
    margin-right: 18px;
    margin-bottom: 10px;
  }
}
