/*
---
name: sw-CardNavA
namespace: SiteWide
category: Navigation
id: [L1, NC-1, NC-2, NC-3]
---

<span class="sw-Label">NC-1</span>

- イベント・ニュース系のニュース記事で利用（イベント期間がないもの）
  - 全体ニュース
  - 再生医療>イベント一覧
  - 入居企業　など
- 縦長や横長の画像はボックスに収まるようにトリミングされます。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg">
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label sw-CardNavA_ReportLabel">イベント</span>
          </div>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          平成29年度 再生医療サポートビジネス懇話会 第2回開催後記(はんなり雑記) を掲載しました。
        </div>
        <span class="sw-CardNavA_PostDate">2017/06/16</span>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/1500x555.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label sw-CardNavA_ReportLabel">イベント</span>
          </div>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          平成29年度 再生医療サポートビジネス懇話会 第2回開催後記(はんなり雑記) を掲載しました。
        </div>
        <span class="sw-CardNavA_PostDate">2017/06/16</span>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x640.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label sw-CardNavA_ReportLabel">イベント</span>
          </div>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          平成29年度 再生医療サポートビジネス懇話会 第2回開催後記(はんなり雑記) を掲載しました。
        </div>
        <span class="sw-CardNavA_PostDate">2017/06/16</span>
      </div>
    </a>
  </li>
</ul>
</div>
```

<span class="sw-Label">NC-2</span>

- イベント・ニュース系のイベント記事で利用（イベント期間があるもの）
  - 全体ニュース
  - 再生医療＞イベント一覧
  - 入居企業　など

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg">
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">イベント</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          第1回 KRPフォーラム 「 3回でつかむ 『人工知能 × ものづくり 』 」　～ 最先端の現状を知る事例紹介 ～
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/1500x555.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">イベント</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
            <div class="sw-CardNavA_To" aria-label="から"></div>
            <div class="sw-CardNavA_DateEnd">
              10/04<span class="sw-CardNavA_DateEndWeekday">(木)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          平成29年度 再生医療サポートビジネス懇話会
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x640.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">イベント</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
            <div class="sw-CardNavA_To" aria-label="から"></div>
            <div class="sw-CardNavA_DateEnd">
              10/04<span class="sw-CardNavA_DateEndWeekday">(木)</span>
            </div>
          </div>
        </div>
        <div class="sw-CardNavA_EndLabels">
          <span class="sw-CardNavA_EndLabel">終了</span>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          E2M Square キックオフフォーラム 『AI・IoT・ビッグデータ活用の進化がもたらす 社会の変化と新ビジネス』～ロボティクス、次世代コンピューター、次世代カメラの行方～
        </div>
      </div>
    </a>
  </li>
</ul> 
</div>
```

<span class="sw-Label">NC-3</span>

- イベント・ニュース系のイベント記事で利用（イベント期間があるもの）
  - KRP WEEK
  - 公開直後は「NEW」、更新されたら「UPDATE」のラベルにします。
  - MTで`.sw-CardNavA_UpdateLabels`に`data-update-label="2018/2/16"`のように更新日を出力すると、4日以上経っている投稿のラベルが非表示になります。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg">
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">2018</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
          </div>
        </div>
        <div class="sw-CardNavA_UpdateLabels">
          <span class="sw-CardNavA_UpdateLabel">NEW</span>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          【2】京都市ベンチャー企業<br>目利き委員会20周年記念事業
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/1500x555.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">2018</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
          </div>
        </div>
        <div class="sw-CardNavA_UpdateLabels" data-update-label="2018/2/18">
          <span class="sw-CardNavA_UpdateLabel">UPDATE</span>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          【味1】かごの屋　調理体験セミナー　～「和食」を知る・つくる・食べる～
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="sw-CardNavA" href="#">
      <div class="sw-CardNavA_Head">
        <img class="sw-CardNavA_Image" src="https://placehold.jp/300x640.png" alt="">
        <div class="sw-CardNavA_Details">
          <div class="sw-CardNavA_Labels">
            <span class="sw-CardNavA_Label">2017</span>
          </div>
          <div class="sw-CardNavA_Date">
            <div class="sw-CardNavA_DataStart">
              04/24<span class="sw-CardNavA_DataStartWeekday">(月)</span>
            </div>
          </div>
        </div>
        <div class="sw-CardNavA_UpdateLabels" data-update-label="2018/2/16">
          <span class="sw-CardNavA_UpdateLabel">NEW</span>
        </div>
      </div>
      <div class="sw-CardNavA_Body">
        <div class="sw-CardNavA_Text">
          <span class="sw-CardNavA_Icon" aria-hidden="true"></span>
          【4】Girls in Tech<br>京都カタリストカンファレンス
        </div>
      </div>
    </a>
  </li>
</ul> 
</div>
```
*/
.sw-CardNavA {
  display: block;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
    color: $color-text;
  }
}

.sw-CardNavA_Head {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: percentage(div(239, 368));
  }
}

.sw-CardNavA_Body {
  margin-top: 14px;

  @include mq-up(lg) {
    margin-top: 16px;
  }
}

.sw-CardNavA_Image {
  // max-width: none;
  // width: 100%;
  border: 1px solid #e8e8e8;

  min-width: 100%;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.sw-CardNavA_Details {
  position: absolute;
  top: 11px;
  left: 11px;
  min-width: 100px;
  line-height: 1;
}

.sw-CardNavA_Labels {
}

.sw-CardNavA_Label {
  width: 100%;
  padding: 4px 7px;
  display: inline-block;
  font-size: rem(11);
  line-height: 1;
  color: #fff;
  background-color: $color-brand;
  text-align: center;
  white-space: nowrap;

  @include mq-up(lg) {
    padding: 5px 8px;
    font-size: rem(13, lg);
  }
}

.sw-CardNavA_ReportLabel {
  background-color: #CB9A50;
}

.sw-CardNavA_Date {
  padding: 7px 18px;
  color: #fff;
  background-color: rgba(#000, 0.7);

  .sw-CardNavA_Labels + & {
    margin-top: 1px;
  }

  @include mq-up(lg) {
    padding: 12px;
  }
}

.sw-CardNavA_DataStart {
  font-size: rem(14);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-CardNavA_DataStartWeekday {
  margin-left: 0.5em;
  font-size: rem(11);
  font-weight: normal;

  @include mq-up(lg) {
    font-size: rem(12, lg);
  }
}

.sw-CardNavA_To {
  margin-top: 3px;
  margin-bottom: 3px;

  &:before {
    content: "";
    display: block;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent transparent;
  }

  @include mq-up(lg) {
    margin-top: 5px;
    margin-bottom: 3px;
  }
}

.sw-CardNavA_DateEnd {
  font-size: rem(14);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-CardNavA_DateEndWeekday {
  margin-left: 0.5em;
  font-size: rem(11);
  font-weight: normal;

  @include mq-up(lg) {
    font-size: rem(12, lg);
  }
}

.sw-CardNavA_EndLabels {
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 1;

  @include mq-up(lg) {
    top: 1px;
    right: 1px;
  }
}

.sw-CardNavA_EndLabel {
  display: inline-block;
  width: 60px;
  padding: 5px 2px;
  font-size: rem(11);
  line-height: 1;
  text-align: center;
  border: 1px solid $color-brand;
  color: $color-brand;
  background-color: #fff;

  @include mq-up(lg) {
    font-size: rem(11, lg);
  }
}

.sw-CardNavA_UpdateLabels {
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 1;

  @include mq-up(lg) {
    top: 1px;
    right: 1px;
  }
}

.sw-CardNavA_UpdateLabel {
  display: inline-block;
  width: 60px;
  padding: 5px 2px;
  font-size: rem(11);
  line-height: 1;
  text-align: center;
  border: 1px solid #c5383b;
  color: #fff;
  background-color: #c5383b;

  @include mq-up(lg) {
    font-size: rem(11, lg);
  }
}

.sw-CardNavA_Text {
  position: relative;
  padding-left: 16px;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    padding-left: 20px;
    font-size: rem(17, lg);
  }

  .sw-CardNavA:hover & {
    color: #d02425;
  }
}

.sw-CardNavA_Icon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  left: 0;
  top: 0.4em;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-CardNavA_PostDate {
  display: block;
  margin-top: 10px;
  font-size: rem(14);
  line-height: 1.4;
  color: #999;

  @include mq-up(lg) {
    margin-top: 10px;
    font-size: rem(13, lg);
    color: #a3a3a3;
  }
}
