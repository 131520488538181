.st-LabPortal_Knowledge {
  & .sw-VisualNavB,
  .sw-VisualNavB_Image {
    height: auto;

    @include mq-up(lg) {
      height: 240px;
    }
  }
}
