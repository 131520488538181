/*
---
name: st-ContentHeaderC
namespace: Structure
category: Navigation
id: [K1, PT3]
---

<span class="sw-Label">PT3</span>

2階層・イベント用のコンテンツヘッダーです。

- グループD
  - イベント
  - ニュースTOP
  - 記事一覧
  - 記事詳細ページは、カテゴリリンクなし

```html
<header class="st-ContentHeaderC">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderC_Title">
      <p class="st-PageCategory" id="st-PageCategory" aria-controls="st-LocalNav4" aria-expanded="false" role="heading">
        イベント
        <span class="st-PageCategory_Small">Event</span>
        <span class="st-PageCategory_Icon" aria-hidden="true"></span>
      </p>
    </div>
    <div class="st-ContentHeaderC_Nav">
      <div class="st-LocalNav4" id="st-LocalNav4" aria-labelledby="st-PageCategory" aria-hidden="true">
        <ul class="st-LocalNav4_Items">
          <li class="st-LocalNav4_Item">
            <a href="/news/" class="st-LocalNav4_Link">すべて</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="/news/news/" class="st-LocalNav4_Link">ニュース</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="/news/media/" class="st-LocalNav4_Link">メディア掲載</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="/news/release/" class="st-LocalNav4_Link">プレスリリース</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="/pub/" class="st-LocalNav4_Link">KRP PRESS</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="" class="st-LocalNav4_Link">ご入居者さま情報</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
```
*/
.st-ContentHeaderC {

  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.st-ContentHeaderC_Title {

}

.st-ContentHeaderC_Nav {

  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.st-ContentHeaderC_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
  }
}

.st-ContentHeaderC_SubNav {
  @include mq-up(lg) {
    margin-top: 30px;
  }
}
