/*
---
name: inquiry-InputResults
namespace: inquiry
category: Form
id: FO-10
---

<span class="sw-Label">FO-10</span>

フォームの回答を表示します。

```html
<div class="st-Wrapper">
<div class="inquiry-Layout_Item">
  <p class="sw-FromLabel">タイプ</p>
  <dl class="inquiry-InputResults">
    <dt class="inquiry-InputResults_Name"></dt>
    <dd class="inquiry-InputResults_Value">お問い合わせ内容が入ります。お問い合わせ内容が入ります。お問い合わせ内容が入ります。お問い合わせ内容が入ります。お問い合わせ内容が入ります。お問い合わせ内容が入ります。お問い合わせ内容が入ります。</dd>
  </dl>
</div>
</div>
```

```html
<div class="st-Wrapper">
<div class="inquiry-Layout_Item">
  <dl class="inquiry-InputResults">
    <dt class="inquiry-InputResults_Name">タイプ：</dt>
    <dd class="inquiry-InputResults_Value">実験研究スペース（ウェットラボ仕様）</dd>
  </dl>
</div>

<div class="inquiry-Layout_Item">
  <dl class="inquiry-InputResults">
    <dt class="inquiry-InputResults_Name">面積：</dt>
    <dd class="inquiry-InputResults_Value">〜 10m²、〜 30m²、300 〜 400m²</dd>
  </dl>
</div>

<div class="inquiry-Layout_Item">
  <dl class="inquiry-InputResults">
    <dt class="inquiry-InputResults_Name">ご利用目的：</dt>
    <dd class="inquiry-InputResults_Value"></dd>
  </dl>
</div>
</div>
```
*/
.inquiry-InputResults {
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;

  & + & {
    margin-top: 20px;
  }
}

.inquiry-InputResults_Name {
  font-size: rem(17, lg);
  line-height: 1.4;
  font-weight: bold;
}

.inquiry-InputResults_Value {
  margin-top: 10px;
  font-size: rem(16, lg);
  line-height: 1.6;
}
