/*
---
name: st-ButtonC
namespace: Structure
category: Layout
---

ボタンをグリッドでレイアウトします。

- `.st-ButtonC-col2`のように1/2から1/5までの均等なカラム指定。
- `.st-ButtonC-col2Md`のように`Md`、`Lg`、`Xl`を追加してブレイクポイントの指定。

```html
<div class="st-Wrapper">
<ul class="st-ButtonC st-ButtonC-col2 st-ButtonC-col3Md st-ButtonC-col4Lg">
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成28年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成27年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成26年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成25年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成24年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
  <li class="st-ButtonC_Item">
    <a href="#" class="sw-Button">平成23年度<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
  </li>
</ul>
</div>
```
*/
$st-buttonc-column-gap: 20px;
$st-buttonc-row-gap: 20px;
$st-buttonc-column-gap-lg: 20px;
$st-buttonc-row-gap-lg: 20px;

.st-ButtonC {
  margin-left: -$st-buttonc-column-gap;
  padding-left: 0;
  list-style-type: none;
  display: block;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -$st-buttonc-column-gap-lg;
  }

  /* 同じ階層にある`.st-ButtonC`同士の間に余白を入れます。 */
  &:not(:first-of-type) {
    margin-top: $st-buttonc-row-gap-lg;

    &.st-ButtonC-narrow {
      margin-top: 1px;
    }

    @include mq-up(lg) {
      margin-top: $st-buttonc-row-gap-lg;

      &.st-ButtonC-narrow {
        margin-top: 1px;
      }
    }
  }
}

.st-ButtonC_Item {
  display: inline-block;
  width: 100%;
  padding-left: $st-buttonc-column-gap;
  font-size: medium;
  font-size: 1rem;
  vertical-align: top;

  @include mq-up(lg) {
    padding-left: $st-buttonc-column-gap-lg;
  }
}

/* 1 Column */
.st-ButtonC_Item {
  &:nth-of-type(n+2) {
    margin-top: $st-buttonc-row-gap;

    @include mq-up(lg) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}

/* 2 Columns */
.st-ButtonC-col2 > .st-ButtonC_Item {
  width: percentage(div(1, 2));

  /**
   * いったんすべての`margin-top`をリセットします。
   * `:nth-of-type(n+1)`は1つ目以降のすべての要素に適応されます。
   * リセット後に対象となる要素に`margin-top`を指定します。
   */
  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-buttonc-row-gap;
  }
}
@include mq-up(md) {
  .st-ButtonC-col2Md > .st-ButtonC_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-buttonc-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-ButtonC-col2Md > .st-ButtonC_Item:nth-of-type(n+3) {
    margin-top: $st-buttonc-row-gap-lg;
  }
  .st-ButtonC-col2Lg > .st-ButtonC_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-ButtonC-col2Xl > .st-ButtonC_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}

/* 3 Columns */
.st-ButtonC-col3 > .st-ButtonC_Item {
  width: percentage(div(1, 3));

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+4) {
    margin-top: $st-buttonc-row-gap;
  }
}
@include mq-up(md) {
  .st-ButtonC-col3Md > .st-ButtonC_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-buttonc-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-ButtonC-col3Md > .st-ButtonC_Item:nth-of-type(n+4) {
    margin-top: $st-buttonc-row-gap-lg;
  }
  .st-ButtonC-col3Lg > .st-ButtonC_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-ButtonC-col3Xl > .st-ButtonC_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}

/* 4 Columns */
.st-ButtonC-col4 > .st-ButtonC_Item {
  width: percentage(div(1, 4));

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+5) {
    margin-top: $st-buttonc-row-gap;
  }
}
@include mq-up(md) {
  .st-ButtonC-col4Md > .st-ButtonC_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-buttonc-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-ButtonC-col4Md > .st-ButtonC_Item:nth-of-type(n+5) {
    margin-top: $st-buttonc-row-gap-lg;
  }
  .st-ButtonC-col4Lg > .st-ButtonC_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-ButtonC-col4Xl > .st-ButtonC_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-buttonc-row-gap-lg;
    }
  }
}
