.st-HeaderNav_Items {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    font-size: 0;
    float: right;
    margin-right: 20px;

    .st-Header-fixedActive & {
      padding: 20px 0;
    }
  }

  @media print, screen and (min-width: 1300px) { 
    html[lang="en"] &,
    html[lang="zh-cmn-Hans"] &,
    html[lang="ko"] & {
      margin-top: 9px;
    }
  }
}

.st-HeaderNav_Item {
  border-bottom: 1px solid #aaa;

  &:last-of-type {
    display: none;
  }

  @include mq-up(lg) {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 0;
    text-align: left;

    &:last-of-type {
      display: table-cell;

      .st-Header-fixedActive & {
        display: none;
      }
    }

    & + &:before {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      border-left: 1px solid #e2e2e2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}

.st-HeaderNav_Link {
  position: relative;
  display: block;
  padding: 16px 40px 16px 20px;
  font-size: rem(15);
  line-height: 1.2;
  color: #fff;
  text-decoration: none;

  @media print, screen and (min-width: 980px) {
    position: static;
    padding: 0 14px;
    font-size: rem(12, lg);
    font-weight: bold;
    text-align: center;
    color: $color-text;

    &:after {
      display: none;
      width: 1em;
      height: 1em;
      margin: auto;
    }

    .st-HeaderNav_Item:last-of-type & {
      display: inline-block;
    }

    .st-HeaderNav_Item_LabPortal:last-of-type & {
      display: inline-block;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      border-bottom: 4px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      bottom: -31px;
      width: 100%;
      height: 4px;
      border-bottom-color: $color-brand;
    }

    &.st-HeaderNav_Link-current:after {
      content: "";
      position: absolute;
      bottom: -31px;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 4px;
      border-bottom: 4px solid $color-brand;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -32px;
      }
    }

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -38px;
      }
    }

    .st-Header-fixedActive &:hover:after,
    .st-Header-fixedActive &.st-HeaderNav_Link-current:after {
      bottom: -20px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -35px;
      }
    }
  }

  @media print, screen and (min-width: 1200px) {
    font-size: rem(13, lg);

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      bottom: -24px;
    }

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -32px;
      }
    }

    .st-Header-fixedActive &:hover:after,
    .st-Header-fixedActive &.st-HeaderNav_Link-current:after {
      bottom: -14px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -32px;
      }
    }
  }

  @media print, screen and (min-width: 1200px) {
    font-size: rem(15, lg);

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      bottom: -23px;
    }

    .st-Header-fixedActive &:hover:after,
    .st-Header-fixedActive &.st-HeaderNav_Link-current:after {
      bottom: -14px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -23px;
      }
    }
  }

  @media print, screen and (min-width: 1300px) {

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      bottom: -29px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -29px;
      }
    }

    .st-Header-fixedActive &:hover:after,
    .st-Header-fixedActive &.st-HeaderNav_Link-current:after {
      bottom: -14px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -23px;
      }
    }
  }

  @include mq-up(xl) {
    padding: 3px 20px;

    &:hover:after,
    &.st-HeaderNav_Link-current:after {
      bottom: -18px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -24px;
      }
    }

    .st-Header-fixedActive &:hover:after,
    .st-Header-fixedActive &.st-HeaderNav_Link-current:after {
      bottom: -7px;

      html[lang="en"] &,
      html[lang="zh-cmn-Hans"] &,
      html[lang="ko"] & {
        bottom: -24px;
      }
    }
  }
}

.st-HeaderNav_Link-icon {
  &:hover {
    opacity: $opacity;
  }
  
  &:after {
    display: none;
  }

  @include mq-up(lg) {
    padding: 0 0 0 13px;

    & + & {
      padding-left: 8px;
    }
  }
}


.st-HeaderNav_Icon {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;

  &:before {
    @include Icon("icon_arrow-right");

    html[lang="ja"] & {
      position: relative;
      top: -0.25em;
    }

    @include mq-up(lg) {
      display: none;
    }
  }
}

.st-HeaderNav_Delimiter {
  @include mq-up(lg) {
    display: block;
  }
}

.st-HeaderNav_MailIcon {
  @include mq-up(lg) {
    font-size: 25px;
    color: #1877f2;

    &:before {
      @include Icon("icon_mail");
    }
  }
}


.st-HeaderNav_FacebookIcon {
  @include mq-up(lg) {
    font-size: 25px;
    color: #007dd5;

    &:before {
      @include Icon("icon_facebook");
    }
  }
}

.st-HeaderNav_TwitterIcon {
  @include mq-up(lg) {
    font-size: 25px;
    color: #1da1f2;

    &:before {
      @include Icon("icon_twitter");
    }
  }
}


//メガメニュー
.st-HeaderNav_Child {
  display: none;
  @include mq-up(lg) {
    // display: block;
    padding: 38px 0 65px 0;
    width: 100%;
    background-color: rgba(16, 13, 13, 0.85);
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 2;

    .st-Header-fixedActive & {
      top: 70px;
    }
    &.is-open {
      // display: block;
    }
  }
  @media print, screen and (min-width: 1300px) {
    top: 106px;
  }
}

.st-HeaderNavChild_Item {
  display: none;
  max-width: $max-width + 40;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
  &.is-open {
    display: block;
  }
  @media print, screen and (min-width: $max-width) {
    // padding: 0;
  }
}

.st-HeaderNavChild_Head {
  @include mq-up(lg) {
    margin-bottom: 30px;
    padding-bottom: 16px;
    font-size: rem(26, lg);
    border-bottom: #555555 1px solid;
    position: relative;
    & > a {
      padding-left: 38px;
      color: #FFF;
      line-height: 1.2;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.st-HeaderNavChild_body {
  display: flex;
  &.st-HeaderNavChild_bodyA {
    padding-top: 20px;
  }
  &.st-HeaderNavChild_bodyB {
    padding-top: 0;
  }
}

.st-HeaderNavChildTitle_Icon:before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  @include Icon("icon_arrow-circle-right");
  @include mq-up(lg) {
    font-size: 1em;
  }
}

.st-HeaderNavChild_ImageA {
  display: flex;
  margin-right: percentage(div(50, 1170));
  padding-left: 0;
  list-style-type: none;
  &.st-HeaderNavChild_ImageA-col3 {
    width: percentage(div(900, 1170));
  }
  &.st-HeaderNavChild_ImageA-col2 {
    width: percentage(div(590, 1170));
  }
}

.st-HeaderNavChildImageA_Item {
  padding-left: 10px;
  .st-HeaderNavChild_ImageA-col3 & {
    width: percentage(div(280, 900));
    &:not(:last-child) {
      margin-right: percentage(div(40, 900));
    }
  }
  .st-HeaderNavChild_ImageA-col2 & {
    width: percentage(div(280, 590));
    &:not(:last-child) {
      margin-right: percentage(div(40, 590));
    }
  }
}

.st-HeaderNavChildImageA_Icon:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  @include Icon("icon_arrow-right");
  @include mq-up(lg) {
    font-size: 0.9em;
  }
}

.st-HeaderNavChildImageA_Link {
  display: block;
  position: relative;
  text-decoration: none;
  transition-duration: $transition-duration;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.st-HeaderNavChildImageA_Link2 {
  display: block;
  position: relative;
  text-decoration: none;
  margin-top: 20px;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.st-HeaderNavChildImageA_Head {
  padding: 10px 20px;
  font-size: rem(14, lg);
  color: #FFF;
  background-color: $color-krp;
  width: 237px;
  position: absolute;
  top: -20px;
  left: -10px;
}

.st-HeaderNavChildImageA_Head2 {
  padding: 10px 20px;
  font-size: rem(14, lg);
  color: #FFF;
  background-color: $color-krp;
  width: 237px;
  position: absolute;
  top: 0px;
  left: -10px;
}

.st-HeaderNavChildImageA_Body {
}

.st-HeaderNavChildImageA_Body2 {
 padding-top:20px;
}

.st-HeaderNavChild_ImageB {
  margin-right: percentage(div(50, 1170));
  width: percentage(div(541, 1170));
}


.st-HeaderNavChild_Listtext {
  padding-left: 0;
  list-style-type: none;
  display: block;
  font-size: rem(13, lg);
  &:not(:last-child) {
    margin-right: 30px;
  }
  .st-HeaderNavChildListtext_Child {
    padding-left: 20px;
  }
}

.st-HeaderNavChildListtext_Item {
}

.st-HeaderNavChildListtext_Link {
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding: 0 0 0 18px;
  color: #FFF;
  line-height: 1.2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.st-HeaderNavChildListtext_Icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  @include Icon("icon_arrow-right");
  @include mq-up(lg) {
    font-size: 0.9em;
  }
}

.st-HeaderNavChild_List {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(18, lg);
  color: #FFF;
  width: percentage(div(580, 1170));
}

.st-HeaderNavChildList_item {
  margin-bottom: 20px;
}

.st-HeaderNavChildList_Link {
  padding: 10px 20px;
  display: block;
  color: #FFF;
  background-color: $color-krp;
  text-decoration: none;
  transition-duration: $transition-duration;
  position: relative;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  .st-HeaderNavChildList_item-search & {
    padding-left: 55px;
  }
}


.st-HeaderNavChildList_Icon:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  @include Icon("icon_arrow-right");
  @include mq-up(lg) {
    font-size: 0.9em;
  }
}

.st-HeaderNavChildList_IconSearch:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  @include Icon("icon_search");
  @include mq-up(lg) {
    font-size: 1.4em;
  }
}
