/*
---
name: serviceoffice-CardNavA
namespace: serviceoffice
category: Navigation
id: [NC-5A]
---

<span class="sw-Label">NC-5A</span>

- serviceoffice以下（BIZ NEXT）で使用
- 縦長や横長の画像はボックスに収まるようにトリミングされます。
- カラムは`.st-Grids`で指定してください。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col4Lg">
  <li class="st-Grids_Item">
    <a class="serviceoffice-CardNavA" href="#">
      <div class="serviceoffice-CardNavA_Head">
        <img class="serviceoffice-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="serviceoffice-CardNavA_Details">
          <ul class="serviceoffice-CardNavA_LabelList">
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-office">オフィス会員</span>
            </li>
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-lounge">ラウンジ会員</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="serviceoffice-CardNavA_Body">
        <div class="serviceoffice-CardNavA_Text">
          <span class="serviceoffice-CardNavA_Icon" aria-hidden="true"></span>
          起業・独立のための仕事場・レンタルオフィスを探して
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="serviceoffice-CardNavA" href="#">
      <div class="serviceoffice-CardNavA_Head">
        <img class="serviceoffice-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="serviceoffice-CardNavA_Details">
          <ul class="serviceoffice-CardNavA_LabelList">
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-lounge">ラウンジ会員</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="serviceoffice-CardNavA_Body">
        <div class="serviceoffice-CardNavA_Text">
          <span class="serviceoffice-CardNavA_Icon" aria-hidden="true"></span>
          京都市内で使える打ち合わせスペースを探している
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="serviceoffice-CardNavA" href="#">
      <div class="serviceoffice-CardNavA_Head">
        <img class="serviceoffice-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="serviceoffice-CardNavA_Details">
          <ul class="serviceoffice-CardNavA_LabelList">
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-office">オフィス会員</span>
            </li>
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-lounge">ラウンジ会員</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="serviceoffice-CardNavA_Body">
        <div class="serviceoffice-CardNavA_Text">
          <span class="serviceoffice-CardNavA_Icon" aria-hidden="true"></span>
          京都に営業所・支店開設を検討している
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a class="serviceoffice-CardNavA" href="#">
      <div class="serviceoffice-CardNavA_Head">
        <img class="serviceoffice-CardNavA_Image" src="https://placehold.jp/300x200.png" alt="">
        <div class="serviceoffice-CardNavA_Details">
          <ul class="serviceoffice-CardNavA_LabelList">
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-office">オフィス会員</span>
            </li>
            <li class="serviceoffice-CardNavA_LabelItem">
              <span class="serviceoffice-CardNavA_Label serviceoffice-CardNavA_Label-lounge">ラウンジ会員</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="serviceoffice-CardNavA_Body">
        <div class="serviceoffice-CardNavA_Text">
          <span class="serviceoffice-CardNavA_Icon" aria-hidden="true"></span>
          セキュリティが高いサービスオフィスを探している
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```
*/
.serviceoffice-CardNavA {
  display: block;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
    color: $color-text;
  }
}

.serviceoffice-CardNavA_Head {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: percentage(div(239, 368));
  }
}

.serviceoffice-CardNavA_Body {
  margin-top: 14px;

  @include mq-up(lg) {
    margin-top: 16px;
  }
}

.serviceoffice-CardNavA_Image {
  // max-width: none;
  // width: 100%;
  border: 1px solid #e8e8e8;

  min-width: 100%;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.serviceoffice-CardNavA_Details {
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 1;
}

.serviceoffice-CardNavA_LabelList {
  padding-left: 0;
  list-style-type: none;
}

.serviceoffice-CardNavA_LabelItem {
  display: block;

}

.serviceoffice-CardNavA_Label {
  width: rem(120);
  padding: 5px 0;
  display: inline-block;
  font-size: rem(10);
  line-height: 1;
  color: #fff;
  background-color: $color-brand;
  text-align: center;
  white-space: nowrap;

  @include mq-up(lg) {
    width: rem(120, lg);
    padding: 6px 0;
    font-size: rem(11, lg);
  }
}

.serviceoffice-CardNavA_Label-office {
  background-color: #382217;
}

.serviceoffice-CardNavA_Label-lounge {
  background-color: #684005;
}

.serviceoffice-CardNavA_Label-lounge2 {
  background-color: #97560D;
}

.serviceoffice-CardNavA_Text {
  position: relative;
  padding-left: 16px;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    padding-left: 20px;
    font-size: rem(17, lg);
  }

  .serviceoffice-CardNavA:hover & {
    color: #d02425;
  }
}

.serviceoffice-CardNavA_Icon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  left: 0;
  top: 0.4em;
  font-size: 0.8em;
  color: $color-brand;
}

.serviceoffice-CardNavA_PostDate {
  display: block;
  margin-top: 10px;
  font-size: rem(14);
  line-height: 1.4;
  color: #999;

  @include mq-up(lg) {
    margin-top: 10px;
    font-size: rem(13, lg);
    color: #a3a3a3;
  }
}
