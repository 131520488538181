/*
---
name: sw-Map
namespace: SiteWide
category: ThirdPartyComponent
id: [C3, MAP-1, MAP-2]
---

<span class="sw-Label">MAP-1</span>

Google Mapsとその情報を載せるモジュールです。

- 印刷ボタンの直近の親要素`.js-print-area`が印刷されます。
- `.js-print-additional-area`で囲むと1箇所だけ印刷範囲を加えることができます。

- カラムは7:5で表示されます。

```html
<div class="js-print-area">
<div class="st-Wrapper">
  <div class="sw-Map sw-Map-col7to5Lg">
    <div class="sw-Map_MapArea">
      <div class="sw-Map_Map sw-Map_Map-1to1">
        <div id="mapFromKyotoStation"></div>
      </div>
    </div>
    <div class="sw-Map_Body">
      <p class="sw-Map_Text">
        <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_TrainIcon" aria-hidden="true"></span>電車</span>
        京都駅よりJR嵯峨野線(山陰線)1駅<br>
      丹波口駅下車　西へ徒歩5分
      </p>
      <p class="sw-Map_Text">
        <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_TaxiIcon" aria-hidden="true"></span>タクシー　約10分</span>
      </p>
      <p class="sw-Map_Text">
        <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_BusIcon" aria-hidden="true"></span>市バス　乗り場C5</span>
        73系統 「洛西バスターミナル」行き<br>
        75系統 「映画村・山越」行き(所要時間約15分)<br>
        「京都リサーチパーク前」下車　西へ徒歩5分
      </p>
      <p class="sw-Map_Text">
        <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_BusIcon" aria-hidden="true"></span>京都バス　乗り場C2</span>
        84系統 「大覚寺・清滝」行き(所要時間約15分)<br>
        「京都リサーチパーク前」下車　西へ徒歩5分
      </p>
      <p class="sw-Map_Text">
        <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_BusIcon" aria-hidden="true"></span>京阪京都交通バス　乗り場C2</span>
        21系統、27系統　「桂坂中央」行き (所要時間約15分)<br>
        「京都リサーチパーク前」下車　西へ徒歩5分
      </p>
      <ul class="sw-Map_ButtonArea sw-Map_ButtonArea-centerLg">
        <li class="sw-Map_ButtonItem"><button type="button" class="sw-Map_Button sw-Map_Button-auto js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button></li>
        <li class="sw-Map_ButtonItem"><a href="#" class="sw-Map_Button sw-Map_Button-auto"><span class="sw-Map_MapIcon" aria-hidden="true"></span>Google Mapで見る</a></li>
      </ul>
    </div>
  </div>
</div>
</div>
```

<span class="sw-Label">MAP-2</span>

- カラムは6:6で表示されます。

```html
<div class="js-print-area">
<div class="st-Wrapper">
  <div class="sw-Map sw-Map-col6to6Lg js-print-area">
    <div class="sw-Map_MapArea">
      <div class="sw-Map_Map sw-Map_Map-16to9">
        <div id="mapFromHankyuSaiin"></div>
      </div>
    </div>
    <div class="sw-Map_Body">
      <p class="sw-Map_Text">
      <span class="sw-Map_Caution">阪急西院駅より</span><br>
      <span class="sw-Map_Icon"><span class="sw-Map_BusIcon" aria-hidden="true"></span>京都市立病院送迎バス（所要時間約10分）</span>
      「ユニクロ前」　下車 西へ徒歩5分<br>
      <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_WalkIcon" aria-hidden="true"></span>徒歩　約20分</span>
      <span class="sw-Map_Caution sw-Map_Icon"><span class="sw-Map_TaxiIcon" aria-hidden="true"></span>タクシー　約10分</span>
      </p>
      <ul class="sw-Map_Text sw-Map_Links">
        <li class="sw-Map_LinksItem">
          <span class="sw-Map_LinksIcon" aria-hidden="true"></span>
          <a class="sw-Map_LinksLink" href="#" target="_blank">阪急西院駅　時刻表<span class="sw-Map_LinkExternal" aria-hidden="true"></a>
        </li>
      </ul>
      <ul class="sw-Map_ButtonArea">
        <li class="sw-Map_ButtonItem"><button type="button" class="sw-Map_Button sw-Map_Button-auto js-print-button"><span class="sw-Map_PrintIcon" aria-hidden="true"></span>印刷する</button></li>
        <li class="sw-Map_ButtonItem"><a href="#" class="sw-Map_Button sw-Map_Button-auto"><span class="sw-Map_MapIcon" aria-hidden="true"></span>Google Mapで見る</a></li>
      </ul>
    </div>
  </div>
</div>
</div>
```
*/
.sw-Map {
  display: block;
  margin-left: -15px;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.sw-Map-col7to5Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(7, 12)) };
    & > *:nth-of-type(even) { width: percentage(div(5, 12)) };
  }
}

.sw-Map-col6to6Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(6, 12)) };
    & > *:nth-of-type(even) { width: percentage(div(6, 12)) };
  }
}

.sw-Map_MapArea {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;
  }
}

.sw-Map_Body {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: 26px;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    margin-top: 0;
    padding-left: 30px;
  }
}

.sw-Map_Map {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(div(9, 16));

  & > *,
  iframe,
  embed,
  object,
  video {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
}

.sw-Map_Map-1to1 {
  padding-bottom: 100%;
}

.sw-Map_Map-4to3 {
  padding-bottom: percentage(div(3, 4));
}

.sw-Map_Map-3to2 {
  padding-bottom: percentage(div(2, 3));
}

.sw-Map_Text {
  font-size: 1rem;
  line-height: 1.8;

  & + & {
    margin-top: 26px;

    @include mq-up(lg) {
      margin-top: 20px;
    }
  }
}

.sw-Map_Caution {
  font-weight: bold;
}

.sw-Map_Icon {
  position: relative;
  display: block;
  padding-left: 20px;
}

.sw-Map_TrainIcon:before {
  @include Icon("icon_access-train");
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 1.25em;
}

.sw-Map_BusIcon:before {
  @include Icon("icon_access-bus");
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 1.2em;
}

.sw-Map_TaxiIcon:before {
  @include Icon("icon_access-taxi");
  position: absolute;
  left: 0;
  top: 0.4em;
  font-size: 1em;
}

.sw-Map_WalkIcon:before {
  @include Icon("icon_access-walk");
  position: absolute;
  left: 0;
  top: 0.3em;
  font-size: 1em;
}

.sw-Map_ButtonArea {
  margin-top: 40px;
  padding-left: 0;
  list-style-type: none;
  text-align: center;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq-up(lg) {
    margin-top: 30px;
    text-align: left;
  }
}

.sw-Map_ButtonItem {
  display: block;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    float: left;

    & + & {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.sw-Map_ButtonArea-centerLg {
  @include mq-up(lg) {
    & > .sw-Map_ButtonItem {
      float: none;

      & + .sw-Map_ButtonItem {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}

.sw-Map_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.sw-Map_Button-auto {
  width: auto;
}

.sw-Map_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-Map_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-Map_PrintIcon:before {
  @include Icon("icon_print");
  position: relative;
  top: -0.1em;
  left: 0;
  margin-right: 8px;
  font-size: 1.2em;
}

.sw-Map_MapIcon:before {
  @include Icon("icon_access-pin");
  position: relative;
  top: -0.1em;
  left: 0;
  margin-right: 8px;
  font-size: 1em;
}

.sw-Map_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(16, lg);
  line-height: 1.4;
}

.sw-Map_LinksItem {
  position: relative;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }
}

.sw-Map_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-Map_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}

.sw-Map_LinkExternal:before {
  @include Icon("icon_windows");
  margin-left: 5px;
  vertical-align: baseline;
  color: $color-brand;
}
