/*
---
name: sw2-CatchImage
namespace: SiteWide2
category: InteractiveComponent
---

コンテンツキャッチ用です。

```html
<div class="st2-Block">
  <section class="sw2-CatchImage" id="catch01">
    <div class="sw2-CatchImage_Image">
      <picture>
        <source srcset="/assets/img/about/index/catch_01.jpg" media="(min-width: 768px)"/>
        <img src="/assets/img/about/index/catch_01_sp.jpg" alt="" loading="lazy">
      </picture>
    </div>
    <div class="sw2-CatchImage_Data">
      <h2 class="sw2-CatchImage_Heading">集まるための空間</h2>
      <div class="sw2-CatchImage_Text">さまざまなプレイヤーが集まるための<br>上質で充実したビジネス空間</div>
    </div>
    <div class="sw2-CatchImage_Point">集</div>
  </section>
</div>
```
*/
.sw2-CatchImage {
  margin-left: rem(-20);
  margin-right: rem(-20);
  position: relative;
  @include mq-up(lg) {
    margin-left: rem(-50, lg);
    margin-right: rem(-50, lg);
  }
}
.sw2-CatchImage_Image {
  img {
    width: 100%;
  
    @include mq-up(md) {
      height: rem(280, md);
      object-fit: cover;
    }
    @include mq-up(lg) {
      height: rem(280, lg);
    }
  }
}
.sw2-CatchImage_Data {
  position: absolute;
  bottom: rem(20);
  left: rem(20);
  @include mq-up(md) {
    bottom: rem(40, md);
    left: rem(50, md);
  }
  @include mq-up(lg) {
    bottom: rem(40, lg);
    left: rem(50, lg);
  }
}
.sw2-CatchImage_Heading {
  font-weight: 700;
  font-size: rem(24);
  line-height: rem(33);
  color: #fff;
  & > br {
    display: none;
    @include mq-up(md) {
      display: initial;
    }
  }
  @include mq-up(md) {
    font-size: rem(32, md);
    line-height: rem(45, md);
  }
  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(45, lg);
  }
}
.sw2-CatchImage_Text {
  font-weight: 700;
  font-size: rem(15);
  line-height: rem(21);
  margin-top: rem(15);
  color: #fff;
  @include mq-up(md) {
    font-size: rem(22, md);
    line-height: rem(31, md);
    margin-top: rem(20, md);
  }
  @include mq-up(lg) {
    font-size: rem(22, lg);
    line-height: rem(31, lg);
    margin-top: rem(20, lg);
  }

  .-is-brSpNone {
    display: none;
    @include mq-up(md) {
      display: initial;
    }
  }
}
.sw2-CatchImage_Point {
  font-family: $font-family-noto-serif-jp;
  font-weight: 200;
  font-size: rem(120);
  line-height: rem(120);
  position: absolute;
  top: rem(-10);
  right: rem(20);
  color: #fff;
  opacity: 0.3;
  @include mq-up(md) {
    font-size: rem(200, md);
    line-height: rem(200, md);
    top: rem(-22, md);
    right: rem(50, md);
  }
}
