/*
---
name: sw-Flow
namespace: SiteWide
category: Block
id: [C3, Fl-1]
---

<span class="sw-Label">Fl-1</span>


```html
<div class="st-Wrapper">
  <ol class="sw-Flow_Items">
    <li class="sw-Flow_Item">
      <div class="sw-Flow_Head">
        <img class="sw-Flow_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-Flow_Body">
        <p class="sw-Flow_Text">研究会に参加することで市場の動向やニーズについて情報収集し、自社の可能性を探る。</p>
      </div>
    </li>
    <li class="sw-Flow_Item">
      <div class="sw-Flow_Head">
        <span class="sw-Flow_Icon" aria-hidden="true"></span>
        <img class="sw-Flow_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-Flow_Body">
        <p class="sw-Flow_Text">事業化の課題となる“不足したリソース”や“専門知識”をE2プラットフォームのネットワークで解決。</p>
      </div>
    </li>
    <li class="sw-Flow_Item">
      <div class="sw-Flow_Head">
        <span class="sw-Flow_Icon" aria-hidden="true"></span>
        <img class="sw-Flow_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-Flow_Body">
        <p class="sw-Flow_Text">課題を抱えるクライアントに対して、磨き上げた技術を提案。技術採用や新規事業化へ。</p>
      </div>
    </li>
  </ol>
</div>
```
*/
.sw-Flow_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -54px;
  }
}

.sw-Flow_Item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;

  & + & {
    margin-top: 57px;
  }

  @include mq-up(lg) {
    position: static;
    width: percentage(div(1, 3));
    padding-left: 54px;

    & + & {
      margin-top: 0;
    }
  }
}

.sw-Flow_Head {
  @include mq-up(lg) {
    position: relative;
  }
}

.sw-Flow_Image {
  display: block;
  max-width: none;
  width: 100%;
}

.sw-Flow_Body {
  margin-top: 10px;

  @include mq-up(lg) {
    margin-top: 8px;
  }
}

.sw-Flow_Text {
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: 1.8;
  }
}

.sw-Flow_Icon:before {
  @include Icon("icon_flow-arrow");
  position: absolute;
  top: -48px;
  right: 0;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 2.6em;
  color: $color-brand;
  transform: rotate(90deg);

  @include mq-up(lg) {
    top: 0;
    right: auto;
    bottom: 0;
    left: -45px;
    font-size: 2.2em;
    transform: rotate(0deg);
  }
}
