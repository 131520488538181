/*
---
name: sw-LinkExternal
namespace: SiteWide
category: Link
---

外部リンクのときに追加します。

```html
<div class="st-Wrapper">
<p>ホール・会議室についてのご質問・資料請求は<a href="#" class="sw-Link" target="_blank">http://www.nijo-nazuna.jp/<span class="sw-LinkExternal" aria-hidden="true"></a>から</p>
</div>
```
*/
.sw-LinkExternal:before {
  @include sw-LinkExternal();
}
