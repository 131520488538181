/*
---
name: sw-VisualNavA
namespace: SiteWide
category: Navigation
id: [L1, CA-1]
---

<span class="sw-Label">CA-1</span>

- オフィスTOPのみで利用

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg">
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavA">
      <img src="/assets/img/_events/event/image01.jpg" alt="" class="sw-VisualNavA_Image">
      <div class="sw-VisualNavA_Ovelay"></div>
      <div class="sw-VisualNavA_Item">
        <div class="sw-VisualNavA_Text">レンタルオフィスを探している方</div>
        <div class="sw-VisualNavA_ButtonArea">
          <span class="sw-VisualNavA_Button">
            オフィススペース
            <span class="sw-VisualNavA_ButtonIcon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-VisualNavA">
      <img src="/assets/img/_events/event/image02.jpg" alt="" class="sw-VisualNavA_Image">
      <div class="sw-VisualNavA_Ovelay"></div>
      <div class="sw-VisualNavA_Item">
        <div class="sw-VisualNavA_Text">レンタルオフィスを探している方</div>
        <div class="sw-VisualNavA_ButtonArea">
          <span class="sw-VisualNavA_Button">
            オフィススペース
            <span class="sw-VisualNavA_ButtonIcon" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```

*/
.sw-VisualNavA {
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.sw-VisualNavA_Image {
  display: block;
  width: 100%;
  max-width: none;
}

.sw-VisualNavA_Ovelay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.5);
}

.sw-VisualNavA_Item {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 100%;
  padding: 0 15px;
  text-align: center;

  @include mq-up(lg) {
    padding: 0 30px;
  }
}

.sw-VisualNavA_Text {
  margin-top: 10px;
  font-size: rem(18);
  line-height: 1.6;

  @include mq-up(lg) {
    margin-top: 20px;
    font-size: rem(22, lg);
  }
}

.sw-VisualNavA_ButtonArea {
  margin-top: 25px;
}

.sw-VisualNavA_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: #fff;
    background-color: $color-brand;
  }
}

.sw-VisualNavA_ButtonIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}
