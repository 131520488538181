/*
---
name: sw-LinkPdf
namespace: SiteWide
category: Link
---

外部リンクのときに追加します。

```html
<div class="st-Wrapper">
<p>会議施設案内パンフレット<a href="#" class="sw-Link" target="_blank">http://www.nijo-nazuna.jp/<span class="sw-LinkPdf" aria-hidden="true"></a>から</p>
</div>
```
*/
.sw-LinkPdf:before {
  @include sw-LinkPdf();
}
