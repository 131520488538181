/*
---
name: sw-FormRadio
namespace: SiteWide
category: Form
id: FO-5
---

<span class="sw-Label">FO-5</span>

`type="radio"`を縦にレイアウトします。

```html
<div class="st-Wrapper">
<fieldset class="inquiry-Layout_Item">
  <ul class="inquiry-Layout_Stack inquiry-Layout_Stack-radio">
    <li class="inquiry-Layout_StackItem">
      <input type="radio" id="howDidYouKnow1" class="sw-FormRadio" name="howDidYouKnow">
      <label for="howDidYouKnow1">Google</label>
    </li>
    <li class="inquiry-Layout_StackItem">
      <input type="radio" id="howDidYouKnow2" class="sw-FormRadio" name="howDidYouKnow">
      <label for="howDidYouKnow2">Yahoo!</label>
    </li>
    <li class="inquiry-Layout_StackItem">
      <input type="radio" id="howDidYouKnow3" class="sw-FormRadio" name="howDidYouKnow">
      <label for="howDidYouKnow3">Facebook</label>
    </li>
  </ul>
</fieldset>
</div>
```

`type="radio"`をグリッドでレイアウトします。

```html
<div class="st-Wrapper">
<fieldset class="inquiry-Layout_Item">
  <ul class="inquiry-Layout_Horizontal inquiry-Layout_Horizontal-radio">
    <li class="inquiry-Layout_HorizontalItem">
      <input type="radio" id="howDidYouKnow4" class="sw-FormRadio" name="howDidYouKnow2">
      <label for="howDidYouKnow4">Google</label>
    </li>
    <li class="inquiry-Layout_HorizontalItem">
      <input type="radio" id="howDidYouKnow5" class="sw-FormRadio" name="howDidYouKnow2">
      <label for="howDidYouKnow5">Yahoo!</label>
    </li>
    <li class="inquiry-Layout_HorizontalItem">
      <input type="radio" id="howDidYouKnow6" class="sw-FormRadio" name="howDidYouKnow2">
      <label for="howDidYouKnow6">Facebook</label>
    </li>
  </ul>
</fieldset>
</div>
```
*/
.sw-FormRadio {
  @include sw-FormRadio()
}
