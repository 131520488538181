/*
---
name: Area
namespace: WISYWIG
category: Block
---

WISYWIG（ウィジウィグ）で入力した要素に対するスタイル。

```html
<div class="wisywig-Area st-Wrapper">
<h2>これはこの記事の見出し2です</h2>
<h3>これはこの記事の見出し3です</h2>
<p>pタグです。</p>
<p><strong>strongタグで囲んだ時のスタイルです。</strong></p>
<p><a href="#">テキストリンク</a>です</p>
<ul>
  <li>サイトマップ</li>
  <li>プライバシーポリシー
    <ul>
      <li>サイトマップ</li>
      <li>サイトマップ</li>
    </ul>
  </li>
  <li>サイトマップ</li>
</ul>
<ol>
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
  <li>入れ子に対応した順序リストです。
    <ol>
      <li>入れ子に対応した順序リストです。</li>
      <li>入れ子に対応した順序リストです。</li>
    </ol>
  </li>
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
</ol>
<table>
  <tbody>
    <tr>
      <th>社名</th>
      <td>京都リサーチパーク株式会社</td>
    </tr>
    <tr>
      <th>代表者</th>
      <td>代表取締役社長　松尾　一哉</td>
    </tr>
    <tr>
      <th>所在地</th>
      <td>〒600-8813　京都市下京区中堂寺南町134番地</td>
    </tr>
    <tr>
      <th>設立</th>
      <td>1999年7月1日（創業1989年10月1日）</td>
    </tr>
    <tr>
      <th>資本金</th>
      <td>1億円（大阪ガスグループ 大阪ガス都市開発（株）100%出資）</td>
    </tr>
    <tr>
      <th>従業員</th>
      <td>85名（2017年4月現在）</td>
    </tr>
    <tr>
      <th>事業内容</th>
      <td>リサーチパークの開発・運営</td>
    </tr>
  </tbody>
</table>
</div>
```
*/
.wisywig-Area {
  & > * {
    margin-bottom: 22px;
    font-size: rem(14);
    line-height: 1.8;

    @include mq-up(lg) {
      margin-bottom: 30px;
      font-size: rem(16, lg);
    }
  }
  & a {
    @include sw-Link();
  }
  & .small {
    font-size: rem(12);
    line-height: 1.6;

    @include mq-up(lg) {
      font-size: rem(13, lg);
    }
  }
  & > .center {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  & > h2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    font-family: $font-family-heading;
    font-size: rem(16);
    font-weight: bold;
    line-height: 1.4;
    border-left: 5px solid #be3134;

    @include mq-up(lg) {
      padding-left: 13px;
      font-size: rem(20, lg);
      border-left: 6px solid #be3134;
    }
  }
  & > h3 {
    padding-bottom: 4px;
    font-family: $font-family-heading;
    font-size: rem(16);
    font-weight: bold;
    line-height: 1.4;
    border-bottom: 1px solid #d2d2d2;

    @include mq-up(lg) {
      padding-bottom: 5px;
      font-size: rem(18, lg);
    }
  }
  & > h4 {}
  & > h5 {}
  & > p {
    font-size: rem(12);
    line-height: 1.8;

    @include mq-up(lg) {
      font-size: rem(16, lg);
    }
  }
  & > ul:not([class="note"]) {
    margin-left: (div(16, 12)) * 1em;
    padding-left: 0;
    line-height: 1.4;
    list-style-type: none;
    font-size: rem(14);

    & > li {
      position: relative;
    }

    & > li:nth-of-type(n+2) {
      margin-top: 9px;

      // 別のリストモジュール内にある時
      ul &,
      ol & {
        margin-top: 9px;
      }
    }

    & > li:before {
      content: "";
      display: block;
      position: absolute;
      top: 0.5em;
      left: -1em;
      width: 6px;
      height: 6px;
      border: 3px solid #878787;
      border-radius: 50%;
    }

    @include mq-up(lg) {
      font-size: rem(16, lg);

      & > li:nth-of-type(n+2) {
        margin-top: 10px;

        ul &,
        ol & {
          margin-top: 10px;
        }
      }
    }
  }
  & > ul:not([class="note"]) > li > ul {
    margin-top: 9px;
    padding-left: 0;
    list-style-type: none;

    & > li {
      position: relative;
      margin-left: 1.5em;
    }

    & > li:nth-of-type(n+2) {
      margin-top: 9px;
    }

    & > li:before {
      content: "";
      display: block;
      position: absolute;
      top: 0.5em;
      left: -1em;
      width: 6px;
      height: 6px;
      border: 3px solid #878787;
      border-radius: 50%;
      background-color: #fff;
    }

    @include mq-up(lg) {
      margin-top: 10px;

      & > li:nth-of-type(n+2) {
        margin-top: 10px;
      }
    }
  }
  & > ol {
    display: table;
    padding-left: 0;
    line-height: 1.4;
    list-style-type: none;
    counter-reset: order;
    font-size: rem(14);

    & > li {
      display: table-row;
      counter-increment: order;
    }

    & > li:nth-of-type(n+2):before {
      padding-top: 9px;
    }

    // 別のリストモジュール内にある時
    li > & {
      padding-top: 9px;

      & > li:nth-of-type(n+2):before {
        padding-top: 9px;
       }
    }

    & > li:before {
      content: counters(order, "-") ".";
      display: table-cell;
      padding-right: (div(12, 16)) * 1em;
      text-align: right;
      white-space: nowrap;
    }

    & > li > ul,
    & > li > ol {
      margin-top: 9px;
    }

    @include mq-up(lg) {
      font-size: rem(16, lg);

      & > li:nth-of-type(n+2):before {
        padding-top: 10px;
      }

      li > & {
        padding-top: 10px;
      }

      & > li > ul,
      & > li > ol {
        margin-top: 10px;
      }
    }
  }
  & > ol > li > ol {
    margin-top: 9px;
    padding-left: 0;
    list-style-type: none;
    counter-reset: childOrder;

    & > li {
      display: table-row;
      counter-increment: childOrder;
    }

    & > li:nth-of-type(n+2):before {
      padding-top: 9px;
    }

    & > li:before {
      content: counters(childOrder, "-") ".";
      display: table-cell;
      padding-right: (div(12, 16)) * 1em;
      text-align: right;
      white-space: nowrap;
    }

    @include mq-up(lg) {
      margin-top: 10px;

      & > li:nth-of-type(n+2):before {
        padding-top: 10px;
      }
    }
  }
  & strong {
    font-weight: bold;
  }
  & > img {}

  & .note {
    margin-left: 1.5em;
    padding-left: 0;
    font-size: rem(11);
    line-height: 1.6;
    list-style-type: none;

    [lang="en"] &,
    [lang="zh-cn"] {
      margin-left: 1em;
    }

    & > li {
      position: relative;
    }

    & > li:before {
      content: "※";
      position: absolute;
      top: 0;
      left: -1.5em;
    }

    [lang="en"] & > li:before,
    [lang="zh-cn"] & > li:before {
      content: "*";
      left: -1em;
    }

    @include mq-up(lg) {
      font-size: rem(11, lg);
    }
  }

  & table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    & th,
    & td {
      padding: 10px 12px 9px;
      border: 1px solid #ccc;
      text-align: left;
      vertical-align: top;
      font-size: rem(13);
      line-height: 1.6;

      @include mq-up(lg) {
        padding: 15px;
        font-size: rem(16, lg);
      }
    }

    & th {
      min-width: 5em;
      font-weight: bold;
      background-color: #f7f7f7;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
