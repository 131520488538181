/*
---
name: st-ContainerD
namespace: structure
category: Layout
id: [K2, CON-4, IMG-4, BA]
---

<span class="sw-Label">CON-4</span>
<span class="sw-Label">IMG-4</span>
<span class="sw-Label">BA</span>

- コンテンツ内の上下に大きめの余白をとります
- PC幅になると10/12のカラム幅になります
- 再生医療やイベントのブログエリアで使用されます
- 見出し上の投稿日かアイキャッチにつく開催日のいずれかを使用します。

レイアウト例です。

```html
<article class="st-ContainerD">
  <div class="st-Wrapper">
    <div class="st-ContainerD_Wrapper">
      <p class="st-ContainerD_Post">2017/03/22<span class="st-ContainerD_PostDate">（木）</span></p>
      <h1 class="st-ContainerD_Title">光をつかったまちづくり　高橋匡太氏トークイベント<span class="st-ContainerD_TitleLabel">終了</span></h1>
      <ul class="st-ContainerD_LabelList">
        <li class="st-ContainerD_LabelItem"><span class="st-ContainerD_Label">イベント</span></li>
        <li class="st-ContainerD_LabelItem"><span class="st-ContainerD_Label">サイエンス</span></li>
        <li class="st-ContainerD_LabelItem"><div class="fb-like" data-layout="button" data-action="like" data-size="large" data-show-faces="false" data-share="false"></div></li>
        <li class="st-ContainerD_LabelItem"><div class="fb-share-button" data-layout="button_count" data-size="large" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">シェア</a></div></li>
        <li class="st-ContainerD_LabelItem"><a class="twitter-share-button" href="https://twitter.com/share" data-dnt="true" data-size="large">Tweet</a></li>
      </ul>
      <div class="st-ContainerD_ImageArea">
        <div class="st-ContainerD_Date">
          <div class="st-ContainerD_DataStart">
            10/05<span class="st-ContainerD_DataStartWeekday">(木)</span>
          </div>
          <div class="st-ContainerD_To" aria-label="から"></div>
          <div class="st-ContainerD_DateEnd">
            01/18<span class="st-ContainerD_DateEndWeekday">(木)</span>
          </div>
        </div>
        <img src="https://placehold.jp/670x330.png" class="st-ContainerD_Image" alt="">
      </div>
      <div class="wisywig-Area">
        <p>京都駅西部エリア（エキニシ）、特に丹波口駅西側周辺（以下、「当エリア」）を、光を使って明るく、楽しく出歩けるまちにするための活動、“エキニシミライルミプロジェクト”がスタートしました。今回は、「ワコール新京都ビル」の演出照明も手掛けられているアーティスト高橋匡太氏を講師にお迎えし、光をつかったまちづくりの事例をお話し頂きます。ぜひご参加ください。</p>
        <img src="https://placehold.jp/670x330.png" alt="" class="center">
        <p><a href="#">ミライルミ１回目ワークショップの様子</a></p>
        <p>日時：2017年10月5日　19:00~<br>
          場所：ワコールスタディホール 京都<br>
          参加費：学生無料・社会人500円<br>
          申込方法：メールにてお申込み下さい。①名前②所属③連絡先を明記の上、<br>
        nb_p＠krp.co.jp</p>
        <p>までご連絡下さい。</p>
        <p>問合先：京都リサーチパーク㈱中根<br>
          主催：エキニシミライルミ実行委員会<br>
          　　　（京都駅西部エリア（エキニシ）を、光を使って明るく、楽しく出歩けるまちにするための活動をしています）<br>
          共催：京都リサーチパーク㈱<br>
        企画制作：㈱カエルグラス</p>
      </div>
    </div>
  </div>
</article>
```
*/
.st-ContainerD {
  padding: $padding-conitaner-a;

  @include mq-up(lg) {
    padding: $padding-conitaner-a-lg;
  }
}

.st-ContainerD_Wrapper {
  @include mq-up(lg) {
    width: percentage(div(10, 12));
    margin: auto;
  }
}

.st-ContainerD_Post {
  margin-bottom: 9px;
  font-size: rem(14);
  line-height: 1.4;
  color: #666;

  @include mq-up(lg) {
    margin-bottom: 18px;
    font-size: rem(16, lg);
  }
}

.st-ContainerD_PostDate {
  font-size: rem(11);

  @include mq-up(lg) {
    font-size: rem(11, lg);
  }
}

.st-ContainerD_Title {
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-family: $font-family-heading;
  font-size: rem(19);
  font-weight: bold;
  line-height: 1.6;
  border-bottom: 1px solid #d2d2d2;

  @include mq-up(lg) {
    margin-bottom: 40px;
    padding-bottom: 12px;
    font-size: rem(30, lg);
    line-height: 1.6;
  }
}

.st-ContainerD_TitleLabel {
  display: inline-block;
  margin-left: 10px;
  padding: 4px 12px;
  font-size: rem(9);
  font-weight: normal;
  line-height: 1;
  border-radius: 3em;
  color: #fff;
  background-color: $color-brand;

  @include mq-up(lg) {
    padding: 6px 14px;
    font-size: rem(13, lg);
  }
}


.st-ContainerD-stripe {
  background-image: url("/assets/img/common/bg_stripe-white.png");
  background-repeat: repeat;
  background-size: auto;
  background-position: 0 0;
}

.st-ContainerD-gray {
  background-color: #f5f5f5;
}

.st-ContainerD-delimiterA {
  border-top: 2px solid $color-brand;

  @include mq-up(lg) {
    border-top-width: 4px;
  }
}

.st-ContainerD-delimiterB {
  border-top: 1px solid #e8e8e8;
}

.st-ContainerD_LabelList {
  margin-bottom: 35px;
  padding-left: 0;
  list-style-type: none;
  line-height: 1;
  
  &:after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq-up(lg) {
    margin-bottom: 50px;
  }
}

.st-ContainerD_LabelItem {
  float: left;

  & + & {
    margin-left: 7px;

    @include mq-up(lg) {
      margin-left: 10px;
    }
  }
}

.st-ContainerD_Label {
  @include sw-Label();

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}

.st-ContainerD_ImageArea {
  position: relative;
  margin-bottom: 25px;

  @include mq-up(lg) {
    margin-bottom: 40px;
    width: percentage(div(6, 12));
    margin-right: auto;
    margin-left: auto;
  }
}

.st-ContainerD_Date {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 90px;
  line-height: 1;
  padding: 7px 18px;
  color: #fff;
  background-color: rgba(#000, 0.5);

  @include mq-up(lg) {
    padding: 15px 12px;
  }
}

.st-ContainerD_DataStart {
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(22, lg);
  }
}

.st-ContainerD_DataStartWeekday {
  margin-left: 0.5em;
  font-size: rem(10);
  font-weight: normal;

  @include mq-up(lg) {
    font-size: rem(10, lg);
  }
}

.st-ContainerD_To {
  margin-top: 3px;
  margin-bottom: 3px;

  &:before {
    content: "";
    display: block;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent transparent;
  }

  @include mq-up(lg) {
    margin-top: 5px;
    margin-bottom: 3px;
  }
}

.st-ContainerD_DateEnd {
  font-size: rem(13);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.st-ContainerD_DateEndWeekday {
  margin-left: 0.5em;
  font-size: rem(10);
  font-weight: normal;

  @include mq-up(lg) {
    font-size: rem(10, lg);
  }
}

.st-ContainerD_Image {

}

.st-ContainerD-delimiterA {
  border-top: 1px solid #e8e8e8;
}
