/*
---
name: st-SmallText
namespace: Structure
category: Layout
---

補足情報などのテキストの余白を指定します。

```html
<div class="st-Wrapper">
<p class="sw-TextSecondary st-SmallText">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
<p class="sw-TextSecondary st-SmallText">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.st-SmallText {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-small-text;

    @include mq-up(lg) {
      margin-top: $margin-small-text-lg;
    }
  }
}
