/*
---
name: st-LocalNav1
namespace: Structure
category: Navigation
id: [K1, NV1]
---

<span class="sw-Label">NV1</span>

ローカルナビゲーションです。

- URLの2階層目（`/events/event/`など）と合致する場合はカレント表示されます。

```html
<div class="st-Wrapper">
<div class="st-LocalNav1">
  <ul class="st-LocalNav1_Items">
    <li class="st-LocalNav1_Item">
      <a href="/_events/event/" class="st-LocalNav1_Link">TOP<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">オフィス紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">部屋の広さ・<span class="st-LocalNav1_Delimiter">人数別</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">建物紹介<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">レンタルラボ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">魅力ある<span class="st-LocalNav1_Delimiter">環境・サービス</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">料金・<span class="st-LocalNav1_Delimiter">ご契約の流れ</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">よくあるご質問<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav1_Item">
      <a href="" class="st-LocalNav1_Link">お問い合わせ<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>
</div>
```
*/
.st-LocalNav1 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav1_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  line-height: 1.2;

  @include mq-up(lg) {
    display: block;
    width: 100%;
    font-size: 0;
    text-align: center;

    .st-ContentHeaderD_Block-fixed & {
      display: table;
      width: 100%;
    }
  }
}

.st-LocalNav1_Item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: rem(15);
  border-bottom: 1px solid #393939;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: top;
    width: percentage(div(1, 7));
    font-size: rem(14, lg);
    border-bottom: 0;
    color: #fff;
    background-color: $color-brand;
    transition-duration: 0.3s;
    transition-property: background-color;

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    .st-LocalNav1_Items &:nth-last-child(n+8),
    .st-LocalNav1_Items &:nth-last-child(n+8) ~ & {
      width: auto;
    }

    .st-ContentHeaderA_Block-fixed &,
    .st-ContentHeaderB_Block-fixed &,
    .st-ContentHeaderE_Block-fixed & {
      position: relative;
      display: table-cell;
      vertical-align: middle;
      color: $color-text;
      background-color: transparent;
      width: auto;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        margin: auto;
        width: 1em;
        height: 5px;
        border-bottom: 5px solid transparent;
        transition-duration: $transition-duration;
        transition-property: width;
      }

      &:hover:after {
        width: 100%;
        border-color: #a81d34;
      }
    }

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 100%;
      margin: auto;
      border-left: 1px solid #c75659;

      .st-ContentHeaderA_Block-fixed &,
      .st-ContentHeaderB_Block-fixed &,
      .st-ContentHeaderE_Block-fixed & {
        height: 28px;
        border-left-color: #e8e8e8;
      }
    }
  }
}

.st-LocalNav1_Item-current {

  @include mq-up(lg) {
    background-color: #6a0012;

    &.st-LocalNav1_Item:before {
      border-left-color: #6a0012;
    }
  }

  .st-ContentHeaderA_Block-fixed &:after,
  .st-ContentHeaderB_Block-fixed &:after,
  .st-ContentHeaderE_Block-fixed &:after {
    @include mq-up(lg) {
      width: 100%;
      border-color: #a81d34;
    }
  }
}

.st-LocalNav1_Item-inquiry {
  .st-ContentHeaderA_Block-fixed &,
  .st-ContentHeaderB_Block-fixed &,
  .st-ContentHeaderE_Block-fixed & {
    &:hover:after {
      @include mq-up(lg) {
        border-color: #6a0012;
      }
    }
  }

  .st-ContentHeaderA_Block-fixed &,
  .st-ContentHeaderB_Block-fixed &,
  .st-ContentHeaderE_Block-fixed & {
    &.st-LocalNav1_Item-current:after {
      @include mq-up(lg) {
        border-color: #6a0012;
      }
    }
  }
}

.st-LocalNav1_Link {
  position: relative;
  display: block;
  padding: 16px 5px;
  padding: 16px 20px;
  text-decoration: none;
  color: #fff;
  transition-duration: 0.3s;
  transition-property: background-color;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 24px 20px;
    font-weight: bold;
    text-align: center;
    color: currentColor;
    background-color: transparent;

    .st-LocalNav1_Items-isChild .st-LocalNav1_Item-current &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-style: solid;
      border-width: 0 6.5px 6px 6.5px;
      border-color: transparent transparent #f9f9f9 transparent;
    }

    .st-ContentHeaderA_Block-fixed &,
    .st-ContentHeaderB_Block-fixed & {
      height: auto !important;
      padding: 21px 20px;
      font-weight: normal;
    }
  }
}

.st-LocalNav1_Link.st-LocalNav1_Link-inquiry {
  .st-ContentHeaderA_Block-fixed &,
  .st-ContentHeaderB_Block-fixed & {
    @include mq-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70px !important;
      line-height: 1.2;
      margin-top: -5px;
      margin-bottom: -5px;
      padding-top: (14 + 4) * 1px;
      padding-bottom: (14 + 4) * 1px;
      color: #fff;
      background-color: #be3134;
    }
  }
}

.st-LocalNav1_Link-isChild {

}

.st-LocalNav1_Icon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;

  .st-LocalNav1_Link-isChild & {
    transform: rotate(-90deg);
  }

  @include mq-up(lg) {
    display: none;
  }
}

.st-LocalNav1_SubItems {
  @extend .st-LocalNav1_Items;
  display: none;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3_child.png");
  background-repeat: repeat;
  background-size: auto;

  .st-LocalNav1_Item-current & {
    display: block;
  }

  @include mq-up(lg) {
    display: none !important;
  }
}

.st-LocalNav1_SubItem {
  @extend .st-LocalNav1_Item;
  padding: 0 20px;
  border-color: #505050;
}

.st-LocalNav1_SubLink {
  @extend .st-LocalNav1_Link;
  padding-right: 0;
  padding-left: 0;
}

.st-LocalNav1_SubIcon:before {
  @extend .st-LocalNav1_Icon;
  right: 0;
}

.st-LocalNav1_Delimiter {
  display: inline-block;

  @include mq-up(lg) {
    display: block;
  }
}
