/*
---
name: sw2-Heading2A
namespace: SiteWide2
category: Heading
---

`<h2>`で使用する見出しです。余白には`.st2-Heading2`を使用します。

- PC未満では`<br>`は適応されません。

```html
<div class="st2-ContainerA">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw2-Heading2A st2-Heading2">「産学公連携拠点・<br>新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```
*/
.sw2-Heading2A {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  font-size: rem(29);
  line-height: rem(39);
  color: #333;
  position: relative;
  padding-left: rem(24);

  &:before {
    content: "";
    display: block;
    width: rem(8);
    height: rem(8);
    background-color: $color-krp;
    border-radius: 100%;
    position: absolute;
    top: rem(18);
    left: 0;
    @include mq-up(lg) {
      top: rem(13, lg);
    }
  }

  & br {
    display: none;
  }

  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: rem(32, lg);
    padding-left: rem(30, lg);

    &:after {
      width: rem(10, lg);
      height: rem(10, lg);
    }

    & br {
      display: initial;
    }
  }
}
