/*
---
name: sw-Heading2B
namespace: SiteWide
category: Heading
id: [K2, HD-4A, HD-4B]
---

<span class="sw-Label">HD-4A</span>

`<h2>`で使用する見出しです。余白には`.st-Heading2`を使用します。

- PC未満では`<br>`は適応されません。

```html
<div class="st-Wrapper">
<h2 class="sw-Heading2B st-Heading2">最新のイベント</h2>
</div>
```

<span class="sw-Label">HD-4B</span>

`.sw-Heading2B-dark`を追加すると、ブランドカラーから黒字に変わります。

```html
<div class="st-Wrapper">
<h2 class="sw-Heading2B sw-Heading2B-dark  st-Heading2">最新のイベント</h2>
</div>
```
*/
.sw-Heading2B {
  margin-right: -10px;
  margin-left: -10px;
  font-family: $font-family-heading;
  font-size: rem(20);
  font-weight: bold;
  line-height: 1.4;
  color: #be3134;

  &:after {
    content: "";
    display: block;
    margin-top: 3px;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    border-top: 1px solid #be3134;
  }

  & br {
    display: none;
  }

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
    text-indent: 0;
    font-size: rem(20, lg);
    text-align: center;

    &:after {
      margin-top: 15px;
      margin-right: 0;
      margin-left: 0;
      border-top: 1px solid #be3134;
    }

    & br {
      display: inline;
    }
  }
}

.sw-Heading2B-dark {
  color: $color-text;
}
