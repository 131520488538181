/*
---
name: st-FooterInquiry
namespace: Structure
category: Global
id: FC-1
---

<span class="sw-Label">FC-1</span>

- オフィス

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">お問い合わせ（空室状況・その他）</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">空き室の状況については、お電話・メールにてお気軽にお問い合わせください。<br>
            ご要望に合うスペースが満室の場合も、スペースが空き次第、お伝えすることも可能です。<br>
            また、KRPスペースの閲覧ツアーも随時、行っています。</p>
          <p class="st-FooterInquiry_Text">TEL : 075-315-9333　<a class="st-FooterInquiry_Link" href="mail:service@krp.co.jp">service@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30　（土日祝・弊社休業日を除く）<br>
        京都リサーチパーク株式会社 営業開発部</p>
      </div>
    <div class="st-FooterInquiry_Foot">
      <a href="/kaigi/inquiry/" class="st-FooterInquiry_Button">お問い合わせフォーム<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
    </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-2</span>

- 会議室

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">ホール・会議室についてのご質問・資料請求はこちらから</h3>
      <div class="st-FooterInquiry_Foot">
        <a href="#" class="st-FooterInquiry_Button">お問い合わせフォーム<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
      </div>
      <div class="st-FooterInquiry_Body">TEL : 075-322-7888　FAX : 075-314-2968<br>
          <a class="st-FooterInquiry_Link st-FooterInquiry_Emphasis" href="mail:convention@krp.co.jp">e-mail : convention@krp.co.jp</a><br>
        お電話の受付時間：平日/土曜 9:00～17:40　※年末年始及びメンテナンス日除く<br>
      京都リサーチパーク株式会社 MICE推進部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-3</span>

- スタートアップ

```html
<div class="st-ContainerA">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">お問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL : 075-315-9185　FAX : 075-322-5348<br>
            お電話の受付時間： 9:00～17:30　（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 成長企業支援部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-4</span>

- TSA

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">お問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL : 075-315-9185　FAX : 075-322-5348<br>
            <a class="st-FooterInquiry_Link" href="mail:sks-info@krp.co.jp">sks-info@krp.co.jp</a>
          </p>
      </div>
      <div class="st-FooterInquiry_Body">
        <a href="/sks/tsa/inquiry/" class="st-FooterInquiry_Button">お問い合わせフォーム<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
      </div>
      <div class="st-FooterInquiry_Foot">
          <p class="st-FooterInquiry_Text">
            お電話の受付時間： 9：00～17:00　（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 成長企業支援部</p>
      </div>
      <div class="st-FooterInquiry_Foot">
        <a href="https://twitter.com/share?hashtags=TSA%2dHAYATE%2d&text=%e2%80%beTechnology%20Startup%20Accelerator%e3%80%8e%e8%bf%85%e3%80%8f%2dHAYATE%2d%e3%80%80%0d%0a%e4%ba%ac%e9%83%bd%e3%83%aa%e3%82%b5%e3%83%bc%e3%83%81%e3%83%91%e3%83%bc%e3%82%af%e6%a0%aa%e5%bc%8f%e4%bc%9a%e7%a4%be%e3%81%af%e3%80%81%e3%80%8e%e4%ba%ac%e9%83%bd%e7%99%ba%20%e4%b8%96%e7%95%8c%e3%80%8f%e3%82%92%e7%9b%ae%e6%8c%87%e3%81%99%e3%83%86%e3%82%af%e3%83%8e%e3%83%ad%e3%82%b8%e3%83%bc%e3%82%92%e3%83%99%e3%83%bc%e3%82%b9%e3%81%a8%e3%81%97%e3%81%9f%e3%82%b9%e3%82%bf%e3%83%bc%e3%83%88%e3%82%a2%e3%83%83%e3%83%97%e3%82%92%e3%82%b5%e3%83%9d%e3%83%bc%e3%83%88%e3%81%97%e3%81%be%e3%81%99%ef%bc%81" target="_blank" rel="noopener" class="st-FooterInquiry_Twitter">
          <span class="st2-FooterSitemap_Snslinks_TwitterIcon" aria-hidden="true"></span>
        </a>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.krp.co.jp/sks/tsa/" target="_blank" rel="noopener" class="st-FooterInquiry_Facebook">
          <span class="st2-FooterSitemap_Snslinks_FacebookIcon" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-5</span>

- HCV

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">お問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <a href="/hvckyoto/inquiry/" class="st-FooterInquiry_Button">お問い合わせはこちら<span class="sw-Button_RightArrow" aria-hidden="true"></span></a><br>
          <a class="st-FooterInquiry_Link" href="mail:hvckyoto@krp.co.jp">E-Mail：hvckyoto@krp.co.jp</a></p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-6</span>

- e2

```html
<div class="st-ContainerA">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">本事業に関するお問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL： 075-315-8491　<a class="st-FooterInquiry_Link" href="mailto:e2-info@krp.co.jp">e2-info@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 産学公連携部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-7</span>

- 再生医療

```html
<div class="st-ContainerA">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">本事業に関するお問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL： 075-315-8476　FAX：075-315-9079<br>
          <a class="st-FooterInquiry_Link" href="mailto:saisei-s@krp.co.jp">saisei-s@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 産学公連携部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-8</span>

- ワンコイン
- 京大
- ICT

```html
<div class="st-ContainerA">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">本事業に関するお問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL： 075-315-8522　<a class="st-FooterInquiry_Link" href="mailto:ict-info@krp.co.jp">ict-info@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 産学公連携部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-9</span>

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">本事業に関するお問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL： 075-315-8491　<a class="st-FooterInquiry_Link" href="mailto:dsn-info@krp.co.jp">dsn-info@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 産学公連携部</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">FC-10</span>

- 産学公連携

```html
<div class="st-ContainerA st-ContainerA-delimiterB">
  <div class="st-Wrapper">
    <div class="st-FooterInquiry">
      <h3 class="st-FooterInquiry_Heading">本事業に関するお問い合わせ</h3>
      <div class="st-FooterInquiry_Body">
          <p class="st-FooterInquiry_Text">TEL： 075-315-8491　<a class="st-FooterInquiry_Link" href="mail:san-gaku@krp.co.jp">san-gaku@krp.co.jp</a><br>
          お電話の受付時間： 9:00～17:30（土日祝・弊社休業日を除く）<br>
          京都リサーチパーク株式会社 産学公連携部</p>
      </div>
    </div>
  </div>
</div>
```


```
      <div class="st-FooterInquiry_Foot">
        <a href="#" class="st-FooterInquiry_Twitter"><img class="st-FooterBanner_Image" src="/assets/img/structure/FooterInquiry/icon_circle-twitter.svg" alt="Twitter"></a>
        <a href="#" class="st-FooterInquiry_Facebook"><img class="st-FooterInquiry_FacebookIcon" src="/assets/img/structure/FooterInquiry/icon_circle-facebook.svg" alt="Facebook"></a>
      </div>
      ```
*/
.st-FooterInquiry {

}

.st-FooterInquiry_Heading {
  margin-right: -10px;
  margin-bottom: 30px;
  margin-left: -10px;
  font-family: $font-family-heading-en;
  font-size: rem(18);
  font-weight: bold;
  line-height: 1.4;
  color: #be3134;
  text-align: center;

  &:after {
    content: "";
    display: block;
    margin-top: 8px;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);

    border-top: 2px solid #be3134;
  }

  @include mq-up(lg) {
    margin-right: 0;
    margin-bottom: $margin-title-lg;
    margin-left: 0;
    font-size: rem(26, lg);
    text-align: center;

    &:after {
      margin-top: 12px;
      margin-right: 0;
      margin-left: 0;
      border-top: 3px solid #be3134;
    }
  }
}

.st-FooterInquiry_Body {
  text-align: center;

  .st-FooterInquiry_Foot + &,
  & + & {
    margin-top: 20px;
  }
}

.st-FooterInquiry_Text {
  font-size: rem(14);
  line-height: 1.8;
  text-align: left;

  & + &,
  :not(#{&}) + & {
    margin-top: $margin-text;

    @include mq-up(lg) {
      margin-top: $margin-text-lg;
    }
  }

  @include mq-up(lg) {
    font-size: rem(16, lg);

    .st-FooterInquiry_Body &,
    .st-FooterInquiry_Foot & {
      text-align: center;
    }
  }
}

.st-FooterInquiry_Link {
  @include sw-Link();
}

.st-FooterInquiry_Foot {
  text-align: center;

  .st-FooterInquiry_Body + &,
  & + & {
    margin-top: 20px;
  }
}

.st-FooterInquiry_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: $color-brand;
    background-color: #fff;
  }
}

.sw-Button_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;

  @include mq-up(lg) {
    right: 12px;
  }
}

.st-FooterInquiry_Twitter {
  display: inline-block;
  width: 54px;
  height: 54px;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    opacity: $opacity;
  }
}

.st-FooterInquiry_TwitterIcon:before {
  
}

.st-FooterInquiry_Facebook {
  display: inline-block;
  width: 54px;
  height: 54px;
  text-decoration: none;
  transition-duration: $transition-duration;

  margin-left: 16px;

  &:hover {
    opacity: $opacity;
  }
}

.st-FooterInquiry_FacebookIcon:before {

}

.st-FooterInquiry_Emphasis {
  font-size: rem(15);
  font-weight: 700;
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(20, lg);
  }
}
