/*
---
name: sw-Video
namespace: SiteWide
category: ThirdPartyComponent
id: [C3, VD]
---

<span class="sw-Label">VD</span>

Youtubeなどの動画をレスポンシブ対応させます。デフォルトで16:9で表示されます。

```html
<div class="st-Wrapper">
<div class="sw-Video_Item">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/1OKZOV-iLj4"></iframe>
</div>
</div>
```

1つだけ配置するときは`.sw-Video_OnlyOne`でラップして8/12カラムにしてください。

```html
<div class="st-Wrapper">
  <div class="sw-Video_OnlyOne">
    <div class="sw-Video_Item">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/1OKZOV-iLj4"></iframe>
    </div>
  </div>
</div>
```
*/
.sw-Video_OnlyOne {
  width: percentage(div(8, 12));
  margin: auto;
}

.sw-Video_Item {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(div(9, 16));

  &_Item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
