/*
---
name: st-FooterPlatform
namespace: Structure
category: Global
id: [FT-B, FT-B-2]
---

<span class="sw-Label">FT-B</span>

- 共通下部バナー

```html
<div class="st-ContainerB st-ContainerB-gray">
  <div class="st-Wrapper">
    <div class="st-FooterPlatform">
      <h3 class="st-FooterPlatform_Heading">PLATFORM<span class="st-FooterPlatform_Small">プラットフォーム</span></h3>
      <ul class="st-FooterPlatform_Items">
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/bio/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_sangaku.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">再生医療サポート<br>プラットフォーム</p>
              <p class="st-FooterPlatform_Description">早期実用化・産業化・イノベーションを図る</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/hvckyoto/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_hvc.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">HVC KYOTO</p>
              <p class="st-FooterPlatform_Description">発見した課題を製品へ成長させる</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="https://rikamo.jp/" target="_blank">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_rikamo.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">理化学機器・医療機器モール - リカモ</p>
              <p class="st-FooterPlatform_Description">研究者とモノづくり企業の橋渡し</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/electronics/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_e2plat.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">E2M Square</p>
              <p class="st-FooterPlatform_Description">E2＋メカニクス分野の新ビジネス創出</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/dschool/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_dschool.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">京大デザインスクール</p>
              <p class="st-FooterPlatform_Description">産学連携による教育を推進する拠点</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/ict/nw/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_ict.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">京都大学<br>
              ICT連携推進ネットワーク</p>
              <p class="st-FooterPlatform_Description">複合的な問題を解決できる人材の育成</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/kobo/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_kobo.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">京都職人工房</p>
              <p class="st-FooterPlatform_Description">作り手が「集い」「作り」「伝える」力を磨く場</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/c_table/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_ctable.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">クリエイティブテーブル</p>
              <p class="st-FooterPlatform_Description">ものづくりレクチャーシリーズのイベント</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sangaku/onecoin/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_onecoin.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">ワンコイン企画</p>
              <p class="st-FooterPlatform_Description">京大とKRP入居企業との交流の場</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="https://www.kougeimagazine.com/" target="_blank">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_cmagazine.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">クラフトマガジン</p>
              <p class="st-FooterPlatform_Description">伝統工芸の仕事とくらしを伝えるメディア</p>
            </div>
          </a>
        </li>
        <li class="st-FooterPlatform_Item">
          <a class="st-FooterPlatform_Link" href="/sks/municipality/">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/FooterPlatform/logo_krp.svg" alt="">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name">自治体企業支援</p>
              <p class="st-FooterPlatform_Description">自治体の企業支援を受託</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
```

<span class="sw-Label">FT-B-2</span>

- 共通下部バナー(リンクなし)
- Move Onで使用

```html
<div class="st-ContainerB st-ContainerB-gray">
  <div class="st-Wrapper">
    <div class="st-FooterPlatform">
      <h3 class="sw-Heading3 st-Heading3">Move Onの3つの特徴</h3>
      <ul class="st-FooterPlatform_Items">
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_point01.svg" alt="ポイント01">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">企業 × 学生</p>
              <p class="st-FooterPlatform_Description-col2">企業の技術や製品・サービスと学生の柔らかい発想の掛け合わせによる化学反応</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_point02.svg" alt="ポイント02">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">企業 × 学生</p>
              <p class="st-FooterPlatform_Description-col2">2社の参画企業による異業種連携・オープンイノベーションを体現</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_point03.svg" alt="ポイント03">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">未来のビジネスビルディング</p>
              <p class="st-FooterPlatform_Description-col2">社会課題を解決し、かつビジネスとしても成り立つビジネスアイデアを導き出す</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_merit01.svg" alt="メリット01">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">他の参画企業との関係づくり</p>
              <p class="st-FooterPlatform_Description-col2">プログラムを通して、参画企業間で連携することにより、他社との関係づくりができる</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_merit02.svg" alt="メリット02">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">企業ブランディング</p>
              <p class="st-FooterPlatform_Description-col2">参加学生に対し、自社の技術・想い・人を知ってもらうことができる</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_merit03.svg" alt="メリット03">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">人材育成</p>
              <p class="st-FooterPlatform_Description-col2">参加する社員のコミュニケーション能力の向上や、新しい価値観、柔軟な考え方の習得</p>
            </div>
          </div>
        </li>
        <li class="st-FooterPlatform_Item-col2">
          <div class="st-FooterPlatform_box">
            <div class="st-FooterPlatform_ImageArea">
              <img class="st-FooterBanner_Image" src="/assets/img/common/moveon/logo_merit04.svg" alt="メリット04">
            </div>
            <div class="st-FooterPlatform_Body">
              <p class="st-FooterPlatform_Name-col2">学生の柔軟なアイデア</p>
              <p class="st-FooterPlatform_Description-col2">学生ならではの柔軟な発想・アイデアから「新たな気づき」を得ることができる</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
```
*/
.st-FooterPlatform {

}

.st-FooterPlatform_Heading {
  position: relative;
  margin-bottom: $margin-title;
  font-family: $font-family-heading-en;
  font-size: rem(21);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: $color-text;

  &:after {
    content: "";
    display: block;
    margin: 4px auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 7px 0 0;
    border-color: $color-brand transparent transparent transparent;
  }

  @include mq-up(lg) {
    margin-bottom: $margin-title-lg;
    font-size: rem(30, lg);
    line-height: 1.4;

    &:after {
      margin: 5px auto 0;
      border-width: 10px 14px 0 0;
    }
  }
}

.st-FooterPlatform_Small {
  display: block;
  font-size: rem(15, lg);
  line-height: 1.4;
  color: $color-brand;
}

.st-FooterPlatform_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
}

.st-FooterPlatform_Item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;
  @include border-collapse(#eee);
  background-color: #fff;

  @include mq-up(lg) {
    width: percentage(div(1, 3));
  }
}

.st-FooterPlatform_Item-col2 {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;
  @include border-collapse(#eee);
  background-color: #fff;

  @include mq-up(lg) {
    width: percentage(div(1, 2));
  }
}

.st-FooterPlatform_Link {
  display: table;
  width: 100%;
  padding: 14px 15px 14px 10px;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    color: $color-brand;
    background-color: #fdeeee;
  }

  @include mq-up(lg) {
    padding: 26px 20px 26px 24px;
  }
}

.st-FooterPlatform_box {
  display: table;
  width: 100%;
  padding: 14px 15px 14px 10px;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 26px 20px 26px 24px;
  }
}

.st-FooterPlatform_ImageArea {
  display: table-cell;
  vertical-align: middle;
  width: 90px;

  @include mq-up(lg) {
    width: 86px;
  }
}

.st-FooterBanner_Image {

}

.st-FooterPlatform_Body {
  display: table-cell;
  vertical-align: middle;
  width: auto;
  padding-left: 12px;

  @include mq-up(lg) {
    padding-left: 10px;
  }
}

.st-FooterPlatform_Name {
  font-size: rem(16);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}

.st-FooterPlatform_Name-col2 {
  font-size: rem(16);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(18, lg);
  }
}

.st-FooterPlatform_Description {
  margin-top: 5px;
  font-size: rem(11);
  line-height: 1.4;

  @include mq-up(lg) {
    margin-top: 9px;
    font-size: rem(12, lg);
  }
}

.st-FooterPlatform_Description-col2 {
  margin-top: 5px;
  font-size: rem(11);
  line-height: 1.4;

  @include mq-up(lg) {
    margin-top: 9px;
    font-size: rem(16, lg);
  }
}
