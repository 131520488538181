/*
---
name: sw-ListArrow
namespace: SiteWide
category: List
id: [K2, LI-1]
---

<span class="sw-Label">LI-1</span>

矢印付きのリンクリストです。

- 別窓リンクの場合は`.sw-LinkExternal`を追加してください。
- PDFへのリンクの場合は`.sw-LinkPdf`を追加してください。

```html
<div class="st-Wrapper">
<ol class="sw-ListArrow">
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#">サイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップ<span class="sw-LinkPdf" aria-hidden="true"></a>
  </li>
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#" target="_blank">阪急大宮駅　時刻表<span class="sw-LinkExternal" aria-hidden="true"></a>
  </li>
</ol>
</div>
```

中央揃えにするには`.sw-ListArrow-center`を追加します。

```html
<div class="st-Wrapper">
<ol class="sw-ListArrow sw-ListArrow-center">
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#">KRP PR媒体への情報掲載に関する規約に</a>
  </li>
</ol>
</div>
```

PC以上で中央揃えにするには`.sw-ListArrow-centerLg`を追加します。

```html
<div class="st-Wrapper">
<ol class="sw-ListArrow sw-ListArrow-centerLg">
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#">KRP PR媒体への情報掲載に関する規約に</a>
  </li>
</ol>
</div>
```

右揃えするには`.sw-ListArrow-right`を追加します。

```html
<div class="st-Wrapper">
<ol class="sw-ListArrow sw-ListArrow-right">
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#">サイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップ</a>
  </li>
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#" target="_blank">阪急大宮駅　時刻表<span class="sw-LinkExternal" aria-hidden="true"></span></a>
  </li>
</ol>
</div>
```

PC以上で右揃えするには`.sw-ListArrow-rightLg`を追加します。。

```html
<div class="st-Wrapper">
<ol class="sw-ListArrow sw-ListArrow-rightLg">
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#">サイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップサイトマップ</a>
  </li>
  <li class="sw-ListArrow_Item">
    <span class="sw-ListArrow_Icon" aria-hidden="true"></span>
    <a class="sw-ListArrow_Link" href="#" target="_blank">阪急大宮駅　時刻表<span class="sw-LinkExternal" aria-hidden="true"></span></a>
  </li>
</ol>
</div>
```
*/
.sw-ListArrow {
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-ListArrow_Item {
  position: relative;
  padding-left: 18px;

  & + & {
    margin-top: 9px;
  }

  @include mq-up(lg) {
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.sw-ListArrow-center {
  text-align: center;
}

.sw-ListArrow-centerLg {
  @include mq-up(lg) {
    text-align: center;
  }
}

.sw-ListArrow-right {
  overflow: hidden;

  & > .sw-ListArrow_Item {
    float: right;
  }
}

.sw-ListArrow-rightLg {
  @include mq-up(lg) {
    overflow: hidden;

    & > .sw-ListArrow_Item {
      float: right;
    }
  }
}

.sw-ListArrow_Icon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;

  .sw-ListArrow-center & {
    position: static;
  }

  @include mq-up(lg) {
    .sw-ListArrow-centerLg & {
    position: static;
  }
  }
}

.sw-ListArrow_Link {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}
