/*
---
name: serviceoffice-FormA
namespace: serviceoffice
category: Layout
---

KRP BIZ NEXTの申し込みフォームです。

```html
<div class="st-ContainerD st-ContainerD-delimiterA">
  <div class="st-Wrapper">
    <form>
      <section class="st-Section2">
        <h2 class="sw-Heading2A st-Heading2">ビジネスラウンジ 1日無料体験チケット</h2>
        <p class="sw-Text sw-Text-center st-Text">ビジネスラウンジをご利用いただける1日無料チケットをご用意いたしました。<br>
        利用の際おご予約等は不要ですので、お気軽にお申込みください。</p>

        <div class="serviceoffice-FormA_Inner st-Text">
          <div class="serviceoffice-FormA_Item">
            <div class="serviceoffice-FormA_LabelArea">
              <label for="yourName" class="serviceoffice-FormA_Label">お名前<em class="serviceoffice-FormA_Require">（必須）</em></label>
            </div>
            <div class="serviceoffice-FormA_InputArea">
              <input type="text" placeholder="京都太郎" class="serviceoffice-FormA_Input" id="yourName" required>
            </div>
          </div>

          <div class="serviceoffice-FormA_Item">
            <div class="serviceoffice-FormA_LabelArea">
              <label for="yourNameAddress" class="serviceoffice-FormA_Label">メールアドレス<em class="serviceoffice-FormA_Require">（必須）</em></label>
            </div>
            <div class="serviceoffice-FormA_InputArea">
              <input type="e-mail" placeholder="例) xxxx@xxxxxx.co.jp" class="serviceoffice-FormA_Input" id="yourNameAddress" required>
            </div>
          </div>

          <div class="serviceoffice-FormA_Item">
            <div class="serviceoffice-FormA_LabelArea">
              <label for="yourComment" class="serviceoffice-FormA_Label">その他コメント</label>
            </div>
            <div class="serviceoffice-FormA_InputArea">
              <textarea class="serviceoffice-FormA_Textarea" id="yourComment" placeholder="空き状況を教えてください。"></textarea>
            </div>
          </div>
        </div>

        <div class="st-ButtonA st-ButtonA-center">
          <button type="button" class="sw-Button sw-Button-invert">確認画面へ<span class="sw-Button_RightArrow" aria-hidden="true"></span></button>
          <p class="serviceoffice-FormA_Note">お申込み後、ご利用の詳細をご案内いたします。</p>
        </div>

      </section>
    </form>

  </div>
</div>
```
*/

.serviceoffice-FormA_Inner {
  max-width: 668px;
  margin: auto;
}

.serviceoffice-FormA_Item {
  & + & {
    margin-top: 20px;
  }

  @include mq-up(lg) {
    font-size: 0;

    & + & {
      margin-top: 26px;
    }
  }
}

.serviceoffice-FormA_LabelArea {
  margin-bottom: 10px;

  @include mq-up(lg) {
    display: inline-block;
    width: percentage(div(145, 668));
    margin-bottom: 0;
    vertical-align: top;
    font-size: 1rem;
  }
}

.serviceoffice-FormA_InputArea {

  @include mq-up(lg) {
    display: inline-block;
    width: percentage(div(523, 668));
    vertical-align: top;
    font-size: 1rem;
  }
}

.serviceoffice-FormA_Label {
  display: block;
  font-size: rem(17, lg);
  font-weight: bold;
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(17, lg);
  }
}

.serviceoffice-FormA_Require {
  display: inline-block;
  font-size: rem(14, lg);
  line-height: 1.4;
  color: #ec0000;

  @include mq-up(lg) {
    display: block;
  }
}

.serviceoffice-FormA_Input {
  @include sw-FormInput();
  max-width: 468px;

  @include mq-up(lg) {
    margin-left: 0;
    transform: scale(1);
  }
}

.serviceoffice-FormA_Input-error {
  background-color: #fceded;
}

.serviceoffice-FormA_Textarea {
  @include sw-FormTextarea();
  max-width: 468px;
  height: 100px;
  background-color: #fff;

  @include mq-up(lg) {
    margin-left: 0;
    transform: scale(1);
  }
}

.serviceoffice-FormA_Textarea-error {
  background-color: #fceded;
}

.serviceoffice-FormA_Note {
  margin-top: 10px;
  font-size: rem(10);
  text-align: center;
  line-height: 1.6;

  @include mq-up(lg) {
    margin-top: 12px;
    font-size: rem(11, lg);
  }

  &:before {
    content: "※";
  }
}
