/*
---
name: sw-LinkExcel
namespace: SiteWide
category: Link
---

エクセルファイルへのリンクのときに追加します。

```html
<div class="st-Wrapper">
<p><a href="#" class="sw-Link" target="_blank">http://example.com/<span class="sw-LinkExcel" aria-hidden="true"></a></p>
</div>
```
*/
.sw-LinkExcel:before {
  @include sw-LinkExcel();
}
