/*
---
name: sw2-MapImageList
namespace: SiteWide2
category: Image
---

画像が横並びで並ぶパーツです。

```html
<div class="sw2-MapImageList">
  <div class="sw2-MapImageList_In">
    <div class="sw2-MapImageList_Item">
      <div class="sw2-MapImageList_ItemImage">
        <picture>
          <img src="/assets/img/kaigi/index/image_maplist_01.jpg" alt="" loading="lazy">
        </picture>
        <div class="sw2-MapImageList_ItemImageText">1号館外観</div>
      </div>
    </div>
    <div class="sw2-MapImageList_Item">
      <div class="sw2-MapImageList_ItemImage">
        <picture>
          <img src="/assets/img/kaigi/index/image_maplist_02.jpg" alt="" loading="lazy">
        </picture>
        <div class="sw2-MapImageList_ItemImageText">4号館外観</div>
      </div>
    </div>
    <div class="sw2-MapImageList_Item">
      <div class="sw2-MapImageList_ItemImage">
        <picture>
          <img src="/assets/img/kaigi/index/image_maplist_03.jpg" alt="" loading="lazy">
        </picture>
        <div class="sw2-MapImageList_ItemImageText">GOCONC外観</div>
      </div>
    </div>
    <div class="sw2-MapImageList_Item">
      <div class="sw2-MapImageList_ItemImage">
        <picture>
          <img src="/assets/img/kaigi/index/image_maplist_04.jpg" alt="" loading="lazy">
        </picture>
        <div class="sw2-MapImageList_ItemImageText">GOCONCカフェ</div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-MapImageList {
  padding-top: rem(35);
  background-color: #f5f5f5;
  @include mq-up(lg) {
    padding-top: rem(60, lg);
  }
}
.sw2-MapImageList_In {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: rem(-5);
  @include mq-up(lg) {
    margin-left: rem(-12, lg);
  }
}
.sw2-MapImageList_Item {
  width: percentage(div(1, 4));
  padding-left: rem(5);
  @include mq-up(lg) {
    padding-left: rem(12, lg);
  }
}
.sw2-MapImageList_ItemImage {
  position: relative;
  img {
    width: 100%;
  }
}
.sw2-MapImageList_ItemImageText {
  font-weight: 500;
  font-size: rem(10);
  line-height: rem(10);
  color: #fff;
  position: absolute;
  bottom: rem(3);
  right: rem(3);
  @include mq-up(lg) {
    bottom: rem(16, lg);
    right: rem(16, lg);
    font-size: rem(13, lg);
    line-height: rem(13, lg);
  }
}
