.st-PageCategory {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 14px 40px 14px 20px;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px solid #cdcdcd;
  color: $color-brand;

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
    font-size: rem(36, lg);
    text-align: center;
    border-bottom: 0;
  }
}

.st-PageCategory_Small {
  display: none;

  @include mq-up(lg) {
    display: block;
    margin-top: 7px;
    font-size: rem(14, lg);
    text-transform: uppercase;
  }
}

.st-PageCategory_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  color: $color-brand;
  transition-duration: 0.3s;

  .st-PageCategory[aria-expanded="true"] & {
    transform: rotate(-180deg);
  }

  @include mq-up(lg) {
    display: none;
  }
}
