.st-HeaderLogo {
  display: table;
  padding: 11px 6px 16.5px 10.5px;
  color: #fff;
  background-color: $color-krp;
  text-decoration: none;

  @include mq-up(lg) {
    height: 100px;
    padding: 25px 14px 26px 18px;

    .st-Header-fixedActive & {
      height: 70px;
      padding: 16px 0 26px;
      background-color: transparent;
    }
  }

  @media print, screen and (min-width: 1300px) {
    height: 106px;
    padding: 35px 14px 40px 16px;
  }
}

.st-HeaderLogo_SiteLogo {
  display: table-cell;
  vertical-align: middle;

  @include mq-up(lg) {
    display: block;

    .st-Header-fixedActive & {
      display: table-cell;
    }
  }

  @media print, screen and (min-width: 1300px) {
    display: table-cell;
  }
}

.st-HeaderLogo_SiteName {
  display: table-cell;
  vertical-align: middle;
  padding-left: 8px;

  @include mq-up(sm) {
    padding-left: 16px;
  }

  @include mq-up(lg) {
    display: block;
    height: 12px;
    padding-left: 0;

    .st-Header-fixedActive & {
      display: table-cell;
      padding-left: 8px;
    }
  }

  @media print, screen and (min-width: 1300px) {
    display: table-cell;
    height: auto;
    padding-left: 8px;
  }
}

.st-HeaderLogo_SiteLogoImage {
  width: 57px;

  @include mq-up(lg) {
    width: 75px;

    .st-Header-fixedActive & {
      width: 55px;
    }
  }

  @media print, screen and (min-width: 1300px) {
    width: 91px;
  }
}

.st-HeaderLogo_SiteNameImage {
  width: 134.5px;

  @include mq-up(lg) {
    width: 167px;

    .st-Header-fixedActive & {
      width: 124px;
    }
  }

  @media print, screen and (min-width: 1300px) {
    width: 204px;
  }
}
