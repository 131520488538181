/*
---
name: st-Heading5
namespace: Structure
category: Layout
---

h5相当の見出し自身の余白を指定します。

```html
<h5 class="st-Heading5">.st-Heading5</h5>
<p class="st-Text">.st-Text</p>
<p class="st-Text">.st-Text</p>
```
*/
.st-Heading5 {
  margin-bottom: $margin-heading5;

  @include mq-up(lg) {
    margin-bottom: $margin-heading5-lg;
  }
}
