/*
---
name: sw-CardNavE
namespace: SiteWide
category: Navigation
id: [L1, TH-4]
---

<span class="sw-Label">TH-4</span>

```html
<div class="st-Wrapper">
<ul class="sw-CardNavE_Items">
  <li class="sw-CardNavE_Item">
    <a href="#" class="sw-CardNavE_Link">
      <div class="sw-CardNavE_Head">
        <img class="sw-CardNavE_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-CardNavE_Body">
        <div class="sw-CardNavE_Text">
          京都大学デザインスクールの創造性開発のノウハウを生かして社員研修を実施
        </div>
      </div>
    </a>
  </li>
  <li class="sw-CardNavE_Item">
    <a href="#" class="sw-CardNavE_Link">
      <div class="sw-CardNavE_Head">
        <img class="sw-CardNavE_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-CardNavE_Body">
        <div class="sw-CardNavE_Text">
          平成29年度 再生医療サポートビジネス懇話会 第2回開催後記(はんなり雑記) を掲載しました。
        </div>
      </div>
    </a>
  </li>
  <li class="sw-CardNavE_Item">
    <a href="#" class="sw-CardNavE_Link">
      <div class="sw-CardNavE_Head">
        <img class="sw-CardNavE_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-CardNavE_Body">
        <div class="sw-CardNavE_Text">
          京都大学デザインスクールの創造性開発のノウハウを生かして社員研修を実施
        </div>
      </div>
    </a>
  </li>
  <li class="sw-CardNavE_Item">
    <a href="#" class="sw-CardNavE_Link">
      <div class="sw-CardNavE_Head">
        <img class="sw-CardNavE_Image" src="https://placehold.jp/300x200.png" alt="">
      </div>
      <div class="sw-CardNavE_Body">
        <div class="sw-CardNavE_Text">
          平成29年度 再生医療サポートビジネス懇話会 第2回開催後記(はんなり雑記) を掲載しました。
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```
*/
.sw-CardNavE_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  &.-is-col4pc {
    .sw-CardNavE_Item {
      @include mq-up(lg) {
        width: percentage(div(1, 4));
    
        & + & {
          margin-top: 0;
        }
    
        &:nth-of-type(n+4) {
          margin-top: 0;
        }
      }
    }
    .sw-CardNavE_Head {
      @include mq-up(lg) {
        margin-top: 0;
        border-bottom-width: 7px;
      }
    }
  }
}

.sw-CardNavE_Item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;

  & + & {
    margin-top: $grid-row-gap;
  }

  @include mq-up(lg) {
    width: percentage(div(1, 3));

    & + & {
      margin-top: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $grid-row-gap-lg;
    }
  }
}

.sw-CardNavE_Link {
  display: block;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;

  &:hover {
    opacity: $opacity;
  }
}

.sw-CardNavE_Head {
  margin-bottom: 12px;
  border-bottom: 5px solid #000;

  .sw-CardNavE_Item:nth-of-type(even) & {
    border-bottom-color: $color-brand;
  }

  @include mq-up(lg) {
    margin-top: 10px;
    border-bottom-width: 7px;
  }
}

.sw-CardNavE_Image {
  display: block;
  width: 100%;
  min-width: none;
}

.sw-CardNavE_Body {
  padding-right: 30px;
}

.sw-CardNavE_Text { 
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}
