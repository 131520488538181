/*
---
name: st-PageLink2
namespace: Structure
category: Navigation
id: [K1, ACL-2]
---

<span class="sw-Label">ACL-2</span>

ページ内リンクです。md以下の場合、1行表示です.

```html
<div class="st-PageLink2">
  <ul class="st-PageLink2_Items">
    <li class="st-PageLink2_Item">
      <a href="#ref1" class="st-PageLink2_Link" data-scroll><span class="st-PageLink2_Icon" aria-hidden="true"></span>京都駅より</a>
    </li>
    <li class="st-PageLink2_Item">
      <a href="#ref2" class="st-PageLink2_Link" data-scroll><span class="st-PageLink2_Icon" aria-hidden="true"></span>JR丹波口駅より</a>
    </li>
    <li class="st-PageLink2_Item">
      <a href="#ref3" class="st-PageLink2_Link" data-scroll><span class="st-PageLink2_Icon" aria-hidden="true"></span>阪急西院駅・大宮駅より</a>
    </li>
    <li class="st-PageLink2_Item">
      <a href="#ref4" class="st-PageLink2_Link" data-scroll><span class="st-PageLink2_Icon" aria-hidden="true"></span>地下鉄五条駅より</a>
    </li>
    <li class="st-PageLink2_Link_Item">
      <a href="#ref5" class="st-PageLink2_Link" data-scroll><span class="st-PageLink2_Icon" aria-hidden="true"></span>京阪清水五条駅より</a>
    </li>
  </ul>
</div>
```
*/
.st-PageLink2 {
  margin-top: 20px;

  @include mq-up(lg) {
    max-width: $max-width;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
  }

  &.-is-pc3col {
    .st-PageLink2_Item {
      @include mq-up(lg) {
        width: percentage(div(1, 3));
      }
    }
  }
}

.st-PageLink2_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
}

.st-PageLink2_Item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: rem(12);
  font-weight: bold;
  line-height: 1.4;
  @include border-collapse(#e8e8e8, 1px);

  @include mq-up(lg) {
    width: percentage(div(1, 4));
    font-size: rem(14, lg);
  }
}

.st-PageLink2_Link {
  display: block;
  padding: 1.3571428571rem .8571428571rem;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;
  @include mq-up(lg) {
    padding: 13px 40px 13px 15px;
    text-align: center;
  }
}

.st-PageLink2_Icon {
  &:after {
    @include Icon("icon_arrow-right");
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    color: $color-brand;

    @include mq-up(lg) {
      font-size: 1em;
      position: static;
      margin-right: 0.8rem;
    }
  }
}
 
.st-PageLink2_Link {
  &:hover {
    .st-PageLink2_Icon {
      &:after {
        @include Icon("icon_arrow-down");
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        width: 1em;
        height: 1em;
        margin: auto;
        color: $color-brand;
    
        @include mq-up(lg) {
          position: static;
          margin-right: 0.8rem;
          font-size: 1em;
        }
      }
    }
    transition-duration: unset;
    border-left: 4px solid #be3134;
    @include mq-up(md) {
       border-left: none;
       box-shadow: 1px 1px 0 0 #e8e8e8, inset 1px 1px 0 0 #e8e8e8;
    }
  }
  &.-is-active {
    .st-PageLink2_Icon {
      &:after {
        @include Icon("icon_arrow-down");
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        width: 1em;
        height: 1em;
        margin: auto;
        color: $color-brand;
    
        @include mq-up(lg) {
          position: static;
          margin-right: 0.8rem;
          font-size: 1em;
        }
      }
    }
    transition-duration: unset;
    border-left: 4px solid #be3134;
    @include mq-up(md) {
       border-left: none;
       box-shadow: 1px 1px 0 0 #e8e8e8, inset 1px 1px 0 0 #e8e8e8;
       position: relative;
       &:after {
         position: absolute;
         bottom: 0;
         width: 100%;
         height: 5px;
         background-color: #be3134;
       }
    }
  }
}
@include mq-up(lg) {
  .st-PageLink2_Item a {
     position: relative;
  }
  .st-PageLink2_Item a::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 0;
    height: 5px;
    background-color: #be3134;
    transition: .3s;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .st-PageLink2_Item a:hover::after {
    // width: 100%;
  }
} 
