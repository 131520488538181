/*
---
name: sw-VisualNavC
namespace: SiteWide
category: Navigation
id: [L1, CA-5]
---

<span class="sw-Label">CA-5</span>

- プラットフォームTOPのみ

```html
<div class="st-ContainerB st-ContainerB-gray">
<div class="st-Wrapper">
<ul class="sw-VisualNavC_Items">
  <li class="sw-VisualNavC_Item">
    <a class="sw-VisualNavC_Link" href="#">
      <div class="sw-VisualNavC_Head">
        <img class="sw-VisualNavC_HeadImage" src="/assets/img/_events/event/image02.jpg" alt="">
        <div class="sw-VisualNavC_HeadOvelay"></div>
        <div class="sw-VisualNavC_HeadItem">
          <div class="sw-VisualNavC_HeadText">再生医療プラットフォーム</div>
          <div class="sw-VisualNavC_HeadIconArea">
            <span class="sw-VisualNavC_HeadButton">
              <span class="sw-VisualNavC_HeadIcon" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="sw-VisualNavC_Foot">
        <div class="sw-VisualNavC_FootItem">
          <div class="sw-VisualNavC_FootImageArea">
            <img class="sw-VisualNavC_FootImage" src="/assets/img/common/logo_platform.png" alt="">
          </div>
          <div class="sw-VisualNavC_FootTextArea">
            <p class="sw-VisualNavC_FootText">モノづくりの視点から、再生医療をはじめとする医療・ライフサイエンス分野の早期実用化をサポート・産業化を図り、モノづくり企業の高付加価値化・イノベーションを図ります。</p>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="sw-VisualNavC_Item">
    <a class="sw-VisualNavC_Link" href="#">
      <div class="sw-VisualNavC_Head">
        <img class="sw-VisualNavC_HeadImage" src="/assets/img/_events/event/image01.jpg" alt="">
        <div class="sw-VisualNavC_HeadOvelay"></div>
        <div class="sw-VisualNavC_HeadItem">
          <div class="sw-VisualNavC_HeadText">再生医療プラットフォーム</div>
          <div class="sw-VisualNavC_HeadIconArea">
            <span class="sw-VisualNavC_HeadButton">
              <span class="sw-VisualNavC_HeadIcon" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="sw-VisualNavC_Foot">
        <div class="sw-VisualNavC_FootItem">
          <div class="sw-VisualNavC_FootImageArea">
            <img class="sw-VisualNavC_FootImage" src="/assets/img/common/logo_hvc.png" alt="">
          </div>
          <div class="sw-VisualNavC_FootTextArea">
            <p class="sw-VisualNavC_FootText">ごく初期の技術インキュベーションに特化し、ライフサイエンス分野の革新的な発見を世界のヘルスケア課題解決に資する製品へと成長させるプラットフォームを提供します。</p>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="sw-VisualNavC_Item">
    <a class="sw-VisualNavC_Link" href="#">
      <div class="sw-VisualNavC_Head">
        <img class="sw-VisualNavC_HeadImage" src="/assets/img/_events/event/image01.jpg" alt="">
        <div class="sw-VisualNavC_HeadOvelay"></div>
        <div class="sw-VisualNavC_HeadItem">
          <div class="sw-VisualNavC_HeadText">再生医療プラットフォーム</div>
          <div class="sw-VisualNavC_HeadIconArea">
            <span class="sw-VisualNavC_HeadButton">
              <span class="sw-VisualNavC_HeadIcon" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="sw-VisualNavC_Foot">
        <div class="sw-VisualNavC_FootItem">
          <div class="sw-VisualNavC_FootImageArea">
            <img class="sw-VisualNavC_FootImage" src="/assets/img/common/logo_rikamo.png" alt="">
          </div>
          <div class="sw-VisualNavC_FootTextArea">
            <p class="sw-VisualNavC_FootText">多方面で活躍するクリエイターの方々を招き、ともに語り、双方向のコミュニケーションを体験することで、つながりの深いモノづくりの場を共有していきたい。</p>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="sw-VisualNavC_Item">
    <a class="sw-VisualNavC_Link" href="#">
      <div class="sw-VisualNavC_Head">
        <img class="sw-VisualNavC_HeadImage" src="/assets/img/_events/event/image01.jpg" alt="">
        <div class="sw-VisualNavC_HeadOvelay"></div>
        <div class="sw-VisualNavC_HeadItem">
          <div class="sw-VisualNavC_HeadText">再生医療プラットフォーム</div>
          <div class="sw-VisualNavC_HeadIconArea">
            <span class="sw-VisualNavC_HeadButton">
              <span class="sw-VisualNavC_HeadIcon" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="sw-VisualNavC_Foot">
        <div class="sw-VisualNavC_FootItem">
          <div class="sw-VisualNavC_FootImageArea">
            <img class="sw-VisualNavC_FootImage" src="/assets/img/common/logo_hvc.png" alt="">
          </div>
          <div class="sw-VisualNavC_FootTextArea">
            <p class="sw-VisualNavC_FootText">ごく初期の技術インキュベーションに特化し、ライフサイエンス分野の革新的な発見を世界のヘルスケア課題解決に資する製品へと成長させるプラットフォームを提供します。</p>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="sw-VisualNavC_Item">
    <a class="sw-VisualNavC_Link" href="#">
      <div class="sw-VisualNavC_Head">
        <img class="sw-VisualNavC_HeadImage" src="/assets/img/_events/event/image01.jpg" alt="">
        <div class="sw-VisualNavC_HeadOvelay"></div>
        <div class="sw-VisualNavC_HeadItem">
          <div class="sw-VisualNavC_HeadText">再生医療プラットフォーム</div>
          <div class="sw-VisualNavC_HeadIconArea">
            <span class="sw-VisualNavC_HeadButton">
              <span class="sw-VisualNavC_HeadIcon" aria-hidden="true"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="sw-VisualNavC_Foot">
        <div class="sw-VisualNavC_FootItem">
          <div class="sw-VisualNavC_FootImageArea">
            <img class="sw-VisualNavC_FootImage" src="/assets/img/common/logo_rikamo.png" alt="">
          </div>
          <div class="sw-VisualNavC_FootTextArea">
            <p class="sw-VisualNavC_FootText">多方面で活躍するクリエイターの方々を招き、ともに語り、双方向のコミュニケーションを体験することで、つながりの深いモノづくりの場を共有していきたい。</p>
          </div>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
</div>
```
*/
.sw-VisualNavC_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -1px;
  }

  & .sw-MediaA_ButtonArea {
    margin-top: 10px;

    @include mq-up(lg) {
      margin-top: 17px;
    }
  }

  & .sw-FeatureNavB_Heading {
    margin-top: 5px;

    @include mq-up(lg) {
      margin-top: 10px;
    }
  }
}

.sw-VisualNavC_Item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;

  & + & {
    margin-top: 35px;
  }

  @include mq-up(lg) {
    width: percentage(div(1, 3));
    padding-left: 1px;

    & + & {
      margin-top: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $grid-row-gap-lg;
    }
  }
}

.sw-VisualNavC_Link {
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.sw-VisualNavC_Head {
  position: relative;
}

.sw-VisualNavC_HeadImage {
  display: block;
  width: 100%;
  max-width: none;
}

.sw-VisualNavC_HeadOvelay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.35);
}

.sw-VisualNavC_HeadItem {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 100%;
  padding: 0 25px;
  text-align: center;
}

.sw-VisualNavC_HeadText {
  font-size: rem(18);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(22, lg);
  }
}

.sw-VisualNavC_HeadIconArea {
  margin-top: 10px;
}

.sw-VisualNavC_HeadIcon:before {
  @include Icon("icon_arrow-circle-right");
  width: 1em;
  height: 1em;
  margin: auto;
  color: #fff;
  font-size: 1.5em;

  @include mq-up(lg) {
    font-size: 1.6em;
  }
}

.sw-VisualNavC_Foot {
  position: relative;
  width: calc(100% - 26px);
  margin: -20px auto 0;
}

.sw-VisualNavC_FootItem {
  padding: 17px 20px;
  color: $color-text;
  background-color: #fff;

  @include mq-up(lg) {
    padding: 20px 15px;
  }
}

.sw-VisualNavC_FootImageArea {
  text-align: center;
}

.sw-VisualNavC_FootImage {

}

.sw-VisualNavC_FootTextArea {
  margin-top: 16px;
}

.sw-VisualNavC_FootText {
  font-size: rem(14);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: 1.6;
  }
}
