/*
---
name: sw-ImageBox
namespace: SiteWide
category: Image
id: [K2, IMG-7]
---

<span class="sw-Label">IMG-7</span>

画像全体にボーダーをつけます。

- 画像自体は中央揃えになります。
- 余白はつかないので、画像自体に余白を入れて調整してください。

```html
<div class="st-Wrapper">
<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>

<div class="sw-ImageBox st-Image">
  <img src="https://placehold.jp/300x200.png" alt="">
</div>

<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>

</div>
```
*/
.sw-ImageBox {
  text-align: center;
  border: 2px solid #e8e8e8;

  @include mq-up(lg) {
    border-width: 1px;
  }
}
