/*
---
name: st-Breadcrumb
namespace: Structure
category: Navigation
id: K1
---

ベーシックなパンくずリストです。`<ol>`タグで指定します。

```html
<div class="st-Wrapper">
<div class="st-Breadcrumb">
  <div class="st-Breadcrumb_Inner">
    <ol class="st-Breadcrumb_Items">
      <li class="st-Breadcrumb_Item"><a href="/" class="st-Breadcrumb_Link">ホーム</a></li>
      <li class="st-Breadcrumb_Item"><a href="#" class="st-Breadcrumb_Link">カテゴリ名</a></li>
      <li class="st-Breadcrumb_Item">ページ名</li>
    </ol>
  </div>
</div>
</div>
```
*/
.st-Breadcrumb {
  padding: 12px 0;
  background-color: $color-krp;
  border-bottom: 1px solid rgba(#fff, 0.3);

  @include mq-up(lg) {
    padding: 14px 16px;
  }
}

.st-Breadcrumb_Inner {
  max-width: $max-width;
  margin: auto;
  padding: 0 25px;

  @include mq-up(lg) {
    padding: 0;
  }
}

.st-Breadcrumb_Items {
  overflow: hidden;
  padding-left: 0;
  font-size: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  list-style-type: none;
}

.st-Breadcrumb_Item {
  display: inline-block;
  font-size: rem(9);
  line-height: 1.2;
  font-weight: bold;
  color: #fff;

  &:last-child {
    display: inline;
  }

  &:not(:first-of-type):before {
    content: "/";
    margin: 0 12px;

    @include mq-up(lg) {
      margin: 0 10px;
    }
  }

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}

.st-Breadcrumb_Link {
  display: inline-block;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
