/*
---
name: st-Section3
namespace: Structure
category: Layout
---

h3相当の見出しをラップするセクションの余白を指定します。

レイアウト例です。

```html
<div class="st-Wrapper">
<section class="st-Section2">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
  <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>

  <section class="st-Section3">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>

  <section class="st-Section3">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>
</section>

<section class="st-Section2">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>

  <section class="st-Section3">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
  そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
  私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
  <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>
</section>
</div>
```

レイアウト例です。`.st-Section3-divider`を追加すると`.st-Section3`同士の間にボーダーがつきます。

```html
<div class="st-Wrapper">
<section class="st-Section2">
  <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>

  <section class="st-Section3 st-Section3-divider">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>

  <section class="st-Section3 st-Section3-divider">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>

  <section class="st-Section3 st-Section3-divider">
    <h3 class="sw-Heading3 st-Heading3">「産学公連携拠点・新産業創出拠点」</h3>
    <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
    そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
    私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
    <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
  </section>
</section>
</div>
```
*/
.st-Section3 {
  &[id]:before {
    content: "";
    display: block;
    margin-top: -120px;
    padding-top: 120px;
  }
  
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section3;

    @include mq-up(lg) {
      margin-top: $margin-section3-lg;
    }
  }

  .st-Heading2 + & {
    margin-top: div($margin-section3, 2);

    @include mq-up(lg) {
      margin-top: div($margin-section3-lg, 2);
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-Section3-divider {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section3-divider;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      padding-top: $margin-section3-divider;
      border-top: 1px solid #e3e3e3;
    }

    @include mq-up(lg) {
      margin-top: $margin-section3-divider-lg;

      &:before {
        padding-top: $margin-section3-divider-lg;
      }
    }
  }

  .st-Heading2 + &:before {
    display: none;

    @include mq-up(lg) {
      display: none;
    }
  }
  
}
