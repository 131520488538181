/*
---
name: sw-Calender
namespace: SiteWide
category: ThirdPartyComponent
---

Googleカレンダーのウィジェットをレスポンシブで表示します。デフォルトではスマホで2:3、PCで16:9で表示されます。

```html
<div class="st-Wrapper">
  <div class="sw-Calender">
    <iframe src="https://www.google.com/calendar/embed?showTitle=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=qisaib7funm1v2tgoeq9nio1uk%40group.calendar.google.com&amp;color=%236B3304&amp;src=r79krh6r2ie445fv9vb3kcsfpk%40group.calendar.google.com&amp;color=%238C500B&amp;src=nigiwai.krp%40gmail.com&amp;color=%235F6B02&amp;ctz=Asia%2FTokyo" style=" border-width:0 " width="980" height="600" scrolling="no"></iframe>
  </div>
</div>
```
*/
.sw-Calender {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(div(3, 2));

  @include mq-up(lg) {
    padding-bottom: percentage(div(9, 16));
  }

  & > *,
  iframe,
  embed,
  object,
  video {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
}
