/*
---
name: ListNoteOrder
namespace: SiteWide
category: List
---

注釈用のリストです。米印やアスタリスクが表示されます。

```html
<ol class="sw-ListNoteOrder">
  <li>注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。</li>
  <li>注釈です。</li>
</ol>
<div lang="en">
  <ul class="sw-ListNoteOrder">
    <li>This is an annotation. This is an annotation. This is an annotation.</li>
    <li>This is an annotation.</li>
  </ul>
</div>
```

リストを2から始める場合は`.sw-ListNoteOrder-from2`のように指定します。2~10まで対応しています。CSSが正常に読み込めなかった場合でも意味が変わらないように`start`属性も指定します。

```html
<ol start="2" class="sw-ListNoteOrder sw-ListNoteOrder-from2">
  <li>注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。</li>
  <li>注釈です。</li>
</ol>
<div lang="en">
  <ol start="2" class="sw-ListNoteOrder sw-ListNoteOrder-from2">
    <li>This is an annotation. This is an annotation. This is an annotation.</li>
    <li>This is an annotation.</li>
  </ol>
</div>
```
*/
.sw-ListNoteOrder {
  margin-left: 2em;
  padding-left: 0;
  font-size: $font-size-secondary;
  list-style-type: none;
  counter-reset: noteOrder 0;

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1.5em;
  }

  & > li {
    position: relative;
    counter-increment: noteOrder;
  }

  & > li:target {
    background-color: lightgray;
  }

  & > li:before {
    content: "※" counter(noteOrder);
    position: absolute;
    top: 0;
    left: -2em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*" counter(noteOrder);
    left: -1.5em;
  }
}

.sw-ListNoteOrder-from2 { counter-reset: noteOrder 1; }
.sw-ListNoteOrder-from3 { counter-reset: noteOrder 2; }
.sw-ListNoteOrder-from4 { counter-reset: noteOrder 3; }
.sw-ListNoteOrder-from5 { counter-reset: noteOrder 4; }
.sw-ListNoteOrder-from6 { counter-reset: noteOrder 5; }
.sw-ListNoteOrder-from7 { counter-reset: noteOrder 6; }
.sw-ListNoteOrder-from8 { counter-reset: noteOrder 7; }
.sw-ListNoteOrder-from9 { counter-reset: noteOrder 8; }
.sw-ListNoteOrder-from10 { counter-reset: noteOrder 9; }
