/*
---
name: sw-Lead
namespace: SiteWide
category: Text
id: [K2, TX-1A, TX-1B]
---

<span class="sw-Label">TX-1A</span>

リード文（コンテンツの冒頭に登場するコンテンツを簡潔に表した文章）のスタイルです。余白には`.st-Lead`を使用します。

他の文章と区別するためだけに利用し、`<strong>`の重要性や深刻性、`<em>`の強調（アクセント）、`<h2>`のような見出しとしての意味はありません。

- PC未満では`<br>`は適応されません。

```html
<div class="st-Wrapper">
<p class="sw-Lead st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、<br>テクノロジーをベースとしたスタートアップをサポートし、<br>『京都発世界』の企業を生み出すことを目指します。</p>
</div>
```

<span class="sw-Label">TX-1B</span>

リード文に英語表記を追加できます。

```html
<div class="st-Wrapper">
<p class="sw-Lead st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、<br>テクノロジーをベースとしたスタートアップをサポートし、<br>『京都発世界』の企業を生み出すことを目指します。
  <span class="sw-Lead_Foot">The HVC KYOTO focuses on early stage technology incubation in life science field to bridge basic and local scientific knowledge and commercial developments for the global market. I</span></p>
</div>
```

<span class="sw-Label">TX-1C</span>

常に左揃えにします。

```html
<div class="st-Wrapper">
<p class="sw-Lead sw-Lead-left st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、<br>テクノロジーをベースとしたスタートアップをサポートし、<br>『京都発世界』の企業を生み出すことを目指します。</p>
</div>
```
*/
.sw-Lead {
  font-family: $font-family-heading;
  font-size: rem(15);
  font-weight: 700;
  line-height: 1.6;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    font-size: rem(20, lg);
    text-align: center;

    & br {
      display: inline;
    }
  }
}

.sw-Lead-left {
  @include mq-up(lg) {
    text-align: left;
  }
}

.sw-Lead_Foot {
  display: block;
  margin-top: 35px;
  font-family: $font-family-en;
  font-weight: 300;
  line-height: 1.5;
  color: #999;

  @include mq-up(lg) {
    margin-top: 20px;
  }
}
