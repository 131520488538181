/*
---
name: sw-Heading3
namespace: SiteWide
category: Heading
id: [K2, HD-5]
---

<span class="sw-Label">HD-5</span>

`<h3>`で使用する見出しです。余白には`.st-Heading3`を使用します。

```html
<div class="st-Wrapper">
<h3 class="sw-Heading3 st-Heading3">機器利用</h3>
</div>
```

```html
<div class="st-Wrapper">
<h3 class="sw-Heading3 st-Heading3">ここにテキストが入ります<br>改行した場合のレイアウトです</h3>
</div>
```
*/
.sw-Heading3 {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  font-family: $font-family-heading;
  font-size: rem(20);
  font-weight: bold;
  line-height: 1.4;
  border-left: 5px solid #be3134;

  @include mq-up(lg) {
    padding-left: 13px;
    font-size: rem(20, lg);
    border-left: 6px solid #be3134;
  }
}
