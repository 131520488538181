.st-HeaderMembers {
  padding: 0 20px;
  list-style-type: none;

  @include mq-up(lg) {
    display: none;
  }
}

.st-HeaderMembers_Item {

}

.st-HeaderMembers_Link {
  position: relative;
  display: block;
  padding: 0.5em 2.5em 0.5em 1em;
  font-size: rem(13);
  color: #fff;
  text-decoration: none;
  background-color: rgba(#393939, 0.5);
}

.st-HeaderMembers_Icon {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 14px;
  height: 14px;
  margin: auto;
  
  &:before {
    @include Icon("icon_arrow-right");

    html[lang="ja"] & {
      position: relative;
      top: -0.25em;
    }
  }
}
