/*
---
name: st-LocalNav5
namespace: Structure
category: Navigation
id: [K1, NV3]
---

<span class="sw-Label">NV3</span>

ローカルナビゲーションです。PT2やPT2-2と併用して、PT3単体で使用する場合はこちらを使用します。

- URLの2階層目（`/events/event/`など）と合致する場合はカレント表示されます。

```html
<header class="st-LocalNav5">
  <div class="st-Wrapper">
    <div class="st-LocalNav5_Head">
      <p class="st-LocalNav5_Title" id="st-LocalNav5_Title" aria-controls="st-LocalNav5_Nav" aria-expanded="false" role="heading">
        イベント
        <span class="st-LocalNav5_Icon" aria-hidden="true"></span>
      </p>
    </div>

    <div class="st-LocalNav5_Nav" id="st-LocalNav5_Nav" aria-labelledby="st-PageCategory" aria-hidden="true">
      <ul class="st-LocalNav5_Items">
        <li class="st-LocalNav5_Item">
          <a href="/news/" class="st-LocalNav5_Link">すべて</a>
        </li>
        <li class="st-LocalNav5_Item">
          <a href="/news/news/" class="st-LocalNav5_Link">KRPeople</a>
        </li>
        <li class="st-LocalNav5_Item">
          <a href="/news/media/" class="st-LocalNav5_Link">コラボ事例</a>
        </li>
      </ul>
    </div>
  </div>
</header>
```

右揃えにする場合は`.st-LocalNav5-right`を追加します。主に日本語以外のページで使用します。

```html
<div class="st-LocalNav5 st-LocalNav5-right">
  <div class="st-Wrapper">
    <div class="st-LocalNav5_Head">
      <p class="st-LocalNav5_Title" id="st-LocalNav5_Title2" aria-controls="st-LocalNav5_Nav2" aria-expanded="false" role="heading">
        イベント
        <span class="st-LocalNav5_Icon" aria-hidden="true"></span>
      </p>
    </div>

    <div class="st-LocalNav5_Nav" id="st-LocalNav5_Nav2" aria-labelledby="st-LocalNav5_Title2" aria-hidden="true">
      <ul class="st-LocalNav5_Items">
        <li class="st-LocalNav5_Item">
          <a href="/news/" class="st-LocalNav5_Link" lang="en">English</a>
        </li>
        <li class="st-LocalNav5_Item">
          <a href="/news/news/" class="st-LocalNav5_Link" lang="zh-cn">中文</a>
        </li>
      </ul>
    </div>
  </div>
</div>
```
*/
.st-LocalNav5 {
  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.st-LocalNav5_Head {

}

.st-LocalNav5_Title {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 14px 40px 14px 20px;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px solid #393939;
  color: #fff;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  @include mq-up(lg) {
    display: none;
  }
}

.st-LocalNav5_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  transition-duration: 0.3s;

  .st-LocalNav5_Title[aria-expanded="true"] & {
    transform: rotate(-180deg);
  }
}

.st-LocalNav5_Nav {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: calc(50vw - 50%);
  padding-bottom: 15px;
  padding-left: calc(50vw - 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav5_Items {
  display: block;
  margin-left: -2.5px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  line-height: 1.2;

  @include mq-up(lg) {
    text-align: center;

    .st-LocalNav5-right & {
      text-align: right;
    }
  }
}

.st-LocalNav5_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  padding-left: 2.5px;
  font-size: rem(9);
  text-align: center;

  &:nth-of-type(n+4) {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    font-size: rem(12, lg);

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    &:nth-of-type(n+1) {
      margin: 9px 0;
    }

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 30px;
      border-left: 1px solid $color-brand;
    }
  }
}

.st-LocalNav5_Link {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 8px 15px;
    font-weight: bold;
    color: $color-brand;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -14px;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.st-LocalNav5_Link-current {
  background-color: #a81d34;

  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}
