/*
---
name: st-LocalNav6
namespace: Structure
category: Navigation
id: [K1, NV3]
---

ローカルナビゲーションです。

- スマホではst-LocalNav6と同じ
- PCでは画面右端に固定されます

```html
<div class="st-Wrapper">
<div class="st-LocalNav6">
  <ul class="st-LocalNav6_Items">
    <li class="st-LocalNav6_Item">
      <a href="/_events/event/" class="st-LocalNav6_Link">すべて</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">イベント</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">イベントレポート</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">スタートアップ</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">ビジネス</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">ものづくり</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">デザイン</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">伝統工芸</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">電子・エネルギー</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">ライフサイエンス</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">2017</a>
    </li>
    <li class="st-LocalNav6_Item">
      <a href="" class="st-LocalNav6_Link">2016</a>
    </li>
  </ul>
</div>
</div>
```
*/
.st-LocalNav6 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: calc(50vw - 50%);
  padding-bottom: 15px;
  padding-left: calc(50vw - 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    position: fixed;
    right: 0;
    top: 206px;
    z-index: 1000;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav6_Items {
  display: block;
  margin-left: -2.5px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  line-height: 1.2;

  @include mq-up(lg) {
    padding-top: 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

.st-LocalNav6_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  padding-left: 2.5px;
  font-size: rem(9);
  text-align: center;

  &:nth-of-type(n+4) {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    font-size: rem(14, lg);

    & + & {
      margin-top: 1px;
    }
  }
}

.st-LocalNav6_Link {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  .st-LocalNav6_Item:nth-of-type(1) & {
    background-color: #a81d34;
  }

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 23px 10px;
    color: #fff;
    background-color: #222;

    .st-LocalNav6_Item:nth-of-type(1) & {
      background-color: $color-brand;
    }
  }
}

