.st-gmap_LabPortal {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;

  @include mq-up(lg) {
    padding-bottom: 56.25%;
  }
}

#gmap_LabPortal_Zenkoku,
#gmap_LabPortal_Kansai,
#gmap_LabPortal_Kanto {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.st-gmap_Window_Name {
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}

.st-gmap_Window_Name a {
  color: black;
}

.st-gmap_Window {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic,
    "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  width: 160px;

  @include mq-up(lg) {
    width: 200px;
  }
}

.st-gmap_Window_Content {
  margin-top: 9px;
}

.gm-style .gm-style-iw-t::after {
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transform: translate(-50%, -52%) rotate(-45deg);
}

.gm-style-iw-t {
  & button {
    top: -3px !important;
    right: 0px !important;
  }
}

.gm-style-iw-d {
  width: 200px;

  @include mq-up(lg) {
    width: 230px;
  }
}

.gm-style-iw {
  width: 200px;
  border: 2px black solid;

  @include mq-up(lg) {
    width: 250px;
  }

  &.gm-style-iw-c {
    border-radius: 12px;
    padding: 15px 15px 8px !important;

    @include mq-up(lg) {
      padding: 17px 22px 8px !important;
    }
  }

  & a {
    font-size: rem(14);
  }
}

.gm-style {
  & .gm-style-iw-c {
    top: 0.3px;
  }
}

.st-gmap_Window_Content {
  font-size: rem(10);
}

#LblFirstName {
  width: 110px;
}
