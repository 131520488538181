/*
---
name: sw-FeatureNavA
namespace: SiteWide
category: Navigation
id: [L1, CA-2]
---

<span class="sw-Label">CA-2</span>

- 総合TOP（日本語）
- 英語TOP

奇数はコピーが左配置、偶数はコピーが右配置になります。

```html
<div class="st-Wrapper">
  <ul class="sw-FeatureNavA_Items">
    <li class="sw-FeatureNavA_Item">
      <a class="sw-FeatureNavA_Link" href="#">
        <div class="sw-FeatureNavA_Head">
          <img src="https://placehold.jp/670x314.png" alt="" class="sw-FeatureNavA_HeadImage">
        </div>
        <div class="sw-FeatureNavA_Body">
          <div class="sw-FeatureNavA_Heading">サービスオフィス BIZ NEXT <span class="sw-FeatureNavA_Icon" aria-hidden="true"></span></div>
          <div class="sw-FeatureNavA_Text">多彩なサービスを備えた新しいワークスタイル。</div>
        </div>
      </a>
    </li>
    <li class="sw-FeatureNavA_Item">
      <a class="sw-FeatureNavA_Link" href="#">
        <div class="sw-FeatureNavA_Head">
          <img src="https://placehold.jp/670x314.png" alt="" class="sw-FeatureNavA_HeadImage">
        </div>
        <div class="sw-FeatureNavA_Body">
          <div class="sw-FeatureNavA_Heading">サービスオフィス BIZ NEXT <span class="sw-FeatureNavA_Icon" aria-hidden="true"></span></div>
          <div class="sw-FeatureNavA_Text">多彩なサービスを備えた新しいワークスタイル。</div>
        </div>
      </a>
    </li>
    <li class="sw-FeatureNavA_Item">
      <a class="sw-FeatureNavA_Link" href="#">
        <div class="sw-FeatureNavA_Head">
          <img src="https://placehold.jp/670x314.png" alt="" class="sw-FeatureNavA_HeadImage">
        </div>
        <div class="sw-FeatureNavA_Body">
          <div class="sw-FeatureNavA_Heading">サービスオフィス BIZ NEXT <span class="sw-FeatureNavA_Icon" aria-hidden="true"></span></div>
          <div class="sw-FeatureNavA_Text">多彩なサービスを備えた新しいワークスタイル。</div>
        </div>
      </a>
    </li>
  </ul>
</div>
```
*/
.sw-FeatureNavA_Items {
  padding-left: 0;
  list-style-type: none;
}

.sw-FeatureNavA_Item {
  display: block;

  & + & {
    margin-top: 35px;

    @include mq-up(lg) {
      margin-top: 90px;
    }
  }
}

.sw-FeatureNavA_Link {
  position: relative;
  display: block;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.sw-FeatureNavA_Head {
  display: block;

  @include mq-up(lg) {

  }
}

.sw-FeatureNavA_HeadImage {
  display: block;
  max-width: none;
  width: 100%;
}

.sw-FeatureNavA_Body {
  padding: 15px 14px 25px;
  color: #fff;
  background-color: $color-brand;

  @include mq-up(lg) {
    position: absolute;
    left: -25px;
    bottom: -30px;
    padding: 40px 25px 74px;

    .sw-FeatureNavA_Item:nth-of-type(even) & {
      left: auto;
      right: -25px;
    }
  }
}

.sw-FeatureNavA_Heading {
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;
  font-family: $font-family-heading-en;

  @include mq-up(lg) {
    font-size: rem(26, lg);
    line-height: 1.2;
  }
}

.sw-FeatureNavA_Icon:before {
  @include Icon("icon_arrow-circle-right");
  position: relative;
  top: -0.05em;
  margin-left: 10px;
  font-size: 1.4em;

  @include mq-up(lg) {
    font-size: 1em;
  }
}

.sw-FeatureNavA_Text {
  margin-top: 10px;
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(18, lg);
  }
}
