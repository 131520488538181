/*
---
name: st-Text
namespace: Structure
category: Layout
---

通常のテキストの余白を指定します。

```html
<div class="st-Wrapper">
<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.st-Text {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-text;

    @include mq-up(lg) {
      margin-top: $margin-text-lg;
    }
  }

  .st-Heading2 + &,
  .st-Heading3 + &,
  .st-Heading4 + &,
  .st-Heading5 + &,
  .st-Lead + &,
  .st-Title + & {
    margin-top: 0;
  }
}
