/*
---
name: inquiry-ErrorMessage
namespace: inquiry
category: Form
id: FO-6
---

<span class="sw-Label">FO-6</span>

フォームのエラーメッセージです。

```html
<div class="st-Wrapper">
<p class="inquiry-ErrorMessage">
  正しく入力されていない項目があります。入力内容をご確認ください。
</p>
</div>
```
*/
.inquiry-ErrorMessage {
  padding: 8px;
  border: 1px solid currentColor;
  font-size: rem(14, lg);
  line-height: 1.4;
  text-align: center;
  color: #ec0000;

  @include mq-up(lg) {
    font-size: rem(14, lg);
  }
}
