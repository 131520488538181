/*
---
name: sw2-ServiceInfo
namespace: SiteWide2
category: Block
---

画像とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-ServiceInfo">
      <div class="sw2-ServiceInfo_Image"><img src="https://placehold.jp/570x290.png" alt="秘書サービス"></div>
      <div class="sw2-ServiceInfo_Data">
        <div class="sw2-ServiceInfo_Title">秘書サービス</div>
        <div class="sw2-ServiceInfo_Hours">平日9:00〜18:00　※一部有料</div>
        <div class="sw2-ServiceInfo_Category">
          <ul>
            <li><span>来客一次対応</span></li>
            <li><span>内線お呼び出し／伝言転送</span></li>
            <li><span>郵便物の受取／転送</span></li>
            <li><span>手土産・ランチ手配</span></li>
            <li><span>ドリンクサーブ</span></li>
            <li><span>製本・ラミネート</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
```

*/
.sw2-ServiceInfo {
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.sw2-ServiceInfo_Image {
  img {
    width: 100%;
  }
  @include mq-up(lg) {
    flex-shrink: 0;
  }
}
.sw2-ServiceInfo_Data {
  margin-top: rem(14);
  @include mq-up(lg) {
    margin-top: 0;
    margin-left: rem(29, lg);
  }
}
.sw2-ServiceInfo_Title {
  font-weight: 700;
  font-size: rem(15);
  line-height: rem(21);
  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(28, lg);
  }
}
.sw2-ServiceInfo_Hours {
  margin-top: rem(10);
  font-weight: 400;
  font-size: rem(13);
  line-height: rem(23);
  @include mq-up(lg) {
    margin-top: rem(12, lg);
    font-size: rem(16, lg);
    line-height: rem(29, lg);
  }
}
.sw2-ServiceInfo_Category {
  margin-top: rem(6);
  @include mq-up(lg) {
    margin-top: rem(8, lg);
  }
  & > ul {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 0 rem(-7);
    @include mq-up(lg) {
      margin: 0 0 0 rem(-18, lg);
    }
    & > li {
      padding: 0 0 0 rem(7);
      margin-top: rem(8);
      list-style: none;
      @include mq-up(lg) {
        padding: 0 0 0 rem(18, lg);
        margin-top: rem(12, lg);
      }
    }
  }
  span {
    display: inline-block;
    font-weight: 400;
    font-size: rem(10);
    line-height: rem(10);
    padding: rem(5) rem(10);
    background: #fff;
    color: #C5383B;
    border: 1px solid #C5383B;
    @include mq-up(lg) {
      font-size: rem(14, lg);
      line-height: rem(14, lg);
      padding: rem(10, lg);
    }
  }
}
