/*
---
name: st-FooterSitemap
namespace: Structure
category: Global
id: K1
---

グローバルフッターにあるサイトマップエリアです。

```html
<div class="st-FooterSitemap">
  <div class="st-FooterSitemap_Inner">
    <div class="st-FooterSitemap_Grid">
      <div class="st-FooterSitemap_GridItem">
        <ul class="st-FooterSitemap_Items">
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/">KRPホーム<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/events">イベント・ニュース<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/access/">アクセスマップ<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/english/">Languages<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>

      <div class="st-FooterSitemap_GridItem">
        <ul class="st-FooterSitemap_Items">
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/rentaloffice/">オフィス・ラボ<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">サービスオフィス KRP BIZ NEXT<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/kaigi/">ホール・会議室<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">プラットフォーム活動<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">プラットホーム事業<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/company">入居企業<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>

      <div class="st-FooterSitemap_GridItem">
        <ul class="st-FooterSitemap_Items">
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">広報・メルマガ<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">Facebook<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">会社案内<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="/about/">KRPについて<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">お問い合わせ<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>

      <div class="st-FooterSitemap_GridItem">
        <ul class="st-FooterSitemap_Items">
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">サイトマップ<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">プライバシーポリシー<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
          <li class="st-FooterSitemap_Item">
            <a class="st-FooterSitemap_Link" href="#">ご利用にあたって<span class="st-FooterSitemap_Icon" aria-hidden="true"></span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```
*/
.st-FooterSitemap {
  background-color: #ececec;

  @include mq-up(lg) {
    padding: 28px 0 40px;
  }
}

.st-FooterSitemap_Inner {
  max-width: $max-width;
  margin: auto;
}

.st-FooterSitemap_Grid {
  display: block;
  margin-left: -30px;
  font-size: 0;
}

.st-FooterSitemap_GridItem {
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  vertical-align: top;
  font-size: 1rem;

  @include mq-up(lg) {
    width: 25%;
    padding-left: 30px;
  }

  & + & {
    border-top: 1px solid #aaa;

    @include mq-up(lg) {
      border-top: 0;
    }
  }
}

.st-FooterSitemap_Items {
  padding-left: 0;
  list-style-type: none;

  .st-FooterSitemap_Text + & {
    border-top: 1px solid #aaa;
  }

  @include mq-up(lg) {
    .st-FooterSitemap_Text + & {
      margin-top: 8px;
      border-top: 0;
    }
  }
}

.st-FooterSitemap_Item {
  
  @include mq-up(lg) {

  }

  & + & {
    border-top: 1px solid #aaa;

    @include mq-up(lg) {
      margin-top: 10px;
      border-top: 0;
    }
  }
}

.st-FooterSitemap_Link {
  position: relative;
  display: block;
  padding: 16px 25px 12px;
  font-size: rem(12);
  line-height: 1.2;
  color: #0b0b0b;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 0;
    padding-left: 20px;
    font-size: rem(16, lg);
    line-height: 1.4;
  }
}

.st-FooterSitemap_Icon {
  color: $color-brand;

  &:before {
    @include Icon("icon_arrow-right");
    position: absolute;
    right: 20px;

    @include mq-up(lg) {
      top: 0.3em;
      left: 0;
      font-size: 0.9em;
    }
  }
}

.st-FooterSitemap_Text {
  & + & {
    border-top: 1px solid #aaa;
  }

  @include mq-up(lg) {
    & + & {
      margin-top: 20px;
      border-top: 0;
    }
  }
}

.st-FooterSitemap_TextLink {
  position: relative;
  display: block;
  padding: 16px 25px 12px;
  font-size: rem(12);
  font-weight: bold;
  line-height: 1.2;
  color: #0b0b0b;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 0;
    font-size: rem(16, lg);
    line-height: 1.4;
  }
}

.st-FooterSitemap_TextLink-inline {
  display: inline-block;
  padding-right: 0;
}

.st-FooterSitemap_TextLink-emphasis {
  display: inline;
  padding: 0;
  color: $color-link;
}
