/*
---
name: st2-Explanation
namespace: Structure2
category: Layout
---

`sw2-Explanation`の上下の隙間を調整します。

*/
.st2-Explanation {
  margin-top: rem(80);
  &:first-child {
    margin-top: rem(0);
  }
  @include mq-up(lg) {
    margin-top: rem(140, lg);
  }
}

