/*
---
name: st-ContentHeaderE
namespace: Structure
category: Navigation
id: [PT4-5]
---

<span class="sw-Label">PT4-5</span>

- 入居者さま専用ページ

```html
<header class="st-ContentHeaderE">
  <div class="st-ContentHeaderE_Inner">
    <div class="st-Wrapper">
      <div class="st-ContentHeaderE_Block">
        <div class="st-ContentHeaderE_Title">
          <h1 class="st-LocalTitleA" id="st-LocalTitleA" aria-controls="st-LocalNav1" aria-expanded="false">ご入居者さま専用サイト<span class="st-LocalTitleA_Small">Dedicated site for residents</span><span class="st-LocalTitleA_Icon" aria-hidden="true"></span></h1>
        </div>
        <div class="st-ContentHeaderE_Nav">
          <div class="st-LocalNav1" id="st-LocalNav1" aria-labelledby="st-LocalTitleA" aria-hidden="true">
            <ul class="st-LocalNav1_Items">
              <li class="st-LocalNav1_Item">
                <a href="/" class="st-LocalNav1_Link">TOP<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">お知らせ一覧<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">集まるサービス<span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
              <li class="st-LocalNav1_Item">
                <a href="" class="st-LocalNav1_Link">関係書類<span class="st-LocalNav1_Delimiter">ダウンロード</span><span class="st-LocalNav1_Icon" aria-hidden="true"></span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="st-ContentHeaderE_Hero">
        <div class="st-LocalHeroF">
          <picture class="st-LocalHeroF_Image">
            <!--[if IE 9]><video style="display: none;"><![endif]-->
            <source media="(min-width: 980px)" srcset="https://placehold.jp/1600x300.png">
              <!--[if IE 9]></video><![endif]-->
              <img src="https://placehold.jp/1600x300.png" alt="alt属性値を指定します。">
          </picture>
        </div>
      </div>
    </div>
  </div>
</header>
```
*/
.st-ContentHeaderE {
}

.st-ContentHeaderE_Inner {
  position: relative;

  @include mq-up(lg) {
    height: 300px;
    overflow: hidden;
  }
}

.st-ContentHeaderE_Block {
  @include mq-up(lg) {
    padding-top: 34px;
  }
}

.st-ContentHeaderE_Block-toFix {
}

.st-ContentHeaderE_Block-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background-color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}

.st-ContentHeaderE_Title {
  @include mq-up(lg) {
    .st-ContentHeaderE_Block-fixed & {
      display: inline-block;
      vertical-align: middle;
      margin-right: 27px;
    }
  }
}

.st-ContentHeaderE_Nav {
  .st-ContentHeaderE_Block-fixed & {
    padding: 0 20px;
  }

  @include mq-up(lg) {
    margin-top: 34px;

    .st-ContentHeaderE_Block-fixed & {
      margin-top: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.st-ContentHeaderE_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    z-index: -1;
  }
}
