/*
---
name: st-LocalTitleB
namespace: Structure
category: Heading
id: [K1, PT4]
---

<span class="sw-Label">PT4</span>


```html
<div class="st-Wrapper">
<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_sangaku.svg" alt="">
  </span>
  再生医療サポートプラットフォーム
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_tsa.svg" alt="">
  </span>
  Technology Startup Accelerator
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_ctable.png" alt="">
  </span>
  クリエイティブ・テーブル
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_hvckyoto.svg" alt="">
  </span>
  HVC KYOTO
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_e2plat.svg" alt="">
  </span>
  E2<span class="st-LocalTitleB_Small">（エレクトロニクス＆エネルギー）</span>プラットフォーム
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_onecoin.png" alt="">
  </span>
  ワンコイン・ミキサー
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  <span class="st-LocalTitleB_Logo">
    <img src="/assets/img/structure/LocalTitle/logo_dschool.svg" alt="">
  </span>
  京都大学デザインスクール
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB">
  スタートアップ・ゼミナール
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

<p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
  <a class="st-LocalTitleB_LogoLink" href="/serviceoffice/">
    <span class="st-LocalTitleB_Logo">
      <img src="/assets/img/structure/LocalTitle/logo_serviceoffice.png" alt="BIZ NEXT">
    </span>
  </a>
  <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
</p>

</div>
```
*/
.st-LocalTitleB {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 14px 40px 14px 10px;
  border-bottom: 1px solid #cdcdcd;
  font-size: rem(15);
  font-weight: bold;
  font-family: $font-family-heading;
  line-height: 1.2;

  & br {
    display: none;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  @include mq-up(lg) {
    margin: 0;
    padding: 0;
    border-bottom: 0;
    font-size: rem(28, lg);
    line-height: 1.4;
    text-align: center;

    & br {
      display: inline;
    }

    .st-ContentHeaderD_Block-fixed & {
      font-size: rem(14, lg);
    }
  }
}

.st-LocalTitleB_Small {
  position: relative;
  top: -2.5px;
  padding-bottom: 5px;
  font-size: rem(10);

  @include mq-up(lg) {
    top: 0;
    font-size: rem(28, lg);
  }
}

.st-LocalTitleB_LogoLink {
  pointer-events: auto;
}

.st-LocalTitleB_Logo {
  display: inline-block;
  margin-right: 8px;
  vertical-align: text-bottom;

  & img {
    width: auto;
    height: 26px;
  }

  & img[src="/assets/img/structure/LocalTitle/logo_serviceoffice.png"],
  & img[src="/assets/img/structure/LocalTitle/logo_serviceoffice.svg"] {
    width: auto;
    height: 30.5px;
  }

  @include mq-up(lg) {
    margin-right: 12px;

    & img {
      height: 40px;
    }

    & img[src="/assets/img/structure/LocalTitle/logo_serviceoffice.png"],
    & img[src="/assets/img/structure/LocalTitle/logo_serviceoffice.svg"] {
      height: 49px;
    }

    .st-ContentHeaderD_Block-fixed & {
      display: none;
    }
  }
}

.st-LocalTitleB_Icon:before {
  @include Icon("icon_arrow-down");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;
  color: $color-brand;
  transition-duration: 0.3s;

  .st-LocalTitleB[aria-expanded="true"] & {
    transform: rotate(-180deg);
  }

  @include mq-up(lg) {
    display: none;
  }
}
