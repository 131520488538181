.office-Timeline {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.office-History {
    margin-top: -5px;
    margin-bottom: 40px;

    @include mq-up(lg) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.office-Timeline_Item {
  @include mq-up(lg) {
    display: flex;
  }

  &:nth-of-type(n+2) {
    margin-top: 64px;
  }

  &.office-Timeline_Item-yearHide {
    &:nth-of-type(n+2) {
      margin-top: 24px;

      @include mq-up(lg) {
        margin-top: 40px;
      }
    }
  }

  &[data-aos] {
    opacity: 0;
    transform: translate(0, 30px);

    &.aos-animate {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

.office-Timeline_Heading2 {
  display: flex;
  flex-direction: column;

  @include mq-up(lg) {
    flex-direction: row;
    width: 283px;
  }
}

.office-Timeline_Year {
  margin-bottom: 24px;
  font-family: $font-family-heading-en;
  font-weight: bold;
  font-size: 36px;
  line-height: 1;
  color: #9CA7AC;

  @include mq-up(lg) {
    width: 149px;
    margin-bottom: 0;
  }

  .office-Timeline_Item-yearHide & {
    opacity: 0;
  }
}

.office-Timeline_YearText {
  @include sr-only();
}

.office-Timeline_Month {
  position: relative;
  font-size: 18px;
  line-height: 1.6;
  color: #000;

  @include mq-up(lg) {
    width: 114px;
    margin-top: 10px;
    font-size: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 60px;
    display: block;
    width: 50px;
    height: 16px;
    border-bottom: 1px solid #000;

    @include mq-up(lg) {
      left: 64px;
    }
  }
}

.office-Timeline_MonthText {
  width: 40px;

  @include mq-up(lg) {
    display: inline-flex;
    justify-content: flex-end;
    width: 44px;
  }
}

.office-Timeline_Details {
  margin-top: 16px;

  @include mq-up(lg) {
    flex: 1 1 0%;
    margin-top: 10px;
    padding-left: 20px;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.office-Timeline_Heading3 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
  color: #000;

  @include mq-up(lg) {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.office-Timeline_Button {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq-up(lg) {
    justify-content: flex-start;
  }

  & > .sw-Button {
    width: 260px;

    @include mq-up(lg) {
      width: 290px;
    }
  }
}

.office-Timeline_Text {
  font-size: 14px;
  line-height: 1.8;
  color: #000;

  @include mq-up(lg) {
    font-size: 16px;
  }
}

.office-Timeline_ImageList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mq-up(lg) {
    flex-direction: row;
    margin-top: 40px;
    margin-left: -5px;
  }
}

.office-Timeline_ImageItem {
  @include mq-up(lg) {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 5px;
  }

  &:nth-of-type(n+2) {
    margin-top: 5px;

    @include mq-up(lg) {
      margin-top: 0;
    }
  }
}

.office-Timeline_Image {
  display: block;
  width: 100%;
}

.office-Timeline_Year-hide {
  @include sr-only();
}

