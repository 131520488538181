.home-kv {
  position: relative;
  @include mq-up(lg) {
    margin-top: 19px;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("/assets/img/structure/bg_continer-stripe.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    @include mq-up(lg) {
      top: 50px;
      width: div(1250, 1600) * 100 + 0%;
      padding-top: div(665, 1600) * 100 + 0%;
      height: 0;
    }
  }
}

.home-kv_image {
  width: 100%;
  // height: 290px;
  overflow: hidden;
  position: relative;
  @include mq-up(lg) {
    margin-left: auto;
    margin-right: 0;
    width: div(1260, 1600) * 100 + 0%;
    // height: 640px;
    border-bottom: 5px solid $color-brand;
  }
}

.home-kv_image-list {
  padding-left: 0;
  list-style-type: none;
  position:relative;
  overflow: hidden;
  @include clearfix;
  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.home-kv_image-item {
  width: div(100, 4) + 0%;
  float: left;
  & img {
    width: 100%;
  }
  & > div:nth-child(n + 2) {
    display: none;
  }
}

.home-kv_writing {
  width: div(593, 750) * 100 + 0%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  @include mq-up(lg) {
    width: 602px;
    bottom: 85px;
  }
}

.home-kv_inner {
  position: relative;
  width: 100%;
  height: 100%;
  @include mq-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.home-kv_Heading {
  margin-left: auto;
  margin-right: auto;
  padding-top: div(40, 525) * 100 + 0%;
  padding-bottom: div(40, 525) * 100 + 0%;
  width: div(525, 750) * 100 + 0%;
  text-align: center;
  position: relative;
  @include mq-up(lg) {
    padding-top: 0;
    margin-top: div(210, 1600) * 100 + 0%;
    padding-bottom: 0;
    width: 525px;
    position: absolute;
    top: 0;
    left: div(45, 1600) * 100 + 0%;
    z-index: 1;
    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}


.home-kv_appearance {
  padding-top: div(165, 750) * 100 + 0%;
  width: 100%;
  height: 0;
  background-image: url('/assets/img/index/image_kv_appearance_sp.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: relative;
  @include mq-up(lg) {
    margin-top: 40px;
    padding-top: div(165, 1600) * 100 + 0%;
    background-image: url('/assets/img/index/image_kv_appearance_pc.jpg');
  }
  // @include mq-up(xl) {
  //   padding-top: 165px;
  //   background-size: cover;
  // }
}

.home-kv_appearance-krp {
  display: none;
  @include mq-up(lg) {
    display: block;
    width: div(167, 1600) * 100 + 0%;
    position: absolute;
    top: 0;
    left: 0;
    top: div(-126, 167) * 100 + 0%;
    left: div(42, 1600) * 100 + 0%;
  }
}

.home-event {
  background-image: url('/assets/img/index/bg_event_deco_sp.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  @include mq-up(lg) {
    background-image: url('/assets/img/index/bg_event_deco_pc.png');
    background-size: 1482px auto;
  }
}

.home-event-emphasis {
  margin-bottom: div(170, 750) * 100 + 0%;
  padding-top: div(120, 750) * 100 + 0%;
  position: relative;
  @include mq-up(lg) {
    margin-bottom: 100px;
    padding-top: div(72, 1600) * 100 + 0%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("/assets/img/structure/bg_continer-stripe.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: 0 0;
    z-index: -2;
  }
  &:after {
    content: '';
    position: absolute;
    top: div(10, 750) * 100 + 0%;
    right: 0;
    width: 120%;
    height: 100%;
    margin: 0 -10%;
    background-color: #be3134;
    transform-origin: right center;
    transform: rotate(-4.4deg);
    z-index: -1;
    @include mq-up(lg) {
      height: 85%;
      top: - 40px;
    }
  }
}

.home-event-emphasis_inner {
  position: relative;
  @include mq-up(lg) {
    display: flex;
    align-items: center;
  }
}

.home-event-emphasis_Head {
  display: block;
  position: relative;
  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
    width: div(552, 1170) * 100 + 0%;
  }
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -8px;
    left: -8px;
    border: 2px solid #fff;
    @include mq-up(lg) {
    }
  }
}

.home-event-emphasis_Body {
  padding-top: div(40, 750) * 100 + 0%;
  display: block;
  color: #fff;
  font-size: rem(15);
  font-weight: bold;
  position: relative;
  @include mq-up(lg) {
    padding-top: 0;
    padding-left: 35px;
    width: div(460, 1170) * 100 + 0%;
    font-size: rem(20, lg);
  }
}

// .home-event-emphasis_Heading {
//   padding-bottom: 12px;
//   font-size: rem(14);
//   line-height: 1.4;
//   position: relative;
//   @include mq-up(lg) {
//     font-size: rem(20, lg);
//   }
//   &:after {
//     content: "";
//     display: block;
//     width: 364 / 670 * 100 + 0%;
//     height: 2.5px;
//     background-color: #fff;
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     @include mq-up(lg) {
//       width: 100%;
//     }
//   }
// }

// .home-event-emphasis_Text {
//   margin-top: 8px;
//   font-size: rem(12);
//   line-height: 1.6;
//   @include mq-up(lg) {
//     font-size: rem(16, lg);
//   }
// }

.home-event-emphasis_image {
  display: none;
  @include mq-up(lg) {
    display: block;
    position: absolute;
    top: 0;
    right: -82px;
  }
}

.home-map-list {
  margin-top: div(30, 750) * 100 + 0%;
  padding-left: 0;
  list-style-type: none;
  & > li {
    border-bottom: 1px solid #cdcdcd;
  }
  @include mq-up(lg) {
    display: none;
  }
}

.home-map-list_Link {
  padding: 16px 8px 12px;
  display: block;
  font-size: rem(12);
  line-height: 1.2;
  color: $color-text;
  text-decoration: none;
}

.home-map-list_Icon {
  color: $color-brand;
  &:before {
    @include Icon("icon_arrow-right");
    position: absolute;
    right: 20px;

    @include mq-up(lg) {
      top: 0.3em;
      left: 0;
      font-size: 0.9em;
    }
  }
}

.home-belt {
  width: 100%;
  overflow: hidden;
  position: relative;
  @include mq-up(lg) {
    background-image: url('/assets/img/index/bg_belt.png');
    background-repeat: repeat;
    background-position: center top;
    background-size: 10px auto;
    height: 725px;
  }
}

.home-belt_Title {
  padding-top: div(70, 670) * 100 + 0%;
  position: relative;
  top: 0;
  z-index: 1;
  @include mq-up(lg) {
    padding-top: 174px;
    padding-left: 40px;
    width: 484px;
    position: absolute;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &:before {
    margin-bottom: div(20, 670) * 100 + 0%;
    padding-top: div(44, 670) * 100 + 0%;
    background-image: url('/assets/img/index/bg_belt_top.png');
    background-position: center top;
  }
  &:after {
    margin-top: div(30, 670) * 100 + 0%;
    padding-top: div(20, 670) * 100 + 0%;
    background-image: url('/assets/img/index/bg_belt_bottom.png');
    background-position: center bottom;
  }
}

.home-belt_Heading {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: div(20, 670) * 100 + 0%;
  color: $color-brand;
  font-size: rem(16);
  font-weight: bold;
  text-align: center;
  @include mq-up(lg) {
    font-size: rem(28, lg);
    padding-left: 25px;
    padding-right: 25px;
  }
}

.home-belt_Text {
  padding-left: 10px;
  padding-right: 10px;
  font-size: rem(13);
  @include mq-up(lg) {
    padding-left: 25px;
    padding-right: 25px;
    font-size: rem(18, lg);
  }
}

.home-belt_Map {
  margin-top: div(70, 750) * 100 + 0%;
  padding-top: div(725, 750) * 100 + 0%;
  width: 100%;
  height: 0;
  position: relative;
  top: 0;
  background-image: url('/assets/img/index/bg_belt_map_sp.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  @include mq-up(lg) {
    margin-top: 0;
    padding-top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('/assets/img/index/bg_belt_map_pc.png');
    width: 1600px;
    height: 725px;
  }
}

.home-belt_Link {
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
    color: $color-text;
  }
}

.home-belt_Icon {
  color: $color-brand;
  &:before {
  @include Icon("icon_arrow-right");
  margin-right: 5px;
  vertical-align: baseline;
  color: $color-brand;
  font-size: 0.9em;
  }
}

.home-startup {
  @include mq-up(lg) {
    padding: 30px;
    background-color: #fff;
  }
}

.home-startup_Link {
  text-align: center;
  display: block;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;
  &:hover {
    text-decoration: none;
    opacity: $opacity;
    color: $color-text;
  }
}

.home-startup_Text {
  margin-top: 10px;
  font-size: rem(12);
  font-weight: bold;
  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

//slick
.home-kv {
  .slick-slider
  {
      position: relative;

      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }

  .slick-list
  {
      position: relative;

      display: block;
      overflow: hidden;

      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }

  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;

      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }

  .slick-slide
  {
      display: none;
      float: left;

      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;

      height: auto;

      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
}



.home-kv_image-item.slick-initialized {
  & > div:nth-child(n + 2) {
  display: block;
  }
}

/* トップページのみのスタイル変更 */
.-is-home-show {
  display: none;
}
.-is-home {
  .-is-home-show {
    display: block;
  }
  .st2-Header_Outer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
  }
  .st2-Header_LogoLink {
    @include mq-up(lg) {
      display: block;
      padding: 19px 19px 19px 30px;
    }
    @media print, screen and (min-width: $max-width) {
      display: flex;
      padding: 28px 19px 28px 30px;
    }
  }
  .st2-Header_Logo_SiteLogoImage {
    @include mq-up(lg) {
      width: rem(76, lg);
      margin-right: rem(13, lg);
    }
  }
  .st2-Header_Logo_SiteNameImage {
    @include mq-up(lg) {
      width: rem(109, lg);
      margin-top: rem(12, lg);
    }
    @media print, screen and (min-width: $max-width) {
      width: rem(141, lg);
      margin-top: rem(0);
    }
  }
  .st2-Header_MenuButton {
    & > span {
      @include mq-up(lg) {
        background-color: $color-krp;
        color: #fff;
        font-size: rem(12, lg);
        line-height: rem(12, lg);
        padding: rem(52, lg) rem(15, lg) rem(16, lg);
        letter-spacing: 0.3em;
      }
      &::before {
        @include mq-up(lg) {
          font-size: rem(42, lg);
          top: rem(10, lg);
        }
      }
    }
  }
  .st2-Header_NavList {
    & > li {
      & > a {
        color: #333333;
        @include mq-up(lg) {
          padding: rem(34, lg) rem(8, lg);
        }
        @media print, screen and (min-width: $max-width) {
          padding: rem(34, lg) rem(12, lg);
        }
      }
    }
  }

  .st2-ContainerB {
    padding: rem(0) rem(0) rem(80);
    @include mq-up(lg) {
      padding: rem(0) rem(0) rem(140, lg);
    }

    &:nth-of-type(3) {
      padding-top: rem(60);
      @include mq-up(lg) {
        padding-top: rem(100, lg);
      }
    }
    &:nth-of-type(6) {
      padding-top: rem(80);
      @include mq-up(lg) {
        padding-top: rem(140, lg);
      }
    }
  }
  .sw2-LinkMap {
    padding-top: rem(0);
  }
  .sw2-LinkMap_Heading {
    @include mq-up(lg) {
      display: none;
    }
  }

  .sw2-MvSlider_In {
    img {
      height: rem(600);
      object-fit: cover;
      @include mq-up(md) {
        height: rem(800, md);
      }
      @include mq-up(lg) {
        height: 100vh;
        max-height: rem(800, lg);
      }
    }
    .slick-nav {
      position: absolute;
      z-index: 5;
      top: rem(96);
      right: rem(20);
      transform: translate(0, 0);
      @include mq-up(md) {
        bottom: auto;
        top: rem(96, md);
        right: rem(40, md);
      }
      @include mq-up(lg) {
        bottom: auto;
        top: rem(120, lg);
        right: rem(40, lg);
      }
    }
  }
  .st2-Header_MegaMenu {
    top: 80px;
  }
}
