/*
---
name: sw-TextImportant
namespace: SiteWide
category: Text
id: [K2, TXT-7]
---

<span class="sw-Label">TXT-7</span>

`<strong>`で使われるようなスタイルです。重要性や緊急性・深刻性があることを示します。

```html
<div class="st-Wrapper">
<p class="st-Text">京都発で世界を目指すStartupを育成する拠点として<strong class="sw-TextImportant">Tech-nology Startup Accelerator『迅』 -HAYATE-</strong>を立ち上げました。</p>
</div>
```
*/
.sw-TextImportant {
  color: $color-text-important;
}
