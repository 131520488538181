/*
---
name: st-Title
namespace: Structure
category: Layout
---

ページタイトルの余白を指定します。

```html
<div class="st-Wrapper">
<h1 class="sw-TitleA st-Title">NEWS & Activity<span class="sw-Title_Small">ニュース＆アクティビティ</span></h1>
<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.st-Title {
  margin-bottom: $margin-title;

  @include mq-up(lg) {
    margin-bottom: $margin-title-lg;
  }
}
