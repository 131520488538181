/*
---
name: sw-CardNavD
namespace: SiteWide
category: Navigation
id:  [L1, CA-6]
---

<span class="sw-Label">CA-6</span>

- 再生医療TOP
- 再生医療とは？

```html
<div class="st-Wrapper">
<div style="background: #f6f8f9;">
  <ul class="sw-CardNavD_Items">
    <li class="sw-CardNavD_Item">
      <a href="#" class="sw-CardNavD_Box">
        <div class="sw-CardNavD_Head">
          <p class="sw-CardNavD_Heading">再生医療サポートビジネス懇話会</p>
        </div>
        <div class="sw-CardNavD_Foot">
          <div class="sw-CardNavD_ImageArea">
            <img src="https://placehold.jp/150x120.png" alt="" class="sw-CardNavD_Image">
          </div>
          <div class="sw-CardNavD_TextArea">
            <p class="sw-CardNavD_Text">第一線の研究者を招き、研究の現状や治具や材料、実験・分析機器、システム等の産業化ニーズを提案していただきます。</p>
          </div>
        </div>
        <span class="sw-CardNavD_Icon" aria-hidden="true"></span>
      </a>
    </li>
    <li class="sw-CardNavD_Item">
      <a href="#" class="sw-CardNavD_Box">
        <div class="sw-CardNavD_Head">
          <p class="sw-CardNavD_Heading">再生医療ビジネスシンポジウム</p>
        </div>
        <div class="sw-CardNavD_Foot">
          <div class="sw-CardNavD_ImageArea">
            <img src="https://placehold.jp/150x120.png" alt="" class="sw-CardNavD_Image">
          </div>
          <div class="sw-CardNavD_TextArea">
            <p class="sw-CardNavD_Text">再生医療を取り巻く現状と課題、対策などを専門家がわかりやすく解説。また、専門家との意見交換も行います。</p>
          </div>
        </div>
        <span class="sw-CardNavD_Icon" aria-hidden="true"></span>
      </a>
    </li>
    <li class="sw-CardNavD_Item">
      <a href="#" class="sw-CardNavD_Box">
        <div class="sw-CardNavD_Head">
          <p class="sw-CardNavD_Heading">再生医療の全体像を見わたせる<br>分かりやすい解説講座</p>
        </div>
        <div class="sw-CardNavD_Foot">
          <div class="sw-CardNavD_ImageArea">
            <img src="https://placehold.jp/150x120.png" alt="" class="sw-CardNavD_Image">
          </div>
          <div class="sw-CardNavD_TextArea">
            <p class="sw-CardNavD_Text">「再生医療の早期実用化」をテーマに、第一線の研究者による講演を実施。</p>
          </div>
        </div>
        <span class="sw-CardNavD_Icon" aria-hidden="true"></span>
      </a>
    </li>
    <li class="sw-CardNavD_Item">
      <a href="#" class="sw-CardNavD_Box">
        <div class="sw-CardNavD_Head">
          <p class="sw-CardNavD_Heading">学会・展示会への出展・PR</p>
        </div>
        <div class="sw-CardNavD_Foot">
          <div class="sw-CardNavD_ImageArea">
            <img src="https://placehold.jp/150x120.png" alt="" class="sw-CardNavD_Image">
          </div>
          <div class="sw-CardNavD_TextArea">
            <p class="sw-CardNavD_Text">活動のPRと、ビジネス機会の発掘を目的に、「BIO tech（国際バイオEXPO）」「日本再生医療学会総会」などの展示会に、参加いただいているモノづくり企業と共同出展しています。<br>
            ここにテキストが入ります。</p>
          </div>
        </div>
        <span class="sw-CardNavD_Icon" aria-hidden="true"></span>
      </a>
    </li>
  </ul>
</div>
</div>
```
*/
.sw-CardNavD_Items {
  display: block;
  padding-left: 0;
  font-size: 0;
}

.sw-CardNavD_Item {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 1rem;
  @include border-collapse(#e8e8e8, 1px);
  background-color: #fff;

  @include mq-up(lg) {
    width: percentage(div(1, 2));
  }
}

.sw-CardNavD_Box {
  display: block;
  position: relative;
  padding: 24px 18px 42px;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;

  &:hover {
    opacity: $opacity;
  }

  @include mq-up(lg) {
    padding: 36px 34px 50px;
  }
}


.sw-CardNavD_Head {
  margin: 0 0 18px;
  text-align: center;

  @include mq-up(lg) {
    margin: 0 0 36px;
  }
}

.sw-CardNavD_Heading {
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(22, lg);
  } 
}

.sw-CardNavD_Foot {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.sw-CardNavD_ImageArea {
  width: 98px;
  margin-right: 13px;
  float: left;

  @include mq-up(lg) {
    width: 150px;
    margin-right: 20px;
    float: left;
  }
}

.sw-CardNavD_Image {

}

.sw-CardNavD_TextArea {
  display: block;
  overflow: hidden;

  @include mq-up(lg) {
  }
}

.sw-CardNavD_Text {
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-CardNavD_Icon {
  position: absolute;
  right: 10px;
  bottom: 10px;

  @include mq-up(lg) {
    right: 20px;
    bottom: 20px;
  }

  &:before {
    @include Icon("icon_arrow-circle-right");
    color: $color-brand;
    font-size: 1.6em;
  }
}
