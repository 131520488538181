.st-HeaderSubNav_Items {
  padding-left: 0;
  list-style-type: none;
  display: block;
  margin-left: -7px;
  font-size: 0;

  @include mq-up(lg) {
    padding-top: 11px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  @include mq-up(xl) {
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

.st-HeaderSubNav_Item {
  display: inline-block;
  vertical-align: top;
  padding-left: 7px;
  font-size: 1rem;
  width: 50%;

  &:last-of-type {
    display: none;
  }

  @include mq-up(lg) {
    &:last-of-type {
      display: inline-block;
      height: 23px;
      margin-top: -0.25em;
      margin-left: 12px;
      padding-left: 0;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 16px;
        margin-right: 5px;
        margin-left: 14px;
        border-left: 1px solid #c2c2c2;
      }
    }
    
    width: auto;
    text-align: left;
    font-size: rem(13, lg);
  }

  @include mq-up(xl) {
    margin-left: 26px;
  }

}

.st-HeaderSubNav_Link {
  position: relative;
  display: block;
  padding: 25px 15px 8px;
  font-size: rem(13);
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #aaa;

  &:hover {
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 0 0 0 14px;
    font-size: rem(13, lg);
    line-height: 1.4;
    color: $color-text;
    border-bottom: 0;

    &:after {
      display: none;
    }
  }
}

.st-GlobalNav_Link-icon {
  padding: 0;
  padding-left: 20px;

  & + & {
    padding-left: 10px;
  }
}

.st-HeaderSubNav_Icon:before {
  content: "";
  position: absolute;
  top: 2.2em;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2.75px 0 2.75px 4px;
  border-color: transparent transparent transparent #ffffff;


  @include mq-up(lg) {
    @include Icon("icon_arrow-right");
    position: absolute;
    left: 0;
    top: 0.25em;
    border: 0;
    color: $color-brand;
    font-size: 0.9em;
  }
}

.st-GlobalNav_MailIcon:before {
  @include Icon("icon_mail");
  font-size: 1.6em;
  color: #3b5a9a;
}

.st-GlobalNav_FacebookIcon:before {
  @include Icon("icon_facebook");
  font-size: 1.6em;
  color: #3b5a9a;
}

.st-HeaderSubNav_SearchButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  color: $color-brand;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background: transparent;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
}

.st-HeaderSubNav_SearchIcon:before {
  @include Icon("icon_search");
  position: relative;
  top: 0;
  left: 0;
  font-size: 1.009em;
  color: $color-text;
}

.st-HeaderSubNav_Search {
  display: none;

  &[aria-hidden="false"] {
    display: inline-block;
    vertical-align: middle;
  }

  .gsc-control-cse.gsc-control-cse-ja {
    padding: 0;
  }
  .cse .gsc-control-cse, .gsc-control-cse {
    border-color: #d6d6d6;
    border: 0;
  }
  .gsc-control-cse, .gsc-control-cse .gsc-table-result {
    font-size: rem(16, lg);
  }
  .gsc-search-button {
    display: none;
  }
  form.gsc-search-box {
    font-size: rem(16, lg);
    margin: 0;
  }
  table.gsc-search-box {
    margin-bottom: 0;
  }
  .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding-right: 0;
  }
  .gsc-input-box {
    width: auto;
    height: auto;
    border-width: 0;
  }
  .gsc-input-box-hover,
  .gsc-input-box-focus {
    box-shadow: none;
  }
  .gsib_a {
    padding: 0;
  }
  input.gsc-input {
    margin: 0 !important;
    height: auto !important;
    text-indent: 0 !important;
    @include sw-FormInput();
    width: 200px !important;
    height: 23px !important;
    border: 1px solid #d0d0d0 !important;
    border-radius: 0;
    color: $color-text;

    &::placeholder {
      color: transparent;
    }
  }
  .gsst_b {
    display: none;
    padding: 0;
  }

  .gsc-input-box-focus {
  }
}
