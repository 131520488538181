/*
---
name: st-LocalNav3
namespace: Structure
category: Navigation
id: [K1, NV2]
---

<span class="sw-Label">NV2</span>

ローカルナビゲーションです。

- URLの2階層目（`/events/event/`など）と合致する場合はカレント表示されます。

```html
<div class="st-Wrapper">
<div class="st-LocalNav3">
  <ul class="st-LocalNav3_Items">
    <li class="st-LocalNav3_Item">
      <a href="/_events/event/" class="st-LocalNav3_Link">すべて<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">イベント<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">イベントレポート<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">スタートアップ<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">ビジネス<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">ものづくり<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">デザイン<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">伝統工芸<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">電子・エネルギー<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">ライフサイエンス<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">2017<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
    <li class="st-LocalNav3_Item">
      <a href="" class="st-LocalNav3_Link">2016<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
    </li>
  </ul>
</div>
</div>
```
*/
.st-LocalNav3 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-right: calc(50vw - 50%);
  padding-left: calc(50vw - 50%);
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav3_Items {
  display: block;
  padding-left: 0;
  list-style-type: none;
  line-height: 1.2;

  @include mq-up(lg) {
    width: 100%;
    font-size: 0;

    .st-ContentHeaderD_Block-fixed & {
      display: table;
      width: 100%;
    }
  }
}

.st-LocalNav3_Item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: rem(15);
  border-bottom: 1px solid #393939;

  @include mq-up(lg) {
    vertical-align: top;
    width: percentage(div(1, 7));
    font-size: rem(14, lg);
    border-bottom: 0;
    transition-duration: 0.3s;
    transition-property: background-color;

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    .st-LocalNav3_Items &:nth-last-child(n+8),
    .st-LocalNav3_Items &:nth-last-child(n+8) ~ & {
      width: auto;
    }

    // serviceofficeページでは等幅のままにする
    .st-ContentHeaderD-serviceoffice .st-LocalNav3_Items &:nth-last-child(n+8),
    .st-ContentHeaderD-serviceoffice .st-LocalNav3_Items &:nth-last-child(n+8) ~ & {
      width: percentage(div(1, 8));
    }

    &:hover {
      color: #fff;
      background-color: #4d4d4d;
    }

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 30px;
      margin: auto;
      border-left: 1px solid #c9c9c9;

      .st-ContentHeaderD_Block-fixed & {
        height: 28px;
      }
    }

    & + &:hover:before {
      border-color: #4d4d4d;

      .st-ContentHeaderD_Block-fixed & {
        border-color: #c9c9c9;
      }
    }

    .st-ContentHeaderD_Block-fixed & {
      display: table-cell;
      vertical-align: middle;
      width: auto;

      &:hover {
        color: $color-text;
        background-color: #fff;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        margin: auto;
        width: 1em;
        height: 5px;
        border-bottom: 5px solid transparent;
        transition-duration: $transition-duration;
        transition-property: width;
      }

      &:hover:after {
        width: 100%;
        border-bottom-color: #a81d34;
      }
    }
    .st-ContentHeaderG_Block-fixed & {
      display: table-cell;
      vertical-align: middle;
      width: auto;

      &:hover {
        color: $color-text;
        background-color: #fff;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: -6px;
        left: 0;
        margin: auto;
        width: 1em;
        height: 5px;
        border-bottom: 5px solid transparent;
        transition-duration: $transition-duration;
        transition-property: width;
      }

      &:hover:after {
        width: 100%;
        border-bottom-color: #a81d34;
      }
    }
  }
}

.st-LocalNav3_Item-current {

  @include mq-up(lg) {
    color: #fff;
    background-color: #4d4d4d;

    &.st-LocalNav3_Item:before {
      border-left-color: #4d4d4d;
    }

    .st-ContentHeaderD-serviceoffice &.st-LocalNav3_Item:before {
      border-color: #c9c9c9;
    }

    .st-ContentHeaderD-serviceoffice .st-ContentHeaderD_Block-fixed &,
    .st-ContentHeaderD-serviceoffice .st-ContentHeaderG_Block-fixed & {
      background-color: transparent;

      &:after {
        width: 100%;
        border-bottom-color: #a81d34;
      }
    }
  }
}

.st-LocalNav3_Item-inquiry {
  .st-ContentHeaderD-serviceoffice .st-ContentHeaderD_Block-fixed &,
  .st-ContentHeaderD-serviceoffice .st-ContentHeaderG_Block-fixed & {
    &:hover:after {
      @include mq-up(lg) {
        border-color: #6a0012;
      }
    }
  }

  .st-ContentHeaderD-serviceoffice .st-ContentHeaderD_Block-fixed &,
  .st-ContentHeaderD-serviceoffice .st-ContentHeaderG_Block-fixed & {
    &.st-LocalNav3_Item-current:after {
      @include mq-up(lg) {
        border-color: #6a0012;
      }
    }
  }
}

.st-LocalNav3_Link {
  position: relative;
  display: block;
  padding: 16px 16px 16px 5px;
  text-decoration: none;
  color: #fff;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 14px 12px;
    font-weight: bold;
    color: $color-text;
    text-align: center;
    background-color: transparent;
    transition-duration: 0.3s;

    .st-LocalNav3_Item:hover &,
    .st-LocalNav3_Item-current & {
      color: #fff;
    }

    .st-ContentHeaderD_Block-fixed & {
      font-weight: normal;

      &:hover {
        color: $color-text;
      }
    }
    .st-ContentHeaderG_Block-fixed & {
      font-weight: normal;

      &:hover {
        color: $color-text;
      }
    }

    .st-ContentHeaderD-serviceoffice .st-ContentHeaderD_Block-fixed &,
    .st-ContentHeaderD-serviceoffice .st-ContentHeaderG_Block-fixed & {
      height: auto !important;
      padding: 21px 20px;
    }

    .st-ContentHeaderD-serviceoffice .st-ContentHeaderD_Block-fixed .st-LocalNav3_Item-current &,
    .st-ContentHeaderD-serviceoffice .st-ContentHeaderG_Block-fixed .st-LocalNav3_Item-current & {
      color: $color-text;
    }
  }
}

.st-LocalNav3_Link.st-LocalNav3_Link-inquiry {
  .st-ContentHeaderD_Block-fixed &,
  .st-ContentHeaderG_Block-fixed & {
    @include mq-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70px !important;
      line-height: 1.2;
      margin-top: -5px;
      margin-bottom: -5px;
      padding-top: (14 + 4) * 1px;
      padding-bottom: (14 + 4) * 1px;
      color: #fff !important;
      background-color: #be3134;
    }
  }
}

.st-LocalNav3_Icon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1em;

  @include mq-up(lg) {
    display: none;
  }
}

.st-LocalNav3_Delimiter {
  @include mq-up(lg) {
    display: block;
  }
}

.st-LocalNav3_SubItemArea {
  @include mq-up(lg) {
    display: none;
  }
}

.st-LocalNav3_SubItems {
  @extend .st-LocalNav3_Items;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-color: #181818;
  background-repeat: repeat;
  background-size: auto;

  .st-LocalNav3_Item-current & {
    display: block;
  }

  @include mq-up(lg) {
    display: none !important;
  }
}

.st-LocalNav3_SubItem {
  @extend .st-LocalNav3_Item;
  padding: 0 20px;
  border-color: #505050;
}

.st-LocalNav3_SubLink {
  @extend .st-LocalNav3_Link;
}

.st-LocalNav3_SubIcon:before {
  @extend .st-LocalNav3_Icon;
  right: 0;
}

.st-LocalNav3_Delimiter {
  display: inline-block;

  @include mq-up(lg) {
    display: block;
  }
}
