/*
---
name: sw-LightboxA
namespace: SiteWide
category: InteractiveComponent
id: LB-1
---

<span class="sw-Label">LB-1</span>

Lightboxです。

- `.sw-LightboxA_Link`の`href`属性値と合致する`id`が表示されます
- 矢印キー（→←）とPrev・Nextボタンでモーダルを移動できます
- 表示する要素は`.sw-LightboxA_Contents`の中に配置してください
- リンクのスタイルは設定されていません（`IMG-6`のクリッカブルマップでも使用可能です）

```html
<a class="sw-LightboxA_Link" href="#sw-LightboxA01">リンク1</a>
<a class="sw-LightboxA_Link" href="#sw-LightboxA02">リンク2</a>

<div class="sw-LightboxA_Contents">
  <div id="sw-LightboxA01" class="sw-LightboxA_Content">
    <div class="sw-LightboxA_Inner">
      <div class="sw-LightboxA_Head">
        <img class="sw-LightboxA_Image" src="https://placehold.jp/570x290.png">
      </div>
      <div class="sw-LightboxA_Body">
        <p class="sw-LightboxA_Heading">1タイトルが入りますタイトルが入りますタイトルが入ります</p>
        <p class="sw-LightboxA_Text">説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。</p>
        <button type="button" class="sw-LightboxA_Next"><span class="sw-LightboxA_NextText">Next</span><span class="sw-LightboxA_NextIcon" aria-hidden="true"></span></button>
        <button type="button" class="sw-LightboxA_Prev" title="Previous"><span class="sw-LightboxA_PrevText">Previous</span><span class="sw-LightboxA_PrevIcon" aria-hidden="true"></span></button>
        <div class="sw-LightboxA_Button">
          <button type="button" class="sw-LightboxA_Close sw-Button sw-Button-invert">閉じる<span class="sw-Button_RightArrow" aria-hidden="true"></span></button>
        </div>
      </div>
    </div>
  </div>
  <div id="sw-LightboxA02" class="sw-LightboxA_Content">
    <div class="sw-LightboxA_Inner">
      <div class="sw-LightboxA_Head">
        <img class="sw-LightboxA_Image" src="https://placehold.jp/570x290.png">
      </div>
      <div class="sw-LightboxA_Body">
        <p class="sw-LightboxA_Heading">2タイトルが入りますタイトルが入りますタイトルが入ります</p>
        <p class="sw-LightboxA_Text">説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。説明文が入ります。</p>
        <button type="button" class="sw-LightboxA_Next"><span class="sw-LightboxA_NextText">Next</span><span class="sw-LightboxA_NextIcon" aria-hidden="true"></span></button>
        <button type="button" class="sw-LightboxA_Prev" title="Previous"><span class="sw-LightboxA_PrevText">Previous</span><span class="sw-LightboxA_PrevIcon" aria-hidden="true"></span></button>
        <div class="sw-LightboxA_Button">
          <button type="button" class="sw-LightboxA_Close sw-Button sw-Button-invert">閉じる<span class="sw-Button_RightArrow" aria-hidden="true"></span></button>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw-LightboxA_Contents {
  display: none;
}

.sw-LightboxA_Inner {
  max-width: 570px;
  margin: auto;
  padding-bottom: 30px;

  @include mq-up(md) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.sw-LightboxA_Content {
  position: relative;
}

.sw-LightboxA_Head {
  width: 100%;
}

.sw-LightboxA_Body {
  padding: 17px 30px 0;

  @include mq-up(md) {
    padding-right: 0;
    padding-left: 0;
  }
}

.sw-LightboxA_Image {
  display: block;
  width: 100%;
}

.sw-LightboxA_Heading {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 1.6;
  color: $color-brand;
  font-weight: 700;

  @include mq-up(md) {
    margin-bottom: 12px;
    font-size: 18px;
  }
}

.sw-LightboxA_Text {
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 1.8;
  color: $color-text;

  @include mq-up(md) {
    margin-bottom: 12px;
    font-size: 16px;
  }
}

.sw-LightboxA_Button {
  margin: 26px auto 0;
  text-align: center;

  @include mq-up(md) {
    margin-top: 20px;
  }
}

.sw-LightboxA_Close {
}

.sw-LightboxA_Prev {
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-color: #ababab;
  color: #fff;
  border-radius: 50%;
  border: 0;

  @include mq-up(lg) {
    left: 40px;
    width: 50px;
    height: 50px;
  }

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  &:hover {
    
  }

  &:active {
    border: 0;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  &:disabled,
  &-disabled {
    opacity: $form-disabled-opacity;
    border-color: $form-disabled-background-color;
    color: #555;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;

    &:hover {
      color: #555;
    }
  }
}

.sw-LightboxA_PrevText {
  @include sr-only();
}

.sw-LightboxA_PrevIcon {
  font-size: 13px;

  @include mq-up(md) {
    font-size: 16px;
  }

  &:before {
    @include Icon(icon_arrow-left);
    position: relative;
    top: -0.1em;
  }
}

.sw-LightboxA_Next {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  background-color: #ababab;
  color: #fff;
  border-radius: 50%;
  border: 0;

  @include mq-up(lg) {
    right: 40px;
    width: 50px;
    height: 50px;
  }

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  &:hover {
    
  }

  &:active {
    border: 0;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  &:disabled,
  &-disabled {
    opacity: $form-disabled-opacity;
    border-color: $form-disabled-background-color;
    color: #555;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;

    &:hover {
      color: #555;
    }
  }
}

.sw-LightboxA_NextText {
  @include sr-only();
}

.sw-LightboxA_NextIcon {
  font-size: 13px;

  @include mq-up(md) {
    font-size: 16px;
  }
  
  &:before {
    @include Icon(icon_arrow-right);
    position: relative;
    top: -0.1em;
  }
}


#colorbox, #cboxOverlay, #cboxWrapper {
  overflow: visible;
}
#cboxWrapper {
  max-width: none;
}
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
#cboxOverlay {
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxContent {
  overflow: visible;
}
#cboxLoadedContent {
  padding: 0;
  overflow: visible !important;
  background: #fff;
}
#cboxPrevious,
#cboxNext,
#cboxClose {
  display: none !important;
}
