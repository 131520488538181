.st-ContentHeaderA_LabPortal {
  & .st-ContentLeadA_Text {
    display: none;

    @include mq-up(lg) {
      display: block;
      font-size: rem(15);
      font-weight: bold;
      color: #fff;
      margin-top: 20px;
    }
  }

  & .st-ContentHeaderA_Text {
    font-size: rem(35);
    letter-spacing: 0.13em;
  }

  & .st-ContentHeaderA_Title {
    @include mq-up(lg) {
      margin-top: 145px;
    }
  }

  & .st-LocalHeroA_Text {
    width: calc(100% + 5px);
    line-height: 1.6;
    letter-spacing: 0.2em;
  }
}

.st-ContentHeaderA_LabPortal ~ .st-ContainerA {
  & .sw-VisualNavB_Image {
    max-height: 191px;
  }
}

.st-LabPortal_Pickup {
  &.st-Wrapper {
    padding-top: 40px;

    @include mq-up(lg) {
      padding-top: 90px;
    }
  }
}

.st-LabPortal_Pickup_Link {
  color: #fff;
  text-decoration: none;
  padding-top: 70px;

  @include mq-up(lg) {
    padding-top: 70px;
  }
}

.st-LabPortal_Pickup_Inner {
  background-image: url(/assets/img/labplus/index/image_blogBg.jpg);
  display: flex;
  position: relative;
  flex-direction: column-reverse;

  @include mq-up(lg) {
    flex-direction: inherit;
  }

  & .sw-CardNavA_Details,
  .sw-CardNavALabplus01_Details,
  .sw-CardNavALabplus02_Details {
    top: 8px;
    left: 8px;

    @media print, screen and (min-width: 1200px) {
      top: 11px;
      left: 11px;
    }
  }

  & .sw-CardNavA_Label,
  .sw-CardNavALabplus01_Label,
  .sw-CardNavALabplus02_Label {
    width: 60%;
  }

  &:hover {
    opacity: 0.7;
  }
}

.st-LabPortal_Pickup_Text {
  width: 100%;
  padding: 20px 30px 25px;
  letter-spacing: 0.12em;

  @include mq-up(lg) {
    width: 40%;
    height: 50%;
    margin: auto;
    padding: 0;
  }
}

.st-LabPortal_Pickup_Title {
  font-size: rem(20);
  text-align: center;
  margin-bottom: 5px;

  @include mq-up(lg) {
    font-size: rem(24);
    text-align: left;
  }

  @media print, screen and (min-width: 1200px) {
    font-size: rem(26);
    margin-bottom: 10px;
  }
}

.st-LabPortal_Pickup_Lead {
  font-size: rem(12.5);

  @media print, screen and (min-width: 1200px) {
    font-size: rem(13);
  }
}

.st-LabPortal_Pickup_Image {
  width: 100%;

  @include mq-up(lg) {
    width: 50%;
  }

  & img {
    min-width: 100%;
  }
}

// _VisualNavC ------------------------------
.sw-VisualNavC_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.sw-VisualNavC_Button:hover {
  background-color: transparent;
}

.sw-VisualNavA_ButtonIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}
