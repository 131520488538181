/*
---
name: st-ContentHeaderG
namespace: Structure
category: Navigation
id: [PT4-6]
---

<span class="sw-Label">PT4-6</span>

- 個別に特色を持つページ
- 2階層・TOP用
- ページタイトル、ナビあり
- PT4（1170✖320）SP(縦220) → PT4-6（1170✖494）SP(縦322)
- Move Onトップで使用

```html
<div class="st-ContentHeaderG">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderG_Block">
      <div class="st-ContentHeaderG_Title">
        <p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
          <span class="st-LocalTitleB_Logo">
            <img src="/assets/img/structure/LocalTitle/logo_sangaku.svg" alt="">
          </span>
          再生医療サポートプラットフォーム
          <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
        </p>
      </div>
      <div class="st-ContentHeaderG_Nav">
        <div class="st-LocalNav3" id="st-LocalNav3" aria-labelledby="st-LocalTitleB" aria-hidden="true">
          <ul class="st-LocalNav3_Items">
            <li class="st-LocalNav3_Item">
              <a href="/_events/event/" class="st-LocalNav3_Link">TOP<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">再生医療サポート<span class="st-LocalNav3_Delimiter">プラットフォームとは</span><span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">情報提供・PR<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">モノづくりサポート<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <span class="st-LocalNav3_Delimiter"><a href="" class="st-LocalNav3_Link">事業創出
                プロデュース<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a></span>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">イベント情報<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">ご相談窓口<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="st-ContentHeaderG_Hero">
      <div class="st-LocalHeroG">
        <div class="st-LocalHeroG_Bg"></div>
        <picture class="st-LocalHeroG_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1170x494.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x322.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroG_Text">再生医療の早期実現と、<br>
      モノづくり企業のイノベーションを目指しています。</p>
      </div>
    </div>
  </div>
</div>
```


*/
.st-ContentHeaderG {

  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.st-ContentHeaderG_Block {

}

.st-ContentHeaderG_Block-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background-color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}

.st-ContentHeaderG_Title {
  @include mq-up(lg) {
    position: relative;

    .st-ContentHeaderG_Block-fixed & {
      display: inline-block;
      vertical-align: middle;
      margin-right: 27px;
    }
  }
}

.st-ContentHeaderG_Nav {
  .st-ContentHeaderG_Block-fixed & {
    padding: 0 20px;
  }

  @include mq-up(lg) {
    margin-top: 43px;

    .st-ContentHeaderG_Block-fixed & {
      margin-top: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.st-ContentHeaderG_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
  }
}

.st-ContentHeaderG_SubNav {
  @include mq-up(lg) {
    margin-top: 30px;

    .st-ContentHeaderG-serviceoffice & {
      margin-top: 0;
    }
  }
}

.st-ContentHeaderG_Additional {
  display: none;

  @include mq-up(lg) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;

    .st-ContentHeaderG_Block-fixed & {
      display: none;
    }
  }
}

.st-ContentHeaderG_AdditionalLink {
  display: block;
  padding: 5px 16px;
  font-size: rem(12, lg);
  font-weight: bold;
  line-height: 1;
  color: #000;
  border: 1px solid currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.st-ContentHeaderG_AdditionalIcon {
  position: relative;
  top: -0.1em;
  font-size: 0.8em;

  &:before {
    @include Icon("icon_arrow-right");
  }
}
