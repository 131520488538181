/*
---
name: st-ContainerE
namespace: events
category: Layout
id: [K2, CON-6, FC-7]
---

<span class="sw-Label">CON-6</span><span class="sw-Label">FC-7</span>

再生医療やイベントのブログエリア下の関連記事を表示するエリアで使用します。直前に`.st-ContainerD`がない場合は上下に余白が入ります。

レイアウト例です。

```html
<footer class="st-ContainerE">
  <div class="st-Wrapper">
    <h2 class="sw-Heading2B sw-Heading2B-dark st-Heading2">過去のイベント</h2>
    <ul class="events-Nav1_Items">
      <li class="events-Nav1_Item">
        <a href="#" class="events-Nav1_Block">
          <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
          <ul class="events-Nav1_Labels">
            <li class="events-Nav1_Label">イベント</li>
          </ul>
          <div class="events-Nav1_Body">
            <div class="events-Nav1_BodyInner" style="height: 112px;">
              <div class="events-Nav1_Date">
                <div class="events-Nav1_DateStart">
                  10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
                </div>
                <div class="events-Nav1_To" aria-label="から"></div>
                <div class="events-Nav1_DateEnd">
                  10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
                </div>
              </div>
              <div class="events-Nav1_Description">
                <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
              </div>
            </div>
          </div>
        </a>
      </li>
      <li class="events-Nav1_Item">
        <a href="#" class="events-Nav1_Block">
          <img src="/assets/img/_events/event/image02.jpg" class="events-Nav1_Image" alt="">
          <ul class="events-Nav1_Labels">
            <li class="events-Nav1_LabelEnd">終了</li>
            <li class="events-Nav1_Label">イベント</li>
          </ul>
          <div class="events-Nav1_Body">
            <div class="events-Nav1_BodyInner" style="height: 112px;">
              <div class="events-Nav1_Description">
                <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
                <span class="events-Nav1_PostDate">2017/10/16</span>
              </div>
            </div>
          </div>
        </a>
      </li>
      <li class="events-Nav1_Item">
        <a href="#" class="events-Nav1_Block">
          <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
          <ul class="events-Nav1_Labels">
            <li class="events-Nav1_Label">イベント</li>
          </ul>
          <div class="events-Nav1_Body">
            <div class="events-Nav1_BodyInner" style="height: 112px;">
              <div class="events-Nav1_Date">
                <div class="events-Nav1_DateStart">
                  10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
                </div>
                <div class="events-Nav1_To" aria-label="から"></div>
                <div class="events-Nav1_DateEnd">
                  10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
                </div>
              </div>
              <div class="events-Nav1_Description">
                <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="st-ContainerE_Foot">
      <a href="#" class="sw-Button sw-Button-invert">イベント一覧<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
    </div>
  </div>
</footer>
```
*/
.st-ContainerE {
  margin-bottom: 60px;
  padding: $padding-conitaner-a;
  padding-bottom: 0;

  @include mq-up(lg) {
    margin-bottom: 100px;
    padding: $padding-conitaner-a-lg;
    padding-bottom: 0;
  }

  .st-ContainerD + & {
    padding: 0;

    @include mq-up(lg) {
      padding: 0;
    }
  }
}

.st-ContainerE_Foot {
  margin-top: 35px;
  text-align: center;

  @include mq-up(lg) {
    margin-top: 45px;
  }
}
