/*
---
name: sw-MediaB
namespace: SiteWide
category: Block
id: [C1, TH-10A, TH-10B]
---

画像とテキストエリアが横並びになるモジュールで、グリッドで2カラムなどにしてレイアウトします。

<span class="sw-Label">TH-10A</span>

```html
<div class="st-Wrapper">
  <ul class="st-Grids st-Grids-col2Lg st-Block">
    <li class="st-Grids_Item">
      <div class="sw-MediaB sw-MediaB-col6to6Lg">
        <div class="sw-MediaB_ImageArea">
          <img src="https://placehold.jp/270x175.png" alt="" class="sw-MediaB_Image">
        </div>
        <div class="sw-MediaB_Body">
          <p class="sw-MediaB_Heading">ここに名前が入ります</p>
          <p class="sw-MediaB_Text">ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
        </div>
        <div class="sw-MediaB_Foot">
          <ul class="sw-MediaA_ButtonArea">
            <li class="sw-MediaA_ButtonItem">
              <a href="#" target="_blank" class="sw-MediaA_Button">テキストが入ります<span class="sw-MediaA_External" aria-hidden="true"></span></a>
            </li>
          </ul>
          <ul class="sw-MediaB_Links">
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">Facebook<span class="sw-MediaB_LinkPdf" aria-hidden="true"></span></a>
            </li>
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">X<span class="sw-MediaB_LinkExternal" aria-hidden="true"></span></a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li class="st-Grids_Item">
      <div class="sw-MediaB sw-MediaB-col6to6Lg">
        <div class="sw-MediaB_ImageArea">
          <img src="https://placehold.jp/270x175.png" alt="" class="sw-MediaB_Image">
        </div>
        <div class="sw-MediaB_Body">
          <a class="sw-MediaB_Heading" href="#" target="_blank"><span class="sw-MediaB_HeadingIcon" aria-hidden="true"></span>ここに名前が入ります<span class="sw-MediaB_LinkExternal" aria-hidden="true"></span></a>
          <p class="sw-MediaB_Text">ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
          <ul class="sw-MediaB_Links sw-MediaB_Links-left">
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">Facebook</a>
            </li>
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">X</a>
            </li>
          </ul>

        </div>
        <div class="sw-MediaB_Foot">
          <ul class="sw-MediaA_ButtonArea">
            <li class="sw-MediaA_ButtonItem">
              <a href="#" class="sw-MediaA_Button">テキストが入ります<span class="sw-MediaA_RightArrow" aria-hidden="true"></span></a>
            </li>
          </ul>
          <ul class="sw-MediaB_Links">
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">Facebook</a>
            </li>
            <li class="sw-MediaB_LinksItem">
              <span class="sw-MediaB_LinksIcon" aria-hidden="true"></span>
              <a class="sw-MediaB_LinksLink" href="#">X</a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</div>
```

<span class="sw-Label">TH-10B</span>

- リンクなし。

```html
<div class="st-Wrapper">
  <ul class="st-Grids st-Grids-col2Lg st-Block">
    <li class="st-Grids_Item">
      <div class="sw-MediaB sw-MediaB-col6to6Lg">
        <div class="sw-MediaB_ImageArea">
          <img src="https://placehold.jp/270x175.png" alt="" class="sw-MediaB_Image">
        </div>
        <div class="sw-MediaB_Body">
          <p class="sw-MediaB_Heading">ここに名前が入ります</p>
          <p class="sw-MediaB_Text">ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
        </div>
      </div>
    </li>
  </ul>
</div>
```
*/
.sw-MediaB {
  margin-left: -15px;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -30px;
  }
}

.sw-MediaB-col6to6Lg {
  @include mq-up(lg) {
    & > .sw-MediaB_ImageArea,
    & > .sw-MediaB_Body {
      width: percentage(div(1, 2));
    }
  }
}

.sw-MediaB-col3to9Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(3, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(9, 12)); }
  }
}
.sw-MediaB-col9to3Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(9, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(3, 12)); }
  }
}

.sw-MediaB-col4to8Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(4, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(8, 12)); }
  }
}
.sw-MediaB-col8to4Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(8, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(4, 12)); }
  }
}

.sw-MediaB-col5to7Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(5, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(7, 12)); }
  }
}
.sw-MediaB-col7to5Lg {
  @include mq-up(lg) {
    & > *:nth-of-type(odd) { width: percentage(div(7, 12)); }
    & > *:nth-of-type(even) { width: percentage(div(5, 12)); }
  }
}

.sw-MediaB-reverse {
  text-align: left;
  direction: rtl;

  & > .sw-MediaB_ImageArea,
  & > .sw-MediaB_Body {
    text-align: left;
    direction: ltr;
  }
}
.sw-MediaB-reverseLg {
  @include mq-up(lg) {
    text-align: left;
    direction: rtl;

    & > .sw-MediaB_ImageArea,
    & > .sw-MediaB_Body {
      text-align: left;
      direction: ltr;
    }
  }
}

.sw-MediaB_ImageArea {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;
  }
}

.sw-MediaB_Image {
  max-width: none;
  width: 100%;
}

.sw-MediaB_Body {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;

    & > :first-child {
      margin-top: 0;
    }
  }
}

.sw-MediaB_Foot {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;

  @include mq-up(lg) {
    padding-left: 30px;
  }
}

.sw-MediaB_Heading {
  position: relative;
  margin-top: 10px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    margin-top: 12px;
    font-size: rem(17, lg);
  }
}
a.sw-MediaB_Heading {
  display: block;
  padding-left: 20px;
  color: $color-text;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    color: #d02425;
    text-decoration: none;
  }
}

.sw-MediaB_HeadingIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.6em;
  left: 0;
  color: $color-brand;
  font-size: 0.7em;
}

.sw-MediaB_Text {
  margin-top: 8px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    margin-top: 20px;
    font-size: rem(16, lg);
  }
}

.sw-MediaB_Caution {
  font-weight: bold;
}

.sw-MediaB_Note {
  margin-left: 1.5em;
  padding-left: 0;
  font-size: rem(11, lg);
  line-height: 1.6;
  list-style-type: none;

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1em;
  }
}

.sw-MediaB_NoteItem {
  position: relative;

  &:before {
    content: "※";
    position: absolute;
    top: 0;
    left: -1.5em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*";
    left: -1em;
  }
}

.sw-MediaB_ButtonArea {
  margin-top: 30px;
  padding-left: 0;
  list-style-type: none;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.sw-MediaB_ButtonItem {
  display: block;

  & + & {
    margin-top: 20px;
  }

  @include mq-up(lg) {
    float: left;
    & + & {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

.sw-MediaB_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.sw-MediaB_Button-auto {
  width: auto;
}

.sw-MediaB_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-MediaB_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-MediaB_LinkPdf:before {
  @include sw-LinkPdf();
}

.sw-MediaB_LinkExternal:before {
  @include sw-LinkExternal();
}

.sw-MediaB_Link {
  @include sw-Link();
}

.sw-MediaB_LinkExternal:before {
  @include Icon("icon_windows");
  margin-left: 5px;
  vertical-align: baseline;
  color: $color-brand;
}

.sw-MediaB_Links {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-MediaB_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  .sw-MediaB_Links-left & {
    width: auto;
  }

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.sw-MediaB_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-MediaB_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}
