/*
---
name: company-Nav1
namespace: company
category: Navigation
---

入居企業トップページから企業検索へのナビゲーションです。

```html
<div class="st-Wrapper">
  <div class="company-Nav1">
    <p class="company-Nav1_Text">入居企業をキーワードで探す</p>
    <div class="company-Nav1_ButtonArea">
      <a href="#" class="company-Nav1_Button company-Nav1_ButtonInvert">企業検索<span class="company-Nav1_RightArrow" aria-hidden="true"></span></a>
    </div>
  </div>
</div>
```
*/
.company-Nav1 {
  display: block;
  font-size: 0;
  text-align: center;
}

.company-Nav1_Text {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
  vertical-align: middle;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.2;
  color: $color-brand;

  @include mq-up(lg) {
    width: auto;
    margin-bottom: 0;
    font-size: rem(26, lg);

    &:after {
      content: "";
      display: inline-block;
      margin: 0 35px 0 29px;
      vertical-align: middle;
      width: 1px;
      height: 40px;
      border-left: 1px solid $color-brand;
    }
  }
}

.company-Nav1_ButtonArea {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  font-size: 1rem;

  @include mq-up(lg) {
    width: auto;
  }
}

.company-Nav1_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.company-Nav1_ButtonInvert {
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: $color-brand;
    background-color: #fff;
  }
}

.company-Nav1_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;

  @include mq-up(lg) {
    right: 12px;
  }
}
