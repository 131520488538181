/*
---
name: st2-ContainerB
namespace: Structure2
category: Layout
---

コンテンツ内の上下に大きめの余白をとります。(デフォルト背景グレーです。)

レイアウト例です。

```html
<div class="st2-ContainerB">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st2-ContainerB-white`を追加すると背景色がつきます。

```html
<div class="st2-ContainerB st2-ContainerB-white">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

*/
.st2-ContainerB {
  padding: rem(60) rem(0);
  background-color: #f5f5f5;

  @include mq-up(lg) {
    padding:  rem(120, lg) rem(0);
  }

  &.st2-ContainerB-white {
    background-color: #fff;
  }
}
