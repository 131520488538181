/*
---
name: sw-FeatureNavB
namespace: SiteWide
category: Navigation
id: [L1, CA-3]
---

<span class="sw-Label">CA-3</span>

- 入居企業TOP

```html
<div class="st-Wrapper">
  <ul class="st-Grids">
    <li class="st-Grids_Item">
      <a class="sw-FeatureNavB_Link" href="#">
        <div class="sw-FeatureNavB_Head">
          <img src="https://placehold.jp/670x314.png" alt="" class="sw-FeatureNavB_HeadImage">
        </div>
        <div class="sw-FeatureNavB_Body">
          <div class="sw-FeatureNavB_Heading">スフェラーパワー株式会社　代表取締役会長　中田仗祐氏　代表取締役社長　井本聡一郎氏</div>
          <div class="sw-FeatureNavB_ButtonArea">
            <span class="sw-FeatureNavB_Button">詳細をみる<span class="sw-FeatureNavB_ButtonIcon" aria-hidden="true"></span></span>
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>
```
*/
.sw-FeatureNavB_Link {
  position: relative;
  display: block;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  color: #fff;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  @include mq-up(lg) {
    margin-right: 0;
    margin-left: 0;
  }
}

.sw-FeatureNavB_Head {
  display: block;

  @include mq-up(lg) {
    width: percentage(div(1070, 1170));
  }
}

.sw-FeatureNavB_HeadImage {
  display: block;
  max-width: none;
  width: 100%;
}

.sw-FeatureNavB_Body {
  padding: 15px 20px 24px;
  text-align: center;
  color: #fff;
  background-color: #333;

  @include mq-up(lg) {
    max-width: 340px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(-50px);
    margin: auto;
    padding: 40px 50px 44px;
    text-align: left;
  }
}

.sw-FeatureNavB_Heading {
  font-size: rem(18);
  line-height: 1.4;
  font-weight: bold;
  font-family: $font-family-heading-en;

  @include mq-up(lg) {
    font-size: rem(22, lg);
    line-height: 1.8;
  }
}

.sw-FeatureNavB_Delimiter {
  @include mq-up(lg) {
    display: block;
  }
}

.sw-FeatureNavB_Text {
  margin-top: 10px;
  font-size: rem(13);
  line-height: 1.6;

  @include mq-up(lg) {
    font-size: rem(18, lg);
  }
}

.sw-FeatureNavB_ButtonArea {
  margin-top: 13px;

  @include mq-up(lg) {
    margin-top: 30px;
  }
}

.sw-FeatureNavB_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
  color: #fff;
  background-color: $color-brand;

  &:hover {
    color: #fff;
    background-color: $color-brand;
  }
}

.sw-FeatureNavB_ButtonIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;

  @include mq-up(lg) {
    right: 12px;
  }
}
