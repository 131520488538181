/*
---
name: st-LocalNav4
namespace: Structure
category: Navigation
id: [K1, NV3]
---

<span class="sw-Label">NV3</span>

ローカルナビゲーションです。

- URLの2階層目（`/events/event/`など）と合致する場合はカレント表示されます。

```html
<div class="st-Wrapper">
<div class="st-LocalNav4">
  <ul class="st-LocalNav4_Items">
    <li class="st-LocalNav4_Item">
      <a href="/_events/event/" class="st-LocalNav4_Link">すべて</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">イベント</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">イベントレポート</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">スタートアップ</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">ビジネス</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">ものづくり</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">デザイン</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">伝統工芸</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">電子・エネルギー</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">ライフサイエンス</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">2017</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="" class="st-LocalNav4_Link">2016</a>
    </li>
  </ul>
</div>
</div>
```

右揃えにする場合は`.st-LocalNav4-right`を追加します。主に日本語以外のページで使用します。

```html
<div class="st-Wrapper">
<div class="st-LocalNav4 st-LocalNav4-right">
  <ul class="st-LocalNav4_Items">
    <li class="st-LocalNav4_Item">
      <a href="#" class="st-LocalNav4_Link" lang="en">English</a>
    </li>
    <li class="st-LocalNav4_Item">
      <a href="#" class="st-LocalNav4_Link" lang="zh-cn">中文</a>
    </li>
  </ul>
</div>
</div>
```
*/
.st-LocalNav4 {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: calc(50vw - 50%);
  padding-bottom: 15px;
  padding-left: calc(50vw - 50%);
  border-top: 1px solid #393939;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq-up(lg) {
    display: block !important;
    margin: 0;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    background-image: none;
  }
}

.st-LocalNav4_Items {
  display: block;
  margin-left: -2.5px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;
  line-height: 1.2;

  @include mq-up(lg) {
    text-align: center;

    .st-LocalNav4-right & {
      text-align: right;
    }
  }
}

.st-LocalNav4_Item {
  position: relative;
  display: inline-block;
  width: percentage(div(1, 3));
  vertical-align: top;
  padding-left: 2.5px;
  font-size: rem(9);
  text-align: center;

  &:nth-of-type(n+4) {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    font-size: rem(12, lg);

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    &:nth-of-type(n+1) {
      margin: 9px 0;
    }

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 1px;
      height: 30px;
      border-left: 1px solid $color-brand;
    }
  }
}

.st-LocalNav4_Link {
  display: block;
  padding: 10px 5px;
  text-decoration: none;
  color: #fff;
  background-color: #393939;

  @include mq-up(lg) {
    position: relative;
    display: block;
    padding: 8px 15px;
    font-weight: bold;
    color: $color-brand;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -14px;
      left: 0;
      width: 1em;
      height: 1em;
      margin: auto;
      border-top: 5px solid transparent;
      transition-duration: 0.3s;
      transition-property: width;
    }

    &:hover:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}

.st-LocalNav4_Link-current {
  background-color: #a81d34;

  @include mq-up(lg) {
    background-color: transparent;

    &:after {
      display: block;
      width: 100%;
      height: 5px;
      border-color: $color-brand;
    }
  }
}
