/*
---
name: sw-ImageCenter
namespace: SiteWide
category: Image
id: [K2, IMG-1]
---

<span class="sw-Label">IMG-1</span>

画像をセンタリングします。

`.sw-ImageCenter-8of12Lg`のように追加をするとサイズを指定できます。

```html
<div class="st-Image">
  <img src="https://placehold.jp/300x200.png" alt="" class="sw-ImageCenter">
</div>

<div class="st-Image">
  <img src="https://placehold.jp/300x200.png" alt="" class="sw-ImageCenter sw-ImageCenter-8of12Lg">
</div>
```
*/
.sw-ImageCenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.sw-ImageCenter-10of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(10, 12));
  }
}

.sw-ImageCenter-9of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(9, 12));
  }
}

.sw-ImageCenter-8of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(8, 12));
  }
}

.sw-ImageCenter-7of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(7, 12));
  }
}

.sw-ImageCenter-6of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(6, 12));
  }
}

.sw-ImageCenter-5of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(5, 12));
  }
}

.sw-ImageCenter-4of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(4, 12));
  }
}

.sw-ImageCenter-3of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(3, 12));
  }
}

.sw-ImageCenter-2of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(2, 12));
  }
}

.sw-ImageCenter-1of12Lg {
  @include mq-up(lg) {
    max-width: none;
    width: percentage(div(1, 12));
  }
}
