/*
---
name: st-SubContainerB
namespace: Structure
category: Layout
id: K2
---


<span class="sw-Label">CON-</span>


*/
.st-SubContainerB {
  padding: $padding-sub-conitaner-b;

  .st-Section2 + & {
    padding-top: 60px;

    @include mq-up(lg) {
      margin-top: 100px;
    }
  }

  @media print, screen and (min-width: $max-width) {
    padding: $padding-sub-conitaner-b-lg;
  }
}

.st-SubContainerB-stripe {
  background-image: url("/assets/img/structure/bg_continer-stripe.png");
  background-repeat: repeat;
  background-size: auto;
  background-position: 0 0;
}

.st-SubContainerB-delimiterA {
  border-top: 2px solid $color-brand;

  @include mq-up(lg) {
    border-top-width: 4px;
  }
}

.st-SubContainerB-delimiterB {
  border-top: 1px solid #e8e8e8;
}
