/*
---
name: sw-TitleD
namespace: SiteWide
category: Heading
id: [K2, HD-2E]
---

<span class="sw-Label">HD-2E</span>

ページタイトルです。KRP色の強いページで使用します。
個々の特色を持つページでは`.sw-TitleD-dark`を追加します。
下の三角なし ▼

```html
<div class="st-Wrapper">
<h1 class="sw-TitleD sw-TitleD-dark st-Title">再生医療サポート<br>プラットフォームとは？</h1>
</div>
```
*/
.sw-TitleD {
  position: relative;
  font-family: $font-family-heading-en;
  font-size: rem(21);
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: $color-brand;

  @include mq-up(lg) {
    font-size: rem(32, lg);

  }
}

.sw-TitleD-dark {
  color: $color-text;
}
