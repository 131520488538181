/*
---
name: st2-FooterInfo
namespace: Structure2
category: Global
---

グローバルフッターにあるインフォメーションエリアです。

```html
<div class="st2-FooterInfo">
  <div class="st2-FooterInfo_Inner">
    <ul class="st2-FooterInfo_HeadItems">
      <li class="st2-FooterInfo_HeadItem">
        <a href="#" class="st2-FooterInfo_HeadItem_link">
          <span class="st2-FooterInfo_HeadItem_HeadIconAccess" aria-hidden="true"></span>
          <span class="st2-FooterInfo_HeadItem_Text">アクセス</span>
        </a>
      </li>
      <li class="st2-FooterInfo_HeadItem">
        <a href="#" class="st2-FooterInfo_HeadItem_link">
          <span class="st2-FooterInfo_HeadItem_HeadIconMap" aria-hidden="true"></span>
          <span class="st2-FooterInfo_HeadItem_Text">エリアマップ</span>
        </a>
      </li>
      <li class="st2-FooterInfo_HeadItem">
        <a href="#" class="st2-FooterInfo_HeadItem_link">
          <span class="st2-FooterInfo_HeadItem_HeadIconShops" aria-hidden="true"></span>
          <span class="st2-FooterInfo_HeadItem_Text">商業施設・店舗情報</span>
        </a>
      </li>
      <li class="st2-FooterInfo_HeadItem">
        <a href="#" class="st2-FooterInfo_HeadItem_link">
          <span class="st2-FooterInfo_HeadItem_HeadIconInstitution" aria-hidden="true"></span>
          <span class="st2-FooterInfo_HeadItem_Text">公的産業支援機関</span>
        </a>
      </li>
    </ul>
    <ul class="st2-FooterInfo_FootItems">
      <li class="st2-FooterInfo_FootItem">
        <a href="#" class="st2-FooterInfo_FootItem_link">ご入居企業さま　専用サイト</a>
      </li>
      <li class="st2-FooterInfo_FootItem">
        <a href="#" class="st2-FooterInfo_FootItem_link">入居企業・団体一覧</a>
      </li>
    </ul>
  </div>
</div>
```
*/
.st2-FooterInfo {
  padding: rem(35) rem(20) rem(15);
  background-color: #EDEFF0;

  @include mq-up(lg) {
    padding: rem(60, lg) rem(20, lg) rem(40, lg);
  }

  @media print, screen and (min-width: $max-width) {
    padding: rem(80, lg) rem(20, lg) rem(50, lg);
  }
}
.st2-FooterInfo_Inner {

  @include mq-up(lg) {
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
  }
}
.st2-FooterInfo_HeadItems {
  margin: rem(0);
  padding: rem(0);
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: rem(-15);
  @include mq-up(lg) {
    flex-flow: nowrap;
    margin-left: rem(-30, lg);
  }
}
.st2-FooterInfo_HeadItem {
  list-style: none;
  margin: rem(0);
  padding: rem(0);
  width: 50%;
  padding-left: rem(15);
  margin-bottom: rem(15);
  @include mq-up(lg) {
    padding-left: rem(30, lg);
    margin-bottom: rem(24, lg);
  }
}
.st2-FooterInfo_HeadItem_link {
  display: block;
  text-decoration: none;
  padding: rem(14) 0;
  text-align: center;
  background-color: #fff;
  border: 1px solid #D3D8DA;
  color: $color-krp;
  @include mq-up(lg) {
    padding: rem(24, lg) 0;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }
  &:hover {
    @include mq-up(lg) {
      color: #fff;
      background-color: $color-krp;
    }
  }
}
.st2-FooterInfo_HeadItem_Text {
  display: block;
  font-weight: 700;
  font-size: rem(13);
  line-height: rem(13);
  margin-top: rem(13);
  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: rem(14, lg);
    margin-top: rem(16, lg);
  }
}
.st2-FooterInfo_HeadItem_HeadIconAccess {
  display: block;
  &::before {
    @include Icon("icon_access");
    font-size: rem(50);
    @include mq-up(lg) {
      font-size: rem(60, lg);
    }
  }
}
.st2-FooterInfo_HeadItem_HeadIconMap {
  display: block;
  &::before {
    @include Icon("icon_areamap");
    font-size: rem(50);
    @include mq-up(lg) {
      font-size: rem(60, lg);
    }
  }
}
.st2-FooterInfo_HeadItem_HeadIconShops {
  display: block;
  &::before {
    @include Icon("icon_build-facility");
    font-size: rem(50);
    @include mq-up(lg) {
      font-size: rem(60, lg);
    }
  }
}
.st2-FooterInfo_HeadItem_HeadIconInstitution {
  display: block;
  &::before {
    @include Icon("icon_build-public");
    font-size: rem(50);
    @include mq-up(lg) {
      font-size: rem(60, lg);
    }
  }
}
.st2-FooterInfo_FootItems {
  margin: rem(0);
  padding: rem(0);

  @include mq-up(lg) {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-30, lg);
  }

  &.-is-col3 {
    .st2-FooterInfo_FootItem {
      @include mq-up(lg) {
        width: 33.33333%;
      }
    }
  }
}
.st2-FooterInfo_FootItem {
  list-style: none;
  margin: rem(15) 0 0;
  padding: rem(0);
  @include mq-up(lg) {
    margin: 0;
    width: 50%;
    padding-left: rem(30, lg);
  }

  &:first-child {
    margin: 0;
  }
}
.st2-FooterInfo_FootItem_link {
  text-decoration: none;
  display: block;
  background-color: #fff;
  border: 1px solid #D3D8DA;
  color: $color-krp;
  text-align: center;
  font-weight: 700;
  font-size: rem(13);
  line-height: rem(13);
  padding: rem(18);
  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: rem(14, lg);
    padding: rem(22, lg);
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;
  }

  &:hover {
    @include mq-up(lg) {
      color: #fff;
      background-color: $color-krp;
    }
  }
}
