/*
---
name: sw-Text
namespace: SiteWide
category: Text
id: [K2, TX-2]
---

<span class="sw-Label">TX-2</span>

本文に使うベーシックなテキストです。余白には`.st-Text`を指定します。

```html
<div class="st-Wrapper">
<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```

常に中央揃えにするには`.sw-Text-center`を追加します。

```html
<div class="st-Wrapper">
<p class="sw-Text sw-Text-center st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```

PC以降で中央揃えにするには`.sw-Text-centerLg`を追加します。

```html
<div class="st-Wrapper">
<p class="sw-Text sw-Text-centerLg st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.sw-Text {
  font-size: rem(14);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-Text-center {
  text-align: center;
}

.sw-Text-centerLg {
  @include mq-up(lg) {
    text-align: center;
  }
}
