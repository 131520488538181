/*
---
name: sw-Heading4A
namespace: SiteWide
category: Heading
id: [K2, HD-6A]
---

<span class="sw-Label">HD-6A</span>

`<h4>`で使用する見出しです。余白には`.st-Heading4`を使用します。

```html
<div class="st-Wrapper">
<h4 class="sw-Heading4A st-Heading4">京都バイオ計測センター</h4>
</div>
```

```html
<div class="st-Wrapper">
<h4 class="sw-Heading4A st-Heading4">ここにテキストが入ります<br>改行した場合のレイアウトです</h4>
</div>
```
*/
.sw-Heading4A {
  padding-bottom: 4px;
  font-family: $font-family-heading;
  font-size: rem(18);
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px solid #d2d2d2;

  @include mq-up(lg) {
    padding-bottom: 5px;
    font-size: rem(18, lg);
  }
}
