/*
---
name: office-Feature
namespace: office
category: Block
id: [C1, TH-11]
---

<span class="sw-Label">TH-5A</span>

オフィス・ラボの「魅力ある環境・サービス」で使用されるモジュールです。

- style属性で背景画像を指定してください。

```html
<div class="st-Wrapper">
  <div class="office-Feature" style="background-image: url(https://placehold.jp/1170x440.png)">
    <div class="office-Feature_Box">
      <p class="office-Feature_Heading">充実の共用部</p>
      <p class="office-Feature_Text">無料で使える共用ロビーや打ち合わせスペースを多数設置。<br>
      割引価格で使える貸会議室も大小約20室あるので、室内に応接・打ち合わせスペースがなくてもOK。お部屋を有効に利用できます。</p>
    </div>
  </div>
</div>
```
*/
.office-Feature {
  position: relative;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: 20px 0;
  background-image: url("https://placehold.jp/1170x440.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include mq-up(lg) {
    padding: 40px 0;
    // background-attachment: fixed;
  }
}

.office-Feature_Box {
  width: calc(100% - 20px);
  min-width: 235px;
  width: percentage(div(470, 750));
  margin: auto;
  padding: 20px 25px;
  background-color: rgba(#fff, 0.7);

  @include mq-up(md) {
    width: 100%;
    max-width: 470px;
    padding: 40px;
  }
}

.office-Feature_Heading {
  margin-bottom: 13px;
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.4;
  text-align: center;

  &:after {
    content: "";
    display: block;
    width: 30px;
    height: 1px;
    margin: auto;
    padding-top: 12px;
    border-bottom: 1px solid currentColor;
  }

  @include mq-up(lg) {
    margin-bottom: 30px;
    font-size: rem(26, lg);

    &:after {
      content: "";
      display: block;
      width: 60px;
      height: 1px;
      margin: auto;
      padding-top: 20px;
      border-bottom: 1px solid currentColor;
    }
  }
}

.office-Feature_Text {
  font-size: rem(12);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}
