/*
---
name: st-ContentHeaderD
namespace: Structure
category: Navigation
id: [K1, PT4, PT4-2, PT4-3, PT4-4]
---

<span class="sw-Label">PT4</span>

- 個別に特色を持つページ
- 2階層・TOP用
- ページタイトル、ナビあり

```html
<div class="st-ContentHeaderD">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderD_Block">
      <div class="st-ContentHeaderD_Title">
        <p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
          <span class="st-LocalTitleB_Logo">
            <img src="/assets/img/structure/LocalTitle/logo_sangaku.svg" alt="">
          </span>
          再生医療サポートプラットフォーム
          <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
        </p>
      </div>
      <div class="st-ContentHeaderD_Nav">
        <div class="st-LocalNav3" id="st-LocalNav3" aria-labelledby="st-LocalTitleB" aria-hidden="true">
          <ul class="st-LocalNav3_Items">
            <li class="st-LocalNav3_Item">
              <a href="/_events/event/" class="st-LocalNav3_Link">TOP<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">再生医療サポート<span class="st-LocalNav3_Delimiter">プラットフォームとは</span><span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">情報提供・PR<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">モノづくりサポート<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <span class="st-LocalNav3_Delimiter"><a href="" class="st-LocalNav3_Link">事業創出
                プロデュース<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a></span>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">イベント情報<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">ご相談窓口<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="st-ContentHeaderD_Hero">
      <div class="st-LocalHeroB">
        <div class="st-LocalHeroB_Bg"></div>
        <picture class="st-LocalHeroB_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1170x320.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x205.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroB_Text">再生医療の早期実現と、<br>
      モノづくり企業のイノベーションを目指しています。</p>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">PT4-2</span>

- 個別に特色を持つページ
- 2階層・下層用
- ページタイトル
- ナビあり

```html
<div class="st-ContentHeaderD">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderD_Block">
      <div class="st-ContentHeaderD_Title">
        <p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
          <span class="st-LocalTitleB_Logo">
            <img src="/assets/img/structure/LocalTitle/logo_sangaku.svg" alt="">
          </span>
          再生医療サポートプラットフォーム
          <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
        </p>
      </div>
      <div class="st-ContentHeaderD_Nav">
        <div class="st-LocalNav3" id="st-LocalNav3" aria-labelledby="st-LocalTitleB" aria-hidden="true">
          <ul class="st-LocalNav3_Items">
            <li class="st-LocalNav3_Item">
              <a href="/_events/event/" class="st-LocalNav3_Link">TOP<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">再生医療サポート<span class="st-LocalNav3_Delimiter">プラットフォームとは</span><span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">情報提供・PR<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">モノづくりサポート<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <span class="st-LocalNav3_Delimiter"><a href="" class="st-LocalNav3_Link">事業創出
                プロデュース<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a></span>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">イベント情報<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="" class="st-LocalNav3_Link">ご相談窓口<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="st-ContentHeaderD_Hero">
      <div class="st-LocalHeroC">
        <div class="st-LocalHeroC_Bg"></div>
        <picture class="st-LocalHeroC_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1170x200.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x205.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroC_Text">再生医療の早期実現と、<br>
      モノづくり企業のイノベーションを目指しています。</p>
      </div>
    </div>
    <div class="st-ContentHeaderD_SubNav">
      <div class="st-LocalNav4">
        <ul class="st-LocalNav4_Items">
          <li class="st-LocalNav4_Item">
            <a href="#" class="st-LocalNav4_Link">すべて</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="#" class="st-LocalNav4_Link">ニュース</a>
          </li>
          <li class="st-LocalNav4_Item">
            <a href="#" class="st-LocalNav4_Link">イベント</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">PT4-3</span>

- serviceoffice（BIZ NEXT）トップページ専用
- ナビが8つ以上になっても横幅は等幅になります
- 英語ページへのリンクがつきます
  - `.st-LocalNav3_SubItemArea`はスマホでのみ表示、`.st-ContentHeaderD_Additional`はPCのみ表示されます

```html
<div class="st-ContentHeaderD st-ContentHeaderD-serviceoffice">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderD_Block">
      <div class="st-ContentHeaderD_Title">
        <p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
          <a href="/serviceoffice/">
            <span class="st-LocalTitleB_Logo">
              <img src="/assets/img/structure/LocalTitle/logo_serviceoffice.png" alt="BIZ NEXT">
            </span>
          </a>
          <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
        </p>
        <div class="st-ContentHeaderD_Additional">
          <a class="st-ContentHeaderD_AdditionalLink" href="/serviceoffice/index_eng.html">
            <span class="st-ContentHeaderD_AdditionalIcon" aria-hidden="true"></span>
            English
          </a>
        </div>
      </div>
      <div class="st-ContentHeaderD_Nav">
        <div class="st-LocalNav3" id="st-LocalNav3" aria-labelledby="st-LocalTitleB" aria-hidden="true">
          <ul class="st-LocalNav3_Items">
            <li class="st-LocalNav3_Item">
              <a href="/_events/event/" class="st-LocalNav3_Link">メリット<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">会員タイプ<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">施設料金<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">ご契約までの流れ<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">よくある質問<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">ご利用の声<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">活用シーン<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">アクセス<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_SubItemArea">
              <ul class="st-LocalNav3_SubItems">
                <li class="st-LocalNav3_SubItem">
                  <a href="/kaigi/room/price.html" class="st-LocalNav3_SubLink">
                    English
                    <span class="st-LocalNav3_SubIcon" aria-hidden="true"></span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="st-ContentHeaderD_Hero">
      <div class="st-LocalHeroD">
        <div class="st-LocalHeroD_Bg"></div>
        <picture class="st-LocalHeroD_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1170x520.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x334.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroD_Text">京都で今までにない<br>
        ハイクラスのサービスと<br>
        上質な空間であなたのビジネスをサポート</p>
      </div>
    </div>
    <div class="st-ContentHeaderD_SubNav">
      <div class="st-LocalNav6">
        <ul class="st-LocalNav6_Items">
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>無料体験</a>
          </li>
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>内覧予約</a>
          </li>
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>お問い合わせ</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```

<span class="sw-Label">PT4-4</span>

- serviceoffice（BIZ NEXT）下層ページ専用
- ナビが8つ以上になっても横幅は等幅になります
- 英語ページへのリンクがつきます
  - `.st-LocalNav3_SubItemArea`はスマホでのみ表示、`.st-ContentHeaderD_Additional`はPCのみ表示されます

```html
<div class="st-ContentHeaderD st-ContentHeaderD-serviceoffice">
  <div class="st-Wrapper">
    <div class="st-ContentHeaderD_Block">
      <div class="st-ContentHeaderD_Title">
        <p class="st-LocalTitleB" id="st-LocalTitleB" aria-controls="st-LocalNav3" aria-expanded="false" role="heading">
          <a href="/serviceoffice/">
            <span class="st-LocalTitleB_Logo">
              <img src="/assets/img/structure/LocalTitle/logo_serviceoffice.png" alt="BIZ NEXT">
            </span>
          </a>
          <span class="st-LocalTitleB_Icon" aria-hidden="true"></span>
        </p>
        <div class="st-ContentHeaderD_Additional">
          <a class="st-ContentHeaderD_AdditionalLink" href="/serviceoffice/index_eng.html">
            <span class="st-ContentHeaderD_AdditionalIcon" aria-hidden="true"></span>
            English
          </a>
        </div>
      </div>
      <div class="st-ContentHeaderD_Nav">
        <div class="st-LocalNav3" id="st-LocalNav3" aria-labelledby="st-LocalTitleB" aria-hidden="true">
          <ul class="st-LocalNav3_Items">
            <li class="st-LocalNav3_Item">
              <a href="/_events/event/" class="st-LocalNav3_Link">メリット<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">会員タイプ・料金<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">施設料金<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">ご契約までの流れ<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">よくある質問<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">ご利用の声<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">活用シーン<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_Item">
              <a href="#" class="st-LocalNav3_Link">アクセス<span class="st-LocalNav3_Icon" aria-hidden="true"></span></a>
            </li>
            <li class="st-LocalNav3_SubItemArea">
              <ul class="st-LocalNav3_SubItems">
                <li class="st-LocalNav3_SubItem">
                  <a href="/kaigi/room/price.html" class="st-LocalNav3_SubLink">
                    English
                    <span class="st-LocalNav3_SubIcon" aria-hidden="true"></span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="st-ContentHeaderD_Hero">
      <div class="st-LocalHeroE">
        <div class="st-LocalHeroE_Bg"></div>
        <picture class="st-LocalHeroE_Image">
          <!--[if IE 9]><video style="display: none;"><![endif]-->
          <source media="(min-width: 980px)" srcset="https://placehold.jp/1170x140.png">
            <!--[if IE 9]></video><![endif]-->
            <img src="https://placehold.jp/750x205.png" alt="alt属性値を指定します。">
        </picture>
        <p class="st-LocalHeroE_Text">再生医療の早期実現と、<br>
      モノづくり企業のイノベーションを目指しています。</p>
      </div>
    </div>
    <div class="st-ContentHeaderD_SubNav">
      <div class="st-LocalNav6">
        <ul class="st-LocalNav6_Items">
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>無料体験</a>
          </li>
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>内覧予約</a>
          </li>
          <li class="st-LocalNav6_Item">
            <a href="#" class="st-LocalNav6_Link" data-scroll>お問い合わせ</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
```
*/
.st-ContentHeaderD {

  @include mq-up(lg) {
    margin-top: 34px;
  }
}

.st-ContentHeaderD_Block {

}

.st-ContentHeaderD_Block-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  background-color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}

.st-ContentHeaderD_Title {
  @include mq-up(lg) {
    position: relative;

    .st-ContentHeaderD_Block-fixed & {
      display: inline-block;
      vertical-align: middle;
      margin-right: 27px;
    }
  }
}

.st-ContentHeaderD_Nav {
  .st-ContentHeaderD_Block-fixed & {
    padding: 0 20px;
  }

  @include mq-up(lg) {
    margin-top: 43px;

    .st-ContentHeaderD_Block-fixed & {
      margin-top: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.st-ContentHeaderD_Hero {
  @include mq-up(lg) {
    margin-top: 20px;
  }
}

.st-ContentHeaderD_SubNav {
  @include mq-up(lg) {
    margin-top: 30px;

    .st-ContentHeaderD-serviceoffice & {
      margin-top: 0;
    }
  }
}

.st-ContentHeaderD_Additional {
  display: none;

  @include mq-up(lg) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;

    .st-ContentHeaderD_Block-fixed & {
      display: none;
    }
  }
}

.st-ContentHeaderD_AdditionalLink {
  display: block;
  padding: 5px 16px;
  font-size: rem(12, lg);
  font-weight: bold;
  line-height: 1;
  color: #000;
  border: 1px solid currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.st-ContentHeaderD_AdditionalIcon {
  position: relative;
  top: -0.1em;
  font-size: 0.8em;

  &:before {
    @include Icon("icon_arrow-right");
  }
}
