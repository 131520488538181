/*
---
name: st-Section5
namespace: Structure
category: Layout
---

h5相当の見出しをラップするセクションの余白を指定します。

```html
<p class="st-Text">.st-Text</p>
<section class="st-Section5">
  <h5 class="st-Heading5">.st-Heading5</h5>
  <p class="st-Text">.st-Text</p>
  <p class="st-Text">.st-Text</p>
</section>
<section class="st-Section5">
  <h5 class="st-Heading5">.st-Heading5</h5>
  <p class="st-Text">.st-Text</p>
</section>
```
*/
.st-Section5 {
  &[id]:before {
    content: "";
    display: block;
    margin-top: -120px;
    padding-top: 120px;
  }
  
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section5;
  }

  .st-Heading4 + & {
    margin-top: div($margin-section4, 2);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
