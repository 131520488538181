.krpweek-MediaNav {
  display: table;
  width: 100%;
  padding-top: 30px;
  padding-right: 2em;
  padding-bottom: 24px;
  text-decoration: none;
  color: $color-text;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    text-decoration: none;
    background-color: #fdeeee;
  }
}

.krpweek-MediaNav_ImageArea {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 100px;

  @include mq-up(lg) {
    width: 170px;
  }
}

.krpweek-MediaNav_Body {
  display: table-cell;
  vertical-align: top;
  width: auto;
  padding-left: 15px;

  @include mq-up(lg) {
    padding-left: 30px;
  }
}

.krpweek-MediaNav_UpdateLabelArea {
  position: absolute;
  top: 0;
  right: 0;
    line-height: 1;
}

.krpweek-MediaNav_UpdateLabel {
  display: inline-block;
  padding: 2px 18px;
  border: 1px solid $color-brand;
  color: #fff;
  font-size: rem(10);
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background-color: $color-brand;

  // aタグをマウスオーバーしたときのスタイル
  &[href]:hover {
    color: currentColor;
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 2px 18px;
    font-size: rem(13, lg);
  }
}

.krpweek-MediaNav_Image {

}

.krpweek-MediaNav_DetailLabelArea {
  margin-top: -10px;
  padding-left: 0;
  list-style-type: none;
}

.krpweek-MediaNav_DetailLabel {
  display: inline-block;
  margin-top: 10px;
  margin-right: 8px;
  line-height: 1;
}

.krpweek-label {
  @include sw-Label();
  vertical-align: top;
}

.krpweek-MediaNav_AmLabel {
  @extend .krpweek-label;
  color: #fff;
  background-color: #59b5ef;
}

.krpweek-MediaNav_PmLabel {
  @extend .krpweek-label;
  color: #fff;
  background-color: #f6a600;
}

.krpweek-MediaNav_NightLabel {
  @extend .krpweek-label;
  color: #fff;
  background-color: #4454f3;
}

.krpweek-MediaNav_DefaultLabel {
  @extend .krpweek-label;
}

.krpweek-MediaNav_Heading {
  position: relative;
  margin-top: 10px;
  margin-bottom: 4px;
  padding-left: 18px;
  font-family: $font-family-heading;
  font-size: rem(17, lg);
  font-weight: bold;
  line-height: 1.4;
  color: $color-brand;
}

.krpweek-MediaNav_HeadingIcon {
  position: absolute;
  top: 0;
  left: 0;

  &:before {
    @include Icon("icon_arrow-right");
    position: relative;
    top: -0.2em;
    color: $color-brand;
    font-size: 0.5em;
  }
}

.krpweek-MediaNav_Text {
  font-size: rem(16, lg);
  line-height: 1.8;
}

.krpweek-MediaNav_EventTime {
  margin-top: 6px;
  font-size: rem(13, lg);
  line-height: 1.8;
  color: #777;
}
