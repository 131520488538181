/*
---
name: sw2-Explanation
namespace: SiteWide2
category: InteractiveComponent
---

画像とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st2-Explanation`を使用してください。

- `.-is-first`を使用すると縦並びとなります。
- `.-is-reverse`を追加すると左右が入れ替わります。
- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st2-Explanation">
    <div class="sw2-Explanation -is-first">
      <div class="sw2-Explanation_Main">
        <div class="sw2-Explanation_Heading"><span>01</span>オフィス・ラボ</div>
        <div class="sw2-Explanation_Lead">小規模～3,000㎡超の賃貸オフィスやラボを400室以上ラインナップ</div>
        <div class="sw2-Explanation_Text">500の企業・団体 6,000人のプレイヤーが集まる 一大ビジネス・研究拠点であるKRPでは、単純な「働く場」の枠を超えた「魅力的な交流の舞台」を提供し、新事業・研究開発など、イノベーション創発に向け挑戦する皆さまを支援いたします。</div>
      </div>
      <div class="sw2-Explanation_Sub">
        <div class="sw2-Explanation_ImageList">
          <div class="sw2-Explanation_ImageListItem">
            <a href="/rentaloffice/">
              <div class="sw2-Explanation_ImageListItemIn">
                <div class="sw2-Explanation_ImageListItemImage">
                  <picture>
                    <source srcset="/assets/img/index/explanation_01_01.jpg" media="(min-width: 980px)"/>
                    <img src="/assets/img/index/explanation_01_01_sp.jpg" alt="オフィス" loading="lazy">
                  </picture>
                </div>
                <div class="sw2-Explanation_ImageListItemData">
                  <h2 class="sw2-Explanation_ImageListItemData_Heading">オフィス</h2>
                  <div class="sw2-Explanation_ImageListItemData_Text">小規模〜3,000m²超の賃貸オフィス</div>
                </div>
              </div>
            </a>
          </div>
          <div class="sw2-Explanation_ImageListItem">
            <a href="/rentaloffice/labo/">
              <div class="sw2-Explanation_ImageListItemIn">
                <div class="sw2-Explanation_ImageListItemImage">
                  <picture>
                    <source srcset="/assets/img/index/explanation_01_02.jpg" media="(min-width: 980px)"/>
                    <img src="/assets/img/index/explanation_01_02_sp.jpg" alt="ラボ" loading="lazy">
                  </picture>
                </div>
                <div class="sw2-Explanation_ImageListItemData">
                  <h2 class="sw2-Explanation_ImageListItemData_Heading">ラボ</h2>
                  <div class="sw2-Explanation_ImageListItemData_Text">約35m²〜1,000m²超の賃貸実験研究スペース</div>
                </div>
              </div>
            </a>
          </div>
          <div class="sw2-Explanation_ImageListItem">
            <a href="/serviceoffice/">
              <div class="sw2-Explanation_ImageListItemIn">
                <div class="sw2-Explanation_ImageListItemImage">
                  <picture>
                    <source srcset="/assets/img/index/explanation_01_03.jpg" media="(min-width: 980px)"/>
                    <img src="/assets/img/index/explanation_01_03_sp.jpg" alt="KRP BIZ NEXT" loading="lazy">
                  </picture>
                </div>
                <div class="sw2-Explanation_ImageListItemData">
                  <h2 class="sw2-Explanation_ImageListItemData_Heading">KRP BIZ NEXT</h2>
                  <div class="sw2-Explanation_ImageListItemData_Text">1ヶ月から利用可能なサービスオフィス</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="st2-Explanation">
    <div class="sw2-Explanation -is-button">
      <div class="sw2-Explanation_Main">
        <div class="sw2-Explanation_Heading"><span>02</span>ホール・会議室</div>
        <div class="sw2-Explanation_Lead">12〜350名まで様々な規模・用途でご利用可能なホール・会議室</div>
        <div class="sw2-Explanation_Text">国際・国内学会、セミナー、研修会、各種試験、展示会など幅広い用途でご利用いただけます。また、高速かつ安定したネットワーク環境で、WEB会議やライブ配信をスムーズかつ快適にご利用いただけます。</div>
        <div class="sw2-Explanation_Button"><a href="/kaigi/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a></div>
      </div>
      <div class="sw2-Explanation_Sub">
        <div class="sw2-Explanation_Image">
          <picture>
            <source srcset="/assets/img/index/explanation_02_01.png" media="(min-width: 980px)"/>
            <img src="/assets/img/index/explanation_02_01_sp.png" alt="ホール・会議室" loading="lazy">
          </picture>
        </div>
      </div>
    </div>
  </div>
  <div class="st2-Explanation">
    <div class="sw2-Explanation -is-reverse -is-button">
      <div class="sw2-Explanation_Main">
        <div class="sw2-Explanation_Heading"><span>03</span>イノベーション創発</div>
        <div class="sw2-Explanation_Lead">大手・中堅企業、ベンチャー 、学生へのイノベーション創発プログラムと交流の場の提供</div>
        <div class="sw2-Explanation_Text">「京都からの新ビジネス・新産業の創出に貢献する」という企業ミッションのもと、イノベーションを志す方々に魅力的な交流の舞台を提供してます。</div>
        <div class="sw2-Explanation_Button"><a href="/sangaku/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a></div>
      </div>
      <div class="sw2-Explanation_Sub">
        <div class="sw2-Explanation_Image">
          <picture>
            <source srcset="/assets/img/index/explanation_03_01.png" media="(min-width: 980px)"/>
            <img src="/assets/img/index/explanation_03_01_sp.png" alt="イノベーション創発" loading="lazy">
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-Explanation {
  position: relative;
  &.-is-button {
    padding-bottom: rem(75);
    @include mq-up(lg) {
      padding-bottom: rem(0);
    }
  }
  &.-is-reverse {
    @include mq-up(lg) {
      flex-direction: row-reverse;
    }
  }
  &.-is-first {
    @include mq-up(lg) {
      display: block;
    }
    .sw2-Explanation_Main {
      @include mq-up(lg) {
        text-align: center;
        width: auto;
      }
    }
    .sw2-Explanation_Sub {
      @include mq-up(lg) {
        width: auto;
        margin-top: rem(50, lg);
      }
    }
  }
  @include mq-up(lg) {
    padding-bottom: rem(0);
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: rem(-80, lg);
  }
  @media print, screen and (min-width: $max-width) {
    margin-left: rem(-100, lg);
  }
}
.sw2-Explanation_Main {
  @include mq-up(lg) {
    width: 50%;
    flex-shrink: 0;
    padding-left: rem(80, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding-left: rem(100, lg);
  }
}
.sw2-Explanation_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  color: $color-krp;
  font-size: rem(16);
  line-height: rem(29);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(29, lg);
  }
  & > span {
    display: inline-block;
    font-family: $font-family-montserrat;
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(16);
    margin-right: rem(16);
    @include mq-up(lg) {
      font-size: rem(16, lg);
      line-height: rem(16, lg);
      margin-right: rem(16, lg);
    }
  }
}
.sw2-Explanation_Lead {
  font-family: $font-family-shippori-mincho;
  font-weight: 400;
  font-size: rem(24);
  line-height: rem(38);
  margin-top: rem(24);
  @include mq-up(lg) {
    font-size: rem(28, lg);
    line-height: rem(52, lg);
    margin-top: rem(24, lg);
  }
  & > br {
    display: none;
    @media print, screen and (min-width: $max-width) {
      display: initial;
    }
  }
}
.sw2-Explanation_Text {
  font-weight: 400;
  color: #686F73;
  font-size: rem(14);
  line-height: rem(28);
  margin-top: rem(24);
  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: rem(28, lg);
    margin-top: rem(40, lg);
  }
  & > br {
    display: none;
    @include mq-up(lg) {
      display: initial;
    }
  }
}
.sw2-Explanation_Button {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  @include mq-up(lg) {
    position: static;
    text-align: left;
    margin-top: rem(50, lg);
  }
}

.sw2-Explanation_Sub {
  margin-top: rem(35);
  @include mq-up(lg) {
    margin-top: rem(0);
    width: 50%;
    flex-shrink: 0;
    padding-left: rem(80, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding-left: rem(100, lg);
  }
}
.sw2-Explanation_ImageListWrap {
  @media print, screen and (min-width: $max-width) {
    margin-left: calc(((((100vw - $max-width) / 2)) - 35px) * -1);
    margin-right: calc(((((100vw - $max-width) / 2)) - 35px) * -1);
  }
}
.sw2-Explanation_ImageList {
  margin-left: rem(-20);
  margin-right: rem(-20);
  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-9, lg);
    margin-right: rem(0);
  }
}
.sw2-Explanation_ImageListItem {
  margin-top: rem(8);
  &:first-child {
    margin-top: rem(0);
  }
  @include mq-up(lg) {
    margin-top: rem(0);
    padding-left: rem(9, lg);
    width: 33.3333%;
  }
  & > a {
    display: block;
    text-decoration: none;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-timing-function;

    &:hover {
      @include mq-up(lg) {
        opacity: 0.7;
      }
    }
  }
}
.sw2-Explanation_ImageListItemIn {
  position: relative;
}
.sw2-Explanation_ImageListItemImage {
  img {
    width: 100%;
  }
}
.sw2-Explanation_ImageListItemData {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: rem(20);
}
.sw2-Explanation_ImageListItemData_Heading {
  font-family: $font-family-shippori-mincho;
  font-weight: 500;
  color: #fff;
  font-size: rem(28);
  line-height: rem(28);
  @include mq-up(lg) {
    font-size: rem(28, lg);
    line-height: rem(28, lg);
  }
}
.sw2-Explanation_ImageListItemData_Text {
  font-weight: 500;
  color: #fff;
  font-size: rem(14);
  line-height: rem(14);
  margin-top: rem(16);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(16, lg);
    margin-top: rem(16, lg);
  }
}
.sw2-Explanation_Image {
  img {
    width: 100%;
  }
}
