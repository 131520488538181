/*
---
name: sw2-ReasonA
namespace: SiteWide2
category: Block
---

画像とテキストエリアが横並びになるモジュールで、グリッドを使わず1カラムで表示します。余白は`.st-Block`を使用してください。

- すべての要素を含めたバリエーションです。不必要な要素を削除できます。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-ReasonA">
      <div class="sw2-ReasonA_Body">
        <div class="sw2-ReasonA_Heading">
          <div class="sw2-ReasonA_HeadingNo"><span>REASON</span>01</div>
          <div class="sw2-ReasonA_HeadingTitle">成長が加速される環境</div>
        </div>
        <p class="sw2-ReasonA_Text">世の中のイノベーションを起こそうとする人々が絶えず行き交い、開発・進化を続けている一大ビジネス拠点で、事業の成長が加速される環境です。 ・500のさまざまな分野の企業・ベンチャー・大学、6,000人のプレイヤーが集まっており、共同研究や異業種交流など繋がりが持てる環境。 ・公的機関とのつながりがあるため、補助金が受けやすい。 ・大学が多い地域なので、採用活動しやすい。</p>
        <div class="sw2-ReasonA_ButtonArea">
          <a href="#" class="sw-Button">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
      <div class="sw2-ReasonA_ImageArea">
        <picture>
          <img src="https://placehold.jp/570x290.png" alt="" class="sw2-ReasonA_Image" loading="lazy">
        </picture>
      </div>
    </div>
  </div>
</div>
```

`.sw2-ReasonA-reverse`を追加すると左右が入れ替わります。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-ReasonA sw2-ReasonA-reverse">
      <div class="sw2-ReasonA_Body">
        <div class="sw2-ReasonA_Heading">
          <div class="sw2-ReasonA_HeadingNo"><span>REASON</span>01</div>
          <div class="sw2-ReasonA_HeadingTitle">成長が加速される環境</div>
        </div>
        <p class="sw2-ReasonA_Text">世の中のイノベーションを起こそうとする人々が絶えず行き交い、開発・進化を続けている一大ビジネス拠点で、事業の成長が加速される環境です。 ・500のさまざまな分野の企業・ベンチャー・大学、6,000人のプレイヤーが集まっており、共同研究や異業種交流など繋がりが持てる環境。 ・公的機関とのつながりがあるため、補助金が受けやすい。 ・大学が多い地域なので、採用活動しやすい。</p>
        <div class="sw2-ReasonA_ButtonArea">
          <a href="#" class="sw-Button">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
        </div>
      </div>
      <div class="sw2-ReasonA_ImageArea">
        <picture>
          <img src="https://placehold.jp/570x290.png" alt="" class="sw2-ReasonA_Image" loading="lazy">
        </picture>
      </div>
    </div>
  </div>
</div>
```


*/
.sw2-ReasonA {

  @include mq-up(lg) {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: rem(-30, lg);
  }

  &.sw2-ReasonA-reverse {
    @include mq-up(lg) {
      flex-direction: row-reverse;
    }
  }

}
.sw2-ReasonA_ImageArea {
  img {
    width: 100%;
  }

  @include mq-up(lg) {
    width: 50%;
    padding-left: rem(30, lg);
  }
}
.sw2-ReasonA_Body {
  margin-bottom: rem(18);

  @include mq-up(lg) {
    margin-bottom: rem(0);
    width: 50%;
    padding-left: rem(30, lg);
  }
  .st-ButtonA {
    @include mq-up(lg) {
      margin-top: rem(20, lg) !important;
    }
  }

}
.sw2-ReasonA_Heading {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: rem(16);

  @include mq-up(lg) {
    margin-bottom: rem(30, lg);
  }
}
.sw2-ReasonA_HeadingNo {
  font-family: $font-family-montserrat;
  font-size: rem(62);
  line-height: rem(42);
  color: $color-krp;
  position: relative;
  padding-left: rem(10);
  letter-spacing: 0.05em;
  flex-shrink: 0;
  margin-right: rem(16);
  font-weight: 300;

  @include mq-up(lg) {
    font-size: rem(90, lg);
    line-height: rem(70, lg);
    margin-right: rem(30, lg);
    padding-left: rem(24, lg);
  }

  & > span {
    font-size: rem(10);
    line-height: rem(10);
    position: absolute;
    top: 17px;
    left: -20px;
    transform: rotate(90deg);
    letter-spacing: 0;
    font-weight: 600;
    @include mq-up(lg) {
      font-size: rem(16, lg);
      line-height: rem(16, lg);
      top: 26px;
      left: -28px;
    }
  }
}
.sw2-ReasonA_HeadingTitle {
  font-size: rem(16);
  line-height: rem(24);
  font-weight: bold;

  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(30, lg);
  }

  & > br {
    display: none;

    @media print, screen and (min-width: $max-width) {
      display: inherit;
    }
  }
}
.sw2-ReasonA_Text {
  font-size: rem(13);
  line-height: rem(23);
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(29, lg);
  }
}
.sw2-ReasonA_ButtonArea {
  text-align: center;
  margin-top: rem(20);
  @include mq-up(lg) {
    text-align: left;
  }
}