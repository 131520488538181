// @see blob/master/src/css/main.css
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    // color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  // a[href]:after {
  //   content: " (" attr(href) ")";
  // }

  // abbr[title]:after {
  //   content: " (" attr(title) ")";
  // }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

/**
 * 印刷時のスタイル。
 */
@media print {
  // 全体のフォントサイズを小さくする。
  html {
    font-size: percentage(div(8, 16));
  }

  // 上部固定をさせない。
  [data-scroll-header] {
    position: static;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .st-Header_Logo {
    display: none;
  }

  .st-Header_Nav,
  .st-Header_NavArea {
    width: 100%;
  }

  .st-HeaderNav_Link {
    padding: 3px 8px;
    font-size: rem(10);
  }

  .st-HeaderSubNav_Items {
    padding-top: 0;
    padding-bottom: 5px;
  }

  .st-HeaderSubNav_Item {
    margin-left: 10px;
  }

  // 横幅に収める。
  .sw-TableScroll td,
  .sw-TableScroll th {
    white-space: inherit;
  }

  // 印刷時には不要な要素なので非表示にする。
  .sw-Map_Button {
    display: none;
  }

  // マップのルートが見れるように幅を広げる。
  .sw-Map_MapArea,
  .sw-Map_Body {
    width: 100% !important;
  }

  // 横長にして1ページに収まるようにする。
  .sw-Map_Map {
    padding-bottom: percentage(div(9, 16)) !important;
  }

  // ローカルナビ背景色
  .st-Content_LabPortal_Search .st-ContentHeaderB_Inner {
    background-color: #f9f9f9 !important;
  }

  .st-LocalNav1_Item,
  .st-LocalNav1_SubItem {
    background-color: #be3134 !important;
  }

  .st-LocalNav1_Item-current {
    background-color: #6a0012 !important;
  }

  .st-LocalNav2_Item {
    background-color: #fff !important;
  }

  // フッター背景色
  .st-FooterRegistration {
    background-color: #2b2b2b !important;
  }

  // ラボプラス 地図で探す
  .gm-style div > img {
    position: absolute;
  }

  .gm-style-iw.gm-style-iw-c {
    background-color: white !important;
  }

}

/**
 * 印刷時とハイコントラストモード（IEとEdge）が有効なときのスタイル。
 */
@media screen and (-ms-high-contrast: active), print {
  .sw-FormCheckbox {
    position: static;
    width: auto;
    height: auto;
    margin: auto;

    & + label {
      padding-left: 0;
    }

    & + label:before {
      display: none;
    }
  }

  .sw-FormRadio {
    position: static;
    width: auto;
    height: auto;
    margin: auto;

    & + label {
      padding-left: 0;
    }

    & + label:before {
      display: none;
    }
  }

  .sw-FormSelect > select {
    appearance: menulist;
  }
}
