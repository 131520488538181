/*
---
name: sw2-FeatureNavC
namespace: SiteWide2
category: Navigation
---

- カラムは`.st2-Grids`で指定します
- ボタンがつく場合は'.-is-button'を追加します。

```html
<div class="st-Wrapper">
  <ul class="st2-Grids st2-Grids-col3Lg">
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC -is-button">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price01.jpg" alt="オフィス会員"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">オフィス会員</div>
            <div class="sw2-FeatureNavC_HeadingText">個室+ラウンジ利用+法人登記可</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">専用で使える個室にはオフィス家具・無線LANなど、ビジネスを開始する時に必要なものがすべて揃っているのですぐに仕事を始めていただけます。ラウンジ利用や秘書サービスはもちろん、KRP BIZ NEXTの住所もご利用いただけます。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/office/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC -is-button">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price02.jpg" alt="ラウンジ会員（登記可）"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員（登記可）</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用+法人登記可</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ラウンジ利用に加えて、KRP BIZ NEXTの住所がご利用いただけるプランです。鍵付きロッカーを専用ポストとして、郵便物/宅配便の受取りも可能です。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/lounge/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC -is-button">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/image_price03.jpg" alt="ラウンジ会員"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ホテルラウンジのような贅沢な空間に、気兼ねなく大切なお客さまをお招きいただけます。電源と無線LANも完備しておりますので、デスクワークはもちろん、多彩なシーンでご利用いただけます。</div>
          <div class="sw2-FeatureNavC_ButtonArea">
              <a href="/serviceoffice/price/lounge/" class="sw-Button sw-Button-invert">もっとみる<span class="sw-Button_RightArrow" aria-hidden="true"></span></a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
```

- 題名を大きくする場合は'.-is-big'を追加します。

```html
<div class="st-Wrapper">
  <ul class="st2-Grids st2-Grids-col2Lg">
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC -is-big">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/lounge/image_price01.jpg" alt="ラウンジ会員（登記可）"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員（登記可）</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用 + 法人登記可</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ラウンジ利用に加えて、KRP BIZ NEXTの住所がご利用いただけるプランです。鍵付きロッカーを専用ポストとして、郵便物/宅配便の受取りも可能です。</div>
        </div>
      </div>
    </li>
    <li class="st2-Grids_Item">
      <div class="sw2-FeatureNavC -is-big">
        <div class="sw2-FeatureNavC_Head">
          <div class="sw2-FeatureNavC_HeadImage"><img src="/assets/img/serviceoffice/price/lounge/image_price02.jpg" alt="ラウンジ会員"></div>
          <div class="sw2-FeatureNavC_HeadingBox">
            <div class="sw2-FeatureNavC_Heading">ラウンジ会員</div>
            <div class="sw2-FeatureNavC_HeadingText">ラウンジ利用</div>
          </div>
        </div>
        <div class="sw2-FeatureNavC_Foot">
          <div class="sw2-FeatureNavC_FootText">ホテルラウンジのような贅沢な空間に、気兼ねなく大切なお客さまをお招きいただけます。電源と無線LANも完備しておりますので、デスクワークはもちろん、多彩なシーンでご利用いただけます。</div>
        </div>
      </div>
    </li>
  </ul>
</div>
```

*/
.sw2-FeatureNavC {
  &.-is-button {
    @include mq-up(lg) {
      height: 100%;
      position: relative;
      padding-bottom: rem(70, lg);
    }
  }
  &.-is-big {
    .sw2-FeatureNavC_HeadingBox {
      @include mq-up(lg) {
        width: rem(290, lg);
        padding: rem(30, lg) 0;
      }
    }
    .sw2-FeatureNavC_Heading {
      @include mq-up(lg) {
        font-size: rem(20, lg);
        line-height: rem(20, lg);
      }
    }
    .sw2-FeatureNavC_HeadingText {
      @include mq-up(lg) {
        font-size: rem(16, lg);
        line-height: rem(23, lg);
      }
    }
  }
}
.sw2-FeatureNavC_Head {
  position: relative;
}
.sw2-FeatureNavC_HeadImage {
  img {
    width: 100%;
  }
}
.sw2-FeatureNavC_HeadingBox {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: rem(-10);
  right: rem(-10);
  width: rem(240);
  padding: rem(20) 0;
  @include mq-up(lg) {
    bottom: rem(-10, lg);
    right: rem(-10, lg);
    width: rem(240, lg);
    padding: rem(20, lg) 0;
  }
}
.sw2-FeatureNavC_Heading {
  font-weight: 700;
  font-size: rem(16);
  line-height: rem(16);
  color: #fff;
  text-align: center;
  @include mq-up(lg) {
    font-size: rem(16, lg);
    line-height: rem(16, lg);
  }
}
.sw2-FeatureNavC_HeadingText {
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(20);
  color: #fff;
  text-align: center;
  margin-top: rem(16);
  @include mq-up(lg) {
    font-size: rem(14, lg);
    line-height: rem(20, lg);
    margin-top: rem(16, lg);
  }
}
.sw2-FeatureNavC_Foot {
  margin-top: rem(30);
  @include mq-up(lg) {
    margin-top: rem(32, lg);
  }
}
.sw2-FeatureNavC_FootText {
  font-weight: 400;
  font-size: rem(13);
  line-height: rem(23);
  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: rem(21, lg);
  }
}
.sw2-FeatureNavC_ButtonArea {
  text-align: center;
  margin-top: rem(16);
  @include mq-up(lg) {
    margin-top: rem(0);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
