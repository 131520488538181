.st-LocalHeroG {
  position: relative;
}

.st-LocalHeroG_Bg {

  @media print, screen and (min-width: 1170px) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    background-color: #f8f8f8;
    width: 100vw;
    height: 494px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.st-LocalHeroG_Image {
  display: block;
  margin-right: -20px;
  margin-left: -20px;

  & img {
    display: block;
    width: 100%;
    max-width: none;
  }

  @include mq-up(lg) {
    position: relative;
    padding-top: 494px;

    & img {
      position: absolute;
      top: 0;
      left: 50%;
      min-width: 1170px;
      min-height: 494px;
      margin-left: -585px;
    }
  }

  @media print, screen and (min-width: 1170px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.st-LocalHeroG_Text {
  display: block;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-top: 15px;
  padding-right: calc(50vw - 50%);
  padding-bottom: 15px;
  padding-left: calc(50vw - 50%);
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.6;
  background-color: #181818;
  background-image: url("/assets/img/structure/bg_local-nav3.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 50%;
    right: percentage(div(1, 12));
    left: percentage(div(1, 12));
    transform: translate(0, 50%);
    font-size: rem(38, lg);
    letter-spacing: 0.03em;
    text-align: center;
    line-height: 1.6;
    background-color: transparent;
    background-image: none;

    & br {
      display: inline;
    }
  }
}
