@mixin sw-FormTextarea() {
  display: block;
  width: percentage(div(17, 14));
  margin: 0;
  margin-left: (((div(17, 14) * 100) - (100)) * 0.5) * -1%;
  padding: 0.5em 1em;
  border: 1px solid #d6d6d6;
  border-radius: $form-border-radius;
  font-size: 17px;
  transform: scale((div(14, 17)));
  line-height: 1.6;
  background-color: #fff;
  appearance: none;
  box-shadow: inset 0px 1px 2px 0px rgba(#000, 0.05);

  @include mq-up(lg) {
    min-height: 100px;
    padding: 10px 12px;
    font-size: rem(16, lg);
  }

  &::placeholder {
    color: $form-placeholder-color;
    font-size: 1em;
  }

  &:focus {
    outline-width: 0;
    border-color: $form-focus-border-color;
    box-shadow: $form-focus-box-shadow;
    background-color: #fff;
  }

  &:disabled {
    opacity: $form-disabled-opacity;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;
  }
}
