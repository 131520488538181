.goconc .st-FooterPlatform_Area {
  display: table;
  width: 100%;
  padding: 14px 15px 14px 10px;
  color: #000;
  text-decoration: none;
  transition-duration: 0.3s;

  @include mq-up(md) {
    padding: 20px 15px 20px 10px;
  }
}
