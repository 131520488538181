.st-PageTop {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition-duration: $transition-duration * 2;
  transition-property: opacity;

  &.st-PageTop-fixed {
    overflow: visible;
    visibility: visible;
    opacity: 1;
    position: fixed;
    right: 15px;
    bottom: 65px;

    @include mq-up(lg) {
      right: 3%;
      bottom: 10%;
    }
  }
}

.st-PageTop_Link {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  font-size: rem(9);
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  color: #333;
  background-color: rgba(#fff, 0.9);
  text-decoration: none;
  transition-duration: $transition-duration;
  transition-property: background-color;

  &:hover {
    text-decoration: none;
    background-color: #fff;
  }

  @include mq-up(lg) {
    width: 60px;
    height: 60px;
    font-size: rem(12, lg);
  }
}

.st-PageTop_Inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 10px;
}

.st-PageTop_Icon {
  display: block;
  margin-top: -4px;
  font-size: 1em;

  @include mq-up(lg) {
    margin-top: 0;
  }

  &:before {
    @include Icon('icon_arrow-up');
  }
}

.st-PageTop_Text {
  display: block;
  margin-top: 1px;
  color: $color-text;
  white-space: nowrap;
  text-transform: uppercase;

  @include mq-up(lg) {
    margin-top: 2px;
  }
}
