/*
---
name: sw-BoxNavB
namespace: SiteWide
category: Navigation
id: [L2, CA-8]
---

<span class="sw-Label">CA-8</span>

- プラットフォーム活動＞京都デザインスクール

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavB_Items sw-BoxNavB_Items-col3Lg">
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト一行の場合
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト二行の場合の<br>
        レイアウトです
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        京都大学<br>
        デザインスクール<br>
        公式サイト
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
<ul class="sw-BoxNavB_Items sw-BoxNavB_Items-col2Lg">
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト一行の場合
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
</div>
```

- PC以上で3カラム
- PC以上で中央揃え

```html
<div class="st-Wrapper">
<ul class="sw-BoxNavB_Items sw-BoxNavB_Items-col3Lg sw-BoxNavB_Items-centerLg">
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト一行の場合
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト二行の場合の<br>
        レイアウトです
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
<ul class="sw-BoxNavB_Items sw-BoxNavB_Items-col3Lg sw-BoxNavB_Items-centerLg">
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト一行の場合
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
  <li class="sw-BoxNavB_Item">
    <a class="sw-BoxNavB_Box" href="#">
      <div class="sw-BoxNavB_ImageArea">
        <img src="https://placehold.jp/99x68.png" alt="" class="sw-BoxNavB_Image">
      </div>
      <div class="sw-BoxNavB_BoxBody">
        テキスト二行の場合の<br>
        レイアウトです
        <span class="sw-BoxNavB_Icon" aria-hidden="true"></span>
      </div>
    </a>
  </li>
</ul>
</div>
```
*/
.sw-BoxNavB_Items {
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    margin-left: -30px;

    & + & {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavB_Item {
  display: inline-block;
  vertical-align: top;
  padding-left: 15px;
  font-size: 1rem;
  width: 100%;

  & + & {
    margin-top: 15px;
  }

  @include mq-up(lg) {
    padding-left: 30px;

    & + & {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavB_Items-col2Lg {
  @include mq-up(lg) {
    & > .sw-BoxNavB_Item {
      width: percentage(div(1, 2));
    }

    & > .sw-BoxNavB_Item + .sw-BoxNavB_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavB_Item:nth-of-type(n+3) {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavB_Items-col3Lg {
  @include mq-up(lg) {
    & > .sw-BoxNavB_Item {
      width: percentage(div(1, 3));
    }

    & > .sw-BoxNavB_Item + .sw-BoxNavB_Item {
      margin-top: 0;
    }

    & > .sw-BoxNavB_Item:nth-of-type(n+4) {
      margin-top: 30px;
    }
  }
}

.sw-BoxNavB_Items-centerLg {
  @include mq-up(lg) {
    text-align: center;
  }
}

.sw-BoxNavB_Box {
  position: relative;
  display: block;
  padding: 17px 30px 17px 15px;
  text-decoration: none;
  color: $color-text;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &:hover {
    text-decoration: none;
    color: $color-text;
    background-color: #fdeeee;
  }

  @include mq-up(lg) {
    padding: 20px 25px;
  }
}

.sw-BoxNavB_ImageArea {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
}

.sw-BoxNavB_Image {

}

.sw-BoxNavB_BoxBody {
  display: inline-block;
  padding-left: 14px;
  vertical-align: middle;
  font-size: rem(13);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    padding-left: 20px;
    font-size: rem(16, lg);
    line-height: 1.6;
  }
}

.sw-BoxNavB_Icon {
  &:before {
    @include Icon("icon_arrow-right");
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    width: 1em;
    height: 1em;
    margin: auto;
    font-size: 0.8em;
    color: $color-brand;
  }
}
