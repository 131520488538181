/*
---
name: st-ContainerB
namespace: Structure
category: Layout
id: [K2, CON-3]
---

<span class="sw-Label">CON-3</span>

コンテンツ内の上下に余白をとります。

レイアウト例です。

```html
<div class="st-ContainerB">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>

    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB`と`.st-ContainerA`が続く（隣接する場合）

```html
<div class="st-ContainerB">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>

    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>

<div class="st-ContainerA st-ContainerA-gray">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB-stripe`を追加すると背景画像がつきます。

```html
<div class="st-ContainerB st-ContainerB-stripe">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB-gray`を追加すると背景色がつきます。

```html
<div class="st-ContainerB st-ContainerB-gray">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB-delimiterA`を追加するとブランドカラーのボーダーがつきます。

```html
<div class="st-ContainerB st-ContainerB-stripe st-ContainerB-delimiterA">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB-delimiterB`を追加するとボーダーがつきます。

```html
<div class="st-ContainerB st-ContainerB-gray st-ContainerB-delimiterB">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw-Heading2A st-Heading2">「産学公連携拠点・新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```

`.st-ContainerB-Offset`を追加すると、`st-ContainerA`の`padding-bottom`を相殺します。
*/
.st-ContainerB {
  padding: $padding-conitaner-b;

  @include mq-up(lg) {
    padding: $padding-conitaner-b-lg;
  }
}

.st-ContainerB-stripe {
  background-image: url("/assets/img/structure/bg_continer-stripe.png");
  background-repeat: repeat;
  background-size: auto;
  background-position: 0 0;
}

.st-ContainerB-gray {
  background-color: #f5f5f5;
}

.st-ContainerB-delimiterA {
  border-top: 2px solid $color-brand;

  @include mq-up(lg) {
    border-top-width: 4px;
  }
}

.st-ContainerB-delimiterB {
  border-top: 1px solid #e8e8e8;
}

.st-ContainerB-Offset {
  .st-ContainerA + & {
    margin-top: -60px;

    @include mq-up(lg) {
      margin-top: -100px;
    }
  }
}
