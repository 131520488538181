/*
---
name: st-Lead
namespace: Structure
category: Layout
---

リード文自身の余白を指定します。

```html
<div class="st-Wrapper">
<p class="sw-Lead st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、テクノロジーをベースとしたスタートアップをサポートし、『京都発世界』の企業を生み出すことを目指します。</p>

<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```

```html
<div class="st-Wrapper">
<p class="sw-Lead st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、テクノロジーをベースとしたスタートアップをサポートし、『京都発世界』の企業を生み出すことを目指します。
  <span class="sw-Lead_Foot">The HVC KYOTO focuses on early stage technology incubation in life science field to bridge basic and local scientific knowledge and commercial developments for the global market. I</span></p>

<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```

```html
<div class="st-Wrapper">
<p class="sw-Lead st-Lead">京都リサーチパーク株式会社の社是である『集・交・創』を体現した場で、テクノロジーをベースとしたスタートアップをサポートし、『京都発世界』の企業を生み出すことを目指します。
  <span class="sw-Lead_Foot">The HVC KYOTO focuses on early stage technology incubation in life science field to bridge basic and local scientific knowledge and commercial developments for the global market. I</span></p>

<p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。<br>
ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
</div>
```
*/
.st-Lead {
  margin-bottom: $margin-lead;

  @include mq-up(lg) {
    margin-bottom: $margin-lead-lg;
  }
}
