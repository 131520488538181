/*
---
name: sw-ListOrder
namespace: SiteWide
category: List
id: [K2, LT-2]
---

<span class="sw-Label">LT-2</span>

入れ子に対応した順序リストです。

```html
<div class="st-Wrapper">
<ol class="sw-ListOrder">
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
  <li>入れ子に対応した順序リストです。
    <ol class="sw-ListOrder_Child">
      <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
      <li>入れ子に対応した順序リストです。</li>
    </ol>
  </li>
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
</ol>
</div>
```

リストを2.から始める場合は`.sw-ListOrder-from2`のように指定します。2~10まで対応しています。CSSが正常に読み込めなかった場合でも意味が変わらないように`start`属性も指定します。

```html
<div class="st-Wrapper">
<ol start="2" class="sw-ListOrder sw-ListOrder-from2">
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
  <li>入れ子に対応した順序リストです。
    <ol class="sw-ListOrder_Child">
      <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
      <li>入れ子に対応した順序リストです。</li>
    </ol>
  </li>
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
</ol>
</div>
```

順序なしリストモジュールと併用したパターンです。

```html
<div class="st-Wrapper">
<ol class="sw-ListOrder">
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
  <li>入れ子に対応した順序リストです。
    <ul class="sw-ListUnorder">
      <li>サイトマップ</li>
      <li>プライバシーポリシー</li>
      <li>サイトマップ</li>
    </ul>
  </li>
  <li>入れ子に対応した順序リストです。入れ子に対応した順序リストです。</li>
</ol>
</div>
```
*/
.sw-ListOrder {
  display: table;
  padding-left: 0;
  line-height: 1.4;
  list-style-type: none;
  counter-reset: order;
  font-size: rem(14);

  & > li {
    display: table-row;
    counter-increment: order;
  }

  & > li:nth-of-type(n+2):before {
    padding-top: 9px;
  }

  // 別のリストモジュール内にある時
  li > & {
    padding-top: 9px;

    & > li:nth-of-type(n+2):before {
      padding-top: 9px;
     }
  }

  & > li:before {
    content: counters(order, "-") ".";
    display: table-cell;
    padding-right: (div(12, 16)) * 1em;
    text-align: right;
    white-space: nowrap;
  }

  & > li > ul,
  & > li > ol {
    margin-top: 9px;
  }

  @include mq-up(lg) {
    font-size: rem(16, lg);

    & > li:nth-of-type(n+2):before {
      padding-top: 10px;
    }

    li > & {
      padding-top: 10px;
    }

    & > li > ul,
    & > li > ol {
      margin-top: 10px;
    }
  }
}

.sw-ListOrder_Child {
  margin-top: 9px;
  padding-left: 0;
  list-style-type: none;
  counter-reset: childOrder;

  & > li {
    display: table-row;
    counter-increment: childOrder;
  }

  & > li:nth-of-type(n+2):before {
    padding-top: 9px;
  }

  & > li:before {
    content: counters(childOrder, "-") ".";
    display: table-cell;
    padding-right: (div(12, 16)) * 1em;
    text-align: right;
    white-space: nowrap;
  }

  @include mq-up(lg) {
    margin-top: 10px;

    & > li:nth-of-type(n+2):before {
      padding-top: 10px;
    }
  }
}

.sw-ListOrder-from2 { counter-reset: order 1; }
.sw-ListOrder-from3 { counter-reset: order 2; }
.sw-ListOrder-from4 { counter-reset: order 3; }
.sw-ListOrder-from5 { counter-reset: order 4; }
.sw-ListOrder-from6 { counter-reset: order 5; }
.sw-ListOrder-from7 { counter-reset: order 6; }
.sw-ListOrder-from8 { counter-reset: order 7; }
.sw-ListOrder-from9 { counter-reset: order 8; }
.sw-ListOrder-from10 { counter-reset: order 9; }
