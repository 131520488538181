/*
---
name: st-Grids
namespace: Structure
category: Layout
---

バリエーションの豊富なグリッドシステムです。親要素（`.st-Grids`）にクラスを追加することで、1/2や1/3のような均等なカラムや、8/12と4/12のようなカラムの指定ができます。

カラム間の余白は幅を`$st-grids-column-gap`、高さを`$st-grids-row-gap`で統一しています。

`.st-Grids`同士が同じ階層にある場合（`.st-Grids + .st-Grids`）には`.st-Grids`の間に余白がつきます。

以下のような指定ができます。

- `.st-Grids-col2`のように1/2から1/5までの均等なカラム指定。
- `.st-Grids-col8to4`のように1/12と11/12から5/12と7/12、7/12と5/12から11/12と1/12までの合計10パターンのカラム指定。
- `.st-Grids-col2Md`のように`Md`、`Lg`、`Xl`を追加してブレイクポイントの指定。
- `.st-Grids-middle`のようにカラムの垂直位置を中央揃え。
- `.st-Grids-noGap`でカラム間のガターを0にする。
- `.st-Grids-narrow`でカラム間のガターを最小にする。

### 均等なカラム指定。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-col2Md">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 1/2</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 1/2</div>
  </div>
</div>
<div class="st-Grids st-Grids-col2 st-Grids-col3Md">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/2 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/2 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Three<br>1/2 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Four<br>1/2 → 1/3</div>
  </div>
</div>
</div>
```

### 8/12と4/12と4/12と8/12のカラム指定。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-col8to4Md">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 8/12</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 4/12</div>
  </div>
</div>
</div>
```

### 8/12と4/12のカラム指定かつ、`md`以上で要素を反転。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-col8to4Md st-Grids-reversMd">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 8/12</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 4/12</div>
  </div>
</div>
</div>
```

### カラムの垂直位置を中央揃え。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-middleMd st-Grids-col2Md">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 6/12<br>.st-Grids-middle<br>.st-Grids-middle</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 6/12</div>
  </div>
</div>
</div>
```

### カラム間のガターを0にします。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-col3Md st-Grids-noGap">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Three<br>1/1 → 1/3</div>
  </div>
</div>
</div>
```

### カラム間のガターを最小にします。

```html
<div class="st-Wrapper st-Inner">
<div class="st-Grids st-Grids-col3Md st-Grids-narrow">
  <div class="st-Grids_Item">
    <div class="test-Box">One<br>1/1 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Two<br>1/1 → 1/3</div>
  </div>
  <div class="st-Grids_Item">
    <div class="test-Box">Three<br>1/1 → 1/3</div>
  </div>
</div>
</div>
```
*/
// `em`と`%`は指定不可
$st-grids-column-gap: $grid-column-gap;
$st-grids-row-gap: $grid-row-gap;
$st-grids-column-gap-lg: $grid-column-gap-lg;
$st-grids-row-gap-lg: $grid-row-gap-lg;

.st-Grids {
  margin-left: -$st-grids-column-gap;
  padding-left: 0;
  list-style-type: none;
  display: block;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -$st-grids-column-gap-lg;
  }

  /* 同じ階層にある`.st-Grids`同士の間に余白を入れます。 */
  &:not(:first-of-type) {
    margin-top: $st-grids-row-gap-lg;

    &.st-Grids-narrow {
      margin-top: 1px;
    }

    @include mq-up(lg) {
      margin-top: $st-grids-row-gap-lg;

      &.st-Grids-narrow {
        margin-top: 1px;
      }
    }
  }

  [data-krpfes-filter-root] & {
    margin-top: -30px !important;

    @include mq-up(lg) {
      margin-top: -50px !important;
    }
  }
}

.st-Grids_Item {
  display: inline-block;
  width: 100%;
  padding-left: $st-grids-column-gap;
  font-size: medium;
  font-size: 1rem;
  vertical-align: top;

  @include mq-up(lg) {
    padding-left: $st-grids-column-gap-lg;
  }

  [data-krpfes-filter-root] & {
    margin-top: 35px !important;

    @include mq-up(lg) {
      margin-top: 50px !important;
    }
  }
}

/* 1 Column */
.st-Grids_Item {
  &:nth-of-type(n+2) {
    margin-top: $st-grids-row-gap;

    .st-Grids-narrow & {
      margin-top: 1px;
    }

    @include mq-up(lg) {
      margin-top: $st-grids-row-gap-lg;

      .st-Grids-narrow & {
        margin-top: 1px;
      }
    }
  }
}

/* 2 Columns */
.st-Grids-col2 > .st-Grids_Item {
  width: percentage(div(1, 2));

  /**
   * いったんすべての`margin-top`をリセットします。
   * `:nth-of-type(n+1)`は1つ目以降のすべての要素に適応されます。
   * リセット後に対象となる要素に`margin-top`を指定します。
   */
  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
  .st-Grids-narrow.st-Grids-col2 > .st-Grids_Item {
    &:nth-of-type(n+3) {
      margin-top: 1px;
    }
  }
}
@include mq-up(md) {
  .st-Grids-col2Md > .st-Grids_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-narrow.st-Grids-col2Md > .st-Grids_Item {
    &:nth-of-type(n+3) {
      margin-top: 1px;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col2Md > .st-Grids_Item:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap-lg;
  }
  .st-Grids-col2Lg > .st-Grids_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col2Lg > .st-Grids_Item {
    &:nth-of-type(n+3) {
      margin-top: 1px;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col2Xl > .st-Grids_Item {
    width: percentage(div(1, 2));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col2Xl > .st-Grids_Item {
    &:nth-of-type(n+3) {
      margin-top: 1px;
      padding-top: 1px;
    }
  }
}

/* 3 Columns */
.st-Grids-col3 > .st-Grids_Item {
  width: percentage(div(1, 3));

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+4) {
    margin-top: $st-grids-row-gap;
  }
  .st-Grids-narrow.st-Grids-col3 > .st-Grids_Item {
    &:nth-of-type(n+4) {
      margin-top: 1px;
    }
  }
}
@include mq-up(md) {
  .st-Grids-col3Md > .st-Grids_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-narrow.st-Grids-col3Md > .st-Grids_Item {
    &:nth-of-type(n+4) {
      margin-top: 1px;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col3Md > .st-Grids_Item:nth-of-type(n+4) {
    margin-top: $st-grids-row-gap-lg;
  }
  .st-Grids-col3Lg > .st-Grids_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col3Lg > .st-Grids_Item {
    &:nth-of-type(n+4) {
      margin-top: 1px;
    }
  }
  .st-Grids-col3Lg.-is-first-col2Lg > .st-Grids_Item {
    &:nth-of-type(1) {
      width: percentage(div(1, 2));
      margin-top: 0 !important;
    }
    &:nth-of-type(2) {
      width: percentage(div(1, 2));
      margin-top: 0 !important;
    }
    &:nth-of-type(n+1) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col3Xl > .st-Grids_Item {
    width: percentage(div(1, 3));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col3Xl > .st-Grids_Item {
    &:nth-of-type(n+4) {
      margin-top: 1px;
    }
  }
}

/* 4 Columns */
.st-Grids-col4 > .st-Grids_Item {
  width: percentage(div(1, 4));

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+5) {
    margin-top: $st-grids-row-gap;
  }
  .st-Grids-narrow.st-Grids-col4 > .st-Grids_Item {
    &:nth-of-type(n+5) {
      margin-top: 1px;
    }
  }
}
@include mq-up(md) {
  .st-Grids-col4Md > .st-Grids_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-narrow.st-Grids-col4Md > .st-Grids_Item {
    &:nth-of-type(n+5) {
      margin-top: 1px;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col4Md > .st-Grids_Item:nth-of-type(n+5) {
    margin-top: $st-grids-row-gap-lg;
  }
  .st-Grids-col4Lg > .st-Grids_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col4Lg > .st-Grids_Item {
    &:nth-of-type(n+5) {
      margin-top: 1px;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col4Xl > .st-Grids_Item {
    width: percentage(div(1, 4));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+5) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col4Xl > .st-Grids_Item {
    &:nth-of-type(n+5) {
      margin-top: 1px;
    }
  }
}

/* 5 Columns */
.st-Grids-col5 > .st-Grids_Item {
  width: percentage(div(1, 5));

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+6) {
    margin-top: $st-grids-row-gap;
  }
  .st-Grids-narrow.st-Grids-col5 > .st-Grids_Item {
    &:nth-of-type(n+6) {
      margin-top: 1px;
    }
  }
}
@include mq-up(md) {
  .st-Grids-col5Md > .st-Grids_Item {
    width: percentage(div(1, 5));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+6) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-narrow.st-Grids-col5Md > .st-Grids_Item {
    &:nth-of-type(n+6) {
      margin-top: 1px;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col5Md > .st-Grids_Item:nth-of-type(n+6) {
    margin-top: $st-grids-row-gap-lg;
  }
  .st-Grids-col5Lg > .st-Grids_Item {
    width: percentage(div(1, 5));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+6) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col5Lg > .st-Grids_Item {
    &:nth-of-type(n+6) {
      margin-top: 1px;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col5Xl > .st-Grids_Item {
    width: percentage(div(1, 5));

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+6) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-narrow.st-Grids-col5Xl > .st-Grids_Item {
    &:nth-of-type(n+6) {
      margin-top: 1px;
    }
  }
}

/**
 * カラム間のガターを0にします。
 */
.st-Grids-noGap {
  margin-left: 0;

  @include mq-up(md) {
    margin-left: 0;
  }

  & > .st-Grids_Item {
    padding-left: 0;

    @include mq-up(md) {
      padding-left: 0;
    }
  }
}

/**
 * カラム間のガターを最小にします。
 */
.st-Grids-narrow {
  margin-left: -1px;

  @include mq-up(md) {
    margin-left: -1px;
  }

  & > .st-Grids_Item {
    padding-left: 1px;

    @include mq-up(md) {
      padding-left: 1px;
    }
  }
}

/**
 * 要素の垂直位置を真ん中寄せ。
 */
.st-Grids-middle > .st-Grids_Item {
  vertical-align: middle;
}
@include mq-up(md) {
  .st-Grids-middleMd > .st-Grids_Item {
    vertical-align: middle;
  }
}
@include mq-up(lg) {
  .st-Grids-middleLg > .st-Grids_Item {
    vertical-align: middle;
  }
}
@include mq-up(xl) {
  .st-Grids-middleXl > .st-Grids_Item {
    vertical-align: middle;
  }
}

/**
 * `.st-Grids_Item`の表示順を逆にします。
 * A,B → B,A
 */
.st-Grids-reverse {
  text-align: left;
  direction: rtl;

  & > .st-Grids_Item {
    text-align: left;
    direction: ltr;
  }
}
@include mq-up(md) {
  .st-Grids-reversMd {
    text-align: left;
    direction: rtl;

    & > .st-Grids_Item {
      text-align: left;
      direction: ltr;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-reverseLg {
    text-align: left;
    direction: rtl;

    & > .st-Grids_Item {
      text-align: left;
      direction: ltr;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-reverseXl {
    text-align: left;
    direction: rtl;

    & > .st-Grids_Item {
      text-align: left;
      direction: ltr;
    }
  }
}

/* 1/12 11/12, 11/12 1/12 */
.st-Grids-col1to11 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(1, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(11, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
.st-Grids-col11to1 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(11, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(1, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
@include mq-up(md) {
  .st-Grids-col1to11Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-col11to1Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col1to11Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col11to1Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col1to11Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col11to1Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(11, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(1, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}

/* 2/12 10/12, 10/12 2/12 */
.st-Grids-col2to10 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(2, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(10, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
.st-Grids-col10to2 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(10, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(2, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
@include mq-up(md) {
  .st-Grids-col2to10Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-col10to2Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col2to10Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col10to2Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col2to10Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col10to2Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(10, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(2, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}

/* 3/12 9/12, 9/12 3/12 */
.st-Grids-col3to9 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(3, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(9, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
.st-Grids-col9to3 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(9, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(3, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
@include mq-up(md) {
  .st-Grids-col3to9Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-col9to3Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col3to9Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col9to3Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col3to9Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col9to3Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(9, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(3, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}

/* 4/12 8/12, 8/12 4/12 */
.st-Grids-col4to8 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(4, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(8, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
.st-Grids-col8to4 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(8, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(4, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
@include mq-up(md) {
  .st-Grids-col4to8Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-col8to4Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col4to8Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col8to4Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col4to8Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col8to4Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(8, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(4, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}

/* 5/12 7/12, 7/12 5/12 */
.st-Grids-col5to7 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(5, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(7, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
.st-Grids-col7to5 > .st-Grids_Item {
  &:nth-of-type(odd) {
    width: percentage(div(7, 12));
  }

  &:nth-of-type(even) {
    width: percentage(div(5, 12));
  }

  &:nth-of-type(n+1) {
    margin-top: 0;
  }
  &:nth-of-type(n+3) {
    margin-top: $st-grids-row-gap;
  }
}
@include mq-up(md) {
  .st-Grids-col5to7Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
  .st-Grids-col7to5Md > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap;
    }
  }
}
@include mq-up(lg) {
  .st-Grids-col5to7Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col7to5Lg > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
@include mq-up(xl) {
  .st-Grids-col5to7Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
  .st-Grids-col7to5Xl > .st-Grids_Item {
    &:nth-of-type(odd) {
      width: percentage(div(7, 12));
    }

    &:nth-of-type(even) {
      width: percentage(div(5, 12));
    }

    &:nth-of-type(n+1) {
      margin-top: 0;
    }
    &:nth-of-type(n+3) {
      margin-top: $st-grids-row-gap-lg;
    }
  }
}
