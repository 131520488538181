/*
---
name: sw-BlockC
namespace: SiteWide
category: Block
id: [C1, TH-3]
---

<span class="sw-Label">TH-3</span>

- すべての要素を含めたバージョンです。不必要な要素を削除できます。
- 画像、見出し、ボタン、リストにリンクを設定できます。
- 画像の上にラベルをつけることができます。
- ボタンは`.sw-BlockC_Button-auto`を追加するとなりゆきになります。

```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col2Lg st-Block">
  <li class="st-Grids_Item">
    <div class="sw-BlockC">
      <a class="sw-BlockC_ImageArea" href="#">
        <div class="sw-BlockC_LabelArea">
          <span class="sw-BlockC_Label">CASE 01</span>
        </div>
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockC_Image">
      </a>
      <a class="sw-BlockC_Heading" href="#"><span class="sw-BlockC_HeadingIcon" aria-hidden="true"></span>3~7坪(10~23㎡) 例：4号館</a>
      <p class="sw-BlockC_Text">共益費・空調・電力料金込み月額80,000円～（消費税、休日・夜間の空調利用料は別途）でご提供している全10室の小規模オフィスゾーンです。<br>
      Small Office専用の打ち合わせコーナーが設置されている他、共用備品としてポット、冷蔵庫が完備されています。<br>
      ここにテキストが入ります。改行した場合のレイアウトです。ここにテキストが入ります。改行した場合のレイアウトです。</p>
      <div class="sw-BlockC_ButtonArea">
        <a href="#" class="sw-BlockC_Button">間取り図をみる<span class="sw-BlockA_RightArrow" aria-hidden="true"></span></a>
      </div>
    </div>
  </li>
  <li class="st-Grids_Item">
    <div class="sw-BlockC">
      <a class="sw-BlockC_ImageArea" href="#">
        <div class="sw-BlockC_LabelArea">
          <span class="sw-BlockC_Label">CASE 02</span>
        </div>
        <img src="https://placehold.jp/570x290.png" alt="" class="sw-BlockC_Image">
      </a>
      <p class="sw-BlockC_Heading">9坪(30㎡) 例：スタジオ棟</p>
      <p class="sw-BlockC_Text">地区内でもリーズナブルな価格でご提供している小規模オフィスです。<br>
      室内は天井が高いため、広々と感じられます。起業まもないベンチャー企業や少人数で活動される企業に適しています。</p>
    </div>
  </li>
</ul>
</div>
```
*/
.sw-BlockC {

}

.sw-BlockC_ImageArea {
  position: relative;
  display: block;

  &:hover {
    opacity: $opacity;
  }
}

.sw-BlockC_LabelArea {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

.sw-BlockC_Label {
  display: inline-block;
  padding: 2px 26px;
  border: 1px solid $color-brand;
  color: #fff;
  font-size: rem(10);
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background-color: $color-brand;

  // aタグをマウスオーバーしたときのスタイル
  &[href]:hover {
    color: currentColor;
    text-decoration: underline;
  }

  @include mq-up(lg) {
    padding: 2px 32px;
    font-size: rem(13, lg);
  }
}

.sw-BlockC_Image {
  display: block;
  width: 100%;
  max-width: none;
}

.sw-BlockC_Heading {
  position: relative;
  margin-top: 10px;
  font-size: rem(15);
  line-height: 1.4;
  font-weight: bold;

  @include mq-up(lg) {
    margin-top: 16px;
    font-size: rem(20, lg);
  }
}

a.sw-BlockC_Heading {
  display: block;
  padding-left: 20px;
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: #d02425;
    text-decoration: none;
  }
}

.sw-BlockC_HeadingIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  color: $color-brand;
  font-size: 0.7em;
}

.sw-BlockC_Text {
  margin-top: 8px;
  font-size: rem(13);
  line-height: 1.8;

  @include mq-up(lg) {
    font-size: rem(16, lg);
  }
}

.sw-BlockC_ButtonArea {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
  text-align: center;

  @include mq-up(lg) {
    margin-top: 30px;
    text-align: left;
  }
}

.sw-BlockC_Button {
  @include sw-Button;
  position: relative;
  width: 290px;
  max-width: 100%;
}

.sw-BlockC_Button-auto {
  width: auto;
}

.sw-BlockC_External:before {
  @include Icon("icon_windows");
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 1.2em;
}

.sw-BlockA_RightArrow:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
}

.sw-BlockC_Links {
  margin-top: 20px;
  padding-left: 0;
  list-style-type: none;
  font-size: rem(14);
  line-height: 1.4;

  @include mq-up(lg) {
    margin-top: 10px;
    font-size: rem(16, lg);
  }
}

.sw-BlockC_LinksItem {
  position: relative;
  width: 290px;
  margin: auto;
  padding-left: 18px;

  & + & {
    margin-top: 10px;
  }

  @include mq-up(lg) {
    width: auto;
    padding-left: 15px;
    
    & + & {
      margin-top: 10px;
    }
  }
}

.sw-BlockC_LinksIcon:before {
  @include Icon("icon_arrow-right");
  position: absolute;
  top: 0.5em;
  left: 0;
  margin: auto;
  font-size: 0.8em;
  color: $color-brand;
}

.sw-BlockC_LinksLink {
  color: $color-text;
  text-decoration: none;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }
}
