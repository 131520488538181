/*
---
name: sw-CardNavC
namespace: SiteWide
category: Navigation
id: [L1, NC-4, NC-4B]
---

<span class="sw-Label">NC-4</span>



```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg">
  <li class="st-Grids_Item">
    <a href="#" class="sw-CardNavC_Block">
      <img src="https://placehold.jp/1500x555.png" class="sw-CardNavC_Image" alt="">
      <ul class="sw-CardNavC_Labels">
        <li class="sw-CardNavC_Label">イベント</li>
      </ul>
      <div class="sw-CardNavC_Body">
        <div class="sw-CardNavC_BodyInner">
          <div class="sw-CardNavC_Date">
            <div class="sw-CardNavC_DateStart">
              10/03 <span class="sw-CardNavC_DateStartWeekday">(水)</span>
            </div>
            <div class="sw-CardNavC_To" aria-label="から"></div>
            <div class="sw-CardNavC_DateEnd">
              10/04 <span class="sw-CardNavC_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="sw-CardNavC_Description">
            <h2 class="sw-CardNavC_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-CardNavC_Block">
      <img src="https://placehold.jp/300x640.png" class="sw-CardNavC_Image" alt="">
      <ul class="sw-CardNavC_Labels">
        <li class="sw-CardNavC_LabelEnd">終了</li>
        <li class="sw-CardNavC_Label">イベントレポート</li>
      </ul>
      <div class="sw-CardNavC_Body">
        <div class="sw-CardNavC_BodyInner">
          <div class="sw-CardNavC_Date">
            <div class="sw-CardNavC_DateStart">
              10/03 <span class="sw-CardNavC_DateStartWeekday">(水)</span>
            </div>
            <div class="sw-CardNavC_To" aria-label="から"></div>
            <div class="sw-CardNavC_DateEnd">
              10/04 <span class="sw-CardNavC_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="sw-CardNavC_Description">
            <h2 class="sw-CardNavC_Heading">ブータンで見つけた、幸せのヒント。KRPワンコイン Mixer Session 5</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```

<span class="sw-Label">NC-4B</span>



```html
<div class="st-Wrapper">
<ul class="st-Grids st-Grids-col3Lg">
  <li class="st-Grids_Item">
    <a href="#" class="sw-CardNavC_Block">
      <img src="/assets/img/_events/event/image01.jpg" class="sw-CardNavC_Image" alt="">
      <ul class="sw-CardNavC_Labels">
        <li class="sw-CardNavC_Label">イベント</li>
      </ul>
      <div class="sw-CardNavC_Body">
        <div class="sw-CardNavC_BodyInner">
          <div class="sw-CardNavC_Description">
            <h2 class="sw-CardNavC_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
            <span class="sw-CardNavB_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="st-Grids_Item">
    <a href="#" class="sw-CardNavC_Block">
      <img src="/assets/img/_events/event/image02.jpg" class="sw-CardNavC_Image" alt="">
      <ul class="sw-CardNavC_Labels">
        <li class="sw-CardNavC_LabelEnd">終了</li>
        <li class="sw-CardNavC_Label">イベントレポート</li>
      </ul>
      <div class="sw-CardNavC_Body">
        <div class="sw-CardNavC_BodyInner">
          <div class="sw-CardNavC_Description">
            <h2 class="sw-CardNavC_Heading">ブータンで見つけた、幸せのヒント。KRPワンコイン Mixer Session 5</h2>
            <span class="sw-CardNavB_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```
*/
.sw-CardNavC_Block {
  display: block;
  position: relative;
  /* Note: Chromeで小数点以下の差分ができてしまうのを修正 */
  overflow: hidden;
  border: 1px solid #e8e8e8;
  color: $color-text;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: percentage(div(239, 368));
  }

  &:hover {
    opacity: $opacity;
  }
}

.sw-CardNavC_Image {
  min-width: 100%;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.sw-CardNavC_Labels {
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 0;
  overflow: hidden;
  text-align: center;
  list-style-type: none;
  font-size: rem(13, lg);
}

.sw-CardNavC_Label {
  float: left;
  padding: 2px 12px;
  line-height: 1.4;
  border: 1px solid #be3134;
  color: #fff;
  background-color: #be3134;
}

.sw-CardNavC_LabelEnd {
  @extend .sw-CardNavC_Label;
  color: #be3134;
  background-color: #fff;
}

.sw-CardNavC_Body {
  position: absolute;
  bottom: 0;
  left: 0;
  /* Note: Chromeで小数点以下の差分ができてしまうのを修正 */
  width: 101%;
}

.sw-CardNavC_BodyInner {
  display: table;
  width: 100%;
}

.sw-CardNavC_Date {
  display: table-cell;
  width: percentage(div(150, 570));
  padding: 18px;
  vertical-align: top;
  line-height: 1.4;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(#000, 0.5);
}

.sw-CardNavC_DateStart {
  font-size: rem(22, lg);
  font-weight: bold;
}

.sw-CardNavC_DateStartWeekday {
  font-size: rem(10, lg);
  font-weight: normal;
}

.sw-CardNavC_To {
  margin-top: 4px;
  margin-bottom: 6px;

  &:before {
    content: "";
    display: block;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.sw-CardNavC_DateEnd {
  font-size: rem(16, lg);
  font-weight: bold;
}

.sw-CardNavC_DateEndWeekday {
  font-size: rem(10, lg);
  font-weight: normal;
}

.sw-CardNavC_Description {
  display: table-cell;
  width: auto;
  width: 100%;
  padding: 15px 26px;
  vertical-align: top;
  background-color: rgba(#fff, 0.9);

  .sw-CardNavC_Date + & {
    width: percentage(div(420, 570));
  }
}

.sw-CardNavC_Heading {
  font-size: rem(17, lg);
  font-weight: bold;
  line-height: 1.4;
}

.sw-CardNavC_PostDate {
  display: block;
  margin-top: 5px;
  font-size: rem(13);
  line-height: 1.4;
  color: #999;
}
