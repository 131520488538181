.english-home-kv {
  position: relative;
  @include mq-up(lg) {
    margin-top: 19px;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("/assets/img/structure/bg_continer-stripe.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    @include mq-up(lg) {
      top: 50px;
      width: div(1250, 1600) * 100 + 0%;
      padding-top: div(665, 1600) * 100 + 0%;
      height: 0;
    }
  }
}

.english-home-kv_image {
  width: 100%;
  overflow: hidden;
  position: relative;
  @include mq-up(lg) {
    margin-left: auto;
    margin-right: 0;
    width: div(1260, 1600) * 100 + 0%;
    border-bottom: 5px solid $color-brand;
  }
}

.english-home-kv_image-list {
  padding-left: 0;
  list-style-type: none;
  position:relative;
  overflow: hidden;
  @include clearfix;
  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.english-home-kv_image-item {
  width: div(100, 4) + 0%;
  float: left;
  & img {
    width: 100%;
  }
  & > div:nth-child(n + 2) {
    display: none;
  }
}

.english-home-kv_writing {
  width: div(593, 750) * 100 + 0%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  @include mq-up(lg) {
    width: 602px;
    bottom: 85px;
  }
}

.english-home-kv_inner {
  position: relative;
  width: 100%;
  height: 100%;
  @include mq-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.english-home-kv_Heading {
  margin-left: auto;
  margin-right: auto;
  padding-top: div(40, 525) * 100 + 0%;
  padding-bottom: div(40, 525) * 100 + 0%;
  width: div(525, 750) * 100 + 0%;
  text-align: center;
  position: relative;
  @include mq-up(lg) {
    padding-top: 0;
    margin-top: div(210, 1600) * 100 + 0%;
    padding-bottom: 0;
    width: 643px;
    position: absolute;
    top: 0;
    left: div(45, 1600) * 100 + 0%;
    z-index: 1;
    // &:before {
    //   display: block;
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(255, 255, 255, 0.8);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    // }
  }
}


.english-home-kv_appearance {
  padding-top: div(165, 750) * 100 + 0%;
  width: 100%;
  height: 0;
  background-image: url('/assets/img/english/index/image_kv_appearance_sp.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: relative;
  @include mq-up(lg) {
    margin-top: 40px;
    padding-top: div(165, 1600) * 100 + 0%;
    background-image: url('/assets/img/english/index/image_kv_appearance_pc.jpg');
  }
}

.english-home-kv_appearance-krp {
  display: none;
  @include mq-up(lg) {
    display: block;
    width: div(167, 1600) * 100 + 0%;
    position: absolute;
    top: 0;
    left: 0;
    top: div(-126, 167) * 100 + 0%;
    left: div(42, 1600) * 100 + 0%;
  }
}

//ABOUT KRP
.english-home-about {
  background-image: url('/assets/img/english/index/bg_about_deco_sp.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  @include mq-up(lg) {
    background-image: url('/assets/img/english/index/bg_about_deco_pc.png');
    background-size: 1482px auto;
  }
}

.english-home-about-emphasis {
  margin-bottom: div(170, 750) * 100 + 0%;
  // padding-top: div(120, 750) * 100 + 0%;
  position: relative;
  @include mq-up(lg) {
    margin-bottom: 100px;
    // padding-top: div(72, 1600) * 100 + 0%;
  }

  &:before {
    content: '';
    position: absolute;
    top: div(80, 750) * 100 + 0%;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("/assets/img/structure/bg_continer-stripe.png");
    background-repeat: repeat;
    background-size: auto;
    background-position: 0 0;
    // z-index: -2;
    @include mq-up(lg) {
      top: 25px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: div(90, 750) * 100 + 0%;
    right: 0;
    width: 120%;
    height: 95%;
    margin: 0 -10%;
    background-color: #be3134;
    transform-origin: right center;
    transform: rotate(-4.4deg);
    // z-index: -1;
    @include mq-up(lg) {
      height: 85%;
      top: - 10px;
    }
  }
}

.english-home-about-emphasis_inner {
  position: relative;
  z-index: 1;
  @include mq-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.english-home-about-emphasis_Head {
  display: block;
  position: relative;
  @include mq-up(lg) {
    // display: inline-block;
    // vertical-align: middle;
    width: div(770, 1170) * 100 + 0%;
  }
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8px;
    left: -8px;
    border: 2px solid #fff;
    @include mq-up(lg) {
    }
  }

  .st-Image {
    position: relative;
  }
}

.english-home-about-emphasis_Body {
  padding-top: div(40, 750) * 100 + 0%;
  display: block;
  color: #fff;
  position: relative;
  @include mq-up(lg) {
    padding-top: 0;
    width: div(370, 1170) * 100 + 0%;
    // padding-left: 35px;
    // display: inline-block;
    // vertical-align: middle;
  }
}


//SUPPORT ACTIVITIES
.english-home-support {
  // background-image: url('/assets/img/english/index/bg_support_deco_sp.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  @include mq-up(lg) {
    background-image: url('/assets/img/english/index/bg_support_deco_pc.png');
    background-size: 1482px auto;
  }
}

.english-home-VisualNavB_Title {
  margin-bottom: 15px;
  font-size: rem(24);
  line-height: 1.4;
  @include mq-up(lg) {
    margin-bottom: 25px;
    font-size: rem(35, lg);
  }
}

.english-home-VisualNavB_Text {
  font-size: rem(15);
  line-height: 1.4;
  @include mq-up(lg) {
    font-size: rem(18, lg);
  }
}



//slick
.english-home-kv {
  .slick-slider
  {
      position: relative;

      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }

  .slick-list
  {
      position: relative;

      display: block;
      overflow: hidden;

      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }

  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;

      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }

  .slick-slide
  {
      display: none;
      float: left;

      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;

      height: auto;

      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
}



.english-home-kv_image-item.slick-initialized {
  & > div:nth-child(n + 2) {
  display: block;
  }
}
