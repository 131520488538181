.st-LabPortal_About {
  text-align: center;
  padding-top: 20px;

  @include mq-up(lg) {
    padding-top: 40px;
  }
}

.st-LabPortal_About_Inner {
  &:hover {
    opacity: 0.7;
  }
}
