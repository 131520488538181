/*
---
name: events-Nav1
namespace: events
category: Navigation
id: L1
---

イベントカテゴリー（CMS管理）で使用されるナビゲーションです。  

- スマホで1カラム、PCから3カラムになります。
- JSで「開始日と終了日」と「概要文」の高さが揃います（同じ列にあるカラムが対象）。
- 「開始日と終了日」がない場合は、「概要文」の中に投稿日を入れます。
- イベントが終了している場合は、「終了」ラベルが追加されます。
- 「開始日と終了日」がない場合は、「概要文」が100%表示になります。
- 縦長や横長の画像はボックスに収まるようにトリミングされます。

```html
<div class="st-Wrapper">
<ul class="events-Nav1_Items">
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="https://placehold.jp/1500x555.png" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="https://placehold.jp/300x640.png" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_LabelEnd">終了</li>
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
            <span class="events-Nav1_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image02.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_LabelEnd">終了</li>
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
            <span class="events-Nav1_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```

`.events-Nav1_Items-wide2`を追加すると、最初の2つの要素が強調されます。

```html
<div class="st-Wrapper">
<ul class="events-Nav1_Items events-Nav1_Items-wide2">
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="https://placehold.jp/1500x555.png" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image02.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_LabelEnd">終了</li>
        <li class="events-Nav1_Label">イベントレポート</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">ブータンで見つけた、幸せのヒント。KRPワンコイン Mixer Session 5</h2>
            <span class="events-Nav1_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image02.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_LabelEnd">終了</li>
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
            <span class="events-Nav1_PostDate">2017/10/16</span>
          </div>
        </div>
      </div>
    </a>
  </li>
  <li class="events-Nav1_Item">
    <a href="#" class="events-Nav1_Block">
      <img src="/assets/img/_events/event/image01.jpg" class="events-Nav1_Image" alt="">
      <ul class="events-Nav1_Labels">
        <li class="events-Nav1_Label">イベント</li>
      </ul>
      <div class="events-Nav1_Body">
        <div class="events-Nav1_BodyInner">
          <div class="events-Nav1_Date">
            <div class="events-Nav1_DateStart">
              10/03 <span class="events-Nav1_DateStartWeekday">(水)</span>
            </div>
            <div class="events-Nav1_To" aria-label="から"></div>
            <div class="events-Nav1_DateEnd">
              10/04 <span class="events-Nav1_DateEndWeekday">(木)</span>
            </div>
          </div>
          <div class="events-Nav1_Description">
            <h2 class="events-Nav1_Heading">セミナー終了後も、創業をバックアップ。第8期スタートアップ・サポートゼミナール</h2>
          </div>
        </div>
      </div>
    </a>
  </li>
</ul>
</div>
```
*/
.events-Nav1_Items {
  display: block;
  margin-left: -15px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(md) {
    margin-left: -30px;
  }
}

.events-Nav1_Item {
  display: inline-block;
  width: 100%;
  padding-left: 15px;
  vertical-align: top;
  font-size: 1rem;

  &:nth-of-type(n+2) {
    margin-top: 25px;
  }

  @include mq-up(md) {
    // Note: 33.33333%ではChromeで隙間ができてしまう。
    width: 33.333333%;
    padding-left: 30px;

    &:nth-of-type(n+2) {
      margin-top: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: 50px;
    }

    .events-Nav1_Items-wide2 &:nth-of-type(1),
    .events-Nav1_Items-wide2 &:nth-of-type(2) {
      width: 50%;
    }
    .events-Nav1_Items-wide2 &:nth-of-type(n+3) {
      margin-top: 50px;
    }
  }
}

.events-Nav1_Block {
  display: block;
  position: relative;
  /* Note: Chromeで小数点以下の差分ができてしまうのを修正 */
  overflow: hidden;
  border: 1px solid #e8e8e8;
  color: $color-text;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: percentage(div(239, 368));
  }

  &:hover {
    opacity: $opacity;
  }
}

.events-Nav1_Image {
  min-width: 100%;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.events-Nav1_Labels {
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 0;
  overflow: hidden;
  text-align: center;
  list-style-type: none;
  font-size: rem(11);

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(13, lg);
  }
}

.events-Nav1_Label {
  float: left;
  padding: 2px 12px;
  line-height: 1.4;
  border: 1px solid #be3134;
  color: #fff;
  background-color: #be3134;
}

.events-Nav1_LabelEnd {
  @extend .events-Nav1_Label;
  color: #be3134;
  background-color: #fff;
}

.events-Nav1_Body {
  position: absolute;
  bottom: 0;
  left: 0;
  /* Note: Chromeで小数点以下の差分ができてしまうのを修正 */
  width: 101%;
}

.events-Nav1_BodyInner {
  display: table;
  width: 100%;
}

.events-Nav1_Date {
  display: table-cell;
  width: percentage(div(150, 570));
  padding: 10px 10px 14px;
  vertical-align: top;
  white-space: nowrap;
  color: #fff;
  background-color: rgba(#000, 0.5);

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    padding: 18px;
  }
}

.events-Nav1_DateStart {
  font-size: rem(22);
  font-weight: bold;

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(30, lg);
  }
}

.events-Nav1_DateStartWeekday {
  font-size: rem(10);
  font-weight: normal;

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(12, lg);
  }
}

.events-Nav1_To {
  margin-bottom: 6px;

  &:before {
    content: "";
    display: block;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.events-Nav1_DateEnd {
  font-size: rem(16);
  font-weight: bold;

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(20, lg);
  }
}

.events-Nav1_DateEndWeekday {
  font-size: rem(10);
  font-weight: normal;

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(12, lg);
  }
}

.events-Nav1_Description {
  display: table-cell;
  width: auto;
  width: 100%;
  padding: 12px 15px;
  vertical-align: top;
  background-color: rgba(#fff, 0.9);

  .events-Nav1_Date + & {
    width: percentage(div(420, 570));
  }

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    padding: 15px 26px;
  }
}

.events-Nav1_Heading {
  font-size: rem(14);
  font-weight: bold;
  line-height: 1.4;

  .events-Nav1_Items-wide2 > .events-Nav1_Item:nth-of-type(-n+2) & {
    font-size: rem(17, lg);
  }
}

.events-Nav1_PostDate {
  display: block;
  margin-top: 5px;
  font-size: rem(13);
  line-height: 1.4;
  color: #999;
}
