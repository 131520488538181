/*
---
name: st-FooterBanner
namespace: Structure
category: Advertising
id: K1
---

グローバルフッターにあるバナーエリアです。

```html
<section class="st-FooterBanner">
  <div class="st-FooterBanner_Inner">
    <h3 class="st-FooterBanner_Heading">Pickup</h3>
    <ul class="st-FooterBanner_Items">
      <li class="st-FooterBanner_Item">
        <a class="st-FooterBanner_Link" href="#">
          <img class="st-FooterBanner_Image" src="http://via.placeholder.com/224x64" alt=""></img>
        </a>
      </li>
      <li class="st-FooterBanner_Item">
        <a class="st-FooterBanner_Link" href="#">
          <img class="st-FooterBanner_Image" src="http://via.placeholder.com/224x64" alt=""></img>
        </a>
      </li>
      <li class="st-FooterBanner_Item">
        <a class="st-FooterBanner_Link" href="#">
          <img class="st-FooterBanner_Image" src="http://via.placeholder.com/224x64" alt=""></img>
        </a>
      </li>
      <li class="st-FooterBanner_Item">
        <a class="st-FooterBanner_Link" href="#">
          <img class="st-FooterBanner_Image" src="http://via.placeholder.com/224x64" alt=""></img>
        </a>
      </li>
      <li class="st-FooterBanner_Item">
        <a class="st-FooterBanner_Link" href="#">
          <img class="st-FooterBanner_Image" src="http://via.placeholder.com/224x64" alt=""></img>
        </a>
      </li>
    </ul>
  </div>
</section>
```
*/
.st-FooterBanner {
  padding: 18px 45px 35px;
  background-color: #f7f7f7;

  @include mq-up(lg) {
    padding: 30px 0 68px;
  }
}

.st-FooterBanner_Inner {
  max-width: $max-width;
  margin: auto;
}

.st-FooterBanner_Heading {
  margin-bottom: 12px;
  font-family: $font-family-heading-en;
  font-size: rem(13);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: $color-brand;
  text-transform: uppercase;

  @include mq-up(lg) {
    margin-bottom: 24px;
    font-size: rem(22, lg);
    text-align: left;
  }
}

.st-FooterBanner_Items {
  display: block;
  margin-left: -8px;
  padding-left: 0;
  list-style-type: none;
  font-size: 0;

  @include mq-up(lg) {
    margin-left: -12px;
  }
}

.st-FooterBanner_Item {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-left: 8px;
  font-size: 1rem;

  &:nth-of-type(n+3) {
    margin-top: 16px;

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  @include mq-up(md) {
    width: percentage(div(1, 3));

    &:nth-of-type(n+4) {
      margin-top: 16px;
    }
  }

  @include mq-up(lg) {
    width: percentage(div(1, 5));
    padding-left: 12px;

    &:nth-of-type(n+4) {
      margin-top: 0;
    }

    &:nth-of-type(n+6) {
      margin-top: 32px;
    }
  }
}

.st-FooterBanner_Link {

}

.st-FooterBanner_Image {

}
