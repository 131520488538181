/*
---
name: sw-FormInput
namespace: SiteWide
category: Form
id: [FO-3, FO-8]
---

<span class="sw-Label">FO-3</span>

1行のテキストエリア`input`です。

```html
<div class="st-Wrapper">
<div class="inquiry-Layout_Item inquiry-Layout_Item-Half">
  <label for="mailAddress" class="sw-FromLabel">メールアドレス<span class="sw-FormLabel_Require">（必須）</span></label>
  <input type="email" placeholder="例) xxxx@xxxxxx.co.jp" class="sw-FormInput" id="mailAddress" required>
</div>

<div class="inquiry-Layout_Item">
  <label for="address" class="sw-FromLabel">ご住所</label>
  <input type="text" placeholder="例）京都市左京区" class="sw-FormInput" id="address" required>
</div>
</div>
```

<span class="sw-Label">FO-8</span>

必須項目が入力されていない時の表示例です。

```html
<div class="st-Wrapper">
<label for="mailAddress1" class="sw-FromLabel">メールアドレス<span class="sw-FormLabel_Require">（必須）</span></label>
<label for="mailAddress1" class="sw-FormInput_Require">メールアドレスは入力必須です</label>
<input type="email" placeholder="例) xxxx@xxxxxx.co.jp" class="sw-FormInput sw-FormInput-error" id="mailAddress1" required>
</div>
```
*/
.sw-FormInput {
  @include sw-FormInput();
}

.sw-FormInput-error {
  background-color: #fceded;
}

.sw-FormInput_Require {
  display: block;
  margin-bottom: 6px;
  font-size: rem(14, lg);
  line-height: 1.4;
  color: #ec0000;
}
