/*
---
name: sw-TitleC
namespace: SiteWide
category: Heading
id: [K2, HD-2C]
---

<span class="sw-Label">HD-2C</span>

- オフィスのジャンルトップ
- 再生医療のトップ
- 980px未満はフルード、980px以上は高さ固定で画像の左右が切れます。1600px以上では再びフルードになります。


```html
<div class="st-Wrapper">
  <div class="sw-TitleC">
    <picture class="sw-TitleC_Image">
      <!--[if IE 9]><video style="display: none;"><![endif]-->
      <source media="(min-width: 980px)" srcset="https://placehold.jp/1600x280.png">
        <!--[if IE 9]></video><![endif]-->
        <img src="https://placehold.jp/750x340.png" alt="alt属性値を指定します。">
    </picture>
    <div class="sw-TitleC_Bg"></div>
    <div class="sw-TitleC_Body">
      <h2 class="sw-TitleC_Heading">PLATFORM<span class="sw-TitleC_Small">プラットフォーム</span></h2>
      <p class="sw-TitleC_Description">京都発の新たな挑戦を支える<br>
      産業×大学×公的機関のシナジーパワー。</p>
    </div>
  </div>
</div>
```
*/
.sw-TitleC {
  position: relative;
}

.sw-TitleC_Image {
  display: block;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);

  & img {
    display: block;
    width: 100%;
    max-width: none;
  }

  @include mq-up(lg) {
    position: relative;
    padding-top: 280px;

    & img {
      position: absolute;
      top: 0;
      left: 50%;
      min-width: 1600px;
      min-height: 280px;
      margin-left: -800px;
    }
  }

  @media print, screen and (min-width: 1600px) {
    padding-top: 0;

    & img {
      position: static;
      min-width: none;
      min-height: auto;
      margin-left: 0;
    }
  }
}

.sw-TitleC_Body {
    padding: 0;
    position: absolute;
    top: 25px;
    right: 0;
    left: 0;
    margin: 0 -20px;
    text-align: center;
    color: #fff;

    @include mq-up(lg) {
      top: 56px;
    }
}

.sw-TitleC_Bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100%;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  background-color: rgba(#000, 0.4);
}

.sw-TitleC_Heading {
  position: relative;
  font-size: rem(24);
  font-family: $font-family-heading-en;
  font-weight: 800;
  line-height: 1.2;

  &:after {
    content: "";
    display: block;
    margin: 4px auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 7px 0 0;
    border-color: currentColor transparent transparent transparent;
  }

  @include mq-up(lg) {
    font-size: rem(32, lg);
    line-height: 1.4;

    &:after {
      margin: 5px auto 0;
      border-width: 10px 14px 0 0;
    }
  }
}

.sw-TitleC_Small {
  display: block;
  margin-top: 5px;
  font-size: rem(10);
  font-weight: bold;
  line-height: 1.4;

  @include mq-up(lg) {
    font-size: rem(15, lg);
  }
}

.sw-TitleC_Description {
  margin-top: 15px;
  font-size: rem(15);
  font-weight: bold;
  line-height: 1.4;

  & br {
    display: none;
  }

  @include mq-up(lg) {
    margin-top: 30px;
    font-size: rem(22, lg);

    & br {
      display: inline;
    }
  }
}


.sw-TitleC {
  .home-TitleC & {
    .sw-TitleC_Image {
      @include mq-up(lg) {
        padding-top: 350px;
      }
      @media print, screen and (min-width: 1600px) {
        padding-top: 0;
      }
    }
  }
}
