.goconc .sw-Button_DownArrow::before {
  content: "\EA0B";
  font-family: iconfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  font-size: 0.9em;
  transform: rotate(90deg);
}

.goconc .sw-Button {
  color: #fff;
  background-color: #be3134;
}
