/*
---
name: sw2-LinkCardA
namespace: SiteWide2
category: Link
---

画像とテキストエリアがリンクになるパーツです。

```html
<div class="st-Wrapper">
  <div class="st-Block">
    <div class="sw2-LinkCardA">
      <div class="sw2-LinkCardA_In">
        <div class="sw2-LinkCardA_ItemWrap">
          <a href="/kaigi/room/breadth360.html" class="sw2-LinkCardA_Link">
            <div class="sw2-LinkCardA_Item">
              <div class="sw2-LinkCardA_ItemImage">
                <picture>
                  <img src="/assets/img/kaigi/index/image_link_card_01.jpg" alt="広さ ～360㎡ 人数 ～350人" loading="lazy">
                </picture>
              </div>
              <div class="sw2-LinkCardA_ItemText">広さ ～360㎡<br>人数 ～350人</div>
            </div>
          </a>
        </div>
        <div class="sw2-LinkCardA_ItemWrap">
          <a href="/kaigi/room/breadth180.html" class="sw2-LinkCardA_Link">
            <div class="sw2-LinkCardA_Item">
              <div class="sw2-LinkCardA_ItemImage">
                <picture>
                  <img src="/assets/img/kaigi/index/image_link_card_02.jpg" alt="広さ ～180㎡ 人数 ～170人" loading="lazy">
                </picture>
              </div>
              <div class="sw2-LinkCardA_ItemText">広さ ～180㎡<br>人数 ～170人</div>
            </div>
          </a>
        </div>
        <div class="sw2-LinkCardA_ItemWrap">
          <a href="/kaigi/room/breadth90.html" class="sw2-LinkCardA_Link">
            <div class="sw2-LinkCardA_Item">
              <div class="sw2-LinkCardA_ItemImage">
                <picture>
                  <img src="/assets/img/kaigi/index/image_link_card_03.jpg" alt="広さ ～90㎡ 人数 ～48人" loading="lazy">
                </picture>
              </div>
              <div class="sw2-LinkCardA_ItemText">広さ ～90㎡<br>人数 ～48人</div>
            </div>
          </a>
        </div>
        <div class="sw2-LinkCardA_ItemWrap">
          <a href="/kaigi/room/breadth30.html" class="sw2-LinkCardA_Link">
            <div class="sw2-LinkCardA_Item">
              <div class="sw2-LinkCardA_ItemImage">
                <picture>
                  <img src="/assets/img/kaigi/index/image_link_card_04.jpg" alt="広さ ～20㎡ 人数 ～16人" loading="lazy">
                </picture>
              </div>
              <div class="sw2-LinkCardA_ItemText">広さ ～20㎡<br>人数 ～16人</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.sw2-LinkCardA {

}
.sw2-LinkCardA_In {
  @include mq-up(lg) {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: rem(-30, lg);
  }
}
.sw2-LinkCardA_ItemWrap {
  margin-top: rem(20);
  @include mq-up(lg) {
    width: 50%;
    padding-left: rem(30, lg);
  }
  @media print, screen and (min-width: $max-width) {
    margin-top: rem(30, lg);
  }
  &:first-child {
    margin-top: 0;
  }
  &:nth-child(2) {
    @include mq-up(lg) {
      margin-top: 0;
    }
  }
}
.sw2-LinkCardA_Link {
  display: block;
  color: $color-krp;
  text-decoration: none;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;

  &:hover {
    opacity: 0.7;
  }
}
.sw2-LinkCardA_Item {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border: 1px solid $color-krp;
  background-color: #fff;
  padding: rem(16) rem(20);
  @include mq-up(lg) {
    padding: rem(20, lg) rem(25, lg);
  }
  @media print, screen and (min-width: $max-width) {
    padding: rem(20, lg) rem(40, lg) rem(20, lg) rem(25, lg);
  }
  &::after {
    @include Icon("icon_arrow-right");
    font-size: rem(11);
    position: absolute;
    top: 50%;
    right: rem(11);
    transform: translate(0, -50%);
    @include mq-up(lg) {
      font-size: rem(15, lg);
      right: rem(15, lg);
    }
  }
}
.sw2-LinkCardA_ItemImage {
  width: rem(103);
  flex-shrink: 0;
  margin-right: rem(20);
  @include mq-up(lg) {
    width: rem(140, lg);
    margin-right: rem(30, lg);
  }
}
.sw2-LinkCardA_ItemText {
  font-weight: 700;
  font-size: rem(15);
  line-height: rem(24);
  @include mq-up(lg) {
    font-size: rem(20, lg);
    line-height: rem(32, lg);
  }
}
