/*
---
name: sw-Hide
namespace: SiteWide
category: Text
---

`<br>`タグに指定をして、改行をブレイクポイントごとにコントロールします。

```html
<p>常に改行<br>されます</p>
<p>ブレイクポイント以降は改行<br class="sw-HideMd">されません</p>
```

スマホのみ非表示することもできます。

```html
<p class="sw-Hide sw-ShowLg">PC以上になると表示されます。</p>
```
*/
.sw-Hide {
  display: none;
}

.sw-HideMd {
  @include mq-up(md) {
    &[class^="sw-Show"] {
      display: none;
    }
  }
}

.sw-HideLg {
  @include mq-up(lg) {
    &[class^="sw-Show"] {
      display: none;
    }
  }
}

.sw-HideXl {
  @include mq-up(xl) {
    &[class^="sw-Show"] {
      display: none;
    }
  }
}
