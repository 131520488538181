/*
---
name: sw-Delimiter
namespace: SiteWide
category: Text
---

途中で改行をさせたくないフレーズやキーワードに指定します。囲まれた範囲（Delimiter）がまとまって改行されます。

```html
<p>テキストは基本的にスペースや役物を基準に改行されますが、<span class="sw-Delimiter">この部分はまとめて改行されます。</span>ここは通常のテキストです。</p>
```
*/
.sw-Delimiter {
  display: inline-block;
}
