.st-googleMap {
  @include mq-up(lg) {
    width: 80%;
    margin: 45px auto 0;
  }
}
.st-googleMap_Wrapper {
  overflow: hidden;
  padding-bottom: 60%;
  position: relative;

  @include mq-up(lg) {
    padding-bottom: 50%;
  }

  & iframe {
    left: 0;
    top: 10px;
    height: 100%;
    width: 100%;
    position: absolute;

    @include mq-up(lg) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}

.st-ContainerD-beige {
  background-color: #ececec;
}

.st-FooterPlatform_LabPortal {
  & img {
    width: 86px;
  }
}

.events-Nav1_Item_LabPortal {
  @include mq-up(md) {
    width: 33.33% !important;
    margin-top: 0 !important;
  }
}
