/*
---
name: sw-Show
namespace: SiteWide
category: Text
---

`<br>`タグに指定をして、改行をブレイクポイントごとにコントロールします。

```html
<p>常に改行<br>されます</p>
<p>ブレイクポイント以降は改行<br class="sw-Hide sw-ShowMd">されます</p>
```

スマホのみ表示することもできます。

```html
<p class="sw-Show sw-HideLg">PC以上になると非表示になります。</p>
```
*/
.sw-Show {
  display: block;
}

.sw-ShowMd {
  @include mq-up(md) {
    &[class^="sw-Hide"] {
      display: block;
    }
  }
}

.sw-ShowLg {
  @include mq-up(lg) {
    &[class^="sw-Hide"] {
      display: block;
    }
  }
}

.sw-ShowXl {
  @include mq-up(xl) {
    &[class^="sw-Hide"] {
      display: block;
    }
  }
}
