/*
---
name: FormSelect
namespace: SiteWide
category: Form
---

`<select>`タグのデフォルトスタイルです。

```html
<div class="sw-FormSelect">
  <select>
    <option value="default">選択してください</option>
    <option value="hokkaido">北海道</option>
    <option value="akita">秋田</option>
  </select>
</div>
<div class="sw-FormSelect is-disabled">
  <select disabled="disabled">
    <option value="default">選択してください</option>
    <option value="hokkaido">北海道</option>
    <option value="akita">秋田</option>
  </select>
</div>
```
*/
.sw-FormSelect {
  @include sw-FormSelect();
}
