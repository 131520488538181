/*
---
name: sw-Divider
namespace: SiteWide
category: Text
---

`<hr>`のような区切り記号を使って、分割・グルーピングします。

```html
<p class="st-Text">テキストです。</p>
<hr class="sw-Divider">
<p class="st-Text">テキストです。</p>
```
*/
.sw-Divider {
  box-sizing: inherit;
  display: block;
  width: 100%;
  height: auto;
  margin: $margin-text auto;
  border: 0;
  border-top: 1px solid #e5e5e5;
}
