/*
---
name: st2-FooterCopyright
namespace: Structure2
category: Global
---

グローバルフッターにあるコピーライトです。
ページトップへのリンクもここで設定しています。

```html
<div class="st2-FooterCopyright">
  <div class="st2-FooterCopyright_Copyright">Copyright(c). All rights reserved by <br>Kyoto Research Park and their respective owners.</div>
  <div class="st2-FooterCopyright_PageTop">
    <a class="st2-FooterCopyright_PageTopLink" href="#">
      <span class="st2-FooterCopyright_PageTopLink_Text">TOP</span>
    </a>
  </div>
</div>
```
*/

.st2-FooterCopyright {
  background-color: $color-krp;
  text-align: center;
  padding: rem(28) 0;
  @include mq-up(lg) {
    padding: rem(20, lg) 0;
  }
}
.st2-FooterCopyright_Copyright {
  color: #fff;
  letter-spacing: 0.1em;
  font-family: $font-family-lato;
  font-weight: 700;
  font-size: rem(10);
  line-height: rem(12);
  @include mq-up(lg) {
    line-height: rem(10, lg);
  }
  br {
    @include mq-up(lg) {
      display: none;
    }
  }
}
.st2-FooterCopyright_PageTop {
  position: fixed;
  bottom: rem(20);
  right: rem(10);
  opacity: 0;
  z-index: 15;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  @include mq-up(lg) {
    bottom: rem(30, lg);
    right: rem(20, lg);
  }

  &.st2-FooterCopyright_PageTop-fixed {
    opacity: 1;
  }
}
.st2-FooterCopyright_PageTopLink {
  display: block;
  width: rem(60);
  height: rem(60);
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  z-index: 5;
  border-radius: 100%;
  position: relative;
  text-decoration: none;
  @include mq-up(lg) {
    width: rem(80, lg);
    height: rem(80, lg);
  }
}
.st2-FooterCopyright_PageTopLink_Text {
  text-align: center;
  display: block;
  font-family: $font-family-lato;
  font-weight: 700;
  font-size: rem(11);
  line-height: rem(11);
  color: $color-krp;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: rem(18, lg);
  }
  &::before {
    @include Icon("icon_arrow-up");
    display: block;
    font-size: rem(12);
    margin-bottom: rem(8);
    @include mq-up(lg) {
      font-size: rem(18, lg);
      margin-bottom: rem(8, lg);
    }
  }
}
