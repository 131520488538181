.goconc .sw-FeatureNavB_Heading {
  @include mq-up(md) {
    text-align: center;
    padding-bottom: 10px;
  }
}

.goconc .sw-FeatureNavB_Heading:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 45%; /*線の上下位置*/
  display: inline-block;
  width: 60px; /*線の長さ*/
  height: 1px; /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: #fff; /*線の色*/

  @include mq-up(md) {
    top: 21%; /*線の上下位置*/
  }
}

.goconc .sw-FeatureNavB_Body .sw-MediaA_Text {
  @include mq-up(md) {
    font-size: 12px;
  }
}
