/*
---
name: st2-Heading2
namespace: Structure2
category: Layout
---

h2相当の見出し自身の余白を指定します。

レイアウト例です。

```html
<div class="st2-ContainerA">
  <div class="st-Wrapper">
    <section class="st-Section2">
      <h2 class="sw2-Heading2A st2-Heading2">「産学公連携拠点・<br>新産業創出拠点」</h2>
      <p class="sw-Text st-Text">京都には、伝統を守りながら革新的なものを創り出す土壌が存在しています。<br>
      そして、多くの大学があり、テクノロジーをベースとした数多くのベンチャー企業があります。<br>
      私たちは、この風土を生かしながら京都発で世界を目指すStartupを育成する拠点としてTech-nology Startup Accelerator『迅』 -HAYATE-を立ち上げました。</p>
      <p class="sw-Text st-Text">ここから世界へ翔くStartupを産み出していきたいと考えております。</p>
    </section>
  </div>
</div>
```
*/
.st2-Heading2 {
  margin-bottom: rem(25);

  @include mq-up(lg) {
    margin-bottom: rem(40, lg);
  }
}
