/*
---
name: st2-LocalNav4
namespace: Structure2
category: Navigation
---

CMSカテゴリ用ナビゲーションです。

```html
<div class="st2-LocalNav4" id="st2-LocalNav4" aria-labelledby="st-PageCategory" aria-hidden="true">
  <ul class="st2-LocalNav4_Items">
    <li class="st2-LocalNav4_Item">
      <a href="#" class="st2-LocalNav4_Link">すべて</a>
    </li>
    <li class="st2-LocalNav4_Item">
      <a href="#" class="st2-LocalNav4_Link">ニュース</a>
    </li>
    <li class="st2-LocalNav4_Item">
      <a href="#" class="st2-LocalNav4_Link">特集記事</a>
    </li>
    <li class="st2-LocalNav4_Item">
      <a href="#" class="st2-LocalNav4_Link">入居企業ニュース</a>
    </li>
    <li class="st2-LocalNav4_Item">
      <a href="#" class="st2-LocalNav4_Link">プレスリリース</a>
    </li>
  </ul>
</div>
```
*/
.st2-LocalNav4 {
}
.st2-LocalNav4_Items {
  margin: 0 0 0 rem(-8);
  padding: 0;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  @include mq-up(lg) {
    margin: 0 0 0 rem(-12, lg);
  }
  [data-krpfes-filter-root] & {
    @include mq-up(lg) {
      justify-content: center;
    }
  }
}
.st2-LocalNav4_Item {
  margin: rem(8) 0 0;
  padding: 0 0 0 rem(8);
  list-style: none;
  @include mq-up(lg) {
    margin: rem(12, lg) 0 0;
    padding: 0 0 0 rem(12, lg);
  }
}
.st2-LocalNav4_Link {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: rem(11);
  line-height: rem(18);
  color: #686F73;
  padding: rem(6) rem(12);
  white-space: nowrap;
  background: #EDEFF0;
  border-radius: 100px;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  @include mq-up(lg) {
    font-size: rem(13, lg);
    line-height: rem(18, lg);
    padding: rem(10, lg) rem(16, lg);
  }
  &:hover {
    @include mq-up(lg) {
      background: #C5383B;
      color: #fff;
    }
  }
  &.-is-active {
    background: #C5383B;
    color: #fff;
  }
}
